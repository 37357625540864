export default {
  datepicker: {
    label: 'Select Period',
    presets: {
      yesterday: 'Yesterday',
      week: 'Last 7 Days',
      twoWeeks: 'Last 14 Days',
      month: 'Last 30 Days',
    },
  },
  popups: {
    pickFreeBet: {
      title: 'Available Free Bets',
    },
    declineBonusConfirm: {
      title: 'Decline Bonus',
      message: 'Are your sure you want to decline <strong>{bonusName}</strong>?',
    },
    terminateConfirm: {
      title: 'Terminate Session',
      message: 'Are you sure you want to terminate this session?',
      button: 'Terminate',
      titleAll: 'Terminate All Sessions',
      messageAll:
        'Are you sure you want to terminate all the sessions except the existing one?',
      buttonAll: 'Terminate All',
    },
    wallets: {
      title: 'Select Wallet',
    },
    betFilter: {
      title: 'Filter',
      events: {
        title: 'Events',
      },
      betType: {
        title: 'Bet Type',
      },
      status: {
        title: 'Status',
      },
    },
    transactionsFilter: {
      title: 'Filter',
      type: 'Transaction Type',
      status: 'Status',
    },
    confirm: {
      cancel: 'Cancel',
      confirm: 'Confirm',
      load: 'Load',
      copy: 'Copy',
      ok: 'Ok',
    },
    confirmation: {
      sendCode: 'Send Code',
      email: {
        title: 'Email Confirmation',
        provideInfo:
          '1. Provide your Email to unlock all the features and increase security!',
        confirm:
          '2. Enter the confirmation code from the Email sent to your address',
        confirmPlaceholder: 'Code from Email',
        successAlertMessage: 'E-mail was confirmed successfully',
      },
      phone: {
        title: 'Phone Confirmation',
        provideInfo:
          '1. Provide your Phone to unlock all the features and increase security!',
        confirm:
          '2. Enter the confirmation code from the SMS sent to your phone number',
        confirmPlaceholder: 'Code from SMS',
        successAlertMessage: 'Your Phone was confirmed successfully',
      },
      confirmationCodeLabel: 'Confirmation Code',
      emailLabel: 'Email',
      emailPlaceholder: 'Your Email',
      phoneActivateText1: 'Your phone number is',
      emailActivateText1: 'Your email is',
      activateText: 'To change it without confirmation contact support',
    },
    confirmationSignup: {
      title: 'Complete Sign Up',
      sendAgain: 'Send Code Again',
      label: 'Confirmation Code',
      back: 'Back',
      email: {
        placeholder: 'Code from Email',
        alertMessage:
          'Your sign up is now complete and you can log in using credentials which were sent via e-mail.',
        text1: 'To complete sign up please confirm your Email',
        text2:
          'Enter the confirmation code from the Email sent to your address',
      },
      phone: {
        placeholder: 'Code from SMS',
        alertMessage:
          'Your sign up is now complete and you can log in using credentials which were sent via SMS.',
        text1: 'To complete sign up please confirm your phone number',
        text2:
          'Enter the confirmation code from the SMS sent to your phone number',
      },
    },
    regComplete: {
      title: 'Registration Successfully Completed',
      text: 'Be sure to save your login and password',
      user: 'User ID',
      password: 'Password',
      copyCredentials: 'ID: {userId}\nPassword: {password}',
    },
    confirmationTfa: {
      text1:
        '2FA was turned on successfully. Please save your backup codes in case of emergency',
      text2: 'Please keep it in the safe place and do not share with anyone.',
    },
    common2FA: {
      title: 'Two Factor Authentication',
    },
    loadBetslip: {
      title: 'Load Bet Slip',
      text: 'Enter the bet slip code to load the events',
      label: 'Bet Slip Code',
      placeholder: 'Bet Slip Code',
      help: 'After bet slip is loaded, all events will be replaced',
    },
    shareBetslip: {
      title: 'Your Bet Slip is Saved',
      text: 'Share this code',
    },
    betslipSettings: {
      title: 'Bet Slip Settings',
      oddsChange: {
        title: 'Odds Change',
        always: 'Always ask',
        rise: 'Accept only if it’s raise',
        any: 'Accept any',
      },
      fastBets: 'Fast Bets',
      fastBet: 'Fast Bet',
    },
    unlinkPhone: {
      title: 'Unlink Phone Number',
      text: 'Enter the confirmation code from the SMS sent to your current phone number',
      alertMessage: 'Your Phone number was successfully unlinked',
      confirmPlaceholder: 'Code from SMS',
      confirmLabel: 'Confirmation Code',
    },
    resultsFilter: {
      title: 'Filter',
    },
    contacts: {
      title: 'Contacts',
    },
    timeRangeFilter: {
      title: 'Kick-off Time In',
      selectList: {
        all: 'All Time',
        hour1: '1 Hour',
        hour3: '3 Hours',
        hour12: '12 Hours',
        day1: '1 Day',
        day2: '2 Days',
      },
    },
    statistics: {
      title: 'Statistics',
      noStatistics: 'There is no data on this event',
    },
  },
  market: {
    top: 'top',
    eventLive: 'Live',
    eventSport: 'Sports',
    topEvents: 'Top Events',
    favorites: 'Favorites',
    period: 'Period',
    day: {
      today: 'Today',
      tomorrow: 'Tomorrow',
    },
    topType: {
      sportsType: 'Top Sports',
      live: 'Top Live',
    },
    type: {
      line: 'Sports',
      live: 'Live',
    },
    card: {
      period: 'Period',
      emptyList: 'No Available Markets',
      emptySearch: 'Nothing was found for your query',
      broadcast: 'Broadcast',
      streamAuth: '{login} or {register} to watch the broadcast',
    },
    noMatches: 'No matches for selected Kick-off Time In criteria',
  },
  casino: {
    title: 'Casino',
    searchTitle: 'Search Results',
    providers: 'Providers',
    viewAll: 'View All',
    categories: 'Categories',
    emptyGames: 'No Available Games',
    emptyProviders: 'No Available Providers',
    providersTitle: 'Casino Providers',
    casinoCategories: 'Casino Categories',
    playGame: 'Play',
    playDemoGame: 'Free Demo',
    generalError: 'An error occurred during launching the game',
    card: {
      auth: 'Please Log In',
    },
    subsection: {
      casino: {
        title: 'Casino',
        providers: 'Casino Providers',
        categories: 'Casino Categories',
      },
      liveCasino: {
        title: 'Live Casino',
        providers: 'Live Casino Providers',
        categories: 'Live Casino Categories',
      },
    },
    types: {
      all: {
        label: 'All',
        title: {
          casino: 'All Casino',
          liveCasino: 'All Live Casino',
        },
      },
      top: {
        label: 'Top',
        title: {
          casino: 'Top Casino',
          liveCasino: 'Top Live Casino',
        },
      },
      new: {
        label: 'New',
        title: {
          casino: 'New Casino',
          liveCasino: 'New Live Casino',
        },
      },
    },
  },
  dictionaries: {
    countries: {
      label: 'Country',
      placeholder: 'Select Country',
    },
    currencies: {
      label: 'Currency',
      placeholder: 'Select Currency',
    },
  },
  buttons: {
    delete: 'Delete',
    login: 'Log In',
    logout: 'Log Out',
    signUp: 'Sign Up',
    register: 'Register',
    deposit: 'Deposit',
    withdrawal: 'Withdrawal',
    profile: 'Profile',
    support: 'Support',
    contacts: 'Contacts',
    cancel: 'Cancel',
    confirm: 'Confirm',
    load: 'Load',
    copy: 'Copy',
    saveAsFile: 'Save as File',
    close: 'Close',
    ok: 'Ok',
    turnOff: 'Turn Off',
    enable2FA: 'Enable 2FA',
    gotIt: 'Got It',
    confirmPhone: 'Confirm Phone',
    confirmEmail: 'Confirm Email',
    changePassword: 'Change Password',
    sendCode: 'Send Code',
    resendCode: 'Resend Code',
    sendCodeAgain: 'Send Code Again',
    sendRecoveryCode: 'Send Recovery Code',
    promotions: 'Promotions',
    requestACall: 'Request a Call',
    edit: 'Edit',
    save: 'Save',
    continue: 'Continue',
    checkCode: 'Check Code',
    reset: 'Reset',
    select: 'Select',
    terminate: 'Terminate',
    terminateAll: 'Terminate All',
    showMore: 'Show More',
    verify: 'Verify',
    favorites: 'Favorites',
    tryAgain: 'Try Again',
    decline: 'Decline',
  },
  header: {
    menu: {
      top: 'Top',
      live: 'Live',
      line: 'Sports',
      results: 'Results',
      promotions: 'Promotions',
      search: 'Search',
      casino: 'Casino',
      liveCasino: 'Live Casino',
    },
    settings: {
      mobileApps: 'Mobile Apps',
      promotions: 'Promotions',
    },
  },
  footer: {
    language: 'Language',
    timezone: 'Timezone',
    copyright: 'Copyright © 2021 - {date} «ComboPlay»',
    rightsReserved: 'All rights reserved and protected by law.',
    information: {
      termsAndConditions: 'General Terms and Conditions',
      responsibleGamingPolicy: 'Responsible Gaming Policy',
      selfExclusionPolicy: 'Self-Exclusion Policy',
      underagePolicy: 'Underage Policy (18+)',
      AMLPolicy: 'AML Policy',
      KYCPolicy: 'KYC Policy',
      termsAndRules: 'Terms & Rules',
    },
    techInfo: 'Version: {version}; Geo: {geo} {country}',
    licence: "ComboPlay® operates with the licence of MAG Group N.V.(MAG Group N.V., Reg No. 155387, Abraham de Veerstraat 9 Curaçao.) which is licensed by the government of Curacao under the <a class='footer__licence-link' target=\"_blank\" href='{licence_link}'>License 365/JAZ Sub-License GLH-OCCHKTW0711112021</a> issued for the provision of sports betting and casino. TYKWER LIMITED (Registration number: HE420239, Address: Spyrou Kyprianou 84, Limassol, Cyprus, 4004) is payment agent of Comboplay® and MAG Group N.V. ComboPlay® is a registered trade mark of GLANOLD LIMITED operated by MAG GROUP N.V. All other derivative marks are registered trademarks of GLANOLD LIMITED. Any and all content on the website (desktop and mobile), including sports odds, are proprietary and may not be copied or disseminated without the express written consent of MAG GROUP N.V.. All Rights Reserved, 2021–{date}.",
    captcha:
      "This site is protected by reCAPTCHA and the Google <a class='footer__licence-link' target=\"_blank\"  href='https://policies.google.com/privacy'>Privacy Policy</a> and <a class='footer__licence-link' target=\"_blank\"  href='https://policies.google.com/terms'>Terms of Service</a> apply.",
  },
  auth: {
    login: {
      title: 'Log In',
      tab: {
        email: 'Email or ID',
        phone: 'By Phone',
      },
      forgotPassword: 'Forgot Your Password?',
      rememberMe: 'Remember Me',
      password: 'Password',
      emailOrId: 'Email or ID',
      emailOrIdPlaceholder: 'Your Email or User ID',
    },
    logout: {
      title: 'Log Out',
      message: 'Are you sure you want to log out?',
    },
    newPassword: {
      title: 'Change Password',
      tfaText: 'Enter the 2FA code from the Authenticator App',
      passwordHelp:
        'Password must contain At least 6 characters and three character types: uppercase letters, lowercase letters, and numbers.',
      codeLabel: '2FA Code',
      codePlaceholder: '2FA Code',
      oldPasswordLabel: 'Old Password',
      newPasswordLabel: 'New Password',
      newPasswordConfirmationLabel: 'Repeat Password',
      success: 'Password was changed',
    },
    passwordRecovery: {
      title: 'Password Recovery',
      tab: {
        email: 'By Email',
        phone: 'By Phone',
      },
      email: {
        step1: '1. We will send the recovery code to your E-Mail',
        step2:
          '2. Enter the confirmation code from the Email sent to your address',
        success: 'We sent you a new password to your email',
        label: 'Email',
        placeholder: 'Your Email',
      },
      phone: {
        step1: '1. We will send the recovery code by SMS',
        step2: '2. Enter the confirmation code from the SMS sent to your phone',
        success: 'We sent you a new password to your phone',
      },
    },
    signUp: {
      title: 'Sign Up',
      agreeText:
        'I agree with the Terms and Conditions and Privacy Policy of the company and confirm that I am of legal age. This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.',
      resendCode: 'Send Code Again: {time}',
      codeExpired: 'Code is expired',
      addPromocode: 'Add Promo Code',
      tab: {
        oneClick: 'One - Click',
        email: 'By Email',
        phone: 'By Phone',
      },
    },
  },
  profile: {
    title: 'Profile',
    id: 'ID',
    info: {
      personal: 'Personal',
      category: {
        general: 'General',
        geo: 'GEO',
        contacts: 'Contacts',
        identification: 'Identification Data',
        other: 'Other',
      },
      notProvided: 'Not Provided',
      unlinkPhone: 'Unlink Phone',
      titleMobile: 'My Profile',
      titleEditMobile: 'Edit My Profile',
      alertCancel: {
        title: 'Unsaved Data',
        message:
          'Do you really want to undo the changes? The data will not be saved.',
      },
    },
    menu: {
      info: 'My Profile',
      deposit: 'Deposit',
      withdrawal: 'Withdrawal',
      '2fa': '2FA Setup',
      newPassword: 'Change Password',
      sessions: 'Active Sessions',
      history: 'Bets & Transactions',
      contacts: 'Contacts & Support',
      contactsMobile: 'Contacts',
      results: 'Results',
      logout: 'Log Out',
      wallet: 'Wallets',
      bonuses: 'Bonuses',
    },
    notConfirmedSubject: {
      email: 'Your Email was not confirmed yet.',
      phone: 'Your Phone was not confirmed yet.',
      both: 'Your Email and Phone was not confirmed yet.',
    },
    notConfirmedMessage:
      'Please verify it to unlock all the features and increase security!',
    wallet: {
      title: 'Wallets',
      mainWallet: 'Main Wallet',
      newWallet: 'New Wallet',
      text: 'Selected wallet will be used for all transactions by default',
      deleteConfirm: {
        title: 'Delete Wallet',
        message:
          'Are you sure you want to delete <strong>{walletName}</strong> wallet?',
        confirmTitle: 'Delete',
      },
    },
    bonus: {
      title: 'Choose Your Bonus',
      refuse: 'Refuse',
      sport: 'Sport',
      casino: 'Casino',
      notify: 'Data saved successfully.',
      popup: 'Choose Your Bonus',
    },
    bonuses: {
      title: 'Bonuses',
      promocodes: {
        title: 'Promo Codes',
        text: 'Check Promo Code and receive bonuses',
        placeholder: 'Enter Promo Code',
        success: 'Promo code successfully activated'
      },
    },
    bonusHistory: {
      title: 'Bonus History',
      empty: 'There is no bonus history!',
      sports: 'Sports',
      casino: 'Casino',
      expired: 'Expired',
      rows: {
        balance: 'Balance',
        wagering: 'Wagering',
        freespins: 'Free Spins',
        games: 'Games',
        amount: 'Amount',
        betType: 'Bet Type',
        minEvents: 'Min. Events',
        minOdds: 'Min. Odds',
        provider: 'Provider',
      },
    },
    bonusWallet: {
      title: 'Bonus Wallet',
      balance: 'Balance',
      wagering: 'Wagering',
      expired: 'Bonus has expired',
      timer: {
        days: '0 Days | 1 Day | {count} Days | {count} Days',
      },
      deleteConfirm: {
        title: 'Refuse Bonus',
        message: 'Are you sure you want to refuse bonus?',
        confirmTitle: 'Refuse',
      },
    },
    all: 'All',
    bets: {
      basket: {
        betSlip: 'Bet Slip',
        openBets: 'Open Bets',
      },
      datepickerPlaceholder: 'All Time',
      empty: 'There ara no bets yet, start winning now!',
      emptyFilter: 'No results were found for these criteria',
      emptyButton: 'Start',
      id: 'ID: {id}',
      details: 'Bet Details',
      tabs: {
        bets: 'Bets',
        transactions: 'Transactions',
      },
      type: {
        all: 'All',
        single: 'Single Bet',
        accumulator: 'Accumulator',
      },
      status: {
        inGame: 'In Game',
        lost: 'Loss',
        won: 'Win',
        canceled: 'Canceled',
      },
      events: {
        all: 'All',
        line: 'Sport',
        live: 'Live',
      },
    },
    transactions: {
      empty:
        'There are no operations yet, deposit something and start winning!',
      emptyButton: 'Deposit',
      info: 'Receipt of e-payment in the amount of {amount} {code}',
      status: {
        succeeded: 'Succeeded',
        pending: 'Pending',
        waiting: 'Waiting',
        sent: 'Sent',
        canceled: 'Canceled',
        refunded: 'Refunded',
      },
      type: {
        deposit: 'Deposit',
        withdrawal: 'Withdrawal',
      },
      nonAuthorized: 'Log in to see the history of your bets & transactions',
    },
    '2fa': {
      panel: {
        title: 'Two Factor Authentication',
        enabled: {
          title: 'Enabled',
          subtitle: 'Two-factor authentication is enabled',
          hint: 'When logging in from an unfamiliar device, in addition<br>to the password, we will ask for a login code.',
        },
        disabled: {
          title: 'Disabled',
          subtitle: 'Two-factor authentication is disabled',
          hint: 'Increase security of your account by adding 2FA auth.<br>When logging in from an unfamiliar device, in addition<br>to the password, we will ask for a login code.',
        },
      },
      setup2FAPanel: {
        step1: '1. Install Google Authenticator App',
        step2_desk: '2. Scan QR Code using Authenticator App or enter this secret key:',
        step2_mob: '2. Link your account to Authenticator App',
        step22: 'Or enter this secret key in Authenticator App manually',
        step3: '3. Input 2FA Code from Authenticator App',
        confirmPlaceholder: '2FA Code',
        confirmLabel: '2FA Code',
        link: 'Link Account in Authenticator App',
      },
      disable2FAPanel: {
        step1: 'To turn off 2FA please input your 2FA code',
        step2: 'If you’ve lost your key — please contact support service.',
        codePlaceholder: '2FA Code',
        codeLabel: '2FA Code',
      },
      check2FAPanel: {
        text1:
          'Confirm login from a new device or a new location. Please enter 2FA code from Google Authenticator App',
        codePlaceholder: '2FA Code',
        codeLabel: '2FA Code',
      },
      activate2faConfirm: {
        title: 'Activate 2fa',
        message: 'Are you sure you want to activate 2fa?',
        confirmTitle: 'Activate',
      },
    },
    deposit: {
      title: 'Deposit',
    },
    withdrawal: {
      title: 'Withdrawal',
    },
    result: {
      title: 'Payment Request',
      text: {
        success: 'Payment Request was created succesfully',
        fail: 'Payment Request was failed',
        waiting: 'Payment Request in waiting',
      },
      button: 'Go to Main Page',
    },
    sessions: {
      title: 'Active Sessions',
      current: 'Current Session',
      active: 'Active Sessions',
      terminateAll: 'Terminate All Other Sessions',
      unknown: 'Unknown Device',
    },
    contacts: {
      title: 'Contacts',
      text: 'You can contact us in several ways',
      generalQueries: 'General Queries'
    }
  },
  promotions: {
    title: 'Promotions',
  },
  betSlip: {
    bets: {
      empty: 'No Open Bets',
      login: 'Log in to see the history of your bets',
      moreBets: 'Show All Bets',
    },
    slip: {
      title: 'Bet Slip',
      login: 'Log In to Place a Bet',
      placeBet: 'Place a Bet',
      acceptOdd: 'Accept Odd',
      possibleWin: 'Possible Win:',
      loading: 'Bet check in progress…',
      freeBetLoading: 'Checking Free Bets...',
      totalOdd: 'Total Odd, {count} Events',
      amountPlaceholder: 'Bet Amount',
      limits: 'From {symbol}\u202f{valueMin} to {symbol}\u202f{valueMax}',
      limitEvents: 'The limit is {maxCoupons} events',
      empty: {
        title: 'Coupon is empty',
        text: 'To select a bet, click on any odds.',
      },
      item: {
        accept: 'Accept Changes',
        closed: 'Betting on this event has temporarily been closed',
        deleted: 'Event was deleted',
      },
    },
    clearBetslip: {
      title: 'Clear Bet Slip',
      message: 'Are you sure you want to clear bet slip?',
      confirmTitle: 'Clear All',
    },
    successNotify: 'Your bet was placed',
  },
  ui: {
    noData: 'No matching data',
    phoneLabel: 'Phone',
    emailPlaceholder: 'Email',
    passwordPlaceholder: '********',
    maintenance: 'Maintenance in Progress',
    blockGeo: 'ComboPlay is not available in your country.',
    search: 'Search',
    copied: 'Copied',
    freebet: 'Free Bet',
    freebet_available: 'Free Bet Available',
    themes: {
      dark: 'Light Theme',
      light: 'Dark Theme',
    },
  },
  tech: {
    network: {
      title: 'No Сonnection',
      text: 'Check your network settings and try again',
    },
    construction: {
      title: 'Keep Calm',
      text: 'This section is under construction',
      button: 'Go to Main Page',
    },
    badRequest: {
      title: 'Oops, Something Went Wrong',
      text: 'Please Refresh The Page',
      button: 'Refresh',
    },
    notFound: {
      title: '404',
      text: 'Oops, Page Not Found',
      button: 'Go to Main Page',
    },
  },
  sportsType: {
    top: 'Top Tournaments',
    countries: 'COUNTRIES',
    tournaments: 'Tournaments',
  },
  search: {
    title: 'Search',
    emptyInput: 'To search enter at least 3 letters',
    emptyList: 'No results found. Try another search',
    error: 'Oops, Something Went Wrong. Try another search',

    live: 'Live',
    line: 'Upcoming',
  },
  favorites: {
    emptyList: 'Add events of your interest and follow them from this section',
    error: 'Oops, Something Went Wrong.',
    live: 'Live',
    line: 'Upcoming',
  },
  result: {
    title: 'Results',
    datepickerPlaceholder: 'Today',
    period: {
      all: 'All',
      past: 'Past',
      live: 'Live',
    },
    noStatistics: 'There are no details on this event',
    search: {
      emptyList: 'There are no matching results on your search request',
      error: 'Oops, Something Went Wrong. Try another search parameters',
    },
  },
  mobileApp: {
    title: 'Stay in the game with <br><strong>ComboPlay Android App!</strong>',
    titleMobile: 'Stay in the game with <strong>ComboPlay Android App!</strong>',
    pageTitle: 'ComboPlay App',
    text: 'Download the latest version of ComboPlay App now.',
    info: 'We support Android 6.0 and higher. Also make sure that you allow to install applications from unknown sources.',
    button: 'Download APK',
    banner: {
      title: 'ComboPlay App',
      subtitle: 'Download the latest version',
      link: 'Download',
    },
    navigation: {
      title: 'ComboPlay App',
      subtitle: 'Download the latest version',
    },
  },
  mobileMenu: {
    top: 'Top',
    sport: 'Sport',
    betSlip: 'Bet Slip',
    bets: 'Bets',
    casino: 'Casino',
    more: 'More',
  },
};
