<template>
  <Popup
    class="confirm-popup"
    :title="title"
    :is-visible.sync="isVisible"
    :close-status="loading ? 'disabled' : 'enabled'"
    @close="handleClose"
  >
    <div
      :class="[
        'confirm-popup__body',
        { 'confirm-popup__body_has-icon': bodyIcon },
      ]"
      slot="body"
    >
      <slot>
        <UiIcon
          v-if="bodyIcon"
          :size="64"
          :color="bodyIconColor"
          :name="bodyIcon"
          lib="fa"
          substyle="fas"
          class="mb-2xl"
        />
        <div class="confirm-popup__message" v-html="message"></div>
      </slot>
    </div>
    <div
      slot="footer"
      class="flex space-x-lg"
      :class="{
        'justify-center': pLayout.length === 1,
      }"
    >
      <UiButton
        v-if="layoutIndex.confirm"
        key="confirm"
        class="w-1/2"
        substyle="fas"
        :icon="cancelIcon"
        type="secondary"
        :disabled="loading"
        @click="cancel"
      >
        {{ cancelTitle || $t('buttons.cancel') }}
      </UiButton>
      <UiButton
        v-if="layoutIndex.cancel"
        key="cancel"
        class="w-1/2"
        substyle="fas"
        :icon="confirmIcon"
        :disabled="loading"
        @click="confirm"
      >
        {{ confirmTitle || $t('buttons.confirm') }}
      </UiButton>
    </div>
  </Popup>
</template>

<script>
export default {
  name: 'Confirm',

  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: null,
    },
    cancelTitle: {
      type: String,
      default: '',
    },
    cancelIcon: {
      type: String,
      default: 'times',
    },
    confirmTitle: {
      type: String,
      default: '',
    },
    confirmIcon: {
      type: String,
      default: 'trash-alt',
    },
    layout: {
      type: String,
      default: 'confirm|cancel',
    },
    keepAfterConfirm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isVisible: false,
      loading: false,
    };
  },

  computed: {
    pLayout() {
      return this.layout.split('|');
    },
    layoutIndex() {
      const result = {};
      this.pLayout.forEach((item) => {
        result[item] = true;
      });
      return result;
    },
    bodyIcon() {
      switch (this.type) {
        case 'success':
          return 'check-circle';
        case 'error':
          return 'times-circle';
        case 'warning':
          return 'exclamation-circle';
        default:
          return '';
      }
    },
    bodyIconColor() {
      switch (this.type) {
        case 'success':
          return this.$config.cp.success;
        case 'error':
          return this.$config.cp['text-error'];
        case 'warning':
          return this.$config.cp.alert;
        default:
          return '';
      }
    },
  },

  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },

  methods: {
    open() {
      this.isVisible = true;
    },

    confirm() {
      this.$emit('confirm', this);
      if (!this.keepAfterConfirm) {
        this.isVisible = false;
      }
    },

    cancel() {
      this.$emit('cancel', this);
      this.isVisible = false;
    },

    handleClose() {
      this.cancel();
    },
  },
};
</script>

<style lang="scss">
.confirm-popup {
  &__body {
    &_has-icon {
      padding-top: 16px;
      padding-bottom: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__body_has-icon &__message {
    text-align: center;
  }
}
</style>
