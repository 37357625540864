<template>
  <Popup
    :title="$t('popups.common2FA.title')"
    :is-visible.sync="isVisible"
    @close="close(true)"
  >
    <Check2FAPanel
      slot="body"
      :loading="loading"
      :form="form"
      :errors="errors"
    />
    <div slot="body"></div>

    <template slot="footer">
      <UiButton
        class="mb-sm"
        substyle="fas"
        icon="check"
        :disabled="!isValid || loading || !initialized"
        @click="handleConfirm"
      >
        {{ $t('buttons.confirm') }}
      </UiButton>
    </template>
  </Popup>
</template>

<script>
import logic from '@/views/Auth/components/Check2FA/logic';
import Check2FAPanel from '@/views/Auth/components/Check2FA/Check2FAPanel.vue';
import { AUTH_LOGOUT } from '@/store/action_types/auth';

export default {
  name: 'Check2FA',
  components: { Check2FAPanel },
  mixins: [logic],

  data() {
    return {
      isVisible: false,
      initialized: false,
    };
  },

  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },

  methods: {
    async open() {
      this.isVisible = true;
      this.loading = true;
      this.initialized = false;
      try {
        await this.init();
      } finally {
        this.loading = false;
        this.initialized = true;
      }
    },

    async close(isLogout = false) {
      if (isLogout) {
        await this.$store.dispatch(`auth/${AUTH_LOGOUT}`);
        this.$eventBus.$emit('auth-fail', {
          reason: 'abort',
          error: null,
        });
      }
      this.isVisible = false;
    },
  },
};
</script>
