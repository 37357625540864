<template>
  <div class="ui-title" @click="clickHandler">
    <UiIcon
      v-if="backRoute !== ''"
      :size="16"
      name="chevron-left"
      lib="fa"
      substyle="fas"
      class="mr-sm"
    />
    <div class="ui-title__text">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiTitle',

  props: {
    backRoute: {
      type: String,
      default() {
        return '';
      },
    },
  },

  methods: {
    clickHandler(event) {
      if (this.backRoute) {
        this.$router.push(this.backRoute);
      }

      this.$emit('click', event);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';

.ui-title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  &__text {
    font-size: 20px;
    font-weight: 700;
    color: var(--color-text);
  }
}

@media (max-width: $screen-mobile) {
  .ui-title {
    display: inline-flex;
  }
}
</style>
