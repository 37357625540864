<template>
  <Popup
    class="new-password-popup"
    :title="$t('auth.newPassword.title')"
    :is-visible.sync="isVisible"
  >
    <NewPasswordPanel
      slot="body"
      ref="newPasswordPanel"
      :form="form"
      :errors="errors"
      :is2-f-a-enabled="is2FAEnabled"
      @isValid="isValid = $event"
    />
    <UiButton
      slot="footer"
      substyle="fas"
      icon="check"
      :disabled="!isValid || isClicked"
      :loading="loading"
      @click="changePassword"
    >
      {{ $t('buttons.changePassword') }}
    </UiButton>
  </Popup>
</template>

<script>
import logic from '@/views/Auth/components/NewPassword/logic';
import NewPasswordPanel from '@/views/Auth/components/NewPassword/NewPasswordPanel.vue';

export default {
  components: { NewPasswordPanel },

  mixins: [logic],

  data() {
    return {
      isVisible: false,
    };
  },
  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },
  methods: {
    open() {
      this.init();
      this.isVisible = true;
    },
    handleComplete() {
      this.close();
    },
    close() {
      this.isVisible = false;
    },
  },
};
</script>
