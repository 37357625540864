import Layout from '@/components/layout/Layout/selector.js';
import Deposit from '@/views/Profile/Payments/Deposit/selector.js';
import Methods from '@/views/Profile/Payments/Methods';
import Withdraw from '@/views/Profile/Payments/Withdrawal/selector.js';
import Result from '@/views/Profile/Payments/Result';
import ProfileLayout from '@/views/Profile/Profile/selector.js';
import ProfileInfo from '@/views/Profile/ProfileInfo/selector.js';
import ProfileHistory from '@/views/Profile/ProfileHistory/selector.js';
import ProfileHistoryInfo from '@/views/Profile/ProfileHistoryInfo';
import ProfileContacts from '@/views/Profile/ProfileContacts/selector.js';
import ProfileList from '@/views/Profile/ProfileList';
import ProfileWallets from '@/views/Profile/ProfileWallets.vue';
import ProfileBonuses from '@/views/Profile/ProfileBonuses/ProfileBonuses.vue';
import Profile2FA from '@/views/Profile/Profile2FA/selector';
import ProfileSessions from '@/views/Profile/ProfileSessions/ProfileSessions';

import multiguard from 'vue-router-multiguard';
// import customGuard from '@/router/guards/customGuard.js';
import authGuard /*,{ authGuardCondition }*/ from '@/router/guards/authGuard.js';
import mobileGuard, {
  mobileGuardCondition,
} from '@/router/guards/mobileGuard.js';

export default [
  {
    path: '',
    name: 'profile.root',
    component: Layout,
    children: [
      {
        path: '/profile',
        name: 'profile',
        component: ProfileLayout,
        meta: {
          view: 'profile',
        },
        children: [
          {
            path: 'info',
            component: ProfileInfo,
            meta: {
              view: 'info',
              layoutFullHeight: true,
            },
            beforeEnter: multiguard([authGuard(true)]),
          },
          {
            path: 'history',
            component: ProfileHistory,
            name: 'history',
            meta: {
              view: 'history',
              layoutFullHeight: true,
              hideFooter: mobileGuardCondition(true),
            },
            beforeEnter: multiguard([authGuard(true)]),
            // beforeEnter: customGuard(() => {
            //   const desktopAuthorized = authGuardCondition(true) && mobileGuardCondition(false);
            //   const mobileAnyway = mobileGuardCondition(true);
            //   const route = !(desktopAuthorized || mobileAnyway) && '/';
            //   return route;
            // }),
          },
          {
            path: 'history/:id',
            component: ProfileHistoryInfo,
            name: 'history-info',
            meta: {
              view: 'history',
              layoutFullHeight: true,
            },
            beforeEnter: multiguard([authGuard(true), mobileGuard(true)]),
          },
          {
            path: 'contacts',
            component: ProfileContacts,
            meta: {
              view: 'contacts',
              layoutFullHeight: true,
            },
            beforeEnter: multiguard([]),
          },
          {
            path: 'wallets',
            component: ProfileWallets,
            meta: {
              view: 'wallets',
              layoutFullHeight: true,
            },
            beforeEnter: multiguard([authGuard(true), mobileGuard(true)]),
          },
          {
            path: '2fa',
            component: Profile2FA,
            meta: {
              view: '2fa',
              layoutFullHeight: true,
            },
            beforeEnter: multiguard([authGuard(true)]),
          },
          {
            path: 'bonuses',
            component: ProfileBonuses,
            meta: {
              view: 'bonuses',
              layoutFullHeight: true,
            },
            beforeEnter: multiguard([authGuard(true)]),
          },
          {
            path: 'sessions',
            component: ProfileSessions,
            meta: {
              view: 'sessions',
              layoutFullHeight: true,
            },
            beforeEnter: multiguard([authGuard(true)]),
          },
          {
            path: '',
            redirect: 'info',
          },
          {
            path: '*',
            redirect: 'info',
          },
        ],
      },
      {
        path: '/more',
        component: ProfileList,
        meta: {
          view: 'more',
          source: 'no-button',
          showFooter: true,
        },
        beforeEnter: multiguard([mobileGuard(true)]),
      },
    ],
  },
  {
    path: '',
    name: 'payments.root',
    component: Layout,
    children: [
      {
        path: '/payments',
        name: 'payments',
        component: ProfileLayout,
        meta: {
          view: 'payments',
        },
        children: [
          {
            path: 'deposit',
            component: Deposit,
            children: [
              {
                path: 'methods',
                component: Methods,
                meta: {
                  view: 'deposit',
                  layoutHideBets: true,
                  layoutFullHeight: true,
                  profilePayments: true,
                },
              },
              {
                path: ':result',
                component: Result,
                meta: {
                  view: 'deposit',
                  layoutHideBets: true,
                  layoutFullHeight: true,
                  profilePayments: true,
                },
              },
              {
                path: '',
                redirect: 'methods',
              },
              {
                path: '*',
                redirect: 'methods',
              },
            ],
            beforeEnter: multiguard([authGuard(true)]),
          },
          {
            path: 'withdraw',
            component: Withdraw,
            children: [
              {
                path: 'methods',
                component: Methods,
                meta: {
                  view: 'withdraw',
                  layoutHideBets: true,
                  layoutFullHeight: true,
                  profilePayments: true,
                },
              },
              {
                path: ':result',
                component: Result,
                meta: {
                  view: 'withdraw',
                  layoutHideBets: true,
                  layoutFullHeight: true,
                  profilePayments: true,
                },
              },
              {
                path: '',
                redirect: 'methods',
              },
              {
                path: '*',
                redirect: 'methods',
              },
            ],
            beforeEnter: multiguard([authGuard(true)]),
          },
          {
            path: '',
            redirect: 'deposit',
          },
          {
            path: '*',
            redirect: 'deposit',
          },
        ],
      },
    ],
  },
];
