import store from '@/store';
import config from '@/config';
import {
  MISC_INIT_DICTIONARIES,
  MISC_IS_MOBILE,
  MISC_IS_LOADING,
  MISC_INIT_CASINO_DICTIONARIES,
  MISC_MOBILE_NAVBAR_ROUTE,
  MISC_MOBILE_APP_BANNER_CLOSE,
} from '../action_types/misc';
import _ from 'lodash';

import { initIsMobile } from '@/helpers/mobile.js';
import Vue from 'vue';

initIsMobile();

const stateParamsWithIndex = [
  'sports',
  'countries',
  'currencies',
  'timezones',
  'languages',
];

const stateCasinoParamsWithIndex = ['casino_categories', 'casino_providers'];

function genStateSourceParams(type, prefix = '') {
  if (type === 'getters') {
    return {
      origin: (key) => (store) => store[getDictionaryKey(key, prefix)],
      index: (key) => (store) => store[getDictionaryKey(key, prefix, 'Index')],
    };
  }

  return {
    origin: () => [],
    index: () => ({}),
  };
}

function getDictionaryKey(key, prefix = '', suffux = '') {
  return _.camelCase(`${prefix}${_.upperFirst(key)}${suffux}`);
}

function genStateWithIndices(params, type, prefix = '') {
  const result = {};
  const source = genStateSourceParams(type, prefix);

  params.forEach((key) => {
    result[getDictionaryKey(key, prefix)] = source.origin(key);
    result[getDictionaryKey(key, prefix, 'Index')] = source.index(key);
  });

  return result;
}

const state = {
  ...genStateWithIndices(stateParamsWithIndex, 'state'),
  ...genStateWithIndices(stateCasinoParamsWithIndex, 'state'),
  isMobile: localStorage.getItem('is-mobile') === 'true',
  isLoading: true,
  isInitDictionaryLoaded: false,
  isRegCountries: null,
  isRegCountriesIndex: null,
  isRegCurrencies: null,
  isRegCurrenciesIndex: null,
  mobileNavbar: {},
  appBannerClosed: sessionStorage.getItem('app-banner-closed'),
  casino: {},
};

const getters = {
  ...genStateWithIndices(stateParamsWithIndex, 'getters'),
  ...genStateWithIndices(stateCasinoParamsWithIndex, 'getters'),
  isMobile: (store) => store.isMobile,
  isLoading: (store) => store.isLoading,
  isInitDictionaryLoaded: (store) => store.isInitDictionaryLoaded,
  isRegCountries: (store) => store.isRegCountries,
  isRegCountriesIndex: (store) => store.isRegCountriesIndex,
  isRegCurrencies: (store) => store.isRegCurrencies,
  isRegCurrenciesIndex: (store) => store.isRegCurrenciesIndex,
  mobileNavbar: (store) => store.mobileNavbar,
  appBannerClosed: (store) => store.appBannerClosed,
  casino: (store) => store.casino,
};

const actions = {
  [MISC_INIT_DICTIONARIES]: async ({ commit }, request) => {
    const response = await store.$api.getDictionaries(request);
    const { payload } = response.data;

    commit(MISC_INIT_DICTIONARIES, payload);
    commit(MISC_INIT_CASINO_DICTIONARIES, payload);
  },
  [MISC_IS_LOADING]: ({ commit }, payload) => {
    commit(MISC_IS_LOADING, payload);
  },
  [MISC_MOBILE_NAVBAR_ROUTE]: ({ commit }, payload) => {
    commit(MISC_MOBILE_NAVBAR_ROUTE, payload);
  },
};

const mutations = {
  [MISC_INIT_DICTIONARIES]: (state, payload) => {
    stateParamsWithIndex.forEach((key) => {
      if (key === 'sports') {
        payload[key].forEach((item) => {
          if (!item.image) {
            item.image = config.params.assets.defaultSportLogo;
          }
        });
        state[key] = payload[key];
      } else if (key === 'countries') {
        const sorted = payload[key];
        sorted.sort((a, b) => a.name.localeCompare(b.name));
        state[key] = sorted;
        payload[key].forEach((item) => {
          item.name_and_code = `+${item.phone_code} ${item.name}`;
          if (!item.image) {
            item.image = config.params.assets.defaultCountryLogo;
          }
        });
      } else if (key === 'currencies') {
        payload[key].forEach((item) => {
          item.name_and_val = `${item.code} – ${item.name}`;
        });
        state[key] = payload[key];
      } else {
        state[key] = payload[key];
      }

      const dictionary = {};
      const idField =
        key === 'languages' ? 'code' : key === 'timezones' ? 'zone' : 'id';
      payload[key].forEach((item, index) => {
        dictionary[item[idField]] = {
          ...item,
          index,
        };
      });
      state[`${key}Index`] = dictionary;
    });

    state.isRegCountries = _.filter(state.countries, (item) => {
      return !item.is_reg_off;
    });
    const dictionary = {};
    state.isRegCountries.forEach((item, index) => {
      dictionary[item['id']] = {
        ...item,
        index,
      };
    });
    state.isRegCountriesIndex = dictionary;

    state.isRegCurrencies = _.filter(state.currencies, (currency) => {
      return !currency.is_hide;
    });
    const dictionaryCurrency = {};
    state.isRegCurrencies.forEach((item, index) => {
      dictionaryCurrency[item['id']] = {
        ...item,
        index,
      };
    });
    state.isRegCurrenciesIndex = dictionaryCurrency;

    state.isInitDictionaryLoaded = true;
  },
  [MISC_INIT_CASINO_DICTIONARIES]: (state, payload) => {
    if (payload.casino) {
      const casinoKeys = Object.keys(payload.casino);
      state.casino = {};
      casinoKeys.forEach((casinoKey) => {
        state.casino[casinoKey] = {};
        const typeKeys = Object.keys(payload.casino[casinoKey]);
        typeKeys.forEach((typeKey) => {
          const payloadItem = _.cloneDeep(payload.casino[casinoKey][typeKey]);

          if (typeKey === 'categories') {
            payloadItem.forEach((item) => {
              if (!item.image) {
                item.image = config.params.assets.defaultCasinoCategory;
              }
            });
          }

          state.casino[casinoKey][typeKey] = payloadItem;
          const dictionary = {};
          const idField = 'id';

          payloadItem.forEach((item, index) => {
            dictionary[item[idField]] = {
              ...item,
              index,
            };
          });

          state.casino[casinoKey][getDictionaryKey(typeKey, '', 'Index')] = dictionary;
        });
      });
    }

    stateCasinoParamsWithIndex
      .filter((key) => payload[key])
      .forEach((key) => {
        state[getDictionaryKey(key)] = payload[key];

        const dictionary = {};
        const idField = 'id';

        payload[key].forEach((item, index) => {
          dictionary[item[idField]] = {
            ...item,
            index,
          };
        });
        if (key === 'casino_categories') {
          payload[key].forEach((item) => {
            if (!item.image) {
              item.image = config.params.assets.defaultCasinoCategory;
            }
          });
        }

        state[getDictionaryKey(key, '', 'Index')] = dictionary;
      });
  },
  [MISC_IS_MOBILE]: (state, payload) => {
    state.isMobile = payload;
  },
  [MISC_IS_LOADING]: (state, payload) => {
    state.isLoading = payload;
  },
  [MISC_MOBILE_APP_BANNER_CLOSE]: (state) => {
    sessionStorage.setItem('app-banner-closed', 'true');
    state.appBannerClosed = 'true';
  },
  [MISC_MOBILE_NAVBAR_ROUTE]: (state, payload) => {
    if (payload.path) {
      Vue.set(state.mobileNavbar, payload.key, payload.path);
    } else {
      Vue.delete(state.mobileNavbar, payload.key);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
