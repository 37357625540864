// import { spoilText } from '@/i18n';

export default {
  getTranslation(languageCode) {
    const params = {
      module: 'site',
      language_code: languageCode,
    };

    return this.baseApi.get('/translations', { params });

    // return new Promise((resolve) => {
    //   resolve({
    //     data: {
    //       success: true,
    //       payload: i18n,
    //     },
    //   });
    // });
  },
};
