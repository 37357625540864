import { install } from './install';
const VERSION = '0.2.11';
const VueDatePicker = {
  installed: false,
  // eslint-disable-next-line no-undef
  version: VERSION,
  install,
};

export default VueDatePicker;
