<template>
  <iframe
    v-if="loadData"
    :src="`${$config.params.apiBaseUrl}/payments/${type}?theme=${$config.themeName}&lang=${$i18n.locale}&domain=${domain}&token=${token}&user_id=${userId}&account_id=${accountId}&is_mobile=${isMobile ? 1 : 0}`"
  ></iframe>
</template>

<style lang="scss"></style>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'IframeComponent',

  props: {
    type: {
      type: String,
      required: true,
      default() {
        return '';
      },
    },
  },

  data() {
    return {
      token: '',
      errors: {},
    };
  },

  computed: {
    ...mapGetters('auth', ['balance', 'user']),
    ...mapGetters('misc', ['isMobile']),
    domain() {
      return window.location.host;
    },

    userId() {
      return this.user?.id;
    },
    accountId() {
      return this.balance?.id;
    },

    loadData() {
      return this.userId !== '' && this.accountId !== '' && this.token !== '';
    },
  },
  created() {
    this.getPaymentToken();
  },

  methods: {
    async getPaymentToken() {
      try {
        const response = await this.$api.getUserPaymentToken();
        this.token = response.data.payload.payment_token;
      } catch (error) {
        this.errors = error.data.errors || {};
        console.log(error);
      }
    },
  },
};
</script>
