<template>
  <div
    id="app"
    class="app"
    :view="currentRoute"
    :class="{
      app_mob: isMobile,
      app_block: isBlockGeo || isOffMaintenance || !isConnected,
      [view]: view,
      app_fullscreen: isFullscreen,
    }"
  >
    <template v-if="isSettingsLoaded">
      <template v-if="!isOffMaintenance && !isBlockGeo && isConnected">
        <Notify />
        <PopupManager ref="popupManager" />
        <MobileAppBanner v-if="isMobile && !isBannerClosed && isAndroid" />
        <SiteSettings v-if="!isMobile" />
        <TheHeader v-if="!isFullscreen" />
        <transition
          name="fade"
          class="app__transition-group"
          mode="out-in"
          appear
        >
          <router-view key="router-view" class="app__view" />
        </transition>
        <transition
          name="fade"
          class="app__transition-group"
          mode="out-in"
          appear
        >
          <TheFooter v-if="!hideFooter" key="footer" />
        </transition>
        <MobileMenu v-if="isMobileMenu" />
      </template>
      <BlockGeo v-if="isBlockGeo" />
      <Maintenance v-if="isOffMaintenance" />
      <NetworkError v-if="!isConnected" />
    </template>
    <Loader></Loader>
  </div>
</template>

<script>
import SiteSettings from '@/components/SiteSettings.vue';
import TheHeader from '@/components/TheHeader/selector.js';
import TheFooter from '@/components/TheFooter/selector.js';
import MobileAppBanner from '@/views/MobileApp/MobileAppBanner.vue';
import i18n, { loadLanguageAsync } from '@/i18n';

import Notify from '@/components/Notify';

import {
  MISC_INIT_DICTIONARIES,
  MISC_IS_LOADING,
} from '@/store/action_types/misc';
import { SETTINGS_CURRENT_TIMEZONE } from '@/store/action_types/settings';
import {
  AUTH_IS_CONNECTED,
  AUTH_ON_TOKEN_FAIL,
  AUTH_TFA_PASSED,
  AUTH_UPDATE_BALANCE,
  AUTH_USER_SETUP,
} from '@/store/action_types/auth';
import { ODDS_CHANGE } from '@/store/action_types/market';
import { GET_BANNERS } from '@/store/action_types/promo';

import { isNeedMobileChange } from '@/helpers/mobile.js';
import { mapGetters } from 'vuex';
import MobileMenu from '@/components/MobileMenu';
import Loader from '@/components/loaders/Loader.vue';
import Maintenance from '@/components/Maintenance.vue';
import BlockGeo from '@/components/BlockGeo.vue';
import NetworkError from '@/components/NetworkError.vue';
import PopupManager from '@/components/PopupManager.vue';
import { checkAcl } from '@/router/guards/acl';
import cookieMixin from '@/helpers/cookieMixin';
import detectDevice from '@/helpers/detectDevice';

let balanceTimer;

export default {
  name: 'App',
  components: {
    MobileMenu,
    SiteSettings,
    TheHeader,
    TheFooter,
    Notify,
    PopupManager,
    Loader,
    Maintenance,
    BlockGeo,
    NetworkError,
    MobileAppBanner,
  },

  mixins: [cookieMixin, detectDevice],

  data() {
    return {
      isTabVisible: !document.hidden,
      isNeedRepeat: false,
    };
  },
  computed: {
    ...mapGetters('misc', [
      'isMobile',
      'isInitDictionaryLoaded',
      'appBannerClosed',
    ]),
    ...mapGetters('settings', ['values']),
    ...mapGetters('auth', ['isAuthenticated', 'isAuthorized', 'isConnected']),
    isBannerClosed() {
      return !!this.appBannerClosed;
    },
    isSettingsLoaded() {
      return this.values && this.isInitDictionaryLoaded;
    },
    onWindowResizeDebounced() {
      return this.$_.debounce(this.onWindowResize, 300);
    },

    isOffMaintenance() {
      return this.values?.is_off_maintenance === 1 || false;
    },
    isBlockGeo() {
      return this.values?.is_blocked_geo === 1 || false;
    },
    currentRoute() {
      return this.$route.meta.view;
    },
    hideFooter() {
      return !this.$route.meta.showFooter && this.isMobile;
    },
    view() {
      if (this.$route.meta.view) {
        return `app_view-${this.currentRoute}`;
      }
      return false;
    },
    isFullscreen() {
      return this.currentRoute === 'casinoGame' && this.isMobile;
    },
    isMobileMenu() {
      return !this.isFullscreen && this.isMobile;
    },
    balanceParams() {
      return ['line.card', 'live.card'].includes(this.$route.name)
        ? this.$route.name
        : this.$route.meta.view;
    },
    casinoViewList() {
      return [
        'casino',
        'liveCasino',
        'casinoGames',
        'casinoProviders',
        'casinoCategories',
        'casinoGame',
      ];
    },
  },
  watch: {
    async '$i18n.locale'() {
      this.$store.dispatch(`misc/${MISC_IS_LOADING}`, true);
      location.reload();
    },
    isAuthenticated(value) {
      if (!value) {
        this.handleAclCheck();
      }
    },
    balanceParams(value, oldValue) {
      this.balanceRepeat();
      if (!oldValue) {
        return;
      }

      const viewList = [
        'top',
        'live',
        'line',
        'line.card',
        'live.card',
        'results',
        'promotions',
        'search',
        'info',
        'deposit',
        'withdraw',
        ...this.casinoViewList,
      ];

      const currentViews = [value, oldValue].filter((item) => !!item);
      const intersectionViews = this.$_.intersection(currentViews, viewList);
      if (intersectionViews.length) {
        this.$store.dispatch(`auth/${AUTH_UPDATE_BALANCE}`);
      }
    },
    isTabVisible(value) {
      if (value && this.isNeedRepeat) {
        this.$store.dispatch(`auth/${AUTH_UPDATE_BALANCE}`);
        this.balanceRepeat();
      }
    },
    isAuthorized(value) {
      if (value) {
        this.balanceRepeat();
      }
    },
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const btag = urlParams.get('click_id');
    if (btag) {
      this.setCookie('btag', btag);
    }

    this.$eventBus.$on('auth-fail', this.onAuthFail);
    try {
      const currentTimezone = localStorage.getItem('select-timezone')
        ? localStorage.getItem('select-timezone')
        : this.$dayjs.tz.guess();
      await this.getUserRequests();
      await this.getLocalStorageLanguage();
      const promises = Promise.all([
        loadLanguageAsync(i18n.locale, true),
        this.$store.dispatch(`misc/${MISC_INIT_DICTIONARIES}`),
        this.$store.commit(
          `settings/${SETTINGS_CURRENT_TIMEZONE}`,
          currentTimezone
        ),
      ]);

      await promises;

      if (!this.isOffMaintenance && !this.isBlockGeo) {
        await this.$store.dispatch(`market/${ODDS_CHANGE}`);
      }
      if (!this.isBlockGeo) {
        const intercomConfig = {
          ...this.values.services.intercom,
          custom_launcher_selector: '.ui-button__intercom',
        };
        this.$intercom.init(intercomConfig);
        this.$intercom.boot();
      }
    } finally {
      window.addEventListener('resize', this.onWindowResizeDebounced);
      this.$store.dispatch(`misc/${MISC_IS_LOADING}`, false);
    }
  },

  mounted() {
    this.$eventBus.$on('check-connection', this.checkConnection);
    if (!this.isOffMaintenance && !this.isBlockGeo) {
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute('content', this.$config.cpRaw.surface);
      this.$eventBus.$on('auth-2fa', this.onAuth2fa);
      this.$eventBus.$on('2fa-complete', this.getUserRequests);
      this.$eventBus.$on('acl-check', this.handleAclCheck);
      window.addEventListener('resize', this.$config.detectSize);
      document.addEventListener(
        'visibilitychange',
        this.onDocumentVisibilityChange
      );
    }
  },

  beforeDestroy() {
    this.$eventBus.$off('check-connection', this.checkConnection);
    this.$eventBus.$off('auth-fail', this.onAuthFail);
    this.$eventBus.$off('auth-2fa', this.onAuth2fa);
    this.$eventBus.$off('2fa-complete', this.getUserRequests);
    this.$eventBus.$off('acl-check', this.handleAclCheck);
    window.removeEventListener('resize', this.onWindowResizeDebounced);
    window.removeEventListener('resize', this.$config.detectSize);
    document.removeEventListener(
      'visibilitychange',
      this.onDocumentVisibilityChange
    );
  },

  methods: {
    checkConnection(value) {
      if (!value) {
        this.$store.dispatch(`auth/${AUTH_IS_CONNECTED}`, value);
        document.querySelector('html').classList.add('no-connection');
      }

      if (value && this.isConnected !== value) {
        location.reload();
      }
    },
    getLocalStorageLanguage() {
      const localStorageLanguage = localStorage.getItem('language');
      if (localStorageLanguage === null) {
        const lang = this.values.country_language || 'en';
        localStorage.setItem('language', lang);
        this.$i18n.locale = lang;
      } else {
        this.$i18n.locale = localStorageLanguage;
      }
    },

    openPopup(popupRef, payload) {
      this.$nextTick(() => {
        this.$refs[popupRef].open(payload);
      });
    },

    onWindowResize() {
      isNeedMobileChange();
      this.$eventBus.$emit('window-resize');
    },
    onDocumentVisibilityChange() {
      this.isTabVisible = !document.hidden;
    },
    async onAuthFail(payload) {
      this.$store.dispatch(`auth/${AUTH_ON_TOKEN_FAIL}`, payload);
    },
    onAuth2fa() {
      this.$store.dispatch(`auth/${AUTH_TFA_PASSED}`, false);
    },
    async getUserRequests() {
      await this.$store.dispatch(`auth/${AUTH_TFA_PASSED}`, true);
      await this.$store.dispatch(`auth/${AUTH_USER_SETUP}`);
      await this.$store.dispatch(`promo/${GET_BANNERS}`);
    },
    handleAclCheck() {
      const result = checkAcl(this.$route);
      if (result) {
        this.$router.push(result);
      }
    },
    balanceRepeat() {
      clearTimeout(balanceTimer);
      const timeout = this.casinoViewList.includes(this.balanceParams)
        ? 60000
        : 900000;
      balanceTimer = setTimeout(() => {
        if (this.isTabVisible) {
          this.$store.dispatch(`auth/${AUTH_UPDATE_BALANCE}`);
        } else {
          this.isNeedRepeat = true;
        }

        this.balanceRepeat();
      }, timeout);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/variables';

:active,
:focus,
:hover {
  outline: none;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  -webkit-tap-highlight-color: transparent !important;
  outline: none !important;
}

body {
  overscroll-behavior-y: contain;
  touch-action: manipulation;
}

.body_scroll-disabled {
  overflow: hidden;
}

.wrapper {
  width: 100%;
  max-width: 1920px;
  margin: auto;
  padding: 0 16px;
}

.link {
  font-size: 12px;
  text-decoration: none;
  color: var(--color-link);
  transition: 0.2s ease-out;
  cursor: pointer;
  font-weight: 600;
  @include hover {
    color: var(--color-link-hover);
  }
}

.app {
  min-height: calc(var(--vh) * 100px);
  //min-height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  &__transition-group {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  &_mob {
    min-width: auto;
    //padding-top: 64px;
    padding-bottom: 60px;
  }
  &_block {
    padding-top: 0;
    padding-bottom: 0;
  }

  &_fullscreen {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.no-connection {
  .intercom-lightweight-app {
    display: none;
  }
}
.no-scroll {
  overflow: hidden;
}
.cursor-pointer {
  cursor: pointer;
}
.element-disabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
  color: var(--color-text-disable) !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.nested-enter-active,
.nested-leave-active {
  transition: all 0.2s linear;
}
.nested-enter,
.nested-leave-to {
  opacity: 0;
}
.nested-enter-active .popup__window,
.nested-leave-active .popup__window {
  transition: all 0.2s linear;
}
.nested-enter .popup__window,
.nested-leave-to .popup__window {
  transform: translateY(100%);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-text-secondary) !important;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-text-secondary) !important;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-text-secondary) !important;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  opacity: 1; /* Firefox */
}

/* Firefox */
.el-table__body-wrapper {
  scrollbar-width: thin;
  scrollbar-color: rgba(144, 147, 153, 0.5) var(--color-surface);
}
/* width */

.el-table__body-wrapper::-webkit-scrollbar {
  height: 6px;
  cursor: pointer;
  transition: 0.2s all ease-out;
}
/* Track */
.el-table__body-wrapper::-webkit-scrollbar-track {
  background: var(--color-surface);
}

/* Handle */
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(144, 147, 153, 0.3);
}

/* Handle on hover */
.el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.5);
}

.grecaptcha-badge {
  visibility: hidden;
}
</style>
