<template>
  <Popup
    class="confirm-popup"
    :title="$t('popups.loadBetslip.title')"
    :is-visible.sync="isVisible"
    @close="handleClose"
  >
    <div slot="body">
      <p class="mb-md">{{ $t('popups.loadBetslip.text') }}</p>
      <UiInput
        v-model="betSlipCode"
        class="mb-md"
        :label="$t('popups.loadBetslip.label')"
        :placeholder="$t('popups.loadBetslip.placeholder')"
        type="text"
        error-clear-on-change
        :error.sync="errors.coupon_code"
      />
      <p>{{ $t('popups.loadBetslip.help') }}</p>
    </div>
    <div slot="footer" class="flex space-x-lg">
      <UiButton
        key="confirm"
        class="w-1/2"
        substyle="fas"
        icon="times"
        type="secondary"
        @click="cancel"
      >
        {{ $t('buttons.cancel') }}
      </UiButton>
      <UiButton
        key="cancel"
        class="w-1/2"
        substyle="fas"
        icon="cloud-arrow-down"
        :disabled="betSlipCode === ''"
        :loading="isLoading"
        @click="confirm"
      >
        {{ $t('buttons.load') }}
      </UiButton>
    </div>
  </Popup>
</template>

<script>
import { ADD_SLIP, CLEAR_SLIP } from '@/store/action_types/market';

export default {
  name: 'LoadBetSlip',

  data() {
    return {
      isVisible: false,
      betSlipCode: '',
      errors: {},
      isLoading: false,
    };
  },

  computed: {},

  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },

  methods: {
    open() {
      this.isVisible = true;
    },

    async confirm() {
      this.errors = {};
      this.isLoading = true;
      try {
        const response = await this.$api.getBetsLoad({
          coupon_code: this.betSlipCode,
        });
        const { events } = response.data.payload;
        const slipItems = [];
        this.$store.dispatch(`market/${CLEAR_SLIP}`);

        events.forEach((event) => {
          const item = {
            id: event.outcome_id,
            name: event.outcome_name,
            status_id: event.sport_id,
            odds: event.odds,
            coef: event.odds,
            matchId: event.id,
            matchName: event.name,
            tournamentId: event.tournament.id,
            tournamentName: event.tournament.name,
            image:
              event.tournament.image ||
              this.$config.params.assets.defaultTournamentLogo,
            sportId: event.sport_id,
            partId: event.event_part_id,
            partName: event.event_part_name,
            isLive: event.is_live,
            isBlocked: event.is_blocked || 0,
            marketId: event.market_id,
            marketName: event.market_name,
            key: `${event.id}_${event.outcome_id}`,
          }

          slipItems.push(item)
        });

        this.$store.dispatch(`market/${ADD_SLIP}`, slipItems);
        this.isVisible = false;
        if (response.data.payload.error) {
          this.$eventBus.$emit('notify-event', {
            type: 'error',
            text: response.data.payload.error,
          });
        }
      } catch (error) {
        this.errors = error?.data?.errors || {};
      } finally {
        this.isLoading = false;
      }
    },

    cancel() {
      this.$emit('cancel');
      this.isVisible = false;
      this.defaultValues();
    },

    handleClose() {
      this.cancel();
    },

    defaultValues() {
      this.errors = {};
      this.betSlipCode = '';
    },
  },
};
</script>
