export default {
  methods: {
    getCookie(name) {
      const prefix = `${name}=`;
      const csi = document.cookie.indexOf(prefix);
      if (csi === -1) return null;
      let cei = document.cookie.indexOf(';', csi + prefix.length);
      if (cei === -1) cei = document.cookie.length;
      return decodeURIComponent(document.cookie.substring(csi + prefix.length, cei));
    },
    setCookie(name, value) {
      const date = new Date(new Date().setMonth(new Date().getDay() + 30)).toUTCString();
      document.cookie = `${name}=${encodeURIComponent(value)}; expires=${date}; path=/;`;
    },
  },
};
