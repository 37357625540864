import { SET_VALUE } from '@/store/action_types/search';

const state = {
  storeSearch: '',
};

const getters = {
  storeSearch: (state) => state.storeSearch,
};

const actions = {};

const mutations = {
  [SET_VALUE]: (state, payload) => {
    state.storeSearch = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
