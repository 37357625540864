<template>
  <component :is="component" class="scrolling-component" ref="scrollComponent">
    <div class="scrolling-component__content" ref="scrollComponentContent">
      <slot />
    </div>
  </component>
</template>

<script>
export default {
  name: 'UiInfiniteScroll',
  props: {
    bottom: {
      type: Number,
      default: 500,
    },
    isWindow: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    handlerScrollDebounced() {
      return this.$_.debounce(this.handlerScroll, 300);
    },
    element() {
      return this.isWindow ? window : this.$refs.scrollComponent.wrap;
    },
    component() {
      return this.isWindow ? 'div' : 'el-scrollbar';
    },
  },

  mounted() {
    this.element.addEventListener('scroll', this.handlerScrollDebounced);
  },

  beforeDestroy() {
    this.element.removeEventListener('scroll', this.handlerScrollDebounced);
  },

  methods: {
    handlerScroll() {
      const element = this.$refs.scrollComponentContent;
      if (!element) {
        return;
      }

      if (
        element.getBoundingClientRect().bottom - this.bottom <
        this.getContainerHeight()
      ) {
        this.$emit('reach-bottom');
      }
    },

    getContainerHeight() {
      return this.isWindow
        ? this.element.innerHeight
        : this.element.clientHeight;
    },
  },
};
</script>
