<template>
  <div class="">
    <UiTitle>{{ $t('auth.login.title') }}</UiTitle>
    <TabsButtons
      class="mx-rlg mb-lg"
      type="tabs"
      :current-tab.sync="currentTab"
      :items="tabsItems"
    />

    <LoginPanel
      ref="loginPanel"
      :current-tab="currentTab"
      :form="form"
      :tab-meta="currentTabMeta"
      :errors="errors"
      @password-recovery="handlePasswordRecovery"
      @isValid="isValid = $event"
    />

    <UiButton
      slot="footer"
      substyle="fas"
      icon="right-to-bracket"
      class="mt-lg"
      :disabled="!isValid"
      @click="login"
    >
      {{ $t('buttons.login') }}
    </UiButton>
  </div>
</template>

<script>
import LoginPanel from '@/views/Auth/components/Login/LoginPanel.vue';

import logic from '@/views/Auth/components/Login/logic';
import routerMixin from '@/helpers/router.js';

export default {
  components: { LoginPanel },

  mixins: [logic, routerMixin],

  mounted() {},

  methods: {
    handlePasswordRecovery() {
      this.$router.push({
        name: 'PasswordRecovery',
        params: {
          initalForm: this.form,
          initalTab: this.currentTab,
        },
      });
    },

    handleLoginSuccess() {
      if (!this.returnTo()) {
        this.$router.push('/');
      }
    },
  },
};
</script>
