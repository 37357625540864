<template>
  <IframeComponent class="payments-iframe" :type="paymentsType" />
</template>

<style lang="scss"></style>

<script>
import IframeComponent from '@/views/Profile/Payments/components/IframeComponent';

export default {
  name: 'Methods',

  components: {
    IframeComponent,
  },

  computed: {
    paymentsType() {
      return this.$route.meta.view;
    },
  },
};
</script>
