// auth views
import Layout from '@/components/layout/Layout/selector.js';
import Promotions from '@/views/Promotions/Categories/selector';
import Article from '@/views/Promotions/Article/selector';

export default [
  {
    path: '',
    name: 'market.root',
    component: Layout,
    children: [
      {
        path: '/promotions',
        name: 'promotions',
        component: Promotions,
        meta: {
          view: 'promotions',
        },
      },
      {
        path: '/promotions/:id',
        name: 'article',
        component: Article,
        meta: {
          view: 'promotions',
        },
      },
    ],
  },
];
