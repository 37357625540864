<template>
  <div class="bonus-history">
    <div class="bonus-history__title">
      {{ $t('profile.bonusHistory.title') }}
    </div>
    <el-scrollbar>
      <div class="bonus-history__filter">
        <div
          v-for="status in statusesList"
          :key="status.code"
          :class="[
            'bonus-history__filter-item',
            { 'bonus-history__filter-item_active': status.code === filterValue }
          ]"
          @click="status.code !== filterValue ? changeStatus(status.code) : ''"
        >
          {{ status.name }}
        </div>
      </div>
    </el-scrollbar>
    <template v-if="isLoading && isLoadingFirst && !error">
      <BonusHistorySkeleton />
    </template>
    <Tech v-if="error" @button-action="getHistory(true)" />
    <template v-if="!(isLoading && isLoadingFirst) && !error">
      <template v-if="bonusHistoryList.length > 0">
        <UiInfiniteScroll @reach-bottom="getHistory">
          <BonusHistoryItem
            v-for="item in bonusHistoryList"
            :key="item.id"
            :data="item"
            :statuses="statusesList"
            class="mb-md"
          />
        </UiInfiniteScroll>
      </template>
      <template v-else>
        <div class="bonus-history__empty">
          <UiIcon
            :size="32"
            :color="$config.cp['text-secondary']"
            name="list"
            lib="fa"
            substyle="fas"
            class="mb-lg"
          />
          <div class="bonus-history__empty-title mb-lg">
            {{ $t('profile.bonusHistory.empty') }}
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import BonusHistoryItem from '@/views/Profile/ProfileBonuses/components/BonusHistory/components/BonusHistoryItem.vue';
import BonusHistorySkeleton from '@/views/Profile/ProfileBonuses/components/BonusHistory/components/BonusHistorySkeleton.vue';

export default {
  name: 'BonusHistory',

  components: {
    BonusHistorySkeleton,
    BonusHistoryItem,
  },

  data() {
    return {
      isLoading: true,
      isLoadingFirst: true,
      error: false,
      scrollIsAvailable: true,
      bonusHistoryList: [],
      statusesList: [
        {
          code: '',
          name: this.$t('profile.all'),
        },
      ],

      limitOffset: 11,
      limit: 10,
      offset: 0,

      filterValue: '',
    };
  },

  mounted() {
    this.getHistory(true);
    this.$eventBus.$on('changed-bonuses', this.getHistory);
  },

  beforeDestroy() {
    this.$eventBus.$off('changed-bonuses', this.getHistory);
  },

  methods: {
    async getHistory(force = false) {
      this.isLoading = true;
      this.error = false;
      if (this.scrollIsAvailable || force) {
        this.scrollIsAvailable = false;
        if (force) {
          this.offset = 0;
          this.isLoadingFirst = true;
          this.bonusHistoryList = [];
        }
        try {
          const params = {
            limit: this.limitOffset,
          };
          if (this.bonusHistoryList.length > 0) {
            this.offset = this.offset + this.limit;
            params.offset = this.offset;
          }
          params.statuses = this.filterValue;

          const response = await this.$api.getUserGifts(params);
          if ( this.statusesList.length === 1)  {
            this.statusesList.push(...response.data.payload.statuses);
          }
          const dataOffset = this.$_.cloneDeep(response.data.payload.list);
          const data = this.$_.cloneDeep(response.data.payload.list);
          if (data.length === this.limitOffset) {
            data.pop();
          }
          this.bonusHistoryList.push(...data);
          if (dataOffset.length === this.limitOffset) {
            this.scrollIsAvailable = true;
          }
          this.isLoadingFirst = false;
        } catch (e) {
          this.error = true;
        }
      }
    },

    changeStatus(status) {
      this.filterValue = status;
      this.offset = 0;
      this.$eventBus.$emit('changed-bonuses', true);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/variables';

.bonus-history {
  &__title {
    font-size: 20px;
    font-weight: 700;
    color: var(--color-text);
    margin-bottom: 12px;
  }
  &__filter {
    display: flex;
    margin-bottom: 16px;
    &-item {
      padding: 5px 12px;
      border-radius: 8px;
      background-color: var(--color-surface);
      font-size: 12px;
      font-weight: 700;
      line-height: 1.67;
      letter-spacing: normal;
      color: var(--color-text);
      margin-right: 8px;
      cursor: pointer;
      transition: all 0.2s;

      @include hover {
        background-color: var(--color-on-surface);
      }

      &_active {
        background-color: var(--color-primary) !important;
        color: var(--color-on-primary) !important;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 265px;
    justify-content: center;
    &-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.67;
      color: var(--color-text-secondary);
    }
  }
}
@media (max-width: $screen-mobile) {
  .bonus-history {
    &__title {
      font-size: 16px;
    }
    &__empty {
      padding: 16px 16px 32px;
      min-height: auto;
    }
  }
}
</style>
