import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('auth', ['bonusBalance']),
    pBonusBalance() {
      const balance = this.bonusBalance ? this.bonusBalance.balance : 0;
      const currencyId = this.bonusBalance ? this.bonusBalance.currency_id : this.balance.currency_id;
      return {
        'balance': balance,
        'currency_id': currencyId,
      }
    },
  },
};
