<template>
  <div class="profile-bonuses">
    <UiTitle :back-route="isMobile ? backRoute : ''">
      {{ $t('profile.bonuses.title') }}
    </UiTitle>
    <div class="profile-bonuses__content">
      <Promocodes class="profile-bonuses__promocodes" />
      <Panel size="medium" class="profile-bonuses__panel">
        <div class="profile-bonuses__panel-title">
          <UiIcon
            :size="14"
            name="gift"
            lib="fa"
            substyle="fas"
            class="mr-2xs"
          />
          {{ $t('profile.bonus.title') }}
        </div>
        <SelectBonus
          :value="user.first_bonus"
          @input="changeBonus"
          :is-locked="isBonusClick"
          mx-size="sm"
        />
      </Panel>
    </div>
    <BonusHistory />
  </div>
</template>

<script>
import Promocodes from '@/views/Profile/ProfileBonuses/components/Promocodes.vue';
import SelectBonus from '@/components/SelectBonus.vue';
import BonusHistory from '@/views/Profile/ProfileBonuses/components/BonusHistory/BonusHistory.vue';
import { mapGetters } from 'vuex';
import { AUTH_USER } from '@/store/action_types/auth';
export default {
  name: 'ProfileBonuses',

  data() {
    return {
      isBonusClick: false,
    };
  },

  components: {
    Promocodes,
    BonusHistory,
    SelectBonus,
  },
  computed: {
    ...mapGetters('misc', ['isMobile']),
    ...mapGetters('auth', ['user']),
    backRoute() {
      return this.isMobile ? '/more' : '';
    },
  },

  methods: {
    async updateUser(payload) {
      await this.$store.commit(`auth/${AUTH_USER}`, payload.user);
      this.$eventBus.$emit('profile-update-user');
      this.$eventBus.$emit('notify-event', {
        type: 'success',
        text: this.$t('profile.bonus.notify'),
      });
    },
    async changeBonus(bonusType) {
      this.isBonusClick = true;
      if (bonusType !== this.user.first_bonus) {
        try {
          const response = await this.$api.putUserBonusChange({
            first_bonus: bonusType,
          });
          await this.updateUser(response.data.payload);
        } catch (errors) {
          this.$eventBus.$emit('notify-event', {
            type: 'error',
            text: errors.data.message,
          });
        } finally {
          this.isBonusClick = false;
        }
      } else {
        this.isBonusClick = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/variables';

.profile-bonuses {
  &__content {
    display: flex;
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: 16px;
  }
  &__panel,
  &__promocodes {
    margin-left: 8px;
    margin-right: 8px;
    width: 50%;
  }
  &__panel {
    &-title {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.43;
      color: var(--color-text);
      margin-bottom: 16px;
    }
  }
}

@media (max-width: $screen-mobile) {
  .profile-bonuses {
    &__content {
      display: flex;
      flex-direction: column-reverse;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
    }
    &__panel,
    &__promocodes {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 16px;
      width: 100%;
    }
  }
}
</style>
