import MobileAppLayout from '@/views/MobileApp/MobileAppLayout/selector';
import MobileApp from '@/views/MobileApp/MobileApp/selector';


export default [
  {
    path: '',
    name: 'mobile-app.root',
    component: MobileAppLayout,
    children: [
      {
        path: '/mobile-app',
        component: MobileApp,
        meta: {
          view: 'mobile-app',
        },
      },
    ],
  },
];

