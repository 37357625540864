import Vue from 'vue';
import App from './App.vue';
import ConfigService from '@/config';
import store from '@/store';

import api from '@/api';
import i18n from '@/i18n';

import PluginsSetup from '@/setup/plugins';
import ComponentsSetup from '@/setup/components';
import LayoutSetup from '@/setup/layout';
import LibsSetup from '@/setup/libs';
import NavigationGuardsSetup from '@/setup/navigationGuards';
import eventBus from '@/setup/eventBus';
import FiltersSetup from '@/setup/filters';
import IntercomSetup from '@/setup/intercom';

import 'vue-loading-skeleton/dist/vue-loading-skeleton.css';
import '@/styles';

Vue.config.productionTip = false;

// eslint-disable-next-line no-undef
BigInt.prototype['toJSON'] = function () {
  return this.toString();
};

PluginsSetup(Vue);
ComponentsSetup(Vue);
LayoutSetup(Vue);
LibsSetup(Vue);
NavigationGuardsSetup(Vue);
eventBus(Vue);
FiltersSetup(Vue);
IntercomSetup(Vue);

const config = ConfigService.params;
const apiConfig = {
  store,
  router,
  baseUrl: `${config.apiBaseUrl}/api/v1`,
};

Vue.use(api, apiConfig);
Vue.use(ConfigService);
Vue.use(i18n);

// router here because of style ordering
import router from '@/router';

const VueInstance = new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
});

VueInstance.$mount('#app');

export default VueInstance;
