export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_SAVE_TOKEN = 'AUTH_SAVE_TOKEN';
export const AUTH_GET_REGISTRATION_PARAMS = 'AUTH_GET_REGISTRATION_PARAMS';
export const AUTH_CLEAR_REGISTRATION_PARAMS = 'AUTH_CLEAR_REGISTRATION_PARAMS';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_USER_SETUP = 'AUTH_USER_SETUP';
export const AUTH_USER_BALANCE = 'AUTH_USER_BALANCE';
export const AUTH_USER_ADD_ACCOUNT = 'AUTH_USER_ADD_ACCOUNT';
export const AUTH_USER_DELETE_ACCOUNT = 'AUTH_USER_DELETE_ACCOUNT';
export const AUTH_USER_DELETE_BONUS_ACCOUNT = 'AUTH_USER_DELETE_BONUS_ACCOUNT';
export const AUTH_USER_SELECT_ACCOUNT = 'AUTH_USER_SELECT_ACCOUNT';
export const AUTH_ON_TOKEN_FAIL = 'AUTH_ON_TOKEN_FAIL';
export const AUTH_REQUEST_STATUS = 'AUTH_REQUEST_STATUS';
export const AUTH_CLEAR_TIMER = 'AUTH_CLEAR_TIMER';
export const AUTH_TFA_PASSED = 'AUTH_TFA_PASSED';
export const AUTH_REMEMBER_ME = 'AUTH_REMEMBER_ME';
export const AUTH_LOST_CONNECTION_TOKEN = 'AUTH_LOST_CONNECTION_TOKEN';
export const AUTH_UPDATE_BALANCE = 'AUTH_UPDATE_BALANCE';
export const AUTH_TWO_FACTOR_AUTHED = 'AUTH_TWO_FACTOR_AUTHED';
export const AUTH_IS_CONNECTED = 'AUTH_IS_CONNECTED';
export const AUTH_TOKEN_CHANGED = 'AUTH_TOKEN_CHANGED';
export const AUTH_TOKEN_CHANGED_DEBOUNCED = 'AUTH_TOKEN_CHANGED_DEBOUNCED';
export const AUTH_ADD_TO_WAITING_RETRY = 'AUTH_ADD_TO_WAITING_RETRY';
export const AUTH_CLEAR_WAITING_RETRY = 'AUTH_CLEAR_WAITING_RETRY';
