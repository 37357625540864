<template>
  <UiDatepicker
    :date="value"
    :placeholder="placeholder"
    icon="calendar-alt"
    date-birth
    :label="label"
    :error="error"
    @update:date="$emit('input', $event)"
  />
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'SignupPanelDatepicker',
  props: {
    value: {
      type: [Object, String],
      default: () => dayjs(),
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    formMeta: {
      type: Object,
      default: () => ({}),
    },
    error: {
      type: Array,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: 'text',
    },
  },
};
</script>
