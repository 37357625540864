<template>
  <div class="tech">
    <UiIcon
      :size="64"
      :color="$config.cp.primary"
      :name="icon"
      lib="fa"
      substyle="fas"
      class="mb-lg"
    />
    <div class="tech__title">{{ title }}</div>
    <div class="tech__text">{{ text }}</div>
    <UiButton
      v-if="type === 'error'"
      class="mb-sm"
      substyle="fas"
      :icon="buttonIcon"
      @click="handlerClick()"
    >
      {{ buttonText }}
    </UiButton>
    <router-link v-else to="/" class="mb-sm no-underline">
      <UiButton substyle="fas" :icon="buttonIcon">
        {{ buttonText }}
      </UiButton>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Tech',

  props: {
    type: {
      type: String,
      default: () => 'error',
    },
  },

  data() {
    return {};
  },

  computed: {
    icon() {
      switch (this.type) {
        case 'error':
          return 'exclamation-circle';
        case 'construction':
          return 'screwdriver-wrench';
        case 'not-found':
          return 'exclamation-circle';
        default:
          return 'exclamation-circle';
      }
    },
    title() {
      switch (this.type) {
        case 'error':
          return this.$t('tech.badRequest.title');
        case 'construction':
          return this.$t('tech.construction.title');
        case 'not-found':
          return this.$t('tech.notFound.title');
        default:
          return this.$t('tech.badRequest.title');
      }
    },
    text() {
      switch (this.type) {
        case 'error':
          return this.$t('tech.badRequest.text');
        case 'construction':
          return this.$t('tech.construction.text');
        case 'not-found':
          return this.$t('tech.notFound.text');
        default:
          return this.$t('tech.badRequest.text');
      }
    },
    buttonText() {
      switch (this.type) {
        case 'error':
          return this.$t('tech.badRequest.button');
        case 'construction':
          return this.$t('tech.construction.button');
        case 'not-found':
          return this.$t('tech.notFound.button');
        default:
          return this.$t('tech.badRequest.button');
      }
    },
    buttonIcon() {
      switch (this.type) {
        case 'error':
          return 'arrows-rotate';
        case 'construction':
          return 'chevron-left';
        case 'not-found':
          return 'chevron-left';
        default:
          return 'arrows-rotate';
      }
    },
  },

  methods: {
    handlerClick() {
      this.$emit('button-action');
    },
  },
};
</script>

<style lang="scss">
.tech {
  padding: 64px 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 8px;
    text-align: center;
  }
  &__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    margin-bottom: 16px;
    text-align: center;
  }
}
</style>
