<template>
  <div>
    <template v-if="isLoading">
      <CouponSkeleton />
    </template>
    <template v-else>
      <div class="coupon">
        <div class="coupon__date">
          {{ (betInfo.created_at * 1000) | formatDateTime }}
          <div class="coupon__divisor"></div>
          ID: {{betInfo.id}}
        </div>
        <div class="coupon__info">
          <div class="coupon__info-title">
            <div class="flex justify-between items-center mb-sm">
              <UiLogo size="bet" />
              <Status :type="couponStatus.type">{{ couponStatus.name }}</Status>
            </div>
            <div class="flex justify-between items-center">
              <div class="flex items-center">
                <template v-if="betInfo.is_free_bet">
                  <UiIcon
                    :size="14"
                    name="gift"
                    lib="fa"
                    substyle="fas"
                    class="mr-2xs"
                  />
                </template>
                <CouponSum
                  :info="betInfo"
                  :status="couponStatus"
                />
              </div>
              <div class="coupon__info-coef">{{ betInfo.odds | formatCoef }}</div>
            </div>
          </div>
          <el-scrollbar>
            <CouponItem
              v-for="(item, key) in betInfo.events"
              :key="key"
              :is-first="key"
              :bet="item"
            />
          </el-scrollbar>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import betMixin from '@/helpers/betMixin';
import CouponItem from './components/CouponItem.vue'
import CouponSum from './components/CouponSum.vue'
import CouponSkeleton from './components/CouponSkeleton.vue'

import {mapGetters} from 'vuex';

export default {
  name: 'Coupon',

  props: {
    betInfo: {
      type: Object,
      default() {
        return null;
      },
    },
  },

  components: {
    CouponItem,
    CouponSum,
    CouponSkeleton
  },

  mixins: [betMixin],

  computed: {
    ...mapGetters('auth', ['balance']),
    isLoading() {
      return !this.betInfo;
    },
    couponStatus() {
      return this.idStatusList[this.betInfo.status] || '';
    },
  },
};
</script>

<style lang="scss">
.coupon{
  .el-scrollbar__view {
    max-height: calc(100vh - 300px);
  }
  &__date{
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--color-text-secondary);
    display: flex;
    align-items: center;
  }
  &__divisor {
    width: 1px;
    height: 12px;
    background-color: var(--color-on-surface);
    margin: 0 8px;
  }
  &__info{
    border-radius: 16px;
    background-color: var(--color-surface);
    padding: 16px 0;
    &-title{
      padding: 0 16px 6px;
    }
    &-coef {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.29;
      color: var(--color-text);
    }
  }
}
</style>
