<template>
  <div class="first-bonus">
    <div class="first-bonus__label">
      {{label}}
    </div>
    <div
      class="flex items-stretch"
      :class="{
        'mx-rxs': mxSize === 'xs',
        'mx-rsm': mxSize === 'sm',
      }"
    >
      <div
        v-for="item in bonusList"
        :key="item.id"
        class="first-bonus__item flex-grow w-1/3"
        :class="{
          'first-bonus__item_active': item.id === value ,
          'mx-xs': mxSize === 'xs',
          'mx-sm': mxSize === 'sm',
        }"
        @click="changeBonus(item.id)"
      >
        <img
          class="first-bonus__icon"
          :src="require(`@/assets/images/bonus/${item.id}.png`)"
          alt=""
        />
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import betMixin from '@/helpers/betMixin';

export default {
  name: 'SelectBonus',
  props: {
    value: {
      type: [String, Number],
      default: 1,
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    formMeta: {
      type: Object,
      default: () => ({}),
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    mxSize: {
      type: String,
      default: 'xs',
    },
  },

  mixins: [betMixin],

  methods: {
    changeBonus(value) {
      if (!this.isLocked) {
        this.$emit('input', value);
      }
    },
  },
};
</script>

<style lang="scss">
.first-bonus {
  &__label {
    font-size: 12px;
    font-weight: 600;
    line-height: 2.67;
    color: var(--color-text);
  }
  &__item {
    border-radius: 8px;
    background-color: var(--color-on-surface);
    text-align: center;
    padding: 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    cursor: pointer;
    border: 2px solid var(--color-on-surface);
    transition: border 0.2s;

    display: flex;
    flex-direction: column;
    justify-content: center;
    &_active {
      border-color: var(--color-primary);
    }
  }
  &__icon {
    width: 24px;
    height: 24px;
    margin: 0 auto 4px;
  }
}
</style>
