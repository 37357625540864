<template>
  <Popup
    class="login-popup"
    :title="$t('auth.login.title')"
    is-tabs
    :is-visible.sync="isVisible"
    :current-tab.sync="currentTab"
    :tabs-items="tabsItems"
    @close="handleClose"
  >
    <LoginPanel
      slot="body"
      ref="loginPanel"
      :current-tab="currentTab"
      :form="form"
      :errors="errors"
      :tab-meta="currentTabMeta"
      @password-recovery="handlePasswordRecovery"
      @isValid="isValid = $event"
    />
    <UiButton
      slot="footer"
      substyle="fas"
      icon="right-to-bracket"
      :disabled="!isValid"
      @click="login"
    >
      {{ $t('buttons.login') }}
    </UiButton>
  </Popup>
</template>

<script>
import logic from '@/views/Auth/components/Login/logic';
import LoginPanel from '@/views/Auth/components/Login/LoginPanel.vue';

export default {
  components: {
    LoginPanel,
  },
  mixins: [logic],
  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },
  methods: {
    handlePasswordRecovery() {
      // this.$emit('open-confirm');
      this.close();
      this.$eventBus.$emit('open-popup', 'PasswordRecovery', {
        data: {
          initalForm: this.form,
          initalTab: this.currentTab,
        },
      });
      // this.$router.push({ name: 'PasswordRecovery' });
    },
    open() {
      this.init();
      this.isVisible = true;
    },
    handleLoginSuccess() {
      this.close();
    },
    handleClose() {
      this.$eventBus.$emit('auth-fail', {
        reason: 'abort',
        error: null,
      });
    },
    close() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
.login-popup {
  &__options {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
