<template>
  <div class="statistics-panel">
    <transition name="fade" mode="out-in" appear>
      <div v-if="!loading" class="statistics-panel__content">
        <template v-if="!isError">
          <div class="statistics-panel__score">
            <div class="statistics-panel__score-team">
              <img
                class="statistics-panel__score-team-logo mb-sm"
                v-lazy="{
                  src: pStatistics.team_home.logo,
                  error: require('@/assets/images/icons/users.svg'),
                }"
                :class="{ 'team-logo_circle': true }"
              />
              <div class="statistics-panel__score-team-name">
                {{ pStatistics.team_home.name }}
              </div>
            </div>
            <div class="statistics-panel__score-value-wrapper">
              <div class="statistics-panel__score-value">{{ totalScore }}</div>
              <LiveBadge
                v-if="pStatistics.is_live"
                class="statistics-panel__score-live-badge mt-2xs"
              />
            </div>
            <div class="statistics-panel__score-team">
              <img
                class="statistics-panel__score-team-logo mb-sm"
                v-lazy="{
                  src: pStatistics.team_away.logo,
                  error: require('@/assets/images/icons/users.svg'),
                }"
                :class="{ 'team-logo_circle': true }"
              />
              <div class="statistics-panel__score-team-name">
                {{ pStatistics.team_away.name }}
              </div>
            </div>
          </div>
          <Scroller
            v-if="!isError"
            class="statistics-panel__scroller"
            item-name="tabs-buttons__item"
            backgroundColor="var(--color-surface)"
          >
            <TabsButtons
              class="statistics-panel__tabs"
              type="tabs"
              :current-tab.sync="currentPart"
              :items="tabsItems"
              @click="currentPart = part.id"
            />
          </Scroller>
          <UiSeparator class="statistics-panel__tabs-separator" />
          <transition name="fade" mode="out-in" appear>
            <component
              v-if="!isError && part"
              :is="component"
              :key="part.id"
              view-class="statistics-panel__scrollbar"
            >
              <div class="statistics-panel__statistics-wrapper">
                <div class="statistics-panel__statistics-part">
                  <!-- <div class="statistics-panel__statistics-part-name mb-sm">
                    {{ part.name }}
                  </div> -->
                  <div
                    v-for="type in part.types"
                    :key="type.id"
                    class="statistics-panel__statistics-type"
                    :class="{
                      'statistics-panel__statistics-type_score': type.isScore,
                      'statistics-panel__statistics-type_text': !type.isScore,
                    }"
                  >
                    <template v-if="type.isScore">
                      <div class="statistics-panel__statistics-chart-wrapper">
                        <div class="statistics-panel__statistics-chart-header mb-2xs">
                          <div class="statistics-panel__statistics-chart-header-value">
                            {{ type.team_home | formatMoney }}
                          </div>
                          <div class="statistics-panel__statistics-chart-header-type-name">
                            {{ type.name }}
                          </div>
                          <div class="statistics-panel__statistics-chart-header-value">
                            {{ type.team_away | formatMoney }}
                          </div>
                        </div>
                        <div class="statistics-panel__statistics-chart">
                          <div class="statistics-panel__statistics-chart-part statistics-panel__statistics-chart-part_right">
                            <div
                              :class="[
                                'statistics-panel__statistics-chart-part-filler',
                                {
                                  'statistics-panel__statistics-chart-part-filler_winner':
                                    type.scoreParameters.winStatus === -1,
                                },
                              ]"
                              :style="{
                                width: type.scoreParameters.teamHomeWeight + '%',
                              }"
                            ></div>
                          </div>
                          <div class="statistics-panel__statistics-chart-part">
                            <div
                              :class="[
                                'statistics-panel__statistics-chart-part-filler',
                                {
                                  'statistics-panel__statistics-chart-part-filler_winner':
                                    type.scoreParameters.winStatus === 1,
                                },
                              ]"
                              :style="{
                                width: type.scoreParameters.teamAwayWeight + '%',
                              }"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div
                        class="statistics-panel__statistics-type-name"
                        :title="type.name"
                      >
                        {{ type.name }}
                      </div>
                      <div
                        class="statistics-panel__statistics-type-result"
                        :title="type.result"
                      >
                        {{ type.result }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </component>
          </transition>
        </template>
        <div v-else class="statistics-panel__empty">
          <UiIcon
            :size="32"
            :color="$config.cp['text-secondary']"
            name="magnifying-glass"
            lib="fa"
            substyle="fas"
            class="mb-lg"
          />
          <div class="statistics-panel__empty-text">
            {{ $t('popups.statistics.noStatistics') }}
          </div>
        </div>
      </div>
      <StatisticsPanelSkeleton v-else />
    </transition>
  </div>
</template>

<script>
import matchMixin from '@/views/Market/mixins/match.js';
import Scroller from '@/components/Scroller';
import StatisticsPanelSkeleton from '@/views/Market/Statistics/components/StatisticsPanelSkeleton.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'StatisticsPanel',
  mixins: [matchMixin],
  components: {
    Scroller,
    StatisticsPanelSkeleton,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isVisible: false,
      pLoading: true,
      statistics: null,
      currentPart: -1,
      error: null,
      match: this.getDefaultMatch(),
    };
  },
  computed: {
    ...mapGetters('misc', ['isMobile']),
    loading() {
      return this.pLoading;
    },
    pStatistics() {
      return this.statistics?.[0] || {};
    },
    part() {
      const parts = this.parts || [];
      return parts.find((item) => item.id === this.currentPart);
    },
    parts() {
      if (!this.pStatistics?.parts) {
        return null;
      }

      return this.pStatistics?.parts.map((part) => {
        const result = {
          ...part,
          types: part.types.map((type) => {
            const isScore = this.isTypeScore(type);
            const scoreParameters = {};

            if (isScore) {
              scoreParameters.metricSuffix = '';
              scoreParameters.winStatus =
                type.team_home > type.team_away
                  ? -1
                  : type.team_home < type.team_away
                    ? 1
                    : 0;

              const sum = type.team_home + type.team_away;
              if (sum !== 0) {
                scoreParameters.teamHomeWeight = (type.team_home / sum) * 100;
                scoreParameters.teamAwayWeight = (type.team_away / sum) * 100;
              } else {
                scoreParameters.teamHomeWeight = 0;
                scoreParameters.teamAwayWeight = 0;
              }
            }

            return {
              ...type,
              isScore,
              scoreParameters,
            };
          }),
        };

        result.types.sort((a, b) => {
          if (a.isScore === b.isScore) {
            return 0;
          }

          if (a.isScore) {
            return -1;
          }

          if (b.isScore) {
            return 1;
          }
        });

        return result;
      });
    },
    tabsItems() {
      return this.parts.map((items) => ({
        label: items.id,
        name: items.name,
      }));
    },
    isError() {
      return !this.parts || this.parts.length === 0 || this.error;
    },
    isLive() {
      return this.pMatch?.is_live;
    },
    component() {
      return this.isMobile ? 'div' : 'el-scrollbar';
    },
  },
  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
    // match: {
    //   immediate: true,
    //   handler(value) {
    //     const eventPartId = value?.event_part_id;
    //     if (eventPartId) {
    //       this.currentPart = eventPartId;
    //     }
    //   },
    // },
    id: {
      immediate: true,
      handler() {
        this.init();
      },
    },
  },

  methods: {
    async init() {
      this.pLoading = true;
      try {
        const response = await this.$api.postEventResultsInfo({
          id: this.id,
        });
        const { list } = response.data.payload;
        this.statistics = list;
        if (this.statistics?.[0].parts?.[0]) {
          this.currentPart = this.statistics?.[0].parts?.[0].id;
        }

        this.match = {
          ...this.getDefaultMatch(),
          ...this.pStatistics,
        };
      } catch (error) {
        this.error = error;
      } finally {
        this.pLoading = false;
      }
    },
    isTypeScore(value) {
      return (
        typeof value.team_home !== 'undefined' ||
        typeof value.team_away !== 'undefined'
      );
    },
  },
};
</script>

<style lang="scss">
.statistics-panel {
  &__tabs-separator {
    margin-bottom: 16px;
    margin-top: -17px;
  }

  &__statistics-wrapper {
    border: 1px solid var(--color-on-surface);
    border-radius: 8px;
    padding: 16px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  &__empty-text {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.67;
    color: var(--color-text-secondary);
  }

  &__score {
    display: flex;
  }

  &__score-team {
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-basis: 100px;
  }

  &__score-team-logo {
    width: 32px;
    height: 32px;
  }

  &__score-team-name {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    text-align: center;
  }

  &__score-value-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    padding: 0 8px;
  }

  &__score-value {
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
  }

  &__score-live-badge {
    width: 31px;
    height: 16px;
  }

  &__statistics-chart {
    display: flex;
  }

  &__statistics-chart-wrapper {
    flex-grow: 1;
  }

  &__statistics-chart-header {
    display: flex;
  }

  &__statistics-chart-header-value {
    flex-grow: 0;
    flex-shrink: 1;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
  }

  &__statistics-chart-header-type-name {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
  }

  &__statistics-chart-part {
    height: 4px;
    flex-grow: 1;
    border-radius: 3px;
    background-color: var(--color-on-surface);
    display: flex;

    & + & {
      margin-left: 8px;
    }

    &_right {
      justify-content: end;
    }
  }

  &__statistics-chart-part-filler {
    height: 100%;
    background-color: var(--color-highlight);
    border-radius: 3px;

    &_winner {
      background-color: var(--color-primary);
    }
  }

  &__statistics-type {
    display: flex;

    & + & {
      margin-top: 16px;
    }

    &_text {
      & + & {
        margin-top: 8px;
        padding-top: 8px;
        border-top: 1px solid var(--color-on-surface);
      }
    }

    &_score + &_text {
      margin-top: 16px;
    }
  }

  &__statistics-part-name {
    font-size: 14px;
    font-weight: 600;
  }

  &__statistics-type-name {
    flex-grow: 1;
    font-size: 12px;
    font-weight: 600;
    flex-basis: 100px;
  }

  &__statistics-type-result {
    flex-grow: 1;
    font-size: 12px;
    font-weight: 500;
    flex-basis: 100px;
    display: flex;
    justify-content: end;
    text-align: end;
  }

  .el-scrollbar__wrap {
    margin-bottom: 0 !important;
  }

  &__tabs {
    &.tabs-buttons {
      // padding-left: 0;
      // padding-right: 0;

      .tabs-buttons__item {
        font-size: 12px;
        font-weight: bold;
        line-height: 1.17;
      }
    }
  }
}
</style>
