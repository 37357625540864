import { render, staticRenderFns } from "./CouponSkeleton.vue?vue&type=template&id=7bbd7bfb&scoped=true"
import script from "./CouponSkeleton.vue?vue&type=script&lang=js"
export * from "./CouponSkeleton.vue?vue&type=script&lang=js"
import style0 from "./CouponSkeleton.vue?vue&type=style&index=0&id=7bbd7bfb&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bbd7bfb",
  null
  
)

export default component.exports