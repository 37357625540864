export function getIsMobile() {
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  return width < 1024;
}

export function initIsMobile() {
  localStorage.setItem('is-mobile', getIsMobile());
}

export function isNeedMobileChange() {
  const isMobile = localStorage.getItem('is-mobile') === 'true';
  const currentIsMobile = getIsMobile();

  if (isMobile !== currentIsMobile) {
    location.reload();
  }
}
