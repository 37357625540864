<template>
  <div>
    <p class="mb-lg">
      Enter Recovery Code From
      {{ recoveryCodeType === 'email' ? 'Email' : 'SMS' }}
    </p>
    <UiInput
      v-model="code"
      placeholder="Recovery Code"
      label="Recovery Code"
      :error="errors.email"
    />
  </div>
</template>
<script>
export default {
  name: 'RecoveryCodePanel',

  props: {
    recoveryCodeType: {
      type: String,
      default() {
        return 'email';
      },
    },
  },

  data() {
    return {
      isVisible: false,
      code: '',
      errors: {},
    };
  },
};
</script>
