<template>
  <div class="coupon-sum">
    <template v-if="isWinOrGame">
      <div>
        {{ balance.currency_id | dictionary('currencies', 'symbol') }}&#x202f;{{ info.amount | formatMoney }}
      </div>
      <UiIcon
        :size="14"
        name="long-arrow-right"
        lib="fa"
        substyle="fas"
        class="mx-2xs"
      />
    </template>
    <div
      class="coupon-sum-text"
      :class="[`coupon-sum-text_${status.type}`]"
    >
      {{ balance.currency_id | dictionary('currencies', 'symbol')}}&#x202f;{{ computedSum | formatCoef }}
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';

export default {
  name: 'CouponSum',

  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    status: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    ...mapGetters('auth', ['balance']),
    isWinOrGame() {
      return (
        this.info.status !== 3 && this.info.status !== 4
      );
    },
    computedSum() {
      return this.info.status === 3 || this.info.status === 4 ? this.info.amount : this.info.status === 2 ? this.info.amount_win : this.info.possible_win;
    },
  },
};
</script>

<style lang="scss">
.coupon-sum{
  font-size: 14px;
  font-weight: 700;
  line-height: 1.25;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  &-text {
    &_info {
      color: var(--color-primary);
    }
    &_lost {
      color: var(--color-text-error);
    }
    &_won {
      color: var(--color-success);
    }
    &_canceled {
      color: var(--color-text);
    }
  }
}
</style>
