export default {
  getUserProfile() {
    return this.baseApi.get('/user/profile');
  },
  getUserTwoFactorRecoveryCodes() {
    return this.baseApi.get('/user/two-factor-recovery-codes');
  },
  postUserTwoFactorRecoveryCodes() {
    return this.baseApi.post('/user/two-factor-recovery-codes');
  },
  deleteUserTwoFactorAuthentication(payload) {
    return this.baseApi.delete('/user/two-factor-authentication', {
      data: payload,
    });
  },
  postUserTwoFactorAuthentication() {
    return this.baseApi.post('/user/two-factor-authentication');
  },
  getUserBalance() {
    return this.baseApi.get('/user/balance');
  },
  postUserAccount(payload) {
    return this.baseApi.post('/user/account', payload);
  },
  deleteUserAccount(accountId) {
    return this.baseApi.delete(`/user/account/${accountId}`);
  },
  deleteBonusAccount(accountId) {
    return this.baseApi.delete(`/user/bonus/${accountId}`);
  },
  putUserBonusChange(payload) {
    return this.baseApi.put('/user/bonus/change', payload);
  },
  getUserProfileForm(params) {
    return this.baseApi.get('/user/profile-form', { params });
  },
  postUserProfileForm(payload) {
    return this.baseApi.post('/user/profile-form', payload);
  },
  getUserTransactionHistory(params, config = {}) {
    return this.baseApi.get('/user/transaction-history', { params, ...config });
  },
  postUserCodeSend(type) {
    return this.baseApi.post(`user/code/send/${type}`);
  },
  putUserChangePassword(payload) {
    return this.baseApi.put('/user/password/change', payload);
  },
  getUserTokens() {
    return this.baseApi.get('/user/tokens');
  },
  deleteUserToken(tokenId) {
    return this.baseApi.delete(`/user/token/${tokenId}`);
  },
  getSumsubAccessToken() {
    return this.baseApi.get('/user/sumsub/access-token');
  },
  getUserGifts(params) {
    return this.baseApi.get('/user/gifts', { params });
  },
  deleteUserGift(id) {
    return this.baseApi.delete(`/user/gift/${id}`);
  },
  postPromoCodeActivate(payload) {
    return this.baseApi.post('/user/promo-codes/activate', payload);
  },
  getUserPaymentToken() {
    return this.baseApi.get('/user/payment-token');
  },
};
