<template>
  <div class="resend-code-button">
    <span
      :class="[
        'resend-code-button__resend-message',
        { 'resend-code-button__resend-message_active': isActive },
      ]"
      @click="handleResend"
    >
      <template v-if="isResend">
        {{ $t('buttons.sendCodeAgain') }}
      </template>
      <template v-else-if="!isCodeSended">
        {{ $t('buttons.sendCodeAgain') }}
      </template>
      <template v-else> {{ message }}</template>
    </span>
  </div>
</template>

<script>
export default {
  name: 'ResendCodeButton',

  props: {
    message: {
      type: String,
      default: null,
    },
    isResend: {
      type: Boolean,
      default: false,
    },
    isCodeSended: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isActive() {
      return this.isResend && this.isCodeSended;
    }
  },

  methods: {
    handleResend(event) {
      if (this.isActive) {
        console.log('click!!', event);
        this.$emit('resend', event);
      }
    },
  },
};
</script>

<style lang="scss">
.resend-code-button {
  &__resend-message {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--color-text-disable);

    &_active {
      cursor: pointer;
      user-select: none;
      color: var(--color-primary);
    }
  }
}
</style>
