import Vue from 'vue';

export default {
  computed: {
    bonusList() {
      return [
        {
          id: 0,
          name: this.$t('profile.bonus.refuse'),
        },
        {
          id: 1,
          name: this.$t('profile.bonus.sport'),
        },
        {
          id: 2,
          name: this.$t('profile.bonus.casino'),
        },
      ];
    },

    idBonusList() {
      const indexList = {};
      this.bonusList.forEach((item) => {
        Vue.set(indexList, item.id, item);
      });
      return indexList;
    },

    transactionsStatusList() {
      return [
        {
          value: '',
          name: this.$t('profile.all'),
        },
        {
          value: '1',
          name: this.$t('profile.transactions.status.pending'),
          type: 'pending',
        },
        {
          value: '2',
          name: this.$t('profile.transactions.status.sent'),
          type: 'sent',
        },
        {
          value: '3',
          name: this.$t('profile.transactions.status.waiting'),
          type: 'waiting',
        },
        {
          value: '4',
          name: this.$t('profile.transactions.status.succeeded'),
          type: 'succeeded',
        },
        {
          value: '5',
          name: this.$t('profile.transactions.status.canceled'),
          type: 'canceled',
        },
        {
          value: '6',
          name: this.$t('profile.transactions.status.refunded'),
          type: 'refunded',
        },
      ];
    },

    idTransactionsStatusList() {
      const indexList = {};
      this.transactionsStatusList.forEach((item) => {
        Vue.set(indexList, item.value, item);
      });
      return indexList;
    },

    statusList() {
      return [
        {
          value: '',
          name: this.$t('profile.all'),
        },
        {
          value: '1',
          name: this.$t('profile.bets.status.inGame'),
          type: 'info',
        },
        {
          value: '3',
          name: this.$t('profile.bets.status.lost'),
          type: 'lost',
        },
        {
          value: '2',
          name: this.$t('profile.bets.status.won'),
          type: 'won',
        },
        {
          value: '4',
          name: this.$t('profile.bets.status.canceled'),
          type: 'canceled',
        },
      ];
    },

    idStatusList() {
      const indexList = {};
      this.statusList.forEach((item) => {
        Vue.set(indexList, item.value, item);
      });
      return indexList;
    },

    typeList() {
      return [
        {
          label: '',
          value: '',
          name: this.$t('profile.all'),
        },
        {
          label: '1',
          value: '1',
          type: 'single',
          name: this.$t('profile.bets.type.single'),
        },
        {
          label: '2',
          value: '2',
          type: 'accumulator',
          name: this.$t('profile.bets.type.accumulator'),
        },
      ];
    },

    idTypeList() {
      const indexList = {};
      this.typeList.forEach((item) => {
        Vue.set(indexList, item.value, item);
      });
      return indexList;
    },
  },

  methods: {
    formatEvents(events) {
      return events.map((item) => {
        return {
          odds: item.odds,
          name: item.outcome_name,
          marketName: item.market_name,
          matchName: item.name,
          isLive: item.is_live,
          partName: item.event_part_name,
          tournamentName: item?.tournament?.name || '',
          image:
            item?.tournament?.image ||
            this.$config.params.assets.defaultTournamentLogo,
          sportId: item.sport_id,
          statusId: item.status_id,
          teamHome: item.team_home,
          teamAway: item.team_away,
          round: item.round,
          startTime: item.start_time,
        };
      });
    },
  },
};
