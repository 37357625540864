import Vue from 'vue';
import Router from 'vue-router';

import Market from './modules/market';
import Profile from './modules/profile';
import Auth from './modules/auth';
import Search from './modules/search';
import Result from './modules/result';
import Promotions from './modules/promotions';
import MobileApp from './modules/mobile-app';
import Casino from './modules/casino';
import Other from './modules/other';

function genRouterFunc(originalFunc) {
  return function push(location, onComplete, onAbort) {
    const result = originalFunc.call(this, location, onComplete, onAbort);
    return result
      ? result.catch((err) => {
        if (err && err.name !== 'NavigationDuplicated') {
          return Promise.reject(err);
        }
      })
      : result;
  };
}

const originalPush = Router.prototype.push;
Router.prototype.push = genRouterFunc(originalPush);

const originalReplace = Router.prototype.replace;
Router.prototype.replace = genRouterFunc(originalReplace);

Vue.use(Router);

const RouterInstance = new Router({
  routes: [
    ...Market,
    ...Profile,
    ...Auth,
    ...Search,
    ...Casino,
    ...Result,
    ...Promotions,
    ...MobileApp,
    ...Other,
    // should be last
    {
      name: '404',
      path: '*',
      redirect: '/',
    },
  ],
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 200);
    });
  },
  mode: 'history',
});

export default RouterInstance;
