<template>
  <div class="promocodes">
    <div class="promocodes__info">
      <div class="promocodes__info-title">
        <UiIcon
          :size="14"
          name="ticket"
          lib="fa"
          substyle="fas"
          class="mr-2xs"
        />
        {{ $t('profile.bonuses.promocodes.title') }}
      </div>
      <div class="promocodes__info-text">{{ $t('profile.bonuses.promocodes.text') }}</div>
    </div>
    <div class="promocodes__action">
      <UiInput
        v-model="promocode"
        class="promocodes__action-input"
        :disabled="isRequest"
        :placeholder="$t('profile.bonuses.promocodes.placeholder')"
        is-clearable
        is-search
        type="text"
      />
      <UiButton
        class="promocodes__action-button"
        :icon-color="$config.cp.text"
        substyle="fas"
        icon="check"
        :icon-size="20"
        size="medium"
        :disabled="isDisabled"
        @click="checkPromocode"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'Promocodes',

  data() {
    return {
      errors: {},
      promocode: '',
      isRequest: false,
    }
  },

  computed: {
    isDisabled() {
      return this.promocode === '' || this.isRequest;
    }
  },

  methods: {
    async checkPromocode() {
      this.isRequest = true;
      try {
        await this.$api.postPromoCodeActivate({code: this.promocode });
        this.promocode = '';

        this.$eventBus.$emit('notify-event', {
          type: 'success',
          text: this.$t('profile.bonuses.promocodes.success'),
        });
        this.$eventBus.$emit('changed-bonuses', true);

      } catch (error) {
        this.$eventBus.$emit('notify-event', {
          type: 'error',
          text: error.data.message,
        });
      } finally {
        this.isRequest = false;
      }


    }
  }
};
</script>

<style lang="scss">
.promocodes{
  border-radius: 8px;
  background-color: var(--color-surface);
  color: var(--color-text);
  padding: 16px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  &__info {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    &-title{
      font-size: 14px;
      font-weight: 700;
      line-height: 1.43;
      color: var(--color-text);
      margin-bottom: 4px;

      display: flex;
      align-items: center;
    }
    &-text {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.33;
      color: var(--color-text-secondary);
    }
  }
  &__action {
    display: flex;
    align-items: center;
    width: 100%;
    &-input{
      width: calc(100% - 48px);
      margin-right: 8px;
    }
    &-button {
      width: 40px;
      height: 40px;
      min-width: 40px;
      i{
        margin-right: 0;
      }
    }
  }
}
</style>
