<template>
  <div class="profile-list">
    <div class="flex items-center justify-between">
      <UiTitle>{{ $t('profile.title') }}</UiTitle>
      <div v-if="isAuthenticated && balance" class="profile-list__balance">
        {{balance.currency_id | dictionary('currencies', 'symbol') }}&#x202f;{{ balance.balance | formatMoney }}
        <UiIcon :size="16" name="wallet" lib="fa" substyle="fas" class="ml-sm" />
      </div>
    </div>
    <div
      v-if="isAuthenticated && profile"
      class="flex items-center justify-between"
    >
      <div class="profile-list__id">
        {{ $t('profile.id') }}: {{ profile.id }}
      </div>
      <div class="profile-list__gift">
        {{ pBonusBalance.currency_id | dictionary('currencies', 'symbol') }}&#x202f;{{ pBonusBalance.balance | formatMoney }}
        <UiIcon
          :size="16"
          name="gift"
          lib="fa"
          substyle="fas"
          class="ml-sm"
        />
      </div>
    </div>
    <div class="flex space-x-lg mt-lg mb-lg">
      <template v-if="!isAuthenticated">
        <router-link class="w-1/2 no-underline" to="/auth/login">
          <UiButton substyle="fas" type="secondary" icon="right-to-bracket">
            {{ $t('buttons.login') }}
          </UiButton>
        </router-link>
        <router-link class="w-1/2 no-underline" to="/auth/registration">
          <UiButton substyle="fas" icon="user-plus">
            {{ $t('buttons.signUp') }}
          </UiButton>
        </router-link>
      </template>
      <template v-else>
        <router-link class="w-1/2 no-underline" to="/payments/withdraw">
          <UiButton substyle="fas" type="secondary" icon="money-bill-wave">
            {{ $t('buttons.withdrawal') }}
          </UiButton>
        </router-link>
        <router-link class="w-1/2 no-underline" to="/payments/deposit">
          <UiButton substyle="fas" icon="plus">
            {{ $t('buttons.deposit') }}
          </UiButton>
        </router-link>
      </template>
    </div>
    <router-link class="no-underline" to="/promotions">
      <UiButton
        class="promo-button mb-md"
        substyle="fas"
        icon="gift"
        type="secondary"
      >
        {{ $t('buttons.promotions') }}
        <img src="../../assets/images/present-mob.png" alt="" />
      </UiButton>
    </router-link>
    <router-link
      v-if="isAuthenticated"
      class="no-underline"
      to="/profile/history"
    >
      <UiButton
        class="promo-button mb-lg"
        substyle="fas"
        icon="tag"
        type="secondary"
      >
        {{ $t('profile.menu.history') }}
        <div class="flex justify-between align-center items-center">
          <UiIcon
            :size="12"
            :color="$config.cp['text-secondary']"
            name="angle-right"
            lib="fa"
            substyle="fas"
            class="ml-md promo-button__icon"
          />
        </div>
      </UiButton>
    </router-link>
    <ProfileConfirmation v-if="isAuthenticated" class="" />
    <template>
      <div v-for="(item, id) in computedProfileMenu" :key="id">
        <ProfileNavLink
          v-if="item.link"
          :info="item"
          :tfa-enable="is2FAEnabled"
        />
        <ProfileNavButton v-else :info="item" :tfa-enable="is2FAEnabled" />
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ProfileNavLink from '@/views/Profile/components/ProfileNav/ProfileNavLink';
import ProfileNavButton from '@/views/Profile/components/ProfileNav/ProfileNavButton';
import ProfileConfirmation from '@/views/Profile/components/ProfileConfirmation';
import logic from '@/views/Profile/Profile/logic.js';
import betMixin from '@/helpers/betMixin';


export default {
  name: 'ProfileList',

  components: {
    ProfileNavLink,
    ProfileNavButton,
    ProfileConfirmation,
  },

  mixins: [logic, betMixin],

  data() {
    return {
      tfaEnable: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['isAuthenticated', 'balance']),
    ...mapGetters('auth', { profile: 'user' }),
    ...mapGetters('auth', ['balance', 'user']),
    is2FAEnabled() {
      return !!this.user?.two_factor_enabled;
    },
    comfirmationCaption() {
      const { phone, email } = this.profile || {};
      if (phone && email) {
        return '';
      }

      const subject = !(phone || email) ? 'both' : email ? 'phone' : 'email';

      const notConfirmedSubject = this.$t(
        `profile.notConfirmedSubject.${subject}`
      );
      return `${notConfirmedSubject} ${this.$t('profile.notConfirmedMessage')}`;
    },
    profileMenu() {
      return [
        {
          name: this.$t('profile.menu.info'),
          type: 'info',
          icon: 'user',
          link: '/profile/info',
          authRequred: true,
        },
        {
          name: this.$t('profile.menu.wallet'),
          type: 'wallet',
          icon: 'wallet',
          link: '/profile/wallets',
          authRequred: true,
        },
        {
          name: this.$t('profile.menu.bonuses'),
          type: 'bonuses',
          icon: 'gift',
          link: '/profile/bonuses',
          authRequred: true,
        },
        {
          name: this.$t('profile.menu.2fa'),
          type: '2fa',
          icon: 'mobile-alt',
          link: '/profile/2fa',
          authRequred: true,
        },
        {
          name: this.$t('profile.menu.newPassword'),
          type: 'newPassword',
          icon: 'key',
          link: '/auth/new-password',
          authRequred: true,
        },
        {
          name: this.$t('profile.menu.sessions'),
          type: 'sessions',
          icon: 'laptop-mobile',
          link: '/profile/sessions',
          authRequred: true,
        },
        {
          name: this.$t('profile.menu.results'),
          type: 'results',
          icon: 'table-list',
          link: '/results',
          authRequred: false,
        },
        {
          name: this.$t('profile.menu.contactsMobile'),
          type: 'contactsMobile',
          view: 'contacts',
          icon: 'phone',
          link: '/profile/contacts',
          authRequred: false,
        },
        {
          name: this.$t('profile.menu.logout'),
          type: 'logout',
          icon: 'right-to-bracket',
          click: 'logout',
          authRequred: true,
        },
      ];
    },

    computedProfileMenu() {
      return this.isAuthenticated
        ? this.profileMenu
        : this.profileMenu.filter((item) => !item.authRequred);
    },
  },
};
</script>

<style lang="scss">
.profile-list {
  &__balance {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
  }
  &__id {
    font-size: 12px;
    font-weight: 500;
    color: var(--color-text-secondary);
  }
  &__gift {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    color: var(--color-text-secondary);
  }
  .promo-button {
    height: 40px;
    justify-content: flex-start;
    overflow: initial;
    .ui-button__text{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-basis: 100%;
    }
    img {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 48px;
    }
  }
}
</style>
