<template>
  <div class="signup-page">
    <template v-if="!!registrationParams">
      <template
        v-if="registrationParams && state === signUpSteps.SIGNUP_STATE_REGISTER"
      >
        <UiTitle> {{ $t('auth.signUp.title') }}</UiTitle>
        <TabsButtons
          class="mx-rlg mb-lg"
          type="tabs"
          :current-tab.sync="currentTab"
          :items="tabsItems"
        />
      </template>
      <SignupPanel
        v-show="state === signUpSteps.SIGNUP_STATE_REGISTER"
        ref="signupPanel"
        :default-form="defaultForm"
        :current-tab="currentTab"
        :form="form"
        :form-meta="formMeta"
        :errors="pErrors"
        :isValid.sync="isValid"
      />
      <SignupConfirmPanel
        v-if="state === signUpSteps.SIGNUP_STATE_CONFIRM"
        ref="confirm"
        :current-tab="currentTab"
        :code="code"
        :form="form"
        :form-meta="formMeta"
        :is-valid.sync="isValidConfirm"
        @back="handleBack"
      ></SignupConfirmPanel>
      <div class="mt-lg">
        <UiButton
          v-if="isOneStep"
          substyle="fas"
          icon="check"
          :disabled="isConfirmDisabled || loading"
          @click="handleSignup"
        >
          {{ $t('buttons.signUp') }}
        </UiButton>
        <UiButton
          v-if="isFinalStep"
          substyle="fas"
          icon="check"
          :disabled="isConfirmDisabled || loading"
          @click="handleSignup"
        >
          {{ $t('buttons.confirm') }}
        </UiButton>
        <UiButton
          v-if="!isFinalStep && !isOneStep"
          substyle="fas"
          icon="arrow-right"
          :disabled="isSingupDisabled || loading"
          @click="handleContinue"
        >
          {{ $t('buttons.continue') }}
        </UiButton>
      </div>
    </template>
    <SignupSkeleton v-else />
  </div>
</template>

<script>
import logic from '@/views/Auth/components/Signup/logic.js';
import SignupPanel from '@/views/Auth/components/Signup/SignupPanel';
import SignupConfirmPanel from '@/views/Auth/components/Signup/SignupConfirmPanel';
import SignupSkeleton from '@/views/Auth/components/Signup/SignupSkeleton.vue';

export default {
  components: {
    SignupPanel,
    SignupConfirmPanel,
    SignupSkeleton,
  },
  mixins: [logic],
  mounted() {
    this.init();
  },
  methods: {
    handleRegistrationComplete() {
      this.$router.push('/');
    },
  },
};
</script>

<style lang="scss">
.signup-page {
  .add-promocode {
    margin: 16px 0;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: var(--color-link);
    cursor: pointer;
    transition: 0.2s linear all;
    i {
      margin-right: 4px;
    }
    &:hover {
      color: var(--color-link-hover);
    }
    &_hide {
      opacity: 0;
      height: 0;
    }
  }
  .promocode-enter-active,
  .promocode-leave-active {
    will-change: height, opacity;
    transition: height 0.2s ease, opacity 0.2s ease;
    overflow: hidden;
  }

  .promocode-enter,
  .promocode-leave-to {
    height: 0 !important;
    opacity: 0;
  }
}
</style>
