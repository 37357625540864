<template>
  <Popup
    class="confirmation-popup disable-2fa-popup"
    :title="$t('popups.common2FA.title')"
    :is-visible.sync="isVisible"
  >
    <Disable2FAPanel
      slot="body"
      :loading="loading"
      :form="form"
      :errors="errors"
    />
    <template slot="footer">
      <UiButton
        class="mb-lg"
        substyle="fas"
        icon="times"
        :disabled="loading || !isValid"
        @click="handleTurnOff"
      >
        {{ $t('buttons.turnOff') }}
      </UiButton>
      <p class="disable-2fa-popup__subtext">
        {{ $t('profile.2fa.disable2FAPanel.step2') }}
      </p>
    </template>
  </Popup>
</template>

<script>
import logic from '@/views/Auth/components/Disable2FA/logic';
import Disable2FAPanel from '@/views/Auth/components/Disable2FA/Disable2FAPanel.vue';

export default {
  name: 'Disable2FA',

  mixins: [logic],

  components: { Disable2FAPanel },

  data() {
    return {
      isVisible: false,
    };
  },

  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },

  methods: {
    open() {
      this.isVisible = true;
      this.init();
    },

    close() {
      this.isVisible = false;
    },
  },
};
</script>
<style lang="scss">
.disable-2fa-popup {
  &__subtext {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.67;
    color: var(--color-text-secondary);
  }
}
</style>
