var render = function (_h,_vm) {
var _obj;
var _c=_vm._c;return _c('div',_vm._b({staticClass:"layout-panel",class:Object.assign(( _obj = {}, _obj[_vm.data.staticClass] = _vm.data.staticClass, _obj ),
    (_vm.data.class || {})),style:(_vm.data.staticStyle || ''),on:{"click":function($event){_vm.listeners.click && _vm.listeners.click($event)}}},'div',_vm.data.attrs,false),[_c('div',{staticClass:"layout-panel__content-wrapper",class:[
      ("layout-panel__content-wrapper_size-" + (_vm.props.size)),
      ("layout-panel__content-wrapper_type-" + (_vm.props.active ? 'active' : _vm.props.type)),
      {
        'layout-panel__content-wrapper_error': _vm.props.error,
      } ]},[_vm._t("prepend"),_c('div',{staticClass:"layout-panel__content"},[_vm._t("default")],2),_vm._t("append")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }