<template>
  <img
    class="logo"
    :class="{
      logo_big: size === 'big',
      logo_medium: size === 'medium',
    }"
    :src="$config.theme.logo"
    alt="defaultbet-logo"
  />
</template>

<script>
export default {
  name: 'UiLogo',

  props: {
    size: {
      type: String,
      default() {
        return 'medium';
      },
    },
  },
};
</script>

<style lang="scss">
.logo {
  &_medium {
    height: 17px;
  }
  &_bet {
    height: 20px;
  }
  &_big {
    width: 134px;
  }
}
</style>
