export default {
  postBetsUpdate(payload) {
    return this.baseApi.post('/bets/update', payload);
  },
  postBetsMake(payload) {
    return this.baseApi.post('/bets/make', payload);
  },
  postBetsSave(payload) {
    return this.baseApi.post('/bets/save', payload);
  },
  getBetsLoad(params) {
    return this.baseApi.get('/bets/load', { params });
  },
  getBetsHistory(params, config = {}) {
    return this.baseApi.get('/bets/history', { params, ...config });
  },
};
