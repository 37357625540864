<template>
  <div class="profile-sessions">
    <UiTitle class="mb-lg" :back-route="backRoute">
      {{ $t('profile.sessions.title') }}
    </UiTitle>
    <template v-if="!error && !isLoading">
      <div class="profile-sessions__title">
        {{ $t('profile.sessions.current') }}
      </div>
      <ProfileSessionItem class="mb-lg" :item="currentSession" :hide-button="otherSessions.length === 0">
        <UiButton substyle="fas" icon="times">
          {{ $t('profile.sessions.terminateAll') }}
        </UiButton>
      </ProfileSessionItem>
      <template v-if="otherSessions.length > 0">
        <div class="profile-sessions__title">
          {{ $t('profile.sessions.active') }}
        </div>
        <ProfileSessionItem
          v-for="item in otherSessions"
          :key="item.id"
          class="mb-md"
          :item="item"
        />
      </template>
    </template>
    <template v-if="isLoading">
      <ProfileSessionsSkeleton />
    </template>
    <Tech v-if="error" @button-action="getSessionsList()"/>
  </div>
</template>

<script>
import ProfileSessionItem from '@/views/Profile/ProfileSessions/components/ProfileSessionItem/selector';
import { mapGetters } from 'vuex';
import ProfileSessionsSkeleton from '@/views/Profile/ProfileSessions/components/ProfileSessionsSkeleton.vue';

export default {
  name: 'ProfileSessions',
  components: {
    ProfileSessionsSkeleton,
    ProfileSessionItem,
  },

  data() {
    return {
      isLoading: true,
      sessionsList: [],
      error: false,
    };
  },

  computed: {
    ...mapGetters('misc', ['isMobile']),
    backRoute() {
      return this.isMobile ? '/more' : '';
    },
    currentSession() {
      return this.$_.find(this.sessionsList, { is_current: 1 }) || null;
    },
    otherSessions() {
      return (
        this.$_.filter(this.sessionsList, (item) => {
          return !item.is_current;
        }) || []
      );
    },
  },

  mounted() {
    this.getSessionsList();
    this.$eventBus.$on('update-sessions', this.getSessionsList);
  },

  beforeDestroy() {
    this.$eventBus.$on('update-sessions', this.getSessionsList);
  },

  methods: {
    async getSessionsList() {
      this.isLoading = true;
      this.error = false;
      try {
        const response = await this.$api.getUserTokens();
        this.sessionsList = this.$_.cloneDeep(response.data.payload.list);
        this.isLoading = false;
      } catch (error) {
        this.error = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.profile-sessions {
  &__title {
    font-size: 14px;
    font-weight: 600;
    line-height: 2.29;
    color: var(--color-text);
    margin-bottom: 4px;
  }
}
</style>
