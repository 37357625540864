<template>
  <Popup
    class="setup-2fa-popup"
    :title="$t('popups.common2FA.title')"
    :is-visible.sync="isVisible"
    size="medium"
    :is-footer="isMobile"
    @close="close"
  >
    <el-scrollbar view-class="setup-2fa-popup__scrollbar" slot="body">
      <Setup2FAPanel
        v-if="initialized"
        :form="form"
        :is-valid="isValid"
        :initialized="initialized"
        :qrUrl="qrUrl"
        :linkUrl="linkUrl"
        :secret="secret"
        :loading="loading"
        :errors="errors"
        :handle-confirm="handleConfirm"
      />

      <Setup2FASkeleton v-else />
    </el-scrollbar>
    <UiButton
      slot="footer"
      class="mb-sm"
      substyle="fas"
      icon="check"
      :disabled="!isValid || loading || !initialized"
      @click="handleConfirm"
    >
      {{ $t('buttons.confirm') }}
    </UiButton>
  </Popup>
</template>

<script>
import logic from '@/views/Auth/components/Setup2FA/logic';
import Setup2FAPanel from '@/views/Auth/components/Setup2FA/selector';
import Setup2FASkeleton from '@/views/Auth/components/Setup2FA/components/Setup2FASkeleton/selector';
import { mapGetters } from 'vuex';

export default {
  name: 'Setup2FA',
  components: { Setup2FAPanel, Setup2FASkeleton },
  mixins: [logic],

  props: {
    closeDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isVisible: false,
      initialized: false,
    };
  },

  computed: {
    ...mapGetters('misc', ['isMobile']),
  },

  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },

  methods: {
    async open() {
      this.isVisible = true;
      this.loading = true;
      this.initialized = false;
      try {
        await this.init();
      } finally {
        this.loading = false;
        this.initialized = true;
      }
    },

    close() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
.setup-2fa-popup {
  .el-scrollbar__wrap {
    margin-bottom: 0 !important;
  }
  &__scrollbar {
    max-height: calc(100vh - 300px);
  }
}
</style>
