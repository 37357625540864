const path = require('path');

module.exports = {
  getConfig: function (includeThemes = false) {
    const result = {
      favicon: path.resolve(__dirname, 'assets/favicon.png'),
    };

    if (includeThemes) {
      result.defaultTheme = 'dark';
      result.themes = {
        dark: require('./themes/dark.js'),
        light: require('./themes/light.js'),
      };
      result.assets = {
        all: require('@/assets/images/all.svg'),
        defaultSportLogo: require('@/assets/images/sport.svg'),
        defaultTournamentLogo: require('@/assets/images/tournament.svg'),
        defaultCountryLogo: require('@/assets/images/country.svg'),
        defaultCasinoCategory: require('@/assets/images/casinoCategory.svg'),
      };
    }

    return result;
  },
};
