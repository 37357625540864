<template>
  <div>
    <CouponDivider :class="isFirst === 0 ? 'mt-xs' : ''" />
    <div
      class="coupon-item"
      :class="[`coupon-item_${theme}`]"
    >
      <div class="flex items-start justify-between mb-2xs">
        <div class="coupon-item__date">
          <template v-if="bet.start_time">
            <UiIcon
              :size="12"
              name="calendar-alt"
              lib="fa"
              substyle="fas"
              class="mr-2xs"
            />
            {{ eventDay }}
            {{ (bet.start_time * 1000) | time }}
          </template>
          <LiveBadge v-if="isLive" class="ml-2xs" />
        </div>
        <div
          class="coupon-item__odds"
          :class="[`coupon-item__odds_${betStatus.type}`]"
        >
          {{ bet.odds | formatCoef }}
        </div>
      </div>
      <div v-if="bet.tournament.name !== ''" class="coupon-item__tournament">
        <ItemLogo class="mr-r2xs" is-mask :link="sportIcon" />
        <div
          class="coupon-item__tournament-logo"
          :style="`background-image: url('${tournamentLogo}')`"
        ></div>
        {{ bet.tournament.name }}
      </div>
      <div class="coupon-item__coef-name">
        <strong>{{ bet.outcome_name }} – </strong> {{ `${bet.market_name} (${bet.event_part_name})` }}
      </div>
      <div class="coupon-item__team">
        <div class="flex items-center min-w-0">
          <div class="coupon-item__team-logo">
            <template v-if="bet.team_away">
              <TeamLogo :team-images="[bet.team_home.logo, bet.team_away.logo]" :is-radius="isRadius" />
            </template>
          </div>
          <div v-if="bet.team_away" class="coupon-item__team-name">
            <div>
              {{ bet.team_home.name }}
            </div>
            <div>
              {{ bet.team_away.name }}
            </div>
          </div>
        </div>
        <div v-if="hasScore" class="coupon-item__score">
          <div class="coupon-item__score-item">{{ bet.state.score.home }}</div>
          <div class="coupon-item__score-item">{{ bet.state.score.away }}</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import betMixin from '@/helpers/betMixin';
import CouponDivider from './CouponDivider.vue'
import {mapGetters} from 'vuex';

export default {
  name: 'CouponItem',

  components: {
    CouponDivider
  },

  mixins: [betMixin],

  props: {
    isFirst: {
      type: Number,
      default() {
        return null;
      },
    },
    bet: {
      type: Object,
      default() {
        return null;
      },
    },
  },

  computed: {
    ...mapGetters('misc', ['sportsIndex']),
    hasScore() {
      return this.bet?.state?.score;
    },
    isLive() {
      return this.bet?.state?.is_live;
    },
    betStatus() {
      return this.idStatusList[this.bet.status_id] || '';
    },
    sportIcon() {
      return this.sportsIndex[this.bet.sport_id]?.image;
    },
    isRadius() {
      return !!this.sportsIndex[this.bet.sport_id].round;
    },
    theme() {
      return this.$config.themeName;
    },
    tournamentLogo() {
      return this.bet?.tournament?.image || this.$config.params.assets.defaultTournamentLogo
    },
    eventDay() {
      let matchDay = null;
      if (this.bet) {
        matchDay = this.$options.filters.dateNOFormat(
          this.bet.start_time * 1000
        );

        const currentDate = this.$options.filters.dateNOFormat(Date.now());
        const isSameDay = currentDate.isSame(matchDay, 'day');
        const tomorrowDate = currentDate.add(1, 'day');
        const isNextDay = tomorrowDate.isSame(matchDay, 'day');

        if (isSameDay) {
          return this.$t('market.day.today');
        }
        if (isNextDay) {
          return this.$t('market.day.tomorrow');
        }
        return this.$options.filters.date(matchDay);
      }
      return '';
    },
  },


};
</script>

<style lang="scss">
.coupon-item{
  padding: 0 16px;
  position: relative;

  &__odds {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.1;
    color: var(--color-text);
    padding: 2px 8px;
    border-radius: 8px;
    &_info,
    &_canceled{
      background-color: var(--color-on-surface);
    }
    &_lost {
      background-color: var(--color-error);
      color: var(--color-on-primary);
    }
    &_won {
      background-color: var(--color-success);
      color: var(--color-on-primary);
    }
  }
  &__date {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: var(--color-text);
  }
  &__tournament {
    font-size: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: var(--color-text-secondary);
    margin-bottom: 8px;
    &-logo{
      width: 16px;
      min-width: 16px;
      display: inline-block;
      height: 16px;
      min-height: 16px;
      background-size: contain;
      margin-right: 8px;
    }
  }
  &__coef-name{
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    color: var(--color-text);
    margin-bottom: 12px;
    strong{
      font-weight: 700;
    }
  }
  &__team {
    align-items: center;
    display: flex;
    justify-content: space-between;
    &-logo {
      display: flex;
      margin-right: 12px;
      min-width: 56px;
      img{
        height: 32px;
        line-height: 0;
        width: 32px;
        z-index: 2;
        &:last-of-type {
          margin-left: -8px;
          z-index: 1;
        }
      }
      .team-logo__component{
        align-items: center;
        display: flex;
      }
    }
    &-name {
      min-width: 0;
      padding-right: 8px;
      font-size: 12px;
      line-height: normal;
      font-weight: 600;
      color: var(--color-text);
      div {
        flex-shrink: 1;
        height: 15px;
        margin-bottom: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  &__score{
    font-size: 12px;
    line-height: normal;
    font-weight: 600;
    text-align: right;
    color: var(--color-text);
    div {
      height: 15px;
      margin-bottom: 4px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
</style>
