<template>
  <div
    class="scroller"
    @touchstart="touchStartHandler"
    @touchend="touchEndHandler"
    @touchmove="touchMoveHandler"
  >
    <div
      v-show="isScrollable && isLeft"
      class="scroller__arrow scroller__arrow_left"
      :style="genScrollArrowStyles('left')"
      @click="leftClick"
    >
      <UiIcon :size="14" name="chevron-left" lib="fa" substyle="fas" />
    </div>
    <div class="scroller__content">
      <div
        class="scroller__wrapper"
        :class="{'scroller__anim': isAnim}"
        :style="{ left: computedLeftPosition }"
      >
        <slot />
      </div>
    </div>
    <div
      v-show="isScrollable && isRight"
      class="scroller__arrow scroller__arrow_right"
      :style="genScrollArrowStyles('right')"
      @click="rightClick"
    >
      <UiIcon :size="16" name="chevron-right" lib="fa" substyle="fas" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Scroller',

  props: {
    itemName: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: 'var(--color-background)',
    },
  },

  data() {
    return {
      scrollerContent: null,
      scrollerContentWidth: 0,
      scrollerWrapper: null,
      scrollerWrapperWidth: 0,
      listItemsCoords: [],
      leftPosition: 0,
      isAnim: true,
      touchStartPosition: null,
      touchEndPosition: null,
    };
  },

  computed: {
    initDebounced() {
      return this.$_.debounce(this.init, 300);
    },
    isScrollable() {
      return this.scrollerWrapperWidth > this.scrollerContentWidth;
    },
    computedLeftPosition() {
      return `${this.leftPosition}px`;
    },
    isRight() {
      return !(
        this.leftPosition <=
        -(this.scrollerWrapperWidth - this.scrollerContentWidth)
      );
    },
    isLeft() {
      return !(this.leftPosition >= 0);
    },
  },

  mounted() {
    this.init();
    window.addEventListener('resize', this.initDebounced);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.initDebounced);
  },

  methods: {
    touchStartHandler(event) {
      this.touchStartPosition = event.changedTouches[0].clientX;
      this.isAnim = false;
    },
    touchEndHandler() {
      this.isAnim = true;
    },
    touchMoveHandler(event) {
      this.touchEndPosition = event.changedTouches[0].clientX;

      const deltaPosition = this.touchEndPosition - this.touchStartPosition;
      this.touchStartPosition = this.touchEndPosition;

      const endPosition = -(
        this.scrollerWrapperWidth - this.scrollerContentWidth
      );

      if (this.leftPosition + deltaPosition < endPosition) {
        this.leftPosition = endPosition;
      } else if (this.leftPosition + deltaPosition > 0) {
        this.leftPosition = 0;
      } else {
        this.leftPosition = this.leftPosition + deltaPosition
      }
    },

    getWidth() {
      this.scrollerContent =
        this.$el.getElementsByClassName('scroller__content')[0];
      this.scrollerContentWidth = this.scrollerContent.offsetWidth;

      this.scrollerWrapper =
        this.$el.getElementsByClassName('scroller__wrapper')[0];
      this.scrollerWrapperWidth = this.scrollerWrapper.scrollWidth;
    },

    getListItemsCoords() {
      const tabItemsList = this.$el.querySelectorAll(`.${this.itemName}`);
      Array.from(tabItemsList).forEach((el) => {
        this.listItemsCoords.push(el.offsetLeft - 8);
      });
    },

    init() {
      this.getWidth();
      if (this.isScrollable) {
        this.getListItemsCoords();
      }
    },

    findTab(coord, arrow) {
      const less = [];
      const greater = [];
      for (let i = 0; i < this.listItemsCoords.length; i++) {
        if (coord === this.listItemsCoords[i]) {
          continue;
        }
        if (this.listItemsCoords[i] < coord) {
          less.push(this.listItemsCoords[i]);
        } else {
          greater.push(this.listItemsCoords[i]);
        }
      }
      if (arrow === 'left') {
        if (coord < 0) {
          return 0;
        }
        return greater.length ? greater[1] : 0;
      } else {
        return less.length ? less[less.length - 2] : 0;
      }
    },

    leftClick() {
      const nextStep = -this.findTab(
        -(this.leftPosition + this.scrollerContentWidth),
        'left'
      );
      if (nextStep > 0) {
        this.leftPosition = 0;
      } else {
        this.leftPosition = nextStep;
      }
    },

    rightClick() {
      console.log('rightClick');
      const nextStep = -this.findTab(
        -(this.leftPosition - this.scrollerContentWidth),
        'right'
      );
      const endPosition = -(
        this.scrollerWrapperWidth - this.scrollerContentWidth
      );
      if (nextStep < endPosition) {
        this.leftPosition = endPosition;
      } else {
        this.leftPosition = nextStep;
      }
    },
    genScrollArrowStyles(arrow) {
      const direction = arrow === 'left' ? 'right' : 'left';
      return {
        background: `linear-gradient(to ${direction}, ${this.backgroundColor}, transparent)`,
      };
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';
.scroller {
  position: relative;
  &__content {
    overflow: hidden;
  }
  &__wrapper {
    position: relative;
  }

  &__anim {
    transition: 0.5s left ease-in-out;
  }

  &__arrow {
    position: absolute;
    top: 0;
    width: 64px;
    height: 40px;
    display: flex;
    align-items: center;
    z-index: 2;
    cursor: pointer;
    &_left {
      justify-content: flex-start;
      left: 0;
    }
    &_right {
      justify-content: flex-end;
      right: 0;
    }
  }
}
@media (max-width: $screen-mobile) {
  .scroller {
    &__arrow {
      padding: 0 16px;
    }
  }
}
</style>
