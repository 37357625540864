<template>
  <div class="bonus-history-item">
    <div class="flex justify-between items-start mb-2xs">
      <div class="bonus-history-item__name">
        {{ pBonusName }}
      </div>
      <div class="flex items-center">
        <div
          class="bonus-history-item__status"
          :style="{ 'background-color': `#${statusInfo.colors[theme]}` }"
        >
          {{ statusInfo.name }}
        </div>
        <div
          class="bonus-history-item__decline"
          :class="{
            'bonus-history-item__decline_disabled': !data.is_cancellable,
          }"
          @click="openPopupHandler('declineBonusConfirm')"
        >
          <UiIcon
            :color="$config.cp['text-secondary']"
            :size="20"
            name="times"
            lib="fa"
            substyle="fas"
          />
        </div>
      </div>
    </div>
    <div v-if="statusInfo.code === 'active'" class="bonus-history-item__time">
      <UiIcon
        :size="10"
        name="stopwatch"
        lib="fa"
        substyle="fas"
        class="mr-2xs"
      />
      <template v-if="computedExpiredTime">
        <span v-if="computedExpiredTime.days" class="mr-2xs">
          {{ `${this.$tc('profile.bonusWallet.timer.days', computedExpiredTime.days)}` }}
        </span>
        {{ computedExpiredTime.time }}
      </template>
      <template v-else>
        {{ $t('profile.bonusHistory.expired') }}
      </template>
    </div>
    <component :is="component" :data="data" class="mt-sm"></component>
  </div>
</template>

<script>
import Casino from '@/views/Profile/ProfileBonuses/components/BonusHistory/components/types/Casino.vue';
import Sports from '@/views/Profile/ProfileBonuses/components/BonusHistory/components/types/Sports.vue';
import Freespin from '@/views/Profile/ProfileBonuses/components/BonusHistory/components/types/Freespin.vue';
import Freebet from '@/views/Profile/ProfileBonuses/components/BonusHistory/components/types/Freebet.vue';
export default {
  name: 'BonusHistoryItem',

  props: {
    data: {
      type: [Object],
      default() {
        return null;
      },
    },
    statuses: {
      type: [Array],
      default() {
        return [];
      },
    },
  },

  components: {
    Casino,
    Sports,
    Freespin,
    Freebet,
  },

  data() {
    return {
      utcTimestamp: Math.floor((new Date()).getTime() / 1000),
      typeComponent: {
        1: 'Sports',
        2: 'Casino',
        3: 'Freebet',
        4: 'Freespin',
      },
    };
  },

  computed: {
    component() {
      return this.typeComponent[this.data.type_id];
    },

    pBonusName() {
      let name = this.data.name;
      if (this.data.code) {
        name = name + ` (${this.data.code})`;
      }
      if (this.data.type_id === 1) {
        name = name + ` (${this.$t('profile.bonusHistory.sports')})`;
      }
      if (this.data.type_id === 2) {
        name = name + ` (${this.$t('profile.bonusHistory.casino')})`;
      }

      return name;
    },

    statusInfo() {
      return this.statuses.filter((status) => status.code === this.data.status)[0]
    },

    theme() {
      return this.$config.themeName;
    },

    computedExpiredTime() {
      const daySeconds = 86400;
      const diff = this.data.expired_at - this.utcTimestamp;
      if (diff > 0) {
        const daysCount = (diff - diff % daySeconds) / daySeconds;

        const sec_num = diff - daysCount * daySeconds;
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        let seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours   = '0'+hours;}
        if (minutes < 10) { minutes = '0'+minutes;}
        if (seconds < 10) { seconds = '0'+seconds;}

        const timer = {
          'time': `${hours}:${minutes}:${seconds}`,
        };
        if (daysCount > 0) {
          timer.days = daysCount;
        }
        return timer;
      }
      return null;
    },
  },

  created() {
    setInterval(() => {
      this.utcTimestamp = Math.floor((new Date()).getTime() / 1000);
    }, 1000);
  },

  methods: {
    openPopupHandler(popup) {
      this.$eventBus.$emit('open-popup', popup, {
        data: {
          name: this.pBonusName,
          id: this.data.id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.bonus-history-item {
  padding: 12px;
  border-radius: 8px;
  background-color: var(--color-surface);
  color: var(--color-text);
  &__name {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.43;
    flex-basis: 65%;
  }
  &__status {
    margin-right: 8px;
    padding: 0 8px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.67;
    color: var(--color-on-primary);
    background-color: var(--color-primary);
  }
  &__time {
    font-size: 10px;
    font-weight: 600;
    line-height: 2;
  }
  &__row {
    border-top: 1px solid var(--color-on-surface);
    padding: 8px 0;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.33;
    color: var(--colo-text);
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: var(--color-text-secondary);
    }
  }
  &__info-value_clickable {
    cursor: pointer;
    .result-line__chervon {
      margin-left: 8px;
    }
  }
  &__accordion {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--color-text-secondary);
  }
  &__decline {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;

    cursor: pointer;
    &_disabled {
      pointer-events: none;
      cursor: default;
      opacity: 0.5;
    }
  }
}
</style>
