import {
  ADD_TO_FAVORITE,
  REMOVE_FAVORITE,
  ADD_SLIP,
  REMOVE_SLIP,
} from '@/store/action_types/market.js';

import { mapGetters } from 'vuex';
import Vue from 'vue';

export function genOutcomeKey(outcome) {
  return `${outcome.matchId}_${outcome.id}`;
}

export default {
  data() {
    return {
      clickFav: false,
    }
  },
  computed: {
    ...mapGetters('market', ['slipIndex', 'slip']),
    ...mapGetters('settings', ['values']),
    ...mapGetters('misc', ['isMobile']),
  },
  methods: {
    async handleToggleFavorites(isFavorite) {
      if (!this.clickFav) {
        this.clickFav = true
        const actions = {
          add: {
            iconValue: true,
            action: ADD_TO_FAVORITE,
          },
          remove: {
            iconValue: false,
            action: REMOVE_FAVORITE,
          }
        };
        let type = {};

        if (isFavorite) {
          type = actions.remove;
        } else {
          type = actions.add;
        }
        this.$store.dispatch(`market/${type.action}`, this.match.id).then(()=>{
          Vue.set(this.match, 'is_favorite', type.iconValue);
        }).catch(error => {
          this.$eventBus.$emit('notify-event', {
            type: 'error',
            text: error.data.message,
          });
        }).finally(() => {
          this.clickFav = false
        });
      }


    },
    handleToggleSlip(outcome) {
      if (this.slipIndex[outcome.key]) {
        this.$store.dispatch(`market/${REMOVE_SLIP}`, outcome.key);
      } else {
        // if (this.slip.length === 0 && this.isMobile) {
        //   this.$router.push('/bet-slip');
        // }
        this.$store.dispatch(`market/${ADD_SLIP}`, [outcome]);
      }
    },
    genOutcomeKey(outcome) {
      return genOutcomeKey(outcome);
    },
  },
};
