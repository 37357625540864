<template>
  <div
    class="slider__item"
    :class="{
      'cursor-pointer': isPath
    }"
    @click="slideClick"
  >
    <img :src="item.image || ''" alt="" :style="{ maxHeight: pMaxHeight }" />
    <div class="slider__item-wrapper">
      <div class="slider__item-content">
        <div class="slider__item-title">{{ item.title }}</div>
        <div class="slider__item-text">{{ item.description }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderItem',

  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    maxHeight: {
      type: String,
      default: null,
    },
  },
  computed: {
    pMaxHeight() {
      return this.maxHeight ? this.maxHeight + 'px' : null;
    },
    isPath() {
      return this.item?.path;
    },
  },
  methods: {
    slideClick() {
      this.$emit('click', this.item);

      if (this.isPath) {
        let pattern = /^((http|https):\/\/)/;
        if (!pattern.test(this.item.path)) {
          this.$router.push({ path: this.isPath });
          return false;
        }
        // window.location.href = this.isPath;
        window.open(
          this.isPath,
          '_blank'
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';

.slider__item {
  line-height: 0;
  position: relative;
  overflow: hidden;
  color: var(--color-on-primary);
  display: block;
  aspect-ratio: 15/4;
  img {
    width: 100%;
  }
  &-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 40px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 32px;
    width: 50%;
    height: 100%;
  }
  &-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 8px;
  }
  &-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
  }

  @media (max-width: $screen-mobile) {
    .slider__item {
      &-wrapper {
        padding: 0;
      }
      &-content {
        padding: 0 16px;
      }
      &-title {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 4px;
      }
      &-text {
        font-size: 8px;
        line-height: 14px;
      }
    }
  }
}
</style>
