<template>
  <div
    class="ui-button"
    :class="[
      `ui-button_size-${size}`,
      `ui-button_type-${type}`,
      {
        'ui-button_active': isActive,
        'ui-button_disabled': isBtnDisabled,
        'ui-button_loading': loading,
      },
    ]"
    @click="onButtonClick"
  >
    <UiIcon
      v-if="icon"
      :name="icon"
      :substyle="substyle"
      :color="getIconColor"
      :size="getIconSize"
      class="ui-button__icon"
    />
    <span class="ui-button__text">
      <slot />
    </span>
    <div v-if="loading" class="ui-button__loading-cover">
      <UiIcon :color="getIconColor" :size="getIconSize + 4" name="spinner" class="ui-button__loading-spiner" />
      <span v-if="loadingTimer" class="ml-2xs">{{ loadingTimerValue }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiButton',
  props: {
    size: {
      type: String,
      default() {
        return 'default';
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isActive: {
      type: Boolean,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    type: {
      type: String,
      default() {
        return 'default';
      },
    },
    icon: {
      type: String,
      default() {
        return '';
      },
    },
    substyle: {
      type: String,
      default() {
        return 'fas';
      },
    },
    iconSize: {
      type: [String, Number],
      default: '',
    },
    iconColor: {
      type: String,
      default: null,
    },
    loadingTimer: {
      type: Boolean,
      default: false,
    },
    loadingTimerValue: {
      type: String,
      default: '',
    },
  },

  computed: {
    computedIconColor() {
      if (this.isActive) {
        return this.$config.cp['on-primary'];
      }

      return this.iconColor === null ? this.$config.cp.text : this.iconColor;
    },
    isBtnDisabled() {
      return this.disabled || this.loading;
    },
    getIconSize() {
      if (this.iconSize) {
        return this.iconSize;
      }
      switch (this.size) {
        case 'medium':
          return 10;
        case 'default':
          return 12;
        default:
          return 12;
      }
    },
    getIconColor() {
      if (this.type === 'default') {
        return this.$config.cp['on-primary'];
      }
      return this.computedIconColor;
    },
  },
  methods: {
    onButtonClick() {
      if (!this.disabled) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins.scss';

.ui-button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.2s ease-out;
    background-color: var(--color-button-hover);
    border-radius: 8px;
  }
  @include hover() {
    &:after {
      opacity: 1;
    }
  }

  &__icon {
    margin-right: 4px;
  }

  &__text {
    line-height: 1;
  }

  &_size {
    &-default {
      padding: 12px;
      font-size: 12px;
      font-weight: 700;
    }
    &-small {
      padding: 8px;
      font-size: 10px;
      font-weight: 700;
    }
  }
  &_type {
    &-default {
      background-color: var(--color-primary);
      color: var(--color-on-primary);
    }
    &-secondary {
      background-color: var(--color-on-surface);
      color: var(--color-text);
    }
    &-icon {
      background-color: var(--color-on-surface);
      color: var(--color-text);
      padding: 0;
      width: 32px;
      height: 32px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      i {
        margin-right: 0;
      }
    }
  }
  &_active {
    background-color: var(--color-primary);
    color: var(--color-on-primary);
  }
  &_disabled {
    cursor: not-allowed;
    border-color: var(--color-dark4);
    opacity: 0.5;
    @include hover() {
      &:after {
        opacity: 0;
      }
    }
  }

  &_loading & {
    &__icon,
    &__text {
      opacity: 0;
    }
  }
  &__loading{
    &-cover {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-spiner{
      animation: rotating 2s linear infinite;
    }
  }
}
</style>
