import Layout from '@/components/layout/Layout/selector.js';
import Login from '@/views/Auth/Login';
import Signup from '@/views/Auth/Signup';
import PasswordRecovery from '@/views/Auth/PasswordRecovery';
import RecoveryCode from '@/views/Auth/RecoveryCode';
import NewPassword from '@/views/Auth/NewPassword';
import multiguard from 'vue-router-multiguard';
import mobileGuard from '@/router/guards/mobileGuard.js';
import authGuard from '@/router/guards/authGuard';

export default [
  {
    path: '/auth',
    name: 'auth',
    component: Layout,
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: {
          view: 'more',
        },
        beforeEnter: multiguard([mobileGuard(true), authGuard(false)]),
      },
      {
        path: 'registration',
        name: 'registration',
        component: Signup,
        meta: {
          view: 'more',
        },
        beforeEnter: multiguard([mobileGuard(true), authGuard(false)]),
      },
      {
        path: 'password-recovery',
        name: 'PasswordRecovery',
        component: PasswordRecovery,
        meta: {
          view: 'more',
        },
        beforeEnter: multiguard([mobileGuard(true), authGuard(false)]),
      },
      {
        path: 'recovery-code',
        name: 'RecoveryCode',
        component: RecoveryCode,
        meta: {
          view: 'more',
        },
        beforeEnter: multiguard([mobileGuard(true)]),
      },
      {
        path: 'new-password',
        name: 'NewPassword',
        component: NewPassword,
        meta: {
          view: 'more',
        },
        beforeEnter: multiguard([mobileGuard(true), authGuard(true)]),
      },
    ],
  },
];
