<template>
  <div>
    <div class="bonus-history-item__row">
      <div class="bonus-history-item__info-name">
        {{ $t('profile.bonusHistory.rows.games') }}
      </div>
      <div
        :class="[
          'bonus-history-item__info-value',
          { 'bonus-history-item__info-value_clickable': isAccordion },
        ]"
        @click="handleOpen"
      >
        <template v-if="games && games.length === 1">
          {{ games[0] }}
        </template>
        <template v-if="games && isAccordion">
          {{ games[0] }} +{{games.length - 1}}
          <UiChevron class="result-line__chervon" :rotate="!isOpen"></UiChevron>
        </template>
        <template v-if="!games || games.length === 0">
          {{ $t('profile.all') }}
        </template>
      </div>
    </div>
    <transition
      v-if="isAccordion"
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div class="bonus-history-item__accordion" v-show="isOpen">
        {{games.join(', ')}}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'TypeAccordion',

  props: {
    games: {
      type: [Array],
      default() {
        return null;
      },
    },
  },

  data() {
    return {
      isOpen: false
    }
  },

  computed: {
    isAccordion() {
      if(this.games) {
        return this.games.length > 1
      }

      return false
    }
  },

  methods: {
    async handleOpen() {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          this.isOpen = !this.isOpen;
        });
      });
    },
    start(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    end(el) {
      el.style.height = '';
    },
  }
};
</script>
