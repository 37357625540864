import { AUTH_TWO_FACTOR_AUTHED, AUTH_USER } from '@/store/action_types/auth';

export default {
  name: 'Disable2FA',

  data() {
    return {
      loading: false,
      errors: {},
      form: {},
    };
  },

  computed: {
    isValid() {
      return !!this.form.code;
    },
  },

  methods: {
    init() {
      this.form = this.getDefaultForm();
    },
    getDefaultForm() {
      return {
        code: '',
      };
    },
    async handleTurnOff() {
      this.loading = true;
      try {
        this.errors = {};
        await this.$api.deleteUserTwoFactorAuthentication(this.form);
        await this.$store.dispatch(`auth/${AUTH_USER}`);
        this.$store.dispatch(`auth/${AUTH_TWO_FACTOR_AUTHED}`, 0);
        this.close();
      } catch (error) {
        this.errors = error?.data?.errors || {};
      } finally {
        this.loading = false;
      }
    },
    close() {},
  },
};
