import { mapGetters } from 'vuex';
import {
  AUTH_GET_REGISTRATION_PARAMS,
  AUTH_REQUEST,
} from '@/store/action_types/auth';
import { version } from '../../../../../package.json';
import cookieMixin from '@/helpers/cookieMixin';

export const SIGNUP_TAB_ONECLICK = 3;
export const SIGNUP_TAB_PHONE = 2;
export const SIGNUP_TAB_EMAIL = 1;

export const SIGNUP_STATE_REGISTER = 'SIGNUP_STATE_REGISTER';
export const SIGNUP_STATE_CONFIRM = 'SIGNUP_STATE_CONFIRM';

export default {
  name: 'Signup',

  mixins: [cookieMixin],

  data() {
    return {
      isVisible: false,
      currentTab: SIGNUP_TAB_ONECLICK,
      form: {},
      formMeta: {},
      errors: {},
      isValid: false,
      isValidConfirm: false,
      isAgree: true,
      loading: false,
      state: SIGNUP_STATE_REGISTER,
      code: null,
      version: version,
    };
  },
  computed: {
    ...mapGetters('auth', ['registrationParams', 'registrationParamsIndex']),
    ...mapGetters('settings', ['values']),
    ...mapGetters('misc', ['isRegCountries', 'isRegCountriesIndex', 'isRegCurrencies', 'isRegCurrenciesIndex']),
    signUpSteps() {
      return {
        SIGNUP_STATE_REGISTER: SIGNUP_STATE_REGISTER,
        SIGNUP_STATE_CONFIRM: SIGNUP_STATE_CONFIRM,
      };
    },
    isConfirmDisabled() {
      if (this.currentTab === SIGNUP_TAB_ONECLICK) {
        return this.isSingupDisabled;
      }

      return !this.isValidConfirm;
    },
    // signUpTabs() {
    //   return {
    //     SIGNUP_TAB_EMAIL: SIGNUP_TAB_EMAIL,
    //     SIGNUP_TAB_ONECLICK: SIGNUP_TAB_ONECLICK,
    //     SIGNUP_TAB_PHONE: SIGNUP_TAB_PHONE,
    //   };
    // },
    isOneStep() {
      return this.currentTab === SIGNUP_TAB_ONECLICK;
    },
    isFinalStep() {
      return this.state === SIGNUP_STATE_CONFIRM;
    },
    pErrors() {
      const keys = Object.keys(this.errors);
      const result = {};
      keys.forEach((key) => {
        // TODO: не забыть перевод полей
        const newKey = key.replace(/fields\./i, '');
        result[newKey] = this.errors[key];
      });
      return result;
    },
    tabsItemsBase() {
      return [
        {
          // label: 'click',
          label: SIGNUP_TAB_ONECLICK,
          name: this.$t('auth.signUp.tab.oneClick'),
        },
        {
          // label: 'phone',
          label: SIGNUP_TAB_PHONE,
          name: this.$t('auth.signUp.tab.phone'),
        },
        {
          // label: 'email',
          label: SIGNUP_TAB_EMAIL,
          name: this.$t('auth.signUp.tab.email'),
        },
      ];
    },
    tabsItems() {
      const result = this.tabsItemsBase.filter(
        (item) =>
          (!this.isLocked && !!this.registrationParamsIndex[item.label]) ||
          (this.isLocked && item.label === this.currentTab)
      );

      result.sort((a, b) => {
        const aIndex = this.registrationParamsIndex[a.label].index;
        const bIndex = this.registrationParamsIndex[b.label].index;

        return aIndex > bIndex ? 1 : aIndex < bIndex ? -1 : 0;
      });

      return result;
    },
    isLocked() {
      return false;
      // return !['filling-form'].includes(this.state);
    },
    isSingupDisabled() {
      return !this.isValid;
      // return (
      //   !this.isValid ||
      //   (this.currentTab !== SIGNUP_TAB_ONECLICK && this.state !== 'complete')
      // );
    },
    defaultForm() {
      return this.getInitialForm(this.form);
    }
  },
  watch: {
    // async '$i18n.locale'() {
    //   await this.loadRegistrationParams();
    // },
  },
  methods: {
    async init() {
      this.form = this.getDefaultForm();
      this.errors = {};
      this.state = SIGNUP_STATE_REGISTER;
      this.isValid = false;
      // this.currentTab = SIGNUP_TAB_ONECLICK;

      if (!this.registrationParams) {
        this.currentTab = 0;
        await this.loadRegistrationParams();
      } else {
        this.currentTab = this.registrationParams[0].reg_type;
      }
    },
    getInitialForm(form) {
      const countryId = this.isRegCountriesIndex[form.country_id] ? form.country_id : this.isRegCountries[0].id;
      const defaultCurrency = this.isRegCountriesIndex[countryId]?.currency_id || this.values.currency_id;
      let isRegCurrenciesIndexFiltered = {};
      let isRegCurrenciesFiltered = {};
      const currenciesList = this.isRegCountriesIndex[countryId].currencies || null;

      if (currenciesList) {
        currenciesList.forEach(el => {
          isRegCurrenciesIndexFiltered = this.$_.pickBy(this.isRegCurrenciesIndex, el.id);
        })
        isRegCurrenciesFiltered = this.$_.filter(this.isRegCurrencies, (currency) => {
          return currenciesList.includes(currency.id)
        });
      } else {
        isRegCurrenciesIndexFiltered = this.isRegCurrenciesIndex;
        isRegCurrenciesFiltered = this.isRegCurrencies;
      }
      const currencyId = isRegCurrenciesIndexFiltered[defaultCurrency] ? defaultCurrency : isRegCurrenciesFiltered[0].id;
      return {
        'country_id': countryId,
        'currency_id': currencyId,
        'first_bonus': 1,
      }
    },
    getDefaultForm() {
      return this.getInitialForm(this.values)
    },
    async loadRegistrationParams() {
      await this.$store.dispatch(`auth/${AUTH_GET_REGISTRATION_PARAMS}`);
      this.currentTab = this.registrationParams[0].reg_type;
    },
    async signup(resolve, reject) {
      const { fields } = this.registrationParamsIndex[this.currentTab];
      const pick = fields.map((field) => field.code);
      const payload = {
        type_id: this.currentTab,
        group_id: this.values.partner_group,
        fields: this.$_.pick(this.form, pick),
        app_v: this.version,
      };

      payload.fields.btag = this.getCookie('btag') || null;

      this.errors = {};
      this.loading = true;
      try {
        const recaptchaParams = await this.$recaptchaChallange();
        const response = await this.$api.postRegistration({
          ...payload,
          ...recaptchaParams,
        });

        if (this.currentTab === SIGNUP_TAB_ONECLICK) {
          const { payload } = response.data;
          await this.$store.dispatch(`auth/${AUTH_REQUEST}`, {
            type: 'signup',
            payload,
          });
        }

        if (resolve) {
          resolve(response.data.payload);
        }
        return response;
      } catch (error) {
        console.error('error', error);
        if (error?.data?.errors?.captcha_text) {
          this.$eventBus.$emit('notify-event', {
            type: 'error',
            text: error?.data?.errors?.captcha_text[0],
          });
        }
        this.errors = error?.data?.errors || {};
        if (reject) {
          reject();
        }
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async handleSignup() {
      try {
        if (this.currentTab === SIGNUP_TAB_ONECLICK) {
          //this.isConfirmDisabled = true;
          const response = await this.signup();
          const { user } = response.data.payload;
          this.$eventBus.$emit('open-popup', 'RegComplete', {
            data: {
              userId: user.id,
              password: user.password,
            },
          });
          this.handleRegistrationComplete();
        } else {
          const payload = await this.$refs.confirm.handleConfirm();
          await this.$store.dispatch(`auth/${AUTH_REQUEST}`, {
            type: 'signup',
            payload,
          });
          this.handleRegistrationComplete();

          this.$eventBus.$emit('open-popup', 'Alert', {
            data: {
              message: this.$refs.confirm.confirmParams.alertMessage,
            },
          });
        }
      } catch (error) {
        console.error('error', error);
      }
    },
    async handleContinue() {
      this.loading = true;
      try {
        const response = await this.signup();
        this.code = response.data.payload.code;
        this.state = SIGNUP_STATE_CONFIRM;
      } finally {
        this.loading = false;
      }
    },
    handleBack() {
      this.state = SIGNUP_STATE_REGISTER;
    },
    // override
    handleRegistrationComplete() {},
    handleAction(action, params) {
      if (action === 'set-state') {
        this.state = params;
      } else if (action === 'singup') {
        this.signup(...params);
      } else if (action === 'close') {
        this.handleRegistrationComplete();
      }
    },
  },
};
