export default {
  data() {
    return {
      loading: false,
      errors: {},
      form: {},
    };
  },

  computed: {
    isValid() {
      return !!this.form.code;
    },
  },

  methods: {
    init() {
      this.form = this.getDefaultForm();
    },
    getDefaultForm() {
      return {
        code: '',
      };
    },
    async handleConfirm() {
      this.loading = true;
      try {
        this.errors = {};
        const response = await this.$api.postAuthTwoFactorChallenge(this.form);
        console.log('response', response);
        this.$eventBus.$emit('2fa-complete');
        //await this.$store.dispatch(`auth/${AUTH_USER}`);
        this.close();
        this.$emit('complete');
      } catch (error) {
        this.errors = error?.data?.errors || {};
      } finally {
        this.loading = false;
      }
    },
    close() {},
  },
};
