<template>
  <div
    class="live-badge"
    :style="`background-image: url('${require('@/assets/images/live-badge.svg')}')`"
  ></div>
</template>

<script>
export default {
  name: 'LiveBadge',
};
</script>

<style lang="scss">
.live-badge {
  width: 25px;
  min-width: 25px;
  display: inline-block;
  height: 13px;
  min-height: 13px;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
