<template>
  <div class="signup-panel">
    <template v-if="step === signUpSteps.SIGNUP_PANEL_STEP_REGISTER">
      <div
        v-show="registration.regType === currentTab"
        :class="['signup-panel__tab', `signup-panel__tab_${registration.regType}`]"
        v-for="registration in registrationFields"
        :key="registration.regType"
      >
        <SignupPanelForm
          :fields="registration.fields"
          :form="form"
          :form-meta="formMeta"
          :errors="pErrors"
          :is-valid="isValid"
          :is-locked="isLocked"
          :is-visible="registration.regType === currentTab"
          @action="handleAction"
        />
      </div>
<!--      <UiCheckbox-->
<!--        v-model="isAgree"-->
<!--        class="signup-panel__agree"-->
<!--        :label="$t('auth.signUp.agreeText')"-->
<!--        :disabled="isLocked"-->
<!--      />-->
    </template>
    <template v-if="step === signUpSteps.SIGNUP_PANEL_STEP_CONFIRM">
      <!-- <div v-if="currentTab === signUpSteps.SIGNUP_TAB_EMAIL">
        SIGNUP_TAB_EMAIL
      </div>
      <div v-if="currentTab === signUpSteps.SIGNUP_TAB_PHONE">
        SIGNUP_TAB_PHONE
      </div> -->
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SignupPanelForm from '@/views/Auth/components/Signup/SignupPanelForm.vue';

import {
  SIGNUP_TAB_EMAIL,
  SIGNUP_TAB_ONECLICK,
  SIGNUP_TAB_PHONE,
} from '@/views/Auth/components/Signup/logic.js';

export const SIGNUP_PANEL_FIELD_BONUS = 'SIGNUP_PANEL_FIELD_BONUS';
export const SIGNUP_PANEL_FIELD_DICIONARY = 'SIGNUP_PANEL_FIELD_DICIONARY';
export const SIGNUP_PANEL_FIELD_INPUT = 'SIGNUP_PANEL_FIELD_INPUT';
export const SIGNUP_PANEL_FIELD_DATE = 'SIGNUP_PANEL_FIELD_DATE';
export const SIGNUP_PANEL_FIELD_IGNORE = 'SIGNUP_PANEL_FIELD_IGNORE';
export const SIGNUP_PANEL_FIELD_PROMOCODE = 'SIGNUP_PANEL_FIELD_PROMOCODE';
export const SIGNUP_PANEL_FIELD_PHONE = 'SIGNUP_PANEL_FIELD_PHONE';
export const SIGNUP_PANEL_FIELD_EMAIL = 'SIGNUP_PANEL_FIELD_EMAIL';
export const SIGNUP_PANEL_FIELD_CHECKBOX = 'SIGNUP_PANEL_FIELD_CHECKBOX';

export const SIGNUP_PANEL_STEP_REGISTER = 'SIGNUP_PANEL_STEP_REGISTER';
export const SIGNUP_PANEL_STEP_CONFIRM = 'SIGNUP_PANEL_STEP_CONFIRM';

export default {
  name: 'SignupPanel',
  components: {
    SignupPanelForm,
  },

  props: {
    currentTab: {
      type: Number,
      default: 0,
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    formMeta: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    defaultForm: {
      type: Object,
      default: () => ({}),
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    state: {
      type: String,
    },
  },

  data() {
    return {
      lazyLoaded: {},
      step: SIGNUP_PANEL_STEP_REGISTER,
    };
  },

  computed: {
    ...mapGetters('auth', ['registrationParams']),
    ...mapGetters('misc', ['isRegCountries', 'isRegCountriesIndex', 'isRegCurrencies', 'isRegCurrenciesIndex', 'countries']),
    ...mapGetters('settings', ['values']),
    signUpTabs() {
      return {
        SIGNUP_TAB_EMAIL: SIGNUP_TAB_EMAIL,
        SIGNUP_TAB_ONECLICK: SIGNUP_TAB_ONECLICK,
        SIGNUP_TAB_PHONE: SIGNUP_TAB_PHONE,
      };
    },
    signUpSteps() {
      return {
        SIGNUP_PANEL_STEP_REGISTER: SIGNUP_PANEL_STEP_REGISTER,
        SIGNUP_PANEL_STEP_CONFIRM: SIGNUP_PANEL_STEP_CONFIRM,
      };
    },
    fieldTypeMeta() {
      return {
        [SIGNUP_PANEL_FIELD_BONUS]: {
          component: 'SignupPanelBonus',
          params: ['label'],
        },
        [SIGNUP_PANEL_FIELD_DICIONARY]: {
          component: 'SignupPanelDictionary',
          params: ['dictionary', 'list', 'placeholder', 'label', 'isDisabled'],
        },
        [SIGNUP_PANEL_FIELD_INPUT]: {
          component: 'SignupPanelInput',
          params: ['placeholder', 'label', 'inputType'],
        },
        [SIGNUP_PANEL_FIELD_DATE]: {
          component: 'SignupPanelDatepicker',
          params: ['label'],
        },
        [SIGNUP_PANEL_FIELD_PROMOCODE]: {
          component: 'SignupPanelPromocode',
          params: ['placeholder', 'label'],
        },
        [SIGNUP_PANEL_FIELD_PHONE]: {
          component: 'SignupPanelPhone',
          params: ['placeholder', 'label'],
        },
        [SIGNUP_PANEL_FIELD_EMAIL]: {
          component: 'SignupPanelEmail',
          params: ['placeholder', 'label'],
        },
        [SIGNUP_PANEL_FIELD_CHECKBOX]: {
          component: 'SignupPanelCheckbox',
          params: ['label'],
        },
      };
    },
    fieldMeta() {
      return {
        first_bonus: {
          type: SIGNUP_PANEL_FIELD_BONUS,
        },
        country_id: {
          type: SIGNUP_PANEL_FIELD_DICIONARY,
          dictionary: 'isRegCountries',
          list: this.isRegCountries.filter((item) => item.phone_code),
          isDisabled: !!this.values?.geo?.is_off_change_country,
        },
        phone: {
          type: SIGNUP_PANEL_FIELD_PHONE,
        },
        currency_id: {
          type: SIGNUP_PANEL_FIELD_DICIONARY,
          list: this.filteredCurrencies,
          dictionary: 'isRegCurrencies',
        },
        promo_code: {
          type: SIGNUP_PANEL_FIELD_PROMOCODE,
        },
        email: {
          type: SIGNUP_PANEL_FIELD_EMAIL,
        },
        birthday: {
          type: SIGNUP_PANEL_FIELD_DATE,
        },
        password: {
          type: SIGNUP_PANEL_FIELD_INPUT,
          inputType: 'password',
          placeholder: this.$t('ui.passwordPlaceholder'),
        },
        password_confirmation: {
          type: SIGNUP_PANEL_FIELD_INPUT,
          inputType: 'password',
          placeholder: this.$t('ui.passwordPlaceholder'),
        },
      };
    },
    registrationFields() {
      const result = this.registrationParams.map((registration) => ({
        regType: registration.reg_type,
        fields: registration.fields
          .map((item) => {
            const { code, name, placeholder } = item;
            const fieldMeta = this.fieldMeta[code] || this.getDefaultFieldMeta(item);
            const fieldTypeMeta = this.fieldTypeMeta[fieldMeta.type] || {};
            const additionalParams = {};
            if (name) {
              additionalParams.label = name;
              additionalParams.placeholder = placeholder || name;
            }
            const params = this.$_.pick(
              {
                ...additionalParams,
                ...fieldMeta,
              },
              fieldTypeMeta.params
            );

            return {
              ...item,
              ...fieldMeta,
              ...fieldTypeMeta,
              params,
            };
          })
          .filter((item) => item.type !== SIGNUP_PANEL_FIELD_IGNORE),
      }));
      return result;
    },
    currentFields() {
      const result = this.registrationFields.find(
        (item) => item.regType === this.currentTab
      );

      return result?.fields || [];
    },
    pIsValid() {
      const requiredFields = this.currentFields
        .filter((item) => !!item?.rules?.is_required)
        .map((item) => item.code);
      return requiredFields.every((key) => !!this.form[key]);
    },
    pErrors() {
      const keys = Object.keys(this.errors);
      const result = {};
      keys.forEach((key) => {
        const field = this.currentFields.find((item) => item.code === key);
        result[key] = field
          ? this.errors[key].map((error) =>
            error.replace(`fields.${key}`, field.name)
          )
          : this.errors[key];
      });
      return result;
    },
    filteredCurrencies() {
      let isRegCurrenciesFiltered = {};
      if (this.countryId && this.isRegCountriesIndex[this.countryId].currencies) {
        isRegCurrenciesFiltered = this.$_.filter(this.isRegCurrencies, (currency) => {
          return this.isRegCountriesIndex[this.countryId].currencies.includes(currency.id)
        });
      } else {
        isRegCurrenciesFiltered = this.isRegCurrencies;
      }
      return isRegCurrenciesFiltered
    },
    countryId() {
      const { country_id } = this.form;
      return country_id;
    },
  },
  watch: {
    pIsValid: {
      handler(value) {
        this.$emit('update:isValid', value);
      },
    },
    countryId: {
      handler() {
        this.form.currency_id = this.defaultForm.currency_id
      },
    },
    // lazyLoaded
  },
  created() {
    this.selectCurrency = this.defaultCurrency;
  },

  methods: {
    getDefaultFieldMeta(item) {
      const dictionary = {
        string: {
          type: SIGNUP_PANEL_FIELD_INPUT,
        },
        checkbox: {
          type: SIGNUP_PANEL_FIELD_CHECKBOX,
        },
        ignore: {
          type: SIGNUP_PANEL_FIELD_IGNORE,
        },
      }
     return item.is_hidden ? dictionary['ignore'] : (dictionary[item.type] || {})
    },
    handleAction(action /*, params*/) {
      if (action === 'send-code') {
        // if (this.state === 'filling-form') {
        //   const [pResolve, pReject] = params;
        //   this.$emit('action', 'set-state', 'confirm-code');
        //   const resolve = (code) => {
        //     pResolve(code);
        //   };
        //   const reject = (error) => {
        //     this.$emit('action', 'set-state', 'filling-form');
        //     pReject(error);
        //   };
        //   this.$emit('action', 'singup', [resolve, reject]);
        // }
      } else {
        this.$emit('action', action);
      }
    },
  },
};
</script>

<style lang="scss">
.signup-panel {
  &__checkbox.ui-checkbox {
    align-items: center;
    font-size: 10px;
    line-height: 1.6;
    color: var(--text-secondary-dark);
    margin-top: 16px;
    a {
      color: var(--color-primary);
      text-decoration: none;
    }
  }
}
</style>
