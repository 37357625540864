export default {
  postAuthLogin(payload) {
    return this.baseApi.post('/auth/login', payload);
  },
  postAuthLogout(payload, config = {}) {
    return this.baseApi.post('/auth/logout', payload, config);
  },
  getRegistration(params) {
    return this.baseApi.get('/auth/registration', { params });
  },
  postRegistration(payload) {
    return this.baseApi.post('/auth/registration', payload);
  },
  getAuthTwoFactorQr(payload) {
    return this.baseApi.get('/auth/two-factor-qr', payload);
  },
  putAuthTwoFactorAuthentication(payload) {
    return this.baseApi.put('/auth/two-factor-authentication', payload);
  },
  postAuthTwoFactorChallenge(payload) {
    return this.baseApi.post('/auth/two-factor-challenge', payload);
  },
  postAuthCodeCheck(payload) {
    return this.baseApi.post('/auth/code/check', payload);
  },
  postAuthCodeSend(payload) {
    return this.baseApi.post('/auth/code/send', payload);
  },
  postPasswordRecovery(payload) {
    return this.baseApi.post('/auth/password/recovery', payload);
  },
};
