<template>
  <label
    class="ui-input"
    :class="[
      `ui-input_${size}`,
      { 'ui-input_focused': focused },
      { 'ui-input_error': currentError },
      { 'ui-input_search': isSearch },
      { 'ui-input_disabled': disabled },
      { 'ui-input_required': required },
    ]"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
  >
    <span v-if="label" class="ui-input__label-text">
      {{ label }}
    </span>
    <span
      class="ui-input__wrapper"
      :class="[{ 'ui-input__wrapper_preicon': previousIcon || currency }]"
    >
      <ui-icon
        v-if="previousIcon !== ''"
        :name="previousIcon"
        :size="14"
        :color="$config.cp['text-secondary']"
        lib="fa"
        substyle="fas"
        class="input-pre-icon"
      />
      <div v-if="currency" class="ui-input__currency">
        {{ currency }}
      </div>
      <div v-if="isPhone" class="ui-input__phone-code">
        +{{ phoneData.phone_code }}
      </div>
      <input
        v-if="isPhone"
        v-mask="phoneNumberMask"
        :placeholder="phoneNumberMask"
        :disabled="disabled"
        :tabindex="tabindex"
        :type="currentType"
        :value="currentValue"
        ref="input"
        class="ui-input__element"
        :autocomplete="autocomplete"
        :name="name"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
        @keyup.enter="handleBlur"
      />
      <input
        v-else
        :placeholder="pPlaceholder"
        :disabled="disabled"
        :tabindex="tabindex"
        :type="currentType"
        :value="currentValue"
        ref="input"
        class="ui-input__element"
        :autocomplete="autocomplete"
        :name="name"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
        @keyup.enter="handleBlur"
      />
      <span
        v-if="type === 'password'"
        class="ui-input__show-password"
        @click="showPassword"
      >
        <UiIcon
          :size="14"
          :color="$config.cp.text"
          :name="passwordIsShow ? 'eye-slash' : 'eye'"
          lib="fa"
          substyle="fas"
        />
      </span>
      <span
        v-if="isClearable || isSearch"
        :class="{ 'show-clear': showClear }"
        class="ui-input__clear"
        @click="clear"
      >
        <UiIcon
          :size="20"
          :color="$config.cp.text"
          name="times-circle"
          lib="fa"
          substyle="fas"
        />
      </span>

    </span>
    <span
      v-if="isFreeBet"
      class="ui-input__freebet"
    >
        {{ $t('ui.freebet') }}
      </span>
    <UiHelp
      v-if="currentError"
      class="mt-2xs"
      :is-error="true"
      :text="currentError"
    />
    <UiHelp v-if="help !== ''" class="mt-2xs" :text="help" />
  </label>
</template>

<script>
import {
  getExampleNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';
import { mask } from 'vue-the-mask';
import examples from '@/helpers/examples.mobile';
import { mapGetters } from 'vuex';

export default {
  name: 'UiInput',

  directives: {
    mask,
  },

  props: {
    isFreeBet: {
      type: [Boolean],
      default() {
        return false;
      },
    },
    value: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    size: {
      type: String,
      default() {
        return 'big';
      },
    },
    type: {
      type: String,
      default() {
        return 'text';
      },
    },
    isPhone: {
      type: Boolean,
      default() {
        return false;
      },
    },
    currency: {
      type: String,
      default() {
        return '';
      },
    },
    phoneData: {
      type: Object,
      default() {
        return {};
      },
    },
    label: {
      type: String,
      default() {
        return '';
      },
    },
    placeholder: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    help: {
      type: String,
      default() {
        return '';
      },
    },
    isSearch: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isClearable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    preIcon: {
      type: String,
      default: '',
    },
    postIcon: {
      type: String,
      default: '',
    },
    tabindex: {
      type: Number,
      default() {
        return 1;
      },
    },
    minSearchLength: {
      type: Number,
      default() {
        return 0;
      },
    },
    error: {
      type: [String, Number, Array],
      default() {
        return '';
      },
    },
    errorTooltip: {
      type: Boolean,
      default() {
        return false;
      },
    },
    errorClearOnChange: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    name: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
  },

  data() {
    return {
      currentValue: '',
      isValidPhone: false,
      passwordIsShow: false,
      focused: false,
      hovered: false,
      searchTimer: 500,
    };
  },
  computed: {
    ...mapGetters('misc', ['isMobile']),
    currentError() {
      return this.$_.isArray(this.error) ? this.error[0] : this.error;
    },
    pPlaceholder() {
      return this.placeholder !== '' ? this.placeholder : this.$t('ui.search');
    },
    phoneNumberMask() {
      const number = getExampleNumber(this.phoneData.code, examples)
        .formatInternational()
        .split(' ')
        .slice(1)
        .join(' ');
      return number.replace(/[0-9]/g, '#');
    },
    currentType() {
      return this.type !== 'password' ||
        (this.type === 'password' && !this.passwordIsShow)
        ? this.type
        : 'text';
    },
    previousIcon() {
      return this.preIcon ? this.preIcon : this.isSearch ? 'search' : '';
    },
    showClear() {
      return (
        (this.isSearch &&
          !this.disabled &&
          this.currentValue !== '' &&
          (this.focused || this.hovered)) ||
        (this.isSearch &&
          !this.disabled &&
          this.currentValue !== '' &&
          this.isMobile)
      );
    },

    debouncedSearch() {
      return this.$_.debounce(this.search, this.searchTimer);
    },
  },

  watch: {
    currentValue(nv, ov) {
      const trimVal = typeof nv === 'string' ? nv.trim() : nv;
      if (
        nv !== ov &&
        this.isSearch &&
        trimVal.length >= this.minSearchLength
      ) {
        this.debouncedSearch();
      }
    },

    value: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.currentValue =
            newValue === undefined || newValue === null
              ? ''
              : newValue.toString();
        }
      },
      deep: true,
    },
  },

  created() {
    this.currentValue = this.value;
    if (this.isPhone) {
      this.checkPhone(this.currentValue);
    }
  },

  methods: {
    search() {
      this.$emit('search', this.currentValue);
    },
    checkPhone(val) {
      const phoneNumber = parsePhoneNumberFromString(
        `+${this.phoneData.phone_code}${val}`
      );

      let event = {
        phone: '',
        isValid: false,
      };

      if (phoneNumber) {
        const nationalNumber = val.replace(/[^0-9]/g, '');
        this.isValidPhone = phoneNumber.isValid();
        event = {
          number: phoneNumber.number,
          nationalNumber: nationalNumber,
          isValid: phoneNumber.isValid(),
        };
      }

      this.$emit('phone', event);
    },
    showPassword() {
      this.passwordIsShow = !this.passwordIsShow;
    },

    handleFocus(event) {
      this.focused = true;
      this.$emit('focus', event);
    },

    focus() {
      this.$refs.input.focus();
    },

    handleBlur() {
      this.focused = false;
      this.blur();
    },

    blur() {
      this.$refs.input.blur();
    },

    handleInput(event) {
      const { value } = event.target;
      this.currentValue = value;
      this.$emit('input', value, event);

      console.log('input');

      if (this.isPhone) {
        this.checkPhone(this.currentValue, event);
      }

      if (this.errorClearOnChange || this.errorClearOnChange === '') {
        this.$emit('update:error', null);
      }
    },

    handleChange(event) {
      const { value } = event.target;
      this.$emit('change', value);
    },

    clear() {
      this.$emit('input', '');
      this.$emit('change', '');
      this.$emit('clear');
      this.currentValue = '';
      this.focus();
    },
  },
};
</script>

<style lang="scss">
.ui-input {
  display: flex;
  flex-direction: column;
  position: relative;
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  &__freebet {
    padding: 0 8px;
    border-radius: 8px;
    background-color: var(--color-primary);
    font-size: 12px;
    font-weight: 700;
    line-height: 1.67;
    color: var(--color-on-primary);
    margin-right: 12px;
    position: absolute;
    right: 0;
    transform: translateY(50%);
  }
  &__currency {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--color-text-secondary);
  }
  &__clear {
    transition: all 0.2s;
    cursor: pointer;
    line-height: 12px;
    opacity: 0;
    margin-right: 12px;
    &.show-clear {
      opacity: 1;
    }
  }

  &__wrapper {
    display: inline-flex;
    align-items: center;
    border: 1px solid var(--color-on-surface);
    background-color: var(--color-on-surface);
    transition: border-color 0.2s;
    border-radius: 8px;
    overflow: hidden;
    &_preicon {
      padding-left: 12px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px var(--color-on-surface) inset !important;
      color: var(--color-text) !important;
    }
  }
  &__phone-code {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.33;
    padding: 0 12px;
    border-right: 1px solid var(--color-highlight);
    height: 38px;
    display: flex;
    align-items: center;
  }
  &__element {
    font-family: Montserrat, sans-serif;
    display: flex;
    align-items: center;
    min-width: 10%;
    max-width: 100%;
    flex: 10 1 auto;
    border: none;
    outline: none;
    padding: 0;
    height: 38px;
    background: transparent;
  }
  &__label-text {
    font-size: 12px;
    font-weight: 600;
    color: var(--color-text);
    line-height: 2.67;
  }

  &__show-password {
    cursor: pointer;
    margin-right: 12px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_big & {
    &__wrapper input {
      padding: 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.33;
      color: var(--color-text);
    }
  }
  &_small & {
    &__wrapper input {
      padding: 7px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.33;
      color: var(--color-text);
    }
  }
  &_error & {
    &__wrapper {
      border-color: var(--color-error);
      //background-color: var(--color-danger10);
    }
  }
  &_focused & {
    &__wrapper {
      border-color: var(--color-primary);
    }
  }
  &_disabled & {
    &__wrapper {
      opacity: 0.5;
    }
  }
}
</style>
