<template>
  <div class="network">
    <UiIcon
      :size="64"
      :color="$config.cp.primary"
      name="exclamation-circle"
      lib="fa"
      substyle="fas"
      class="mb-lg"
    />
    <div class="network__title">
      {{ $t('tech.network.title') }}
    </div>
    <div class="network__text">
      {{ $t('tech.network.text') }}
    </div>
    <UiButton
      class="mt-lg"
      substyle="fas"
      icon="sync-alt"
      @click="handlePing"
    >
      {{ $t('buttons.tryAgain') }}
    </UiButton>
  </div>
</template>

<script>
export default {
  name: 'NetworkError',

  methods: {
    handlePing() {
      this.$api.getPing();
    },
  },
};
</script>

<style lang="scss">
.network {
  height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__title {
    text-align: center;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    color: var(--color-text);
  }
  &__text {
    text-align: center;
    padding: 0 16px;
    color: var(--color-text);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
  }
}
</style>
