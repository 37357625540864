<template>
  <div class="profile-wallets">
    <UiTitle back-route="/more">{{ $t('profile.wallet.title') }}</UiTitle>
    <ProfileWalletsPanel />
  </div>
</template>

<script>
import ProfileWalletsPanel from '@/views/Profile/components/ProfileWalletsPanel/selector';

export default {
  name: 'ProfileWallets',
  components: {
    ProfileWalletsPanel,
  },
};
</script>
