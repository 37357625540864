<template>
  <Popup class="alert-popup" :title="title" :is-visible.sync="isVisible">
    <div slot="body">
      <SuccessPanel class="mt-4xl mb-2xl" />
      <div :class="['flex', 'justify-center', 'text-center', 'mb-lg']">
        {{ message }}
      </div>
    </div>
    <UiButton slot="footer" substyle="fas" icon="check" @click="cancel">
      {{ buttonText }}
    </UiButton>
  </Popup>
</template>

<script>
export default {
  name: 'Alert',

  props: {
    message: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Success!',
    },
    buttonText: {
      type: String,
      default: 'Got It',
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },

  methods: {
    open() {
      this.isVisible = true;
    },

    cancel() {
      this.isVisible = false;
    },
  },
};
</script>
