<template>
  <div class="casino-providers">
    <div v-if="!isMobile" class="casino-providers__header">
      <UiTitle>{{
        $t(`casino.subsection.${liveCasinoParams.i18nKey}.providers`)
      }}</UiTitle>
      <Breadcrumbs :items="breadcrumbs" />
    </div>

    <div v-else>
      <UiTitle class="mb-lg" :back-route="null" @click="handleBack">{{
        $t(`casino.subsection.${liveCasinoParams.i18nKey}.providers`)
      }}</UiTitle>
    </div>

    <div class="casino-providers__content">
      <UiInput
        v-model.trim="search"
        class="mb-2xl"
        name="search"
        :placeholder="$t('ui.search')"
        autocomplete="off"
        is-search
        @search="handleSearch"
      />
      <div
        v-if="casinoProvidersFitered.length"
        :class="[
          'casino-providers__providers-list',
          'flex',
          'flex-wrap',
          { 'ml-rlg': !isMobile },
          { 'mt-rlg': !isMobile },
          { 'ml-rmd': isMobile },
          { 'mt-rmd': isMobile },
        ]"
      >
        <router-link
          v-for="provider in casinoProvidersFitered"
          :key="provider.id"
          :class="[
            'casino-providers__provider-item',
            { 'pl-lg': !isMobile },
            { 'pt-lg': !isMobile },
            { 'pl-md': isMobile },
            { 'pt-md': isMobile },
          ]"
          :to="`/${liveCasinoParams.path}/games?provider=${provider.id}`"
        >
          <Panel
            class="casino-providers__provider-panel"
            size="custom"
            :title="provider.name"
          >
            <div class="casino-providers__provider-content">
              <div class="casino-providers__provider-image-wrapper">
                <template v-if="provider.image">
                  <img
                    class="casino-providers__provider-image"
                    v-lazy="{ src: provider.image }"
                    style="max-height: 100%"
                  />
                </template>
                <template v-else>
                  <div class="casino__provider-wrapper">
                    <UiIcon
                      class="mb-2xs"
                      :size="20"
                      :color="$config.cp['text-secondary']"
                      name="window-maximize"
                      lib="fa"
                      substyle="fas"
                    />
                  </div>
                </template>
              </div>
              <div class="casino-providers__provider-label">
                {{ provider.name }}
              </div>
            </div>
          </Panel>
        </router-link>
      </div>
      <div v-else class="casino-providers__empty-wrapper">
        <Empty
          class="casino-providers__empty"
          :text="$t('casino.emptyProviders')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Empty from '@/components/Empty';
import routerMixin from '@/helpers/router.js';
import liveCasinoMixin from '@/views/Casino/mixins/liveCasino.js';

export default {
  name: 'CasinoProviders',
  mixins: [routerMixin, liveCasinoMixin],
  components: {
    Empty,
  },
  data() {
    return {
      search: '',
      pSearch: '',
    };
  },
  computed: {
    ...mapGetters('misc', ['isMobile']),
    pCasinoProviders() {
      return this.casinoProviders.map((item) => ({
        ...item,
        search: this.$_.toLower(item.name),
      }));
    },
    casinoProvidersFitered() {
      const search = this.$_.toLower(this.pSearch);
      return this.pCasinoProviders.filter((item) =>
        item.search.includes(search)
      );
    },
    breadcrumbs() {
      const { i18nKey } = this.liveCasinoParams;
      return [
        {
          title: this.$t(`casino.subsection.${i18nKey}.title`),
          link: `/${this.liveCasinoParams.path}/games`,
        },
        {
          title: this.$t('casino.providers'),
        },
      ];
    },
  },
  mounted() {},
  methods: {
    handleSearch(value) {
      this.pSearch = value;
    },
    handleBack() {
      if (!this.returnTo(false)) {
        this.$router.push({
          name: `${this.liveCasinoParams.name}.games`,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.casino-providers {
  @import '@/styles/variables';

  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__providers-list {
    min-width: 0;
  }

  &__provider-item {
    width: 12.5%;
    text-decoration: none;
  }

  @media (max-width: $screen-medium) {
    &__provider-item {
      width: 14.28%;
    }
  }

  @media (max-width: $screen-mobile) {
    &__provider-item {
      width: 33.33%;
    }
  }

  &__provider-label {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
    padding-bottom: 8px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    transition: color 0.3s ease-out;
  }

  &__provider-image {
    max-width: 100%;
  }

  &__provider-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8px 8px 0;
    flex-grow: 1;
    height: 100%;
    max-height: calc(100% - 28px);
  }

  &__provider-panel {
    cursor: pointer;
    height: 84px;

    .layout-panel__content {
      border-radius: 8px;
      height: 100%;
      transition: background-color 0.3s ease-out;
      align-items: center;
      display: flex;
    }

    &:hover .layout-panel__content {
      background-color: var(--color-selected);
    }

    .layout-panel__content-wrapper {
      border: 1px solid;
      border-color: transparent;
      transition: border-color 0.3s ease-out;
      align-items: center;
    }
  }

  &__provider-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    height: 100%;
  }

  &__empty-wrapper {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding-bottom: 50px;
  }

  &__empty {
    margin-top: 0;
    min-height: 300px;
  }
}
</style>
