<template>
  <SkeletonComponent>
    <div class="flex">
      <div class="flex flex-auto flex-col items-center justify-center">
        <Skeleton class="mb-sm" width="32px" height="32px" />
        <Skeleton width="70px" height="20px" />
      </div>
      <div class="flex flex-col items-center justify-center">
        <Skeleton width="60px" height="36px" />
        <Skeleton class="mt-2xs" width="31px" height="16px" />
      </div>
      <div class="flex flex-auto flex-col items-center justify-center">
        <Skeleton class="mb-sm" width="32px" height="32px" />
        <Skeleton width="70px" height="20px" />
      </div>
    </div>
    <div class="mb-xl mt-md">
      <Skeleton width="100%" height="40px" />
    </div>

    <div v-for="item in [0, 1, 2, 3, 4, 5]" :key="item" class="mb-lg">
      <Skeleton width="100%" height="32px" />
    </div>
  </SkeletonComponent>
</template>

<script>
export default {
  name: 'StatisticsPanelSkeleton',
};
</script>
