<template>
  <div class="item-logo">
    <div
      v-if="isMask"
      class="item-logo__img item-logo__img_mask"
      :style="`-webkit-mask-image: url(${icon}); mask-image: url(${icon});`"
    />
    <img
      v-else
      class="item-logo__img"
      v-lazy="{
        src: icon,
      }"
    />
  </div>
</template>

<script>
export default {
  name: 'ItemLogo',

  props: {
    isMask: {
      type: Boolean,
      default() {
        return false;
      },
    },
    link: {
      type: String,
      default() {
        return '';
      },
    },
    dictionaryItem: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    dictionary: {
      type: String,
      default() {
        return '';
      },
    },
  },
  computed: {
    icon() {
      return this.link !== ''
        ? this.link
        : this.$options.filters.dictionary(
          this.dictionaryItem,
          this.dictionary,
          'image'
        ) || '';
    },
  },
};
</script>

<style lang="scss">
.item-logo {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  &__img {
    width: 16px;
    display: inline-block;
    height: 16px;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-size: 100%;
    &_mask {
      background-color: var(--color-text);
    }
  }
}
</style>
