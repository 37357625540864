// auth views
import Layout from '@/components/layout/Layout/selector.js';
import MarketLayout from '@/views/Market/MarketLayout/selector';
import Search from '@/views/Search/Search';

export default [
  {
    path: '',
    name: 'market.root',
    component: Layout,
    children: [
      {
        path: '/search',
        name: 'search',
        component: MarketLayout,
        meta: {
          view: 'search',
        },
        children: [
          {
            path: '',
            component: Search,
            meta: {
              view: 'search',
              source: 'line',
            },
          },
        ],
      },
    ],
  },
];
