<template>
  <div>
    <UiTitle back-route="/auth/login">
      {{ $t('auth.passwordRecovery.title') }}
    </UiTitle>
    <TabsButtons
      v-if="isTabs"
      class="mx-rlg mb-lg"
      type="tabs"
      :current-tab.sync="currentTab"
      :items="tabsItems"
      :disabled="isTabsDisabled"
    />
    <PasswordRecoveryPanel
      ref="passwordRecoveryPanel"
      :current-tab="currentTab"
      :form="form"
      :status="status"
      :errors="errors"
      :tab-meta="currentTabMeta"
      :is-loading="loading"
      @submit="handlePasswordRecovery"
      @success="handleSuccess"
    />
    <UiButton
      v-if="status === 'success'"
      class="mt-lg"
      key="close"
      substyle="fas"
      icon="check"
      @click="close"
    >
      {{ $t('buttons.gotIt') }}
    </UiButton>
  </div>
</template>

<style lang="scss"></style>

<script>
import logic from '@/views/Auth/components/PasswordRecovery/logic';
import PasswordRecoveryPanel from '@/views/Auth/components/PasswordRecovery/PasswordRecoveryPanel.vue';

export default {
  name: 'PasswordRecovery',
  components: { PasswordRecoveryPanel },
  mixins: [logic],

  created() {
    const { initalForm = {}, initalTab = 'email' } = this.$route.params;
    this.init(initalForm, initalTab);
  },

  methods: {
    apply() {
      this.$router.push({
        name: 'RecoveryCode',
        params: { recoveryCodeType: this.currentTab },
      });
    },
    close() {
      this.$router.push('/auth/login');
    },
    handleSuccess() {
      this.$eventBus.$emit('open-popup', 'Alert', {
        data: {
          message: this.$t(`auth.passwordRecovery.${this.currentTab}.success`),
          title: this.$t('auth.passwordRecovery.title'),
        },
        listeners: {
          close: () => {
            this.close();
          },
        },
      });
    },
  },
};
</script>
