<template>
  <div
    class="ui-tapable"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
    @touchmove="handleTouchMove"
    @touchcancel="handleTouchCancel"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiTapable',
  data() {
    return {
      tracked: false,
    };
  },
  methods: {
    handleTouchStart() {
      this.tracked = true;
      return false;
    },
    handleTouchEnd() {
      if (this.tracked) {
        this.$emit('tap');
        this.tracked = false;
      }
    },
    handleTouchCancel() {
      this.tracked = false;
    },
    handleTouchMove(event) {
      const { left, right, top, bottom } = this.$el.getBoundingClientRect();
      const { clientX, clientY } = event.touches[0];
      this.tracked = clientX > left && clientX < right && clientY > top && clientY < bottom;
    },
  },
};
</script>
