<template>
  <div class="profile-history-info">
    <UiTitle class="mb-2xs" :back-route="null" @click="handleBack">
      <SkeletonComponent v-if="!betName">
        <Skeleton width="150px" height="32px" />
      </SkeletonComponent>
      <template v-else>
        {{ betName }}
        <template v-if="betInfo.is_free_bet">
          ({{ $t('ui.freebet') }})
        </template>
      </template>
    </UiTitle>
    <Coupon :bet-info="betInfo" />
  </div>
</template>

<script>
import betMixin from '@/helpers/betMixin';
import routerMixin from '@/helpers/router.js';
import Coupon from '@/components/Coupon/Coupon';
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileHistoryInfo',

  components: {
    Coupon,
  },

  mixins: [betMixin, routerMixin],

  data() {
    return {
      betInfoStatus: 'not_loaded',
      betInfo: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['balance']),
    pAccountId() {
      return this.balance?.id;
    },
    betId() {
      return this.$route?.params?.id || null;
    },
    betName() {
      return this.idTypeList[this.betInfo?.type]?.type === undefined
        ? ''
        : this.idTypeList[this.betInfo?.type]?.type === 'single'
          ? this.$t('profile.bets.type.single')
          : this.$t('profile.bets.type.accumulator');
    },
  },

  watch: {
    pAccountId: {
      immediate: true,
      async handler(value) {
        //TODO: potential concurrency request error
        if (!value) {
          this.betInfo = {};
          this.betInfoStatus = 'not_loaded';
        //TODO: maybe need to replace this.betInfo !== {} to !this.$_.isEmpty(this.betInfo)
        } else if (this.betInfo !== {}) {
          this.betInfoStatus = 'loading';
          try {
            await this.getBetsHistory();
            this.betInfoStatus = 'loaded';
          } catch (error) {
            this.betInfoStatus = 'error';
          }
        }
      }
    },
  },
  methods: {
    handleBack() {
      if (!this.returnTo(false)) {
        this.$router.push('/profile/history');
      }
    },
    async getBetsHistory() {
      try {
        const params = {
          account_id: this.pAccountId,
          coupon_id: this.betId,
        };
        const response = await this.$api.getBetsHistory(params);
        if (response.data.payload.list.length > 0) {
          this.betInfo = this.$_.cloneDeep(response.data.payload.list[0]);
        } else {
          await this.$router.push('/profile/history');
        }
      } catch (error) {
        this.errors = error.data.errors || {};
      }
    },
  },
};
</script>

<style lang="scss">
.profile-history-info {

}
</style>
