export default {
  methods: {
    getLinkWithReturn(to) {
      return `${to}?returnPath=${this.$route.fullPath}`;
    },
    goWithReturn(route) {
      if (this.$route.name !== route.name) {
        const params = route.params || {};
        this.$router.push({
          ...route,
          params: {
            ...params,
            returnPath: this.$route.fullPath,
            returnName: this.$route.name,
          },
        });
      }
    },
    returnTo(replace = true, successCb = null, errorCb = null) {
      if (
        this.$route.params.returnPath &&
        this.$route.params.returnPath !== '/auth/registration'
      ) {
        const returnParams = this.$route.params.returnParams || {};
        const route = {
          params: {
            ...returnParams,
            manualReturnTo: true,
          },
        };
        if (
          returnParams &&
          !this.$_.isEmpty(returnParams) &&
          this.$route.params.returnName
        ) {
          route.name = this.$route.params.returnName;
        } else {
          route.path = this.$route.params.returnPath;
        }

        const _successCb = successCb || (() => {});
        const _errorCb = errorCb || (() => {});

        if (replace) {
          this.$router.replace(route, _successCb, _errorCb);
        } else {
          this.$router.push(route, _successCb, _errorCb);
        }

        return true;
      }

      return false;
    },
  },
};
