import dayjs from 'dayjs';

export default {
  postRefreshTokenMultitab(refreshToken, authToken) { //, setLoggedOut
    console.log('postRefreshTokenMultitab', refreshToken, authToken);
    return this.baseApi
      .post(
        '/auth/refresh-token',
        { refresh_token: refreshToken },
        {
          ignoreIntercetors: { 401: true },
          headers: { authorization: `Bearer ${authToken}` },
        }
      )
      .then((response) => {
        console.log('response', response);
        const { refresh_token, token, token_ttl, refresh_ttl } = response.data.payload.token;
        const tokens = {
          accessToken: token,
          accessTokenExp: dayjs().add(token_ttl, 'seconds').unix() - 120,
          refreshToken: refresh_token,
          refreshTokenExp: dayjs().add(refresh_ttl, 'seconds').unix() - 120,
          tokenTtl: token_ttl,
          refreshTtl: refresh_ttl
        };

        console.log('tokens', tokens);
        return tokens;
      })
      .catch((error) => {
        console.trace(error);
        console.error('postRefreshTokenMultitab error', error);
        throw error;
      });
  },
};
