import store from '@/store';
import { EventBusInstance } from '@/setup/eventBus';

export async function casinoGuardCondition(isLive) {
  if (!store.getters['settings/values']) {
    const promise = new Promise((resolve, reject) => {
      EventBusInstance.$once('settings-loaded', (value) => {
        if (value) {
          resolve(value);
        } else {
          reject();
        }
      });
    });

    await promise;
  }

  const { is_open_regular, is_open_live } = store.getters['settings/values']?.casino || {}; 
  return (is_open_regular && !isLive) || (is_open_live && isLive);
}

export default function casinoGuard(isLive, route = '/') {
  return async function (to, from, next) {
    const result = await casinoGuardCondition(isLive);
    if (!result) {
      next(route);
    } else {
      next();
    }
  };
}
