<template>
  <div class="statistics">
    <div class="flex justify-between items-center mb-lg">
      <div @click="handleBack" class="flex min-w-0 items-center">
        <UiIcon
          :size="16"
          name="chevron-left"
          lib="fa"
          substyle="fas"
          class="mr-md"
        />
        <div class="statistics__title">
          {{ $t('popups.statistics.title') }}
        </div>
      </div>
    </div>
    <StatisticsPanel class="statistics__statistics-panel" :id="id" />
  </div>
</template>

<script>
import StatisticsPanel from '@/views/Market/Statistics/components/StatisticsPanel.vue';

export default {
  components: { StatisticsPanel },
  data() {
    return {};
  },
  computed: {
    id() {
      const { event } = this.$route.params;
      return +event;
    },
  },
  mounted() {},
  methods: {
    handleBack() {
      const { league, sport, event } = this.$route.params;

      this.$router.push({
        name: 'live.card',
        params: { league, sport, event },
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="scss">
.statistics {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-shrink: 1;
  }

  &__statistics-panel {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    flex-direction: column;

    .tabs-buttons_type-tabs {
      border-bottom: 1px solid transparent;
    }

    .statistics-panel__tabs-separator,
    .statistics-panel__scroller {
      margin-left: -16px;
      margin-right: -16px;
    }

    .statistics-panel__tabs-separator {
      width: auto;
    }

    .statistics-panel__content {
      flex-grow: 1;
    }

    .statistics-panel__statistics-wrapper {
      background-color: var(--color-surface);
      border: none;
    }
  }
}
</style>
