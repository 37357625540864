import { mapGetters } from 'vuex';
import ProfileNavContent from '@/views/Profile/components/ProfileNav/ProfileNavContent.vue';

export default {
  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    tfaEnable: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  components: {
    ProfileNavContent,
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters('misc', ['isMobile']),
    ...mapGetters('auth', ['balance']),

    balanceCurrency() {
      return this.balance?.currency_id || 1;
    },
    currentProfileSection() {
      return this.$route.meta.view;
    },
  },
};
