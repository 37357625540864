<template>
  <SkeletonComponent>
    <div v-for="item in [0, 1]" :key="item" class="bonus-history-item">
      <div class="flex justify-between items-start mb-2xs">
        <div class="bonus-history-item__name">
          <Skeleton width="100px" height="20px" />
        </div>
        <div class="flex items-center">
          <div class="bonus-history-item__decline">
            <Skeleton width="20px" height="20px" />
          </div>
        </div>
      </div>
      <div class="bonus-history-item__time">
        <Skeleton width="70px" height="10px" />
      </div>
      <div class="bonus-history-item__info">
        <div class="bonus-history-item__row">
          <Skeleton width="100px" height="15px" />
          <Skeleton width="100px" height="15px" />
        </div>
        <div class="bonus-history-item__row">
          <Skeleton width="100px" height="15px" />
          <Skeleton width="100px" height="15px" />
        </div>
      </div>
    </div>
  </SkeletonComponent>
</template>

<script>
export default {
  name: 'BonusHistorySkeleton',
};
</script>

<style scoped lang="scss">
.bonus-history__filter {
  display: flex;
  margin-bottom: 16px;
}
.bonus-history-item {
  padding: 12px;
  border-radius: 8px;
  background-color: var(--color-surface);
  margin-bottom: 12px;
  &__time {
    margin-bottom: 8px;
  }
  &__row {
    border-top: 1px solid var(--color-on-surface);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__decline {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
