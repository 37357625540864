<template>
  <div class="ui-help" :class="{ 'ui-help_error': isError }">
    <slot>
      <div v-html="text" />
    </slot>
  </div>
</template>

<script>
export default {
  name: 'UiHelp',

  props: {
    text: {
      type: String,
      default() {
        return '';
      },
    },
    isError: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  computed: {},
};
</script>

<style lang="scss">
.ui-help {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--color-text-secondary);
  text-align: left;
  &_error {
    color: var(--color-text-error);
  }
}
</style>
