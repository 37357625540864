<template>
  <Confirm
    ref="confirm"
    :title="$t('popups.declineBonusConfirm.title')"
    :message="$t('popups.declineBonusConfirm.message', {bonusName: name })"
    :confirm-title="$t('buttons.decline')"
    confirm-icon="trash-alt"
    @confirm="decline"
    @close="$emit('close')"
  >
  </Confirm>
</template>

<script>

export default {
  name: 'DeclineBonusConfirm',

  props: {
    name: {
      type: [String],
      default() {
        return '';
      },
    },
    id: {
      type: [Number, String],
      default() {
        return '';
      },
    },
  },

  methods: {
    open() {
      this.$refs.confirm.open();
    },
    async decline() {
      await this.$api.deleteUserGift(this.id);
      this.$eventBus.$emit('changed-bonuses', true);
      this.isVisible = false;
    },
  },
};
</script>
