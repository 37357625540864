import Connectors from './connectors';
import Interceptors from './interceptors';
import RequestsTokenService from './requestsTokenService';
import dayjs from 'dayjs';

import { 
  TokenService, 
  createAxiosAuthRequestInterceptor 
} from '@weareyipyip/multitab-token-refresh';
import {
  AUTH_LOGOUT,
  AUTH_SUCCESS,
  AUTH_TOKEN_CHANGED_DEBOUNCED,
  AUTH_SAVE_TOKEN,
} from '@/store/action_types/auth';

function logger(...args) {
  // console.log(dayjs().format('HH:mm:ss:SSS'));
  // console.log(...args);
}

export default function initTokenService(baseApiInstance, config) {
  const { baseApi } = Connectors;

  const tokenApiInstance = baseApi(config.baseUrl);

  const interceptorsInstance = Interceptors(tokenApiInstance);

  tokenApiInstance.interceptors.request.use(
    interceptorsInstance.requestSuccessInterceptor,
    interceptorsInstance.requestErrorInterceptor
  );
  tokenApiInstance.interceptors.response.use(
    interceptorsInstance.responseSuccessInterceptor,
    interceptorsInstance.responseErrorInterceptor
  );

  const apiClient = new RequestsTokenService(tokenApiInstance);

  const myTokenService = new TokenService(async () => {
    logger('waiting for local storage');
    await new Promise((resolve) => {
      setTimeout(resolve, 700);
    });
    logger('waiting for local storage end');

    const currentStatus = myTokenService.getStatus();
    logger(config.store);

    const authToken = config.store.getters['auth/token'];

    logger('authToken 1', authToken);
    logger('authToken 2', currentStatus.accessToken);

    return apiClient.postRefreshTokenMultitab(currentStatus.refreshToken, currentStatus.accessToken)
      .then((tokens) => {
        config.store.commit(`auth/${AUTH_SAVE_TOKEN}`, {
          token: tokens.accessToken,
          refresh_token: tokens.refreshToken,
          token_ttl: tokens.tokenTtl,
          refresh_ttl: tokens.refreshTtl,
        });

        config.store.commit(`auth/${AUTH_SUCCESS}`);
        config.store.dispatch(`auth/${AUTH_TOKEN_CHANGED_DEBOUNCED}`, tokens);
        logger('tokens', tokens);

        return tokens;
      })
      .catch((error) => {
        console.trace(error);
        config.store.dispatch(`auth/${AUTH_LOGOUT}`);
        throw error;
      });
  });

  myTokenService.subscribeStatusUpdates((newStatus) => {
    const isAuthenticated = config.store.getters['auth/isAuthenticated'];
    config.store.dispatch(`auth/${AUTH_TOKEN_CHANGED_DEBOUNCED}`, newStatus);
    logger('subscribeStatusUpdates', newStatus, newStatus.loggedIn, isAuthenticated);
  });

  logger('myTokenService.statusSubscribers', myTokenService.statusSubscribers);

  const axiosAuthRequestInterceptor = createAxiosAuthRequestInterceptor(myTokenService);
  baseApiInstance.interceptors.request.use((config) => {
    const currentStatus = myTokenService.getStatus();
    logger('currentStatus', currentStatus);
    if (currentStatus.accessTokenExp) {
      console.log(
        `token expires in ${currentStatus.accessTokenExp - dayjs().unix()} sec`
      );
    }

    if (currentStatus.loggedIn) {
      return axiosAuthRequestInterceptor(config);
    } else {
      config.headers.authorization = '';
      logger('axiosAuthRequestInterceptor config', config);
      return config;
    }
  });

  if (!config.store.$tokenService) {
    Object.defineProperty(config.store, '$tokenService', {
      value: myTokenService,
      writable: false,
    });
  }
}
