export default () => {
  const isMobile = localStorage.getItem('is-mobile') === 'true';
  return isMobile
    ? import(
      /* webpackChunkName: "mobile" */ '@/views/Profile/ProfileSessions/components/ProfileSessionItem/ProfileSessionItem.mobile.vue'
    )
    : import(
      /* webpackChunkName: "desktop" */ '@/views/Profile/ProfileSessions/components/ProfileSessionItem/ProfileSessionItem.desktop.vue'
    );
};
