<template>
  <SkeletonComponent>
    <div class="profile-sessions">
      <Skeleton class="profile-sessions__title" width="200px" height="16px" />
      <div class="profile-session__item mb-lg">
        <Skeleton class="mb-sm" width="400px" height="16px" />
        <Skeleton width="400px" height="16px" />
      </div>
      <Skeleton class="profile-sessions__title" width="200px" height="16px" />
      <div class="profile-session__item mb-md">
        <Skeleton class="mb-sm" width="400px" height="16px" />
        <Skeleton width="400px" height="16px" />
      </div>
      <div class="profile-session__item mb-md">
        <Skeleton class="mb-sm" width="400px" height="16px" />
        <Skeleton width="400px" height="16px" />
      </div>
      <div class="profile-session__item mb-md">
        <Skeleton class="mb-sm" width="400px" height="16px" />
        <Skeleton width="400px" height="16px" />
      </div>
    </div>
  </SkeletonComponent>
</template>

<script>
export default {
  name: 'ProfileSessionsSkeleton',

  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.profile-session {
  &__title {
    margin-bottom: 4px;
  }
  &__item {
    padding: 14px 12px;
    border-radius: 8px;
    background-color: var(--color-surface);
    display: flex;
    flex-direction: column;
  }
}
</style>
