import { AUTH_TWO_FACTOR_AUTHED, AUTH_USER } from '@/store/action_types/auth';

export default {
  name: 'Setup2FA',

  data() {
    return {
      loading: false,
      qrUrl: '',
      linkUrl: '',
      secret: '',
      codes: null,
      errors: {},
      form: {},
    };
  },

  computed: {
    isValid() {
      return !!this.form.code;
    },
  },

  methods: {
    async init() {
      this.form = this.getDefaultForm();
      const response = await this.$api.postUserTwoFactorAuthentication();
      const { codes, secret, svg, url } = response.data.payload;
      this.qrUrl = svg;
      this.linkUrl = url;
      this.secret = secret;
      this.codes = codes;
    },
    async handleConfirm() {
      this.loading = true;
      try {
        this.errors = {};
        await this.$api.putAuthTwoFactorAuthentication(this.form);
        this.$store.dispatch(`auth/${AUTH_USER}`);
        this.$store.dispatch(`auth/${AUTH_TWO_FACTOR_AUTHED}`, 1);
        this.$eventBus.$emit('open-popup', 'ConfirmationTfa', {
          data: {
            secret: this.codes,
          },
        });
        this.close();
      } catch (error) {
        this.errors = error?.data?.errors || {};
        this.$nextTick(() => {
          const el = document.getElementsByClassName('el-scrollbar__wrap')[0];
          el.scrollTo({
            top: el.scrollHeight,
            left: 0,
            behavior: 'smooth',
          });
        });
      } finally {
        this.loading = false;
      }
    },
    close() {},
    getDefaultForm() {
      return {
        code: '',
      };
    },
  },
};
