<template>
  <div v-if="isLoading" class="site-loader">
    <UiLogo size="big" class="mb-4xl" />
    <Spinner />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Loader',
  computed: {
    ...mapGetters('misc', ['isLoading']),
  },
  watch: {
    isLoading(value) {
      const html = document.querySelector('html');
      if (value) {
        html.classList.add('html_popup');
      } else {
        html.classList.remove('html_popup');
      }
    },
  },
};
</script>

<style lang="scss">
.site-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-background);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
