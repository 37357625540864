<template>
  <div
    class="separator"
    :style="style"
  ></div>
</template>

<script>
export default {
  name: 'UiSeparator',
  props: {
    color: {
      type: String,
      default: '--color-on-surface',
    },
  },
  computed: {
    style() {
      return {
        backgroundColor: `var(${this.color})`,
      };
    },
  }
};
</script>

<style lang="scss">
.separator {
  width: 100%;
  height: 1px;
}
</style>
