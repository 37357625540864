<template>
  <div class="mobile-app-banner">
    <div class="mobile-app-banner__section">
      <div @click="closeBanner">
        <UiIcon
          :size="10"
          :color="$config.cp.secondary"
          name="times"
          lib="fa"
          substyle="fas"
          class="mr-md"
        />
      </div>
      <img src="@/assets/images/icons/app.svg" class="mobile-app-banner__logo"  alt=""/>
      <div class="mobile-app-banner__text">
        <div class="mobile-app-banner__title">
          {{ $t('mobileApp.banner.title') }}
        </div>
        <div class="mobile-app-banner__subtitle">
          {{ $t('mobileApp.banner.subtitle') }}
        </div>
      </div>
    </div>
    <a
      :href="appDownloadUrl"
      target="_blank"
      class="link"
    >
      <UiIcon
        :size="20"
        :color="$config.cp.link"
        name="download"
        lib="fa"
        substyle="fas"
        class="mr-2xs"
      />
      <!--        {{ $t('mobileApp.banner.link') }}-->
    </a>
  </div>
</template>

<script>
import AppDownloadLink from '@/helpers/AppDownloadLink';
import { MISC_MOBILE_APP_BANNER_CLOSE } from '@/store/action_types/misc';
export default {
  name: 'MobileAppBanner',

  mixins: [AppDownloadLink],

  methods: {
    closeBanner() {
      this.$store.commit(`misc/${MISC_MOBILE_APP_BANNER_CLOSE}`);
    },
  },
};
</script>

<style lang="scss">
.mobile-app-banner {
  width: 100%;
  height: 72px;
  padding: 12px 16px;
  background-color: var(--color-background);

  display: flex;
  justify-content: space-between;
  align-items: center;
  &__section {
    display: flex;
    align-items: center;
  }
  &__logo {
    margin-right: 12px;
    width: 48px;
    line-height: 0;
  }
  &__text {
    padding-right: 8px;
  }
  &__title {
    font-size: 12px;
    font-weight: 700;
    color: var(--color-text);

    margin-bottom: 4px;
  }
  &__subtitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.67;
    color: var(--color-text-secondary);
  }
}
</style>
