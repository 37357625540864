import ThreeSectionLayout from '@/components/layout/ThreeSectionLayout';
import bonusBalance from '@/helpers/bonusBalance';
import { mapGetters } from 'vuex';

export default {
  components: {
    ThreeSectionLayout,
  },
  mixins: [bonusBalance],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('auth', { profile: 'user' }),
    isEmailVerified() {
      return this.profile.email && this.profile.email_verified_at;
    },
    isPhoneVerified() {
      return this.profile.phone && this.profile.phone_verified_at;
    },
    comfirmationCaption() {
      if (this.isPhoneVerified && this.isEmailVerified) {
        return '';
      }

      const subject = !(this.isPhoneVerified || this.isEmailVerified)
        ? 'both'
        : this.isEmailVerified
          ? 'phone'
          : 'email';

      const notConfirmedSubject = this.$t(
        `profile.notConfirmedSubject.${subject}`
      );
      return `${notConfirmedSubject} ${this.$t('profile.notConfirmedMessage')}`;
    },
  },
  async mounted() {},
};
