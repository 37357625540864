import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('misc', { casinoDictionary: 'casino' }),
    isLive() {
      return this.$route.meta.live;
    },
    pCasinoDictionary() {
      const { dictionaryKey } = this.liveCasinoParams;
      return this.casinoDictionary[dictionaryKey];
    },
    casinoCategories() {
      return this.pCasinoDictionary.categories;
    },
    casinoCategoriesIndex() {
      return this.pCasinoDictionary.categoriesIndex;
    },
    casinoProviders() {
      return this.pCasinoDictionary.providers;
    },
    casinoProvidersIndex() {
      return this.pCasinoDictionary.providersIndex;
    },
    liveCasinoParams() {
      return this.isLive
        ? {
          id: 'live-casino',
          path: 'live-casino',
          name: 'liveCasino',
          i18nKey: 'liveCasino',
          dictionaryKey: 'live',
        } : {
          id: 'casino',
          path: 'casino',
          name: 'casino',
          i18nKey: 'casino',
          dictionaryKey: 'regular',
        };
    },
  },
};
