import ElementUI from './element-ui';
import Vuelidate from 'vuelidate';
import VueClipboard from 'vue-clipboard2';
import Notifications from 'vue-notification';
import VueLazyload from 'vue-lazyload';
import VueDragscroll from 'vue-dragscroll';

export default (App) => {
  App.use(ElementUI);
  App.use(Vuelidate);
  App.use(VueClipboard);
  App.use(Notifications);
  App.use(VueLazyload);
  App.use(VueDragscroll);
};
