<template>
  <BetSlip />
</template>

<script>
import BetSlip from '@/components/BetSlip/selector';

export default {
  name: 'Basket',

  components: {
    BetSlip,
  },
};
</script>

<style lang="scss"></style>
