export const SETUP_LIVE_CASINO = 'SETUP_LIVE_CASINO';
export const LIVE_CASINO_SCRIPT_STATUS = 'LIVE_CASINO_SCRIPT_STATUS';
export const LIVE_CASINO_DGA_STATUS = 'LIVE_CASINO_DGA_STATUS';
export const LIVE_CASINO_TABLE_KEY = 'LIVE_CASINO_TABLE_KEY';
export const LIVE_CASINO_SET_TABLE_INFO = 'LIVE_CASINO_SET_TABLE_INFO';
export const LIVE_CASINO_SUBSCRIBE = 'LIVE_CASINO_SUBSCRIBE';
export const LIVE_CASINO_UNSUBSCRIBE = 'LIVE_CASINO_UNSUBSCRIBE';
export const LIVE_CASINO_CLEAR_TABLE_INFO = 'LIVE_CASINO_CLEAR_TABLE_INFO';
export const LIVE_CASINO_CURRENCY = 'LIVE_CASINO_CURRENCY';
export const LIVE_CASINO_CONNECT = 'LIVE_CASINO_CONNECT';
export const LIVE_CASINO_PROVIDERS = 'LIVE_CASINO_PROVIDERS';
