import { render, staticRenderFns } from "./UiChevron.vue?vue&type=template&id=fd9d9778&functional=true"
var script = {}
import style0 from "./UiChevron.vue?vue&type=style&index=0&id=fd9d9778&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports