export default {
  getDictionaries() {
    return this.baseApi.get('/dictionaries');
  },
  getSettings(params) {
    return this.baseApi.get('/settings', { params });
  },
  getPing() {
    return this.baseApi.get('/ping');
  },
};
