<template>
  <Popup
    class="confirmation-popup"
    :title="$t('popups.unlinkPhone.title')"
    :is-visible.sync="isVisible"
    :click-outside="false"
  >
    <div slot="body">
      <template v-if="!initialLoading">
        <div class="unlink-phone__value mb-sm">{{ phone }}</div>
        <div class="unlink-phone__text mb-sm">
          {{ $t('popups.unlinkPhone.text') }}
        </div>
        <UiButton
          class="mt-lg mb-md"
          substyle="fas"
          icon="paper-plane"
          :disabled="isCodeSended"
          @click="handleSendCode"
        >
          {{ $t('popups.confirmation.sendCode') }}
        </UiButton>
        <UiInput
          class="mb-lg"
          v-model="codeValue"
          :placeholder="$t(`popups.confirmation.${type}.confirmPlaceholder`)"
          :label="$t(`popups.confirmation.confirmationCodeLabel`)"
          error-clear-on-change
          :error.sync="codeErrors.code"
          :disabled="!isCodeSended"
        />
        <ResendCodeButton
          class="flex justify-center mt-lg mb-lg"
          :message="resendMessage"
          :is-resend="isResend"
          :is-code-sended="isCodeSended"
          @resend="handleSendNewCode"
        ></ResendCodeButton>
      </template>
      <SkeletonComponent v-else>
        <div v-for="item in [0, 1, 2]" :key="item" class="mb-sm">
          <div>
            <Skeleton class="mb-2xs" width="30%" height="15px" />
          </div>
          <div>
            <Skeleton width="100%" height="40px" />
          </div>
        </div>
      </SkeletonComponent>
    </div>
    <UiButton
      slot="footer"
      substyle="fas"
      icon="check"
      :disabled="!isCodeSended || loading || !isCodeValid"
      @click="handleConfirm"
    >
      {{ $t('buttons.confirm') }}
    </UiButton>
  </Popup>
</template>

<script>
import { AUTH_USER } from '@/store/action_types/auth';
import confirmCodeMixin from '@/views/Auth/components/Signup/mixins/confirm-code.js';
import { mapGetters } from 'vuex';
import ResendCodeButton from '@/components/ResendCodeButton.vue';

export default {
  name: 'UnlinkPhone',
  mixins: [confirmCodeMixin],
  components: { ResendCodeButton },

  props: {
    type: {
      type: String,
      default: 'email',
    },
  },
  data() {
    return {
      isVisible: false,
      errors: {},
      phone: '',
      initialLoading: false,
    };
  },
  computed: {
    ...mapGetters('settings', {
      settingsValues: 'values',
    }),
    ...mapGetters('auth', ['user']),
    isResend() {
      return this.diff <= 0;
    },
  },
  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },
  methods: {
    async init() {
      this.errors = {};
      this.phone = this.$options.filters.phoneHidden(this.user.phone);
    },
    open() {
      this.isVisible = true;
      this.init();
    },
    async handleSendNewCode() {
      if (this.isResend) {
        await this.handleSendCode();
      }
    },
    async handleSendCode() {
      const type = 'change_phone';
      this.loading = true;
      try {
        this.clearErrors();
        this.pMessage = '';
        const response = await this.$api.postUserCodeSend(type);

        this.setCode(response.data.payload.code);
      } catch (error) {
        this.codeErrors = error?.data?.errors || {};
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async updateUser(payload) {
      await this.$store.commit(`auth/${AUTH_USER}`, payload.user);
      this.$eventBus.$emit('profile-update-user');
    },
    expired() {
      this.isCodeSended = true;
    },
    async close(payload) {
      this.updateUser(payload);
      this.isVisible = false;
      this.$eventBus.$emit('open-popup', 'Alert', {
        data: {
          message: this.$t('popups.unlinkPhone.alertMessage'),
          title: this.$t('popups.unlinkPhone.title'),
        },
      });
    },
  },
};
</script>

<style lang="scss">
.unlink-phone {
  &__value {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.11;
    color: var(--color-primary);
  }

  &__text {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.43;
  }
}
</style>
