<template>
  <Popup
    class="password-recovery-popup"
    :title="$t('auth.passwordRecovery.title')"
    :is-visible.sync="isVisible"
    :is-tabs="isTabs"
    :current-tab.sync="currentTab"
    :tabs-items="tabsItems"
    :tabs-disabled="isTabsDisabled"
  >
    <template slot="body">
      <PasswordRecoveryPanel
        ref="passwordRecoveryPanel"
        :current-tab="currentTab"
        :form="form"
        :status="status"
        :errors="errors"
        :tab-meta="currentTabMeta"
        :is-loading="loading"
        @submit="handlePasswordRecovery"
        @success="handleSuccess"
      />
    </template>
    <template slot="footer">
      <UiButton
        v-if="status === 'success'"
        substyle="fas"
        icon="check"
        @click="close"
      >
        {{ $t('buttons.gotIt') }}
      </UiButton>
      <!-- <UiButton v-else substyle="fas" icon="check" @click="apply">
        {{ $t('buttons.checkCode') }}
      </UiButton> -->
    </template>
  </Popup>
</template>

<script>
import logic from '@/views/Auth/components/PasswordRecovery/logic';
import PasswordRecoveryPanel from '@/views/Auth/components/PasswordRecovery/PasswordRecoveryPanel.vue';

export default {
  name: 'PasswordRecovery',
  components: { PasswordRecoveryPanel },
  mixins: [logic],
  props: {
    initalForm: {
      type: Object,
      default: () => {},
    },
    initalTab: {
      type: String,
      default: () => 'email',
    },
  },
  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },
  methods: {
    open() {
      this.isVisible = true;
      this.init(this.initalForm, this.initalTab);
    },

    apply() {},

    close() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
.password-recovery-popup {
  .popup__footer {
    padding: 0;
  }
}
</style>