<template>
  <div class="curacao-verification">
    <iframe :src='licenseLink'></iframe>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CuracaoVerification',

  computed: {
    ...mapGetters('settings', {
      settingsValues: 'values',
    }),
    licenseId() {
      return this.settingsValues['content-static']?.license_id || '';
    },
    licenseLink() {
      return `https://licensing.gaming-curacao.com/validator/?lh=${this.licenseId}&template=tseal`;
    },
  },
};
</script>

<style lang="scss">
.curacao-verification{
  width: 120px;
  height: 49px;
  iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
}
</style>
