<template>
  <Popup
    class="contacts__popup"
    :title="$t('popups.contacts.title')"
    :is-visible.sync="isVisible"
    @close="close"
  >
    <div slot="body">
      <ProfileContactList is-hide />
    </div>
    <div slot="footer">
      <UiButton
        substyle="fas"
        icon="check"
        @click="close"
      >
        {{ $t('buttons.gotIt') }}
      </UiButton>
    </div>
  </Popup>
</template>

<script>
import ProfileContactList from '@/views/Profile/components/ProfileContacts/ProfileContactList';

export default {
  name: 'ContactsPopup',

  components: {
    ProfileContactList,
  },

  data() {
    return {
      isVisible: false,
    };
  },

  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },

  methods: {
    open() {
      this.isVisible = true;
    },

    close() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
.contacts__popup {
  .popup__body {
    margin-bottom: 32px;
  }
}
</style>
