<template>
  <Confirm
    ref="confirm"
    :title="$t('auth.logout.title')"
    :message="$t('auth.logout.message')"
    :confirm-title="$t('buttons.logout')"
    confirm-icon="right-to-bracket"
    @confirm="logout"
    @close="$emit('close')"
  >
  </Confirm>
</template>

<script>
import { AUTH_LOGOUT } from '@/store/action_types/auth';

export default {
  name: 'Logout',

  methods: {
    open() {
      this.$refs.confirm.open();
    },
    async logout() {
      await this.$store.dispatch(`auth/${AUTH_LOGOUT}`);
      // this.$router.push('/');
      this.isVisible = false;
    },
  },
};
</script>
