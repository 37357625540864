<template>
  <div class="site-settings">
    <div class="wrapper">
      <div class="site-settings__wrapper">
        <ThemeButton class="mr-lg" />
        <LanguagesSelect class="mr-md" />
        <TimezoneSelect class="mr-md" />
        <router-link
          to="/mobile-app"
          :class="[
            'smart-link',
            { 'smart-link_active': mobileAppActive  }
          ]"
        >
          <UiIcon
            :size="12"
            name="mobile"
            lib="fa"
            substyle="fas"
            class="mr-sm"
          />
          {{ $t('header.settings.mobileApps') }}
        </router-link>
      </div>
      <router-link class="no-underline site-settings__wrapper" to="/promotions">
        <UiButton substyle="fas" icon="gift" type="secondary">
          {{ $t('header.settings.promotions') }}
          <img src="../assets/images/present.png" alt="" />
        </UiButton>
      </router-link>
    </div>
  </div>
</template>
<script>
import ThemeButton from '@/components/ThemeButton';
import LanguagesSelect from '@/components/LanguagesSelect';
import TimezoneSelect from '@/components/TimezoneSelect/selector';

export default {
  components: { ThemeButton, LanguagesSelect, TimezoneSelect },

  computed: {
    mobileAppActive() {
      return this.$route.meta.view === 'mobile-app';
    },
  }
};
</script>

<style lang="scss">
.site-settings {
  padding: 12px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &__wrapper {
    display: flex;
    align-items: stretch;
  }
  .wrapper {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .ui-button {
      padding: 6px 48px 6px 12px;
      overflow: inherit;
      img {
        width: 40px;
        line-height: 0;
        position: absolute;
        bottom: 0;
        right: 0;
      }
      i {
        margin-right: 8px;
      }
    }
  }
  .smart-link {
    white-space: nowrap;
    display: flex;
    align-items: center;
    text-decoration: none;

    font-size: 12px;
    font-weight: 700;
    line-height: 1.33;
    color: var(--color-text);

    transition: all 0.2s;

    &:hover,
    &_active {
      color: var(--color-link);
    }
  }
}
</style>
