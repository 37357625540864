<template>
  <div class="result">
    <div class="result__ico">
      <UiIcon
        :size="64"
        :color="iconColor"
        :name="resultIcon"
        lib="fa"
        substyle="fas"
        class="mb-2xl"
      />
    </div>
    <div class="result__title">{{ $t('profile.result.title') }}</div>
    <div class="result__text">{{ resultText }}</div>
    <router-link to="/" class="no-underline">
      <UiButton substyle="fas" icon="chevron-left">
        {{ $t('profile.result.button') }}
      </UiButton>
    </router-link>
  </div>
</template>

<style lang="scss">
.result {
  color: var(--color-text);
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 8px;
  }
  &__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    margin-bottom: 16px;
  }
}
</style>

<script>
export default {
  name: 'Result',

  computed: {
    result() {
      return this.$router.currentRoute.params.result;
    },
    resultIcon() {
      switch (this.result) {
        case 'success':
          return 'check-circle';
        case 'fail':
          return 'times-circle';
        case 'waiting':
          return 'fa-clock';
        default:
          return '';
      }
    },
    iconColor() {
      switch (this.result) {
        case 'success':
          return this.$config.cp.success;
        case 'fail':
          return this.$config.cp['text-error'];
        case 'waiting':
          return this.$config.cp.alert;
        default:
          return '';
      }
    },
    resultText() {
      switch (this.result) {
        case 'success':
          return this.$t('profile.result.text.success');
        case 'fail':
          return this.$t('profile.result.text.fail');
        case 'waiting':
          return this.$t('profile.result.text.waiting');
        default:
          return '';
      }
    },
  },
};
</script>
