const baseConfigModule = require('../comboplay-common');

module.exports = {
  getConfig: function (includeThemes = false) {
    const result = baseConfigModule.getConfig(includeThemes);
    result.apiBaseUrl = 'https://api.comboplay.com';
    result.partnerId = 1;

    return result;
  },
};
