import Vue from 'vue';
import VueI18n from 'vue-i18n';
import baseTranslations from '@/i18n/base-translations';
import _ from 'lodash';
import { spoil } from '@/i18n/helpers';

export const defaultLanguage = localStorage.getItem('language') || 'en';
// export const spoilText = '!TEXT!';
export const spoilText = false;

Vue.use(VueI18n);

class VueI18nExtend extends VueI18n {
  getChoiceIndex(choice, choicesLength) {
    // eslint-disable-next-line no-empty
    if (this.locale !== 'ru') {
    }
    if (choice === 0) {
      return 0;
    }
    const teen = choice > 10 && choice < 20;
    const endsWithOne = choice % 10 === 1;
    if (!teen && endsWithOne) {
      return 1;
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
      return 2;
    }
    return choicesLength < 4 ? 2 : 3;
  }
}

const loadedLanguages = [];

const i18n = new VueI18nExtend({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
});

function setI18nLanguage(lang) {
  i18n.locale = lang;
  if (Vue.prototype.$api) {
    const { $api } = Vue.prototype;
    $api.baseApi.defaults.headers.common['x-language'] = lang;
  }

  return lang;
}

export function loadLanguageAsync(lang, force = false, oldValue = null) {
  setI18nLanguage(lang);
  if (!force) {
    // If the same language
    // or if the language was already loaded
    if (oldValue === lang || loadedLanguages.includes(lang)) {
      return Promise.resolve(lang);
    }
  }

  if (Vue.prototype.$api) {
    const { $api } = Vue.prototype;
    return $api
      .getTranslation(lang)
      .then((response) => {
        const { translations } = response.data.payload;
        let result = {};
        _.merge(result, baseTranslations, translations);
        if (spoilText) {
          result = spoil(result, spoilText);
        }

        loadedLanguages.push(lang);
        // console.log('i18n-debug: setLocaleMessage', {
        //   i18n: _.cloneDeep(result),
        //   lang,
        // });
        i18n.setLocaleMessage(lang, result);
      })
      .catch(() => {
        i18n.setLocaleMessage(lang, baseTranslations);
        // console.log('i18n-debug: setLocaleMessage fallback', {
        //   i18n: baseTranslations,
        //   lang,
        // });
      });
  } else {
    console.error('Vue.prototype.$api is unset');
  }

  // // If the language hasn't been loaded yet
  // return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.js`).then(
  //   messages => {
  //     i18n.setLocaleMessage(lang, messages.default)
  //     loadedLanguages.push(lang)
  //     return setI18nLanguage(lang)
  //   }
  // )
}

export default i18n;
