<template>
  <div class="breadcrumbs">
    <div v-for="(item, index) in items" :key="index" class="breadcrumbs__item">
      <template v-if="index < items.length - 1">
        <router-link
          v-if="item.title"
          class="breadcrumbs__link"
          :to="item.link"
        >
          {{ item.title }}
        </router-link>
        <RouteBackSkeleton v-else />

        <span class="breadcrumbs__divider">></span>
      </template>

      <span v-else class="breadcrumbs__inactive-link">
        <span v-if="item.title" class="title">{{ item.title }}</span>
        <RouteBackSkeleton v-else />
      </span>
    </div>
  </div>
</template>

<script>
import RouteBackSkeleton from '@/components/skeletons/RouteBackSkeleton.vue';
export default {
  name: 'Breadcrumbs',

  components: { RouteBackSkeleton },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss">
.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  flex-grow: 1;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  color: var(--color-text-secondary);

  &__item {
    display: flex;
    white-space: nowrap;
  }

  &__link {
    text-decoration: none;
    transition: 0.2s ease-out;
    color: var(--color-text-secondary);
    &:hover {
      color: var(--color-primary);
    }
  }

  &__divider {
    margin: 0 8px;
  }

  &__inactive-link {
    color: var(--color-dark1);
  }
}
</style>
