<template>
  <Confirm
    ref="confirm"
    :title="title"
    :message="message"
    :confirm-title="button"
    @confirm="terminate"
    @close="$emit('close')"
  >
  </Confirm>
</template>

<script>
export default {
  name: 'TerminateConfirm',
  props: {
    isAll: {
      type: Boolean,
      default() {
        return false;
      },
    },
    sessionId: {
      type: Number,
      default: null,
    },
  },

  computed: {
    title() {
      return !this.isAll
        ? this.$t('popups.terminateConfirm.title')
        : this.$t('popups.terminateConfirm.titleAll');
    },
    message() {
      return !this.isAll
        ? this.$t('popups.terminateConfirm.message')
        : this.$t('popups.terminateConfirm.messageAll');
    },
    button() {
      return !this.isAll
        ? this.$t('popups.terminateConfirm.button')
        : this.$t('popups.terminateConfirm.buttonAll');
    },
  },

  methods: {
    open() {
      this.$refs.confirm.open();
    },
    async terminate() {
      const param = this.isAll ? 'all' : this.sessionId;
      await this.$api.deleteUserToken(param);
      this.$eventBus.$emit('update-sessions');
      this.isVisible = false;
    },
  },
};
</script>
