<template>
  <div @click="onClick" class="theme-button" :class="[`theme-button_${theme}`]">
    <div
      class="theme-toggle"
      id="theme-toggle"
      aria-label="light"
      aria-live="polite"
    >
      <svg
        class="sun-and-moon"
        aria-hidden="true"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <mask class="moon" id="moon-mask">
          <rect x="0" y="0" width="100%" height="100%" fill="white"></rect>
          <circle cx="24" cy="10" r="6" fill="black"></circle>
        </mask>
        <circle
          class="sun"
          cx="12"
          cy="12"
          r="6"
          mask="url(#moon-mask)"
          fill="currentColor"
        ></circle>
        <g class="sun-beams" stroke="currentColor">
          <line x1="12" y1="1" x2="12" y2="3"></line>
          <line x1="12" y1="21" x2="12" y2="23"></line>
          <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
          <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
          <line x1="1" y1="12" x2="3" y2="12"></line>
          <line x1="21" y1="12" x2="23" y2="12"></line>
          <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
          <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
        </g>
      </svg>
    </div>
    <div class="theme-button__text">
      {{ $t(`ui.themes.${theme}`) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThemeButton',

  computed: {
    theme() {
      return this.$config.themeName;
    },
  },

  methods: {
    onClick() {
      this.$config.switchTheme();
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute('content', this.$config.cpRaw.surface);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins.scss';

:where(html) {
  --ease-1: cubic-bezier(0.25, 0, 0.5, 1);
  --ease-2: cubic-bezier(0.25, 0, 0.4, 1);
  --ease-3: cubic-bezier(0.25, 0, 0.3, 1);
  --ease-4: cubic-bezier(0.25, 0, 0.2, 1);
  --ease-5: cubic-bezier(0.25, 0, 0.1, 1);

  --ease-in-1: cubic-bezier(0.25, 0, 1, 1);
  --ease-in-2: cubic-bezier(0.5, 0, 1, 1);
  --ease-in-3: cubic-bezier(0.7, 0, 1, 1);
  --ease-in-4: cubic-bezier(0.9, 0, 1, 1);
  --ease-in-5: cubic-bezier(1, 0, 1, 1);

  --ease-out-1: cubic-bezier(0, 0, 0.75, 1);
  --ease-out-2: cubic-bezier(0, 0, 0.5, 1);
  --ease-out-3: cubic-bezier(0, 0, 0.3, 1);
  --ease-out-4: cubic-bezier(0, 0, 0.1, 1);
  --ease-out-5: cubic-bezier(0, 0, 0, 1);

  --ease-in-out-1: cubic-bezier(0.1, 0, 0.9, 1);
  --ease-in-out-2: cubic-bezier(0.3, 0, 0.7, 1);
  --ease-in-out-3: cubic-bezier(0.5, 0, 0.5, 1);
  --ease-in-out-4: cubic-bezier(0.7, 0, 0.3, 1);
  --ease-in-out-5: cubic-bezier(0.9, 0, 0.1, 1);

  --ease-elastic-1: cubic-bezier(0.5, 0.75, 0.75, 1.25);
  --ease-elastic-2: cubic-bezier(0.5, 1, 0.75, 1.25);
  --ease-elastic-3: cubic-bezier(0.5, 1.25, 0.75, 1.25);
  --ease-elastic-4: cubic-bezier(0.5, 1.5, 0.75, 1.25);
  --ease-elastic-5: cubic-bezier(0.5, 1.75, 0.75, 1.25);

  --ease-squish-1: cubic-bezier(0.5, -0.1, 0.1, 1.5);
  --ease-squish-2: cubic-bezier(0.5, -0.3, 0.1, 1.5);
  --ease-squish-3: cubic-bezier(0.5, -0.5, 0.1, 1.5);
  --ease-squish-4: cubic-bezier(0.5, -0.7, 0.1, 1.5);
  --ease-squish-5: cubic-bezier(0.5, -0.9, 0.1, 1.5);

  --ease-step-1: steps(2);
  --ease-step-2: steps(3);
  --ease-step-3: steps(4);
  --ease-step-4: steps(7);
  --ease-step-5: steps(10);
}

.theme-button {
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 8px;
  background-color: var(--color-on-surface);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.2s ease-out;
    background-color: var(--color-button-hover);
  }
  @include hover {
    &:after {
      opacity: 1;
    }
  }
  &__text {
    font-size: 12px;
    font-weight: 700;
    line-height: 1.33;
    margin-left: 4px;
    color: var(--color-text);
  }

  .theme-toggle {
    --size: 16px;

    inline-size: var(--size);
    block-size: var(--size);
    aspect-ratio: 1;

    background: none;
    border: none;
    padding: 0;
    border-radius: 50%;

    svg {
      inline-size: 100%;
      block-size: 100%;
      stroke-linecap: round;
    }
  }
  .sun-and-moon {
    :is(.moon, .sun, .sun-beams) {
      transform-origin: center center;
    }
    :is(.moon, .sun) {
      fill: var(--color-text);
    }
    .sun {
      transition: transform 0.5s var(--ease-elastic-3);
    }
    .sun-beams {
      stroke: var(--color-text);
      stroke-width: 2px;
      transition: transform 0.5s var(--ease-elastic-4),
        opacity 0.5s var(--ease-3);
    }

    .moon circle {
      transition: transform 0.25s var(--ease-out-5);
    }
  }
  &_light {
    .sun-and-moon {
      .sun {
        transform: scale(1.75);
        transition-timing-function: var(--ease-3);
        transition-duration: 0.25s;
      }
      .sun-beams {
        opacity: 0;
        transform: rotate(-25deg);
        transition-duration: 0.15s;
      }

      .moon > circle {
        transform: translateX(-7px);
        transition-delay: 0.25s;
        transition-duration: 0.5s;
      }
    }
  }
}

@supports (cx: 1) {
  .sun-and-moon .moon > circle {
    transition: cx 0.25s var(--ease-out-5);
  }

  [data-theme='dark'] {
    .sun-and-moon .moon > circle {
      transform: translate(0);
      cx: 17;
    }
  }
}
</style>
