<template>
  <div class="favorites-list" :class="{ 'px-lg': !isMobile }">
    <UiTitle :back-route="isMobile ? null : ''" @click="handleBack"> {{ $t('market.favorites') }} </UiTitle>
    <template v-if="isLoading && isLoadingFirst && !error">
      <FavoritesSkeleton />
    </template>
    <div v-if="emptyList && !isLoading" class="favorites-list__empty">
      <UiIcon
        :size="32"
        :color="$config.cp['text-secondary']"
        name="stars"
        lib="fa"
        substyle="fas"
        class="mb-lg"
      />
      <div class="favorites-list__empty-text">
        <template v-if="emptyList && !error">
          {{ $t('favorites.emptyList') }}
        </template>
        <template v-if="error">
          {{ $t('favorites.error') }}
        </template>
        <div class="mt-md">
          <router-link to="/" class="no-underline">
            <UiButton substyle="fas" icon="chevron-left">
              {{ $t('profile.result.button') }}
            </UiButton>
          </router-link>
        </div>

      </div>
    </div>
    <template v-if="!(isLoading && isLoadingFirst) && !emptyList">
      <UiInfiniteScroll @reach-bottom="getFavoritesList">
        <div v-if="favoritesListLive.length > 0">
          <div
            class="favorites-list__title"
            :class="{ 'favorites-list__title_mobile': isMobile }"
          >
            {{ $t('favorites.live') }}
          </div>
          <FavoritesLine
            v-for="event of favoritesListLive"
            :key="event.id"
            :data="event"
          />
        </div>
        <div v-if="favoritesListLine.length > 0" class="favorites-list__events-wrapper">
          <div
            class="favorites-list__title"
            :class="{ 'favorites-list__title_mobile': isMobile }"
          >
            {{ $t('favorites.line') }}
          </div>
          <FavoritesLine
            v-for="event of favoritesListLine"
            :key="event.id"
            :data="event"
          />
        </div>
      </UiInfiniteScroll>

    </template>
  </div>
</template>

<script>
import FavoritesLine from '@/views/Market/Favorites/components/FavoritesLine/selector';
import FavoritesSkeleton from '@/views/Market/Favorites/components/FavoritesSkeleton/selector';

import {mapGetters} from 'vuex';

export default {
  name: 'FavoritesList',

  components: {
    FavoritesLine,
    FavoritesSkeleton
  },

  data() {
    return {
      error: false,

      isLoading: true,
      isLoadingFirst: true,
      scrollIsAvailable: true,

      limitOffset: 31,
      limit: 30,
      offset: 0,

      favoritesListLine: [],
      favoritesListLive: [],
    }
  },

  computed: {
    ...mapGetters('misc', ['isMobile']),

    emptyList() {
      return !this.favoritesListLine.length && !this.favoritesListLive.length;
    },
  },

  mounted() {
    this.getFavoritesList(true);
  },

  methods: {
    async getFavoritesList(force = false) {
      this.isLoading = true;
      this.error = false;
      if (this.scrollIsAvailable || force) {
        this.scrollIsAvailable = false;
        if (force) {
          this.offset = 0;
          this.isLoadingFirst = true;
          this.favoritesListLine = [];
          this.favoritesListLive = [];
        }
        try {
          const params = {
            limit: this.limitOffset,
          }
          if (this.favoritesListLine?.length + this.favoritesListLive?.length > 0) {
            this.offset = this.offset + this.limit;
            params.offset = this.offset;
          }
          params.statuses = this.filterValue;

          const response = await this.$api.getFavorites(params);

          const dataOffset = this.$_.cloneDeep(response.data.payload.list);
          const data = this.$_.cloneDeep(response.data.payload.list);
          if(data.length === this.limitOffset) {
            data.pop();
          }
          const line = data.filter(item => !item.is_live)
          const live = data.filter(item => item.is_live)
          this.favoritesListLine.push(...line);
          this.favoritesListLive.push(...live);

          if (dataOffset.length === this.limitOffset) {
            this.scrollIsAvailable = true;
          }
          this.isLoadingFirst = false;
        } catch (error) {
          this.error = true;
        } finally {
          this.isLoading = false;
        }
      }
    },
    handleBack() {
      if(this.isMobile) {
        this.$router.go(-1)
      }
    }
  }
};
</script>

<style lang="scss">
.favorites-list{
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-text);
    padding: 11px 16px;
    border-radius: 8px;
    background-color: var(--color-surface);
    &_mobile{
      font-size: 16px;
      line-height: 1.5;
      background-color: transparent;
      padding: 0;
      margin-bottom: 8px;
    }
  }
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 465px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.67;
    color: var(--color-text-secondary);
    &-text {
      display: flex;
      flex-direction: column;
      align-items: center;

      text-align: center;
    }
  }
}
</style>
