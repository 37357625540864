<template>
  <UiSelect
    :value="value"
    :list="pList"
    filterable
    :label="label"
    option-value-param="id"
    :option-label-param="dictionary === 'isRegCurrencies' ? 'name_and_val' : 'name'"
    :option-name-param="pOptionSelect"
    :placeholder="placeholder"
    :is-item-ico="this.dictionary === 'isRegCountries'"
    :is-list-item-ico="this.dictionary === 'isRegCountries'"
    is-icons-dictionary="countriesIndex"
    type="medium"
    class="mb-sm"
    :error="error"
    :disabled="isLocked || isDisabled"
    @update:value="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'SignupPanelDictionary',
  props: {
    dictionary: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    list: {
      type: Array,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    formMeta: {
      type: Object,
      default: () => ({}),
    },
    error: {
      type: Array,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    pList() {
      return (this.list || this.$store.getters[`misc/${this.dictionary}`] || []);
    },
    pOptionSelect() {
      return this.dictionary === 'isRegCurrencies'
        ? 'name_and_val'
        : this.dictionary === 'isRegCountries'
          ? 'name_and_code'
          : 'name';
    },
  },
};
</script>
