<template>
  <div
    class="ui-datepicker"
    :class="[
      `ui-datepicker_${size}`,
      { 'ui-datepicker_focused': focused },
      { 'ui-datepicker_disabled': disabled },
      { 'ui-datepicker_has-icon': hasIcon },
    ]"
  >
    <span v-if="label" class="ui-datepicker__label">
      {{ label }}
    </span>
    <div class="ui-datepicker__wrapper">
      <UiIcon
        v-if="hasIcon"
        class="ui-datepicker__icon"
        :size="14"
        :name="icon"
        lib="fa"
        substyle="fas"
      />
      <VueDatePicker
        v-model="selectedDate"
        class="ui-datepicker__activator"
        no-header
        no-calendar-icon
        :name="pName"
        format="DD MMM YYYY"
        range-input-text="%d - %d"
        :disabled="disabled"
        :locale="{ lang: currentLang }"
        :placeholder="placeholder"
        :max-date="maxDate"
        :min-date="minDate"
        :range-presets="presets"
        :range="range"
        :validate="validate"
        :color="$config.cpRaw.primary"
        content-class="contentClass"
        :clearable="clearable"
        ref="menu"
        fullscreen-mobile
        @onOpen="openDatepicker"
        @onClose="closeDatepicker"
        @onChange="dateSelected"
      />
    </div>
  </div>
</template>

<script>
import { VueDatePicker } from '@/lib/vue-datepicker/src';
import '@/lib/vue-datepicker/dist/vue-datepicker.min.css';
import en from '@/lib/vue-datepicker/src/locale/translations/en';
import { mapGetters} from 'vuex';

export default {
  name: 'UiDatepicker',

  components: {
    VueDatePicker,
  },

  props: {
    date: {
      type: [Object, String],
      default() {
        return null;
      },
    },
    size: {
      type: String,
      default() {
        return 'big';
      },
    },
    label: {
      type: String,
      default() {
        return '';
      },
    },
    placeholder: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dateBirth: {
      type: Boolean,
      default() {
        return false;
      },
    },
    minDate: {
      type: [String, Date],
      default: '1950-01-01',
    },
    contentClass: {
      type: String,
      default: '',
    },
    range: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Boolean,
      default: false,
    },
    isPresets: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      menu: false,
      focused: false,
    };
  },

  computed: {
    ...mapGetters('settings', ['currentTimezone']),
    currentDate() {
      return this.$options.filters.dateNOFormat(Date.now());
    },
    hasIcon() {
      return this.icon !== '';
    },
    pName() {
      return this.name ? this.name : this.$t('datepicker.label');
    },
    presets() {
      return this.isPresets
        ? [
          {
            name: this.$t('datepicker.presets.yesterday'),
            dates: {
              start: this.$options.filters.dateJSFormat(this.currentDate.subtract(1, 'day')),
              end: this.$options.filters.dateJSFormat(this.currentDate.subtract(1, 'day')),
            },
          },
          {
            name: this.$t('datepicker.presets.week'),
            dates: {
              start: this.$options.filters.dateJSFormat(this.currentDate.subtract(1, 'week')),
              end: this.$options.filters.dateJSFormat(this.currentDate),
            },
          },
          {
            name: this.$t('datepicker.presets.twoWeeks'),
            dates: {
              start: this.$options.filters.dateJSFormat(this.currentDate.subtract(2, 'week')),
              end: this.$options.filters.dateJSFormat(this.currentDate),
            },
          },
          {
            name: this.$t('datepicker.presets.month'),
            dates: {
              start: this.$options.filters.dateJSFormat(this.currentDate.subtract(30, 'day')),
              end: this.$options.filters.dateJSFormat(this.currentDate),
            },
          },
        ]
        : [];
    },

    currentLang() {
      return this.$i18n.locale;
    },
    selectedDate: {
      get() {
        return this.date;
      },
      set(newVal) {
        this.$emit('update:date', newVal);
      },
    },
    maxDate() {
      const maxDate =  this.currentDate;
      return this.$options.filters.dateJSFormat(maxDate);
    },
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.menu.$refs.agenda.mode = 'year'));
    },
  },

  created() {
    en.weekStart = 1;
  },

  methods: {
    dateSelected() {
      this.$eventBus.$emit('date-picker-selected');
    },
    openDatepicker() {
      if (this.dateBirth) {
        this.menu = true;
      }
      this.focused = true;
    },
    closeDatepicker() {
      this.menu = false;
      this.focused = false;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';

.vd-overlay__inner {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.vd-menu__content {
  background-color: var(--color-on-surface);
  margin-top: 12px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
}

.vd-icon.vd-icon {
  color: var(--color-text);
  padding: 0;
  width: 32px;
  min-width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.vd-picker__input-clear__icon .vd-icon.vd-icon {
  color: var(--color-text) !important;
}
.vd-picker {
  background-color: var(--color-on-surface);
  border-radius: 8px;
  &__input-clear {
    align-self: center;
    padding-right: 4px;
  }
  &__title {
    p {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.6;
    }
  }
  .vd-picker__table {
    padding: 0 16px 16px 16px;
    //height: 338px;
    &-weekday {
      font-size: 12px;
      font-weight: 500;
      color: var(--color-text-secondary);
    }
    &-day {
      &__current {
        color: var(--color-primary);
      }
      &__effect {
        background-color: var(--color-primary);
        border: solid 1px var(--color-primary);
      }
      &__text {
        font-size: 14px;
        font-weight: 500;
        color: var(--color-text);
      }
    }
  }
  &__controls {
    button {
      color: var(--color-text) !important;
      &[disabled] svg {
        color: var(--color-text) !important;
        opacity: 0.3;
      }
    }
  }

  &__months {
    &-list {
      padding: 0 16px 16px 16px;
    }
    &-button {
      font-size: 14px;
      font-weight: 500;
      color: var(--color-text);
      border: solid 1px var(--color-on-surface);
      border-radius: 8px;
      height: 100%;
      &__wrapper {
        padding: 0;
      }
      &:hover {
        background-color: var(--color-on-surface);
        border: solid 1px var(--color-primary);
      }
      &.active {
        border: solid 1px var(--color-primary);
        background-color: var(--color-primary);
      }
    }
  }

  &__years {
    scrollbar-width: thin;
    scrollbar-color: rgba(144, 147, 153, 0.3) var(--color-on-surface);
    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      background-color: var(--color-on-surface);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(144, 147, 153, 0.3);
      border-radius: 20px;
      border: 3px solid var(--color-on-surface);
    }

    li {
      font-size: 14px;
      font-weight: 500;
      color: var(--color-text);
      border: solid 1px var(--color-on-surface);
      border-radius: 8px;
      height: 60px;
      &:hover {
        background-color: var(--color-on-surface);
        border: solid 1px var(--color-primary);
      }
      &.active {
        border: solid 1px var(--color-primary);
        background-color: var(--color-primary);
      }
    }
  }
}
.vd-picker__table-day:hover:not(.vd-picker__table-day--disabled):not(.vd-picker__table-day--selected):not(.vd-picker__table-day--between) .vd-picker__table-day__effect {
  background-color: transparent;
  border: solid 1px var(--color-primary);
  opacity: 1;
}

.vd-picker__table-day:hover:not(.vd-picker__table-day--disabled):not(.vd-picker__table-day--selected):not(.vd-picker__table-day--between).vd-picker__table-day--select-end .vd-picker__table-day__wrapper:before,
.vd-picker__table-day:hover:not(.vd-picker__table-day--disabled):not(.vd-picker__table-day--selected):not(.vd-picker__table-day--between).vd-picker__table-day--select-start .vd-picker__table-day__wrapper:before {
  opacity: 0;
}
.vd-picker__table-day:hover:not(.vd-picker__table-day--disabled):not(.vd-picker__table-day--selected):not(.vd-picker__table-day--between).vd-picker__table-day--select-end .vd-picker__table-day__wrapper:after {
  border-radius: 0 50% 50% 0;
}
.vd-picker__table-day:hover:not(.vd-picker__table-day--disabled):not(.vd-picker__table-day--selected):not(.vd-picker__table-day--between).vd-picker__table-day--select-start .vd-picker__table-day__wrapper:after {
  border-radius: 50% 0 0 50%;
}

.vd-picker-validate__button {
  font-family: Montserrat, sans-serif;
}

.vd-picker__table-day--first .vd-picker__table-day__wrapper:before,
.vd-picker__table-day--last .vd-picker__table-day__wrapper:before {
  opacity: 0;
}
.vd-picker__table-day--first .vd-picker__table-day__wrapper:after {
  border-radius: 50% 0 0 50%;
}
.vd-picker__table-day--last .vd-picker__table-day__wrapper:after {
  border-radius: 0 50% 50% 0;
}

.vd-picker__table-day--last.vd-picker__table-day--first .vd-picker__table-day__wrapper:after {
  border-radius: 50%;
}
.vd-picker-presets__wrapper {
  justify-content: space-between;
}
.vd-picker-preset {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.2;
  color: var(--color-text);
  border: none;
  flex: initial;
  margin-bottom: 8px;
  padding: 4px 12px;
  border-radius: 8px;
  background-color: var(--color-on-surface);
  width: calc((100% - 8px) / 2);
  transform: translateZ(0);

  &--selected {
    color: var(--color-on-primary);
  }
  &:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
}
.vd-picker--bottomsheet .vd-picker__table-day__current,
.vd-picker--bottomsheet .vd-picker__table-day__effect {
  width: 40px;
  height: 40px;
}
.vd-picker--bottomsheet .vd-picker__table-day__text {
  width: 40px;
  height: 40px;
}
.vd-picker--bottomsheet .vd-picker__table-day__wrapper:after {
  height: 40px;
}
.vd-picker__table-day--selected {
  .vd-picker__table-day__text {
    color: var(--color-on-primary);
  }
}

.ui-datepicker {
  display: flex;
  flex-direction: column;
  &__label {
    font-size: 12px;
    font-weight: 600;
    color: var(--color-text);
    line-height: 2.67;
  }
  &__wrapper {
    display: inline-flex;
    align-items: center;
    border: 1px solid var(--color-on-surface);
    background-color: var(--color-on-surface);
    transition: border-color 0.2s;
    border-radius: 8px;
    overflow: hidden;
  }
  &_big & {
    &__wrapper input {
      padding: 12px;
      font-size: 14px !important; // font-size: 16px!important; in component
    }
  }
  &_small & {
    &__wrapper input {
      padding: 7px;
      font-size: 12px !important; // font-size: 16px!important; in component
    }
  }
  &_focused & {
    &__wrapper {
      border-color: var(--color-primary);
    }
  }
  input {
    font-family: Montserrat, sans-serif;
    display: flex;
    align-items: center;
    min-width: 10%;
    max-width: 100%;
    flex: 10 1 auto;
    border: none;
    outline: none;
    padding: 0;
    height: 38px;
    max-height: 38px;
    background: transparent;
    color: var(--color-text);
    font-weight: 500;
    line-height: 1.33;

    text-overflow: ellipsis;
    overflow: hidden;
    flex-shrink: 1;
    white-space: nowrap;
  }

  &_disabled {
    input {
      opacity: 1;
    }

    .vd-picker__input-clear {
      display: none;
    }
  }

  &_disabled &__wrapper {
    opacity: 0.5;
  }

  &_has-icon {
    position: relative;
  }

  &__icon {
    position: absolute;
    left: 12px;
  }

  &_has-icon .vd-picker__input {
    padding-left: 24px;
  }
}

.app_view-history .vd-menu__content {
  margin-left: -24px;
}

@media (max-width: $screen-mobile) {
  .vd-picker,
  .vd-menu__content {
    background-color: var(--color-surface);
  }
  .app_view-history .vd-menu__content {
    margin-left: 0;
  }
}
</style>
