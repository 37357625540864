<template>
  <div class="bonus-history-item__info">
    <div class="bonus-history-item__row">
      <div class="bonus-history-item__info-name">
        {{ $t('profile.bonusHistory.rows.freespins') }}
      </div>
      <div class="bonus-history-item__info-value">
        {{ data.spins || data.spins_total }} / <span>{{ data.spins_total }}</span>
      </div>
    </div>
    <div class="bonus-history-item__row">
      <div class="bonus-history-item__info-name">
        {{ $t('profile.bonusHistory.rows.provider') }}
      </div>
      <div class="bonus-history-item__info-value">
        {{ data.provider }}
      </div>
    </div>
    <TypeAccordion :games="data.games" />
  </div>
</template>

<script>
import logic from '@/views/Profile/ProfileBonuses/components/BonusHistory/components/logic';
import TypeAccordion from '@/views/Profile/ProfileBonuses/components/BonusHistory/components/types/TypeAccordion.vue';
export default {
  name: 'Freespin',
  components: {TypeAccordion},

  mixins: [logic],
};
</script>
