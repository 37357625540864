<template>
  <Popup
    class="results-filter-popup"
    :title="$t('popups.resultsFilter.title')"
    :is-visible.sync="isVisible"
  >
    <div class="results-filter-popup__body" slot="body">
      <UiInput
        class="mb-md"
        name="search"
        :placeholder="$t('ui.search')"
        autocomplete="off"
        is-search
        @search="handleSearch"
      />

      <el-scrollbar class="flex-grow">
        <ResultSport
          class="mr-sm"
          :selected.sync="pSelected"
          :filter="filter"
        />
      </el-scrollbar>
    </div>
    <div slot="footer" class="flex">
      <UiButton
        class="flex-grow"
        substyle="fas"
        icon="times-circle"
        type="secondary"
        @click="handleReset"
      >
        {{ $t('buttons.reset') }}
      </UiButton>
      <UiButton
        class="flex-grow ml-lg"
        substyle="fas"
        icon="check"
        @click="handleConfirm"
      >
        {{ $t('buttons.confirm') }}
      </UiButton>
    </div>
  </Popup>
</template>

<script>
import ResultSport from '@/views/Result/components/ResultSport/selector.js';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default {
  name: 'ResultsFilter',

  components: {
    ResultSport,
  },

  props: {
    selected: {
      type: String,
      default: 'all',
    },
  },
  data() {
    return {
      isVisible: false,
      pSelected: 'all',
      filter: '',
    };
  },
  computed: {
    emptyList() {
      return this.eventGroups.length === 0;
    },
  },
  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },
  methods: {
    handleSearch(filter) {
      this.filter = filter;
    },
    handleReset() {
      this.pSelected = 'all';
      this.filter = '';
      this.handleConfirm();
    },
    handleConfirm() {
      this.$emit('select', this.pSelected);
      this.isVisible = false;
    },
    open() {
      enableBodyScroll();
      this.pSelected = this.selected;
      this.isVisible = true;
    },
    cancel() {
      disableBodyScroll();
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
.results-filter-popup {
  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;
  }

  .popup__body {
    min-height: 0;
    flex-grow: 1;
  }

  // &__sports-wrapper {
  //   max-height: 100%;
  //   overflow-y: auto;
  // }

  .popup__window {
    height: 100%;
    padding-top: 100px;
    display: flex;
    flex-direction: column;

    & > .layout-panel {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      max-height: 100%;
      min-height: 0;
      position: relative;

      & > .layout-panel__content-wrapper {
        min-height: 0;

        & > .layout-panel__content {
          max-height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
</style>
