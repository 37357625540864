<template>
  <div class="signup-confirm-panel">
    <div class="signup-confirm-panel__title-container">
      <div
        class="signup-confirm-panel__back inline-flex items-center"
        @click="handleBack"
      >
        <UiIcon
          :size="16"
          name="chevron-left"
          lib="fa"
          substyle="fas"
          class="mr-sm"
        />
        <div>{{ $t('popups.confirmationSignup.back') }}</div>
      </div>
      <div class="signup-confirm-panel__title mt-md">
        {{ $t('popups.confirmationSignup.title') }}
      </div>
      <div class="signup-confirm-panel__subtitle mt-lg">
        {{ confirmParams.text1 }}
      </div>
      <div class="signup-confirm-panel__value mt-lg break-all">
        {{ confirmParams.value }}
      </div>
      <div class="signup-confirm-panel__subtitle mt-sm">
        {{ confirmParams.text2 }}
      </div>
      <div class="mt-sm">
        <UiInput
          v-model="codeValue"
          :label="$t('popups.confirmationSignup.label')"
          type="text"
          :placeholder="confirmParams.placeholder"
          name="confirmation_code"
          :error="errors"
          @input="handleInput"
        />
        <ResendCodeButton
          class="flex justify-center mt-lg"
          :message="resendMessage"
          :is-resend="isResend"
          :is-code-sended="isCodeSended"
          @resend="handleSendNewCode"
        ></ResendCodeButton>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SIGNUP_TAB_EMAIL,
  SIGNUP_TAB_ONECLICK,
  SIGNUP_TAB_PHONE,
} from '@/views/Auth/components/Signup/logic.js';

import confirmCodeMixin from '@/views/Auth/components/Signup/mixins/confirm-code.js';
import { parsePhoneNumber } from 'libphonenumber-js';
import ResendCodeButton from '@/components/ResendCodeButton.vue';

export default {
  name: 'SignupConfirmPanel',
  components: {
    ResendCodeButton,
  },
  mixins: [confirmCodeMixin],
  props: {
    currentTab: {
      type: Number,
      default: 0,
    },
    code: {
      type: Object,
      default: () => ({}),
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    formMeta: {
      type: Object,
      default: () => ({}),
    },
    isValid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    errors() {
      return this.codeErrors
        ? this.codeErrors.code
          ? this.codeErrors.code
          : this.codeErrors.message
        : null;
    },
    phoneNumber() {
      const phoneNumber = parsePhoneNumber(this.formMeta.phone);
      return phoneNumber.formatInternational();
    },
    isResend() {
      return this.diff <= 0;
    },
    pIsValid() {
      return !!this.codeValue;
    },
    signUpTabs() {
      return {
        SIGNUP_TAB_EMAIL: SIGNUP_TAB_EMAIL,
        SIGNUP_TAB_ONECLICK: SIGNUP_TAB_ONECLICK,
        SIGNUP_TAB_PHONE: SIGNUP_TAB_PHONE,
      };
    },
    isPhone() {
      return this.currentTab === SIGNUP_TAB_PHONE;
    },
    confirmParams() {
      if (this.isPhone) {
        return {
          text1: this.$t('popups.confirmationSignup.phone.text1'),
          text2: this.$t('popups.confirmationSignup.phone.text2'),
          value: this.phoneNumber,
          placeholder: this.$t('popups.confirmationSignup.phone.placeholder'),
          alertMessage: this.$t('popups.confirmationSignup.phone.alertMessage'),
        };
      }

      return {
        text1: this.$t('popups.confirmationSignup.email.text1'),
        text2: this.$t('popups.confirmationSignup.email.text2'),
        value: this.form.email,
        placeholder: this.$t('popups.confirmationSignup.email.placeholder'),
        alertMessage: this.$t('popups.confirmationSignup.email.alertMessage'),
      };
    },
  },
  watch: {
    pIsValid: {
      immediate: true,
      handler(value) {
        this.$emit('update:isValid', value);
      },
    },
  },
  methods: {
    handleInput() {
      this.clearErrors();
    },
    handleBack() {
      this.$emit('back');
    },
    // handleResend() {
    //   console.log('resend');
    // },
    close() {
      // this.$eventBus.$emit('open-popup', 'Alert', {
      //   data: {
      //     message: this.confirmParams.alertMessage,
      //   },
      // });
    },
    expired() {
      this.handleBack();
    },
  },
  mounted() {
    this.setCode(this.code);
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins.scss';

.signup-confirm-panel {
  &__title-container {
    display: inline-block;
  }

  &__back {
    font-size: 12px;
    font-weight: 600;
    line-height: 2;
    cursor: pointer;
    user-select: none;

    transition: 0.2s ease-out;
    @include hover {
      color: var(--color-link-hover);
    }
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
  }

  &__value {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.11;
    color: var(--color-primary);
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
  }

  &__resend-message {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--color-text-disable);

    &_active {
      cursor: pointer;
      user-select: none;
      &:hover {
        color: var(--color-primary);
      }
    }
  }
}
</style>
