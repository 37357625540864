// auth views
import Layout from '@/components/layout/Layout/selector.js';
import MarketLayout from '@/views/Market/MarketLayout/selector.js';
import TopList from '@/views/Market/TopList/selector.js';
import FavoritesList from '@/views/Market/Favorites/FavoritesList.vue';
import EventList from '@/views/Market/EventList/selector.js';
import EventCard from '@/views/Market/EventCard/selector.js';
import Basket from '@/views/Market/Basket.vue';
import Statistics from '@/views/Market/Statistics/Statistics.vue';

import multiguard from 'vue-router-multiguard';
import mobileGuard
// , { mobileGuardCondition } 
from '@/router/guards/mobileGuard.js';
import authGuard from '@/router/guards/authGuard';
// import customGuard from '../guards/customGuard';
// import { authGuardCondition } from '../guards/authGuard';

// const eventListMobileGuard = customGuard((to) => {
//   const { league, sport } = to.params;
//   const isMobile = mobileGuardCondition(true);
//   const isSportsOnly = sport && !league;

//   return isMobile && isSportsOnly && { name: to.name };
// });

export default [
  {
    path: '',
    name: 'market.root',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'top',
        component: MarketLayout,
        meta: {
          view: 'top',
        },
        children: [
          {
            path: '',
            component: TopList,
            meta: {
              view: 'top',
              source: 'line',
            },
          },
        ],
      },
      {
        path: '/favorites',
        name: 'favorites',
        component: MarketLayout,
        meta: {
          view: 'favorites',
        },
        beforeEnter: multiguard([authGuard(true)]),
        children: [
          {
            path: '',
            component: FavoritesList,
            meta: {
              view: 'favorites',
              source: 'line',
            },
          },
        ],
      },
      {
        path: '/live',
        name: 'live',
        component: MarketLayout,
        meta: {
          view: 'live',
        },
        children: [
          {
            path: ':sport(\\d+)/:league(\\d+)/:event(\\d+)',
            component: EventCard,
            name: 'live.card',
            meta: {
              view: 'live',
              source: 'live',
            },
          },
          {
            path: ':sport(\\d+)?/:league(\\d+)?',
            component: EventList,
            name: 'live.list',
            meta: {
              view: 'live',
              source: 'live',
            },
            // beforeEnter: eventListMobileGuard,
          },
        ],
      },
      {
        path: '/line',
        name: 'line',
        component: MarketLayout,
        meta: {
          view: 'line',
        },
        children: [
          {
            path: ':sport(\\d+)/:league(\\d+)/:event(\\d+)',
            component: EventCard,
            name: 'line.card',
            meta: {
              view: 'line',
              source: 'line',
            },
          },
          {
            path: ':sport(\\d+)?/:league(\\d+)?',
            component: EventList,
            name: 'line.list',
            meta: {
              view: 'line',
              source: 'line',
            },
            // beforeEnter: eventListMobileGuard,
          },
        ],
      },
      {
        path: '/live/:sport(\\d+)/:league(\\d+)/:event(\\d+)/statistics/',
        name: 'statistics',
        component: Statistics,
        meta: {
          view: 'statistics',
          hideFooter: true,
        },
        beforeEnter: multiguard([mobileGuard(true)]),
      },
    ],
  },
  {
    path: '/bet-slip',
    name: 'bet-slip',
    component: Basket,
    meta: {
      view: 'bet-slip',
      hideFooter: true,
    },
    beforeEnter: multiguard([mobileGuard(true)]),
  },
];
