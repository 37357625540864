<template>
  <Popup
    class="confirmation-popup"
    title="Success"
    :is-visible.sync="isVisible"
  >
    <div slot="body">
      <p class="mb-lg" v-html="$t('popups.confirmationTfa.text1')" />
      <UiCopyRow class="confirmation-popup__copy mb-lg" :value="secret" />
      <p>{{ $t('popups.confirmationTfa.text2') }}</p>
    </div>
    <UiButton slot="footer" substyle="fas" icon="check" @click="close">
      {{ $t('buttons.gotIt') }}
    </UiButton>
  </Popup>
</template>

<script>
export default {
  name: 'ConfirmationTfa',
  props: {
    secret: {
      type: [String, Array],
      default: '',
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },
  methods: {
    open() {
      this.isVisible = true;
    },

    close() {
      this.isVisible = false;
    },
  },
};
</script>
