<template>
  <Popup
    class="reg-complete-popup"
    :title="$t('popups.regComplete.title')"
    :is-visible.sync="isVisible"
    :click-outside="false"
    close-status="hide"
    popup-logo
    @close="close"
  >
    <div slot="body">
      <p>{{ $t('popups.regComplete.text') }}</p>
      <div class="reg-complete-popup__info">
        <div class="reg-complete-popup__info-label">
          {{ $t('popups.regComplete.user') }}
        </div>
        <div class="reg-complete-popup__info-value">
          {{ userId }}
        </div>
      </div>
      <UiSeparator class="my-md" />
      <div class="reg-complete-popup__info">
        <div class="reg-complete-popup__info-label">
          {{ $t('popups.regComplete.password') }}
        </div>
        <div class="reg-complete-popup__info-value">
          {{ password }}
        </div>
      </div>
      <div class="reg-complete-popup__actions">
        <UiButton
          substyle="fas"
          icon="copy"
          @click="copyInfo(saveText)"
          class="mr-sm"
        >
          {{ $t('buttons.copy') }}
        </UiButton>
        <UiButton
          type="secondary"
          substyle="fas"
          icon="file"
          @click="downloadInfo"
        >
          {{ $t('buttons.saveAsFile') }}
        </UiButton>
      </div>
    </div>
    <UiButton
      slot="footer"
      substyle="fas"
      icon="check"
      :disabled="foolproof > 0"
      :loading="foolproof > 0"
      loading-timer
      :loading-timer-value="timerValue"
      @click="close"
    >
      {{ $t('buttons.confirm') }}
    </UiButton>
  </Popup>
</template>

<script>
import copyHelper from '@/helpers/copy';

export default {
  name: 'RegComplete',

  mixins: [copyHelper],

  props: {
    message: {
      type: String,
      default: '',
    },
    userId: {
      type: [String, Number],
      default: '',
    },
    password: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isVisible: false,
      foolproof: 5,
    };
  },

  computed: {
    saveText() {
      return this.$t('popups.regComplete.copyCredentials', {
        userId: this.userId,
        password: this.password,
      });
    },
    timerValue() {
      return `0:0${this.foolproof}`
    }
  },

  methods: {
    open() {
      this.isVisible = true;
      this.foolproof = 5;
      if (this.foolproof > 0) {
        setInterval(() => {
          this.foolproof = this.foolproof - 1;
        }, 1000);
      }

    },

    close() {
      this.isVisible = false;
      this.$emit('close');
    },

    downloadInfo() {
      const element = document.createElement('a');
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(this.saveText)
      );
      element.setAttribute(
        'download',
        `SavePassword_${this.saveText
          .split('\n')[0]
          .replace(/[^0-9]/g, '')}.txt`
      );
      element.setAttribute('target', '_blank');
      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
  },
};
</script>

<style lang="scss">
.reg-complete-popup {
  p {
    margin-bottom: 34px;
  }
  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-text);
    &-label {
      font-weight: 600;
    }
    &-value {
      font-weight: 500;
    }
  }
  &__actions {
    margin-top: 16px;
    display: flex;
  }
}
</style>
