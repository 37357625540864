export default {
  data() {
    return {
      isVisible: false,
      currentTab: 'email',
      loading: false,
      form: {},
      errors: {},
      status: 'pending-identity',
      isValid: false,
    };
  },
  computed: {
    isTabs() {
      return this.status !== 'success';
    },
    isTabsDisabled() {
      if (this.status === 'confirm-identity') {
        const items = this.tabsItems.filter(
          (item) => this.currentTab !== item.label
        );
        return items[0]?.label;
      }

      return '';
    },
    tabsItems() {
      return [
        {
          label: 'email',
          name: this.$t('auth.passwordRecovery.tab.email'),
          formParams: ['email'],
        },
        {
          label: 'phone',
          name: this.$t('auth.passwordRecovery.tab.phone'),
          formParams: ['phone'],
        },
      ];
    },
    currentTabMeta() {
      const meta = this.tabsItems.find(
        (item) => item.label === this.currentTab
      );

      return meta || {};
    },
  },
  methods: {
    init(initalForm, initalTab) {
      this.form = this.$_.pick(initalForm, [
        'id',
        'phone',
        'country',
        'phoneMasked',
      ]);

      if (this.form.id) {
        this.form.email = this.form.id;
        this.$delete(this.form, 'id');
        this.form = this.$_.cloneDeep(this.form);
      }
      this.currentTab = initalTab;
    },
    async handlePasswordRecovery() {
      try {
        this.loading = true;
        this.errors = {};
        const recaptchaParams = await this.$recaptchaChallange();
        const form = this.$_.pick(this.form, this.currentTabMeta.formParams);
        const response = await this.$api.postPasswordRecovery({
          ...form,
          ...recaptchaParams,
        });
        const { code } = response.data.payload;
        this.$refs.passwordRecoveryPanel.setCode(code);
        this.status = 'confirm-identity';
      } catch (errors) {
        this.errors = errors.data.errors;
        if (errors.data.message) {
          this.$eventBus.$emit('notify-event', {
            type: 'error',
            text: errors.data.message,
          });
        }
      } finally {
        this.loading = false;
      }
    },
    handleSuccess() {
      this.status = 'success';
    },
    //override
    close() {},
  },
};
