<template>
  <SkeletonComponent>
    <div class="coupon">
      <div class="coupon__date">
        <Skeleton width="200px" height="16px" />
      </div>
      <div class="coupon__info">
        <div class="coupon__info-title">
          <div class="flex justify-between items-center mb-sm">
            <Skeleton width="100px" height="20px" />
            <Skeleton width="50px" height="20px" />
          </div>
          <div class="flex justify-between items-center">
            <Skeleton width="150px" height="18px" />
            <Skeleton width="50px" height="18px" />
          </div>
        </div>
        <CouponDivider />
        <div
          class="coupon-item"
          :class="[`coupon-item_${theme}`]"
        >
          <div class="flex items-start justify-between mb-2xs">
            <div class="coupon-item__date">
              <Skeleton width="150px" height="15px" />
            </div>
            <Skeleton width="50px" height="20px" />
          </div>
          <div class="coupon-item__tournament">
            <Skeleton width="250px" height="16px" />
          </div>
          <div class="coupon-item__coef-name">
            <Skeleton width="250px" height="16px" />
          </div>
          <div class="coupon-item__team">
            <div class="flex items-center min-w-0">
              <div class="coupon-item__team-logo">
                <Skeleton width="32px" height="32px" />
                <Skeleton width="32px" height="32px" />
              </div>
              <div class="coupon-item__team-name">
                <Skeleton width="150px" height="15px" />
                <Skeleton width="150px" height="15px" />
              </div>
            </div>
            <div class="coupon-item__score">
              <Skeleton width="20px" height="15px" />
              <Skeleton width="20px" height="15px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </SkeletonComponent>
</template>

<script>
import CouponDivider from './CouponDivider.vue'

export default {
  name: 'CouponSkeleton',

  components: {
    CouponDivider
  },

  computed: {
    theme() {
      return this.$config.themeName;
    },
  }
};
</script>

<style scoped lang="scss">
.coupon{
  &__date{
    margin-bottom: 12px;
  }
  &__info{
    border-radius: 16px;
    background-color: var(--color-surface);
    padding: 16px 0;
    &-title{
      padding: 0 16px 6px;
    }
  }
}
.coupon-item{
  padding: 0 16px;
  position: relative;
  &__tournament {
    margin-bottom: 8px;
  }
  &__coef-name{
    margin-bottom: 12px;
  }
  &__team {
    align-items: center;
    display: flex;
    justify-content: space-between;
    &-logo {
      display: flex;
      margin-right: 12px;
      min-width: 56px;
      span{
        &:last-of-type {
          margin-left: -8px;
          z-index: 1;
        }
      }
    }
    &-name {
      span {
        display: block;
        margin-bottom: 4px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  &__score{
    span {
      display: block;
      margin-bottom: 4px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
</style>
