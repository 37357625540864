<template>
  <div @click="openPopupHandler">
    <ProfileNavContent
      :class="[
        'profile-nav__item',
        {'profile-nav__item_active': isActive}
      ]"
      :balance-currency="balanceCurrency"
      :icon="info.icon"
      :is-mobile="isMobile"
      :name="info.name"
      :type="info.type"
      :tfa-enable="tfaEnable"
    />
  </div>
</template>

<script>
import logic from '@/views/Profile/components/ProfileNav/logic';
export default {
  name: 'ProfileNavButton',

  mixins: [logic],

  data() {
    return {
      isActive: false,
    };
  },

  methods: {
    openPopupHandler() {
      this.isActive = this.info.click === 'newPassword' || (this.info.click === 'logout' && !this.isMobile);
      this.$eventBus.$emit('open-popup', this.info.click, {
        listeners: {
          close: () => {
            this.isActive = false;
          },
        },
      });
    },
  },

};
</script>

<style lang="scss">
@import 'style.scss';
</style>
