import auth from './modules/auth';
import i18n from './modules/i18n';
import misc from './modules/misc';
import events from './modules/events';
import user from './modules/user';
import bets from './modules/bets';
import promo from './modules/promo';
import casino from './modules/casino';
import content from './modules/content';
import favorites from './modules/favorites';

export default class Requests {
  constructor(baseApi) {
    Object.assign(this, { baseApi });

    const requests = {
      ...auth,
      ...i18n,
      ...misc,
      ...events,
      ...user,
      ...bets,
      ...promo,
      ...casino,
      ...content,
      ...favorites,
    };

    Object.assign(this, requests);
  }
}
