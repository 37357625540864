// auth views
import Layout from '@/components/layout/Layout/selector.js';
import Terms from '@/views/Terms/Terms';

export default [
  {
    path: '',
    name: 'other.root',
    component: Layout,
    children: [
      {
        path: '/terms/:id',
        component: Terms,
      },
    ],
  },
];
