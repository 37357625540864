<template>
  <Popup
    class="bet-details__popup"
    :title="betName"
    :is-visible.sync="isVisible"
    @close="clear"
  >
    <div slot="body">
      <Coupon :bet-info="betInfo" />
    </div>
    <UiButton
      slot="footer"
      substyle="fas"
      type="secondary"
      icon="times"
      @click="close"
    >
      {{ $t('buttons.close') }}
    </UiButton>
  </Popup>
</template>

<script>
import Coupon from '@/components/Coupon/Coupon';
import betMixin from '@/helpers/betMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'BetDetails',

  components: { Coupon },

  mixins: [betMixin],
  props: {
    betId: {
      type: [Number, String],
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      isVisible: false,
      betInfo: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['balance']),
    pAccountId() {
      return this.balance?.id;
    },
    betName() {
      let text = this.idTypeList[this.betInfo?.type]?.type === undefined
        ? ''
        : this.idTypeList[this.betInfo?.type]?.type === 'single'
          ? this.$t('profile.bets.type.single')
          : this.$t('profile.bets.type.accumulator');
      if(this.betInfo?.is_free_bet) {
        text = text + ` (${this.$t('ui.freebet')})`
      }
      return text
    },
  },

  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },
  methods: {
    defaultValues() {},
    async getBetsHistory() {
      try {
        const params = {
          account_id: this.pAccountId,
          coupon_id: this.betId,
        };
        const response = await this.$api.getBetsHistory(params);
        this.betInfo = this.$_.cloneDeep(response.data.payload.list[0]);
      } catch (error) {
        this.errors = error.data.errors || {};
      }
    },
    open() {
      this.getBetsHistory();
      document.querySelector('html').classList.add('no-scroll');
      this.isVisible = true;
    },

    close() {
      this.clear();
      this.isVisible = false;
    },

    clear() {
      document.querySelector('html').classList.remove('no-scroll');
      this.defaultValues();
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';

.bet-details__popup {
  .el-scrollbar__wrap {
    margin-bottom: 0!important;
  }
  .layout-panel__content-wrapper_type-default {
    background-color: var(--color-background);
  }
  .popup__title{
    margin-bottom: 4px;
  }
  //.el-scrollbar__wrap {
  //  max-height: calc(100vh - 300px);
  //  padding: 18px 24px;
  //}
  //.bet-slip-item {
  //  padding: 12px 0;
  //  &__delete {
  //    display: none;
  //  }
  //  &:last-of-type {
  //    border-bottom: none;
  //  }
  //}
}
@media (max-width: $screen-mobile) {
  //.bet-details__popup .el-scrollbar__wrap {
  //  max-height: calc((var(--vh) * 100px) - 148px);
  //  padding: 18px 24px;
  //}
}
</style>
