<template>
  <div :class="['ui-copy-row', { 'ui-copy-row_array': type === 'array' }]">
    <div v-if="type === 'row'" class="ui-copy-row__value">
      {{ value }}
    </div>
    <div v-else class="ui-copy-row__value-list flex flex-wrap mb-sm">
      <div
        class="ui-copy-row__value-list-item w-1/2"
        v-for="(item, index) in value"
        :key="index"
      >
        {{ item }}
      </div>
    </div>
    <UiButton
      class="ui-copy-row__button"
      substyle="fas"
      icon="copy"
      @click="copyHandler"
    >
      {{ $t('buttons.copy') }}
    </UiButton>
  </div>
</template>

<script>
import copyHelper from '@/helpers/copy';

export default {
  name: 'UiCopyRow',

  mixins: [copyHelper],

  props: {
    value: {
      type: [String, Number, Array],
      default() {
        return '';
      },
    },
  },
  computed: {
    type() {
      return Array.isArray(this.value) ? 'array' : 'row';
    },
    pValue() {
      let value = this.value;
      if (this.type === 'array') {
        value = value.reduce((a, b, index) => {
          if (index === 0) {
            return b;
          }
          return index % 2 === 1 ? `${a}\t${b}` : `${a}\r\n${b}`;
        }, '');
      }

      return value;
    },
  },
  methods: {
    copyHandler() {
      this.copyInfo(this.pValue);
    },
  },
};
</script>

<style lang="scss">
.ui-copy-row {
  border-radius: 8px;
  padding: 4px;
  background-color: var(--color-on-surface);

  display: flex;
  justify-content: space-between;
  align-items: center;

  &_array {
    flex-direction: column;
    padding: 16px;
  }

  &_array &__button {
    width: 100%;
  }

  &__value-list-item {
    line-height: 1.43;
    font-size: 14px;
  }

  &__value {
    padding-left: 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.67;
    color: var(--color-text);
  }
}
</style>
