<template>
  <div>
    <p class="mb-lg">
      {{ $t('profile.2fa.check2FAPanel.text1') }}
    </p>
    <UiInput
      v-model="form.code"
      :label="$t('profile.2fa.check2FAPanel.codeLabel')"
      :placeholder="$t('profile.2fa.check2FAPanel.codePlaceholder')"
      type="text"
      class="mb-lg"
      error-clear-on-change
      :error.sync="errors.code"
      :disabled="loading"
    />
  </div>
</template>

<script>
export default {
  name: 'Check2FAPanel',
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss"></style>
