import PhoneWithCountry from '@/components/PhoneWithCountry';
import { validateEmail } from '@/helpers/validate.js';
export default {
  name: 'LoginPanel',

  components: { PhoneWithCountry },

  props: {
    currentTab: {
      type: String,
      default: 'email',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    tabMeta: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      phoneMasked: '',
      selectCountry: null,
      isPhoneValid: false,
    };
  },

  computed: {
    isValid() {
      const checkEmail = (field) => {
        const login = this.form[field] || '';
        const isValidId = login.match(/^\d+$/);
        const isValidEmail = validateEmail(login);
        const validEntity =
          field === 'email' ? isValidEmail : !!isValidId || isValidEmail;

        return !!(this.form[field] && validEntity);
      };

      const validChecks = {
        phone: () => {
          return this.form.phone && this.isPhoneValid;
        },
        id: () => checkEmail('id'),
        email: () => checkEmail('email'),
        password: () => {
          return this.form.password && this.form.password.length >= 6;
        },
      };
      const result = {};
      this.formParams.map((param) => {
        result[param] = validChecks[param]();
      });

      return result;
    },
    pIsValid() {
      const keys = Object.keys(this.isValid);
      return keys.every((key) => this.isValid[key]);
    },
    formParams() {
      return this.tabMeta.formParams || [];
    },
    formParamsIndex() {
      const result = {};
      this.formParams.forEach((item) => {
        result[item] = true;
      });
      return result;
    },
  },

  watch: {
    pIsValid: {
      immediate: true,
      handler(value) {
        this.$emit('isValid', value);
      },
    },
  },

  methods: {
    handlePhoneChange(phone) {
      if (phone) {
        const phoneNumber = phone?.number
          ? phone.number.replace(/[^0-9]/g, '')
          : '';
        this.$set(this.form, 'phone', phoneNumber);
        this.isPhoneValid = phone.isValid;
      } else {
        this.isPhoneValid = false;
      }
    },
  },
};
