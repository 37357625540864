<template>
  <div>
    <UiTitle :back-route="backRoute">{{
      $t('auth.newPassword.title')
    }}</UiTitle>
    <NewPasswordPanel
      ref="newPasswordPanel"
      :form="form"
      :errors="errors"
      :is2-f-a-enabled="is2FAEnabled"
      @isValid="isValid = $event"
    />
    <UiButton
      class="mt-lg"
      substyle="fas"
      icon="check"
      :disabled="!isValid || isClicked"
      :loading="loading"
      @click="changePassword"
    >
      {{ $t('buttons.changePassword') }}
    </UiButton>
  </div>
</template>

<script>
import logic from '@/views/Auth/components/NewPassword/logic';
import NewPasswordPanel from '@/views/Auth/components/NewPassword/NewPasswordPanel';

export default {
  components: { NewPasswordPanel },

  mixins: [logic],

  computed: {
    backRoute() {
      return this.$route.params.isBack === false ? '' : '/more';
    },
  },

  methods: {
    handleComplete() {
      this.$router.push('/more');
    },
  },
};
</script>
