<template>
  <div class="empty">
    <UiIcon :size="32" :name="icon" lib="fa" substyle="fas" class="mb-lg" />
    <div class="empty__text">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Empty',

  props: {
    icon: {
      type: String,
      default: () => 'list',
    },
    text: {
      type: String,
      default: () => '',
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: var(--color-text-secondary);
  margin-top: 32px;
  &__text {
    font-size: 12px;
    font-weight: 500;
  }
}
</style>
