import CasinoLayout from '@/views/Casino/CasinoLayout/selector.js';
import CasinoGames from '@/views/Casino/CasinoGames/selector.js';
import CasinoProviders from '@/views/Casino/CasinoProviders';
import CasinoCategories from '@/views/Casino/CasinoCategories.vue';
import CasinoGame from '@/views/Casino/CasinoGame';

import multiguard from 'vue-router-multiguard';
import mobileGuard from '@/router/guards/mobileGuard.js';
import casinoGuard from '@/router/guards/casinoGuard.js';

export default [
  {
    path: '/casino',
    name: 'casino',
    component: CasinoLayout,
    meta: {
      view: 'casinoGames',
      live: false,
    },
    children: [
      {
        path: 'games',
        name: 'casino.games',
        component: CasinoGames,
        meta: {
          view: 'casino',
          live: false,
        },
        beforeEnter: multiguard([casinoGuard(false)]),
      },
      {
        path: 'providers',
        name: 'casino.providers',
        component: CasinoProviders,
        meta: {
          view: 'casino',
          providers: true,
          live: false,
        },
        beforeEnter: multiguard([casinoGuard(false)]),
      },
      {
        path: 'providers/:providerId',
        name: 'casino.provider',
        component: CasinoGames,
        meta: {
          view: 'casino',
          providers: true,
          live: false,
        },
        beforeEnter: multiguard([casinoGuard(false)]),
      },
      {
        path: 'categories',
        name: 'casino.categories',
        component: CasinoCategories,
        meta: {
          view: 'casino',
          live: false,
        },
        beforeEnter: multiguard([mobileGuard(true), casinoGuard(false)]),
      },
      {
        path: 'games/:id',
        name: 'casino.game',
        component: CasinoGame,
        meta: {
          view: 'casino',
          live: false,
        },
        beforeEnter: multiguard([casinoGuard(false)]),
      },
      {
        path: '',
        redirect: {
          name: 'casino.games',
        },
      },
      {
        path: '*',
        redirect: {
          name: 'casino.games',
        },
      },
    ],
  },
  {
    path: '/live-casino',
    name: 'liveCasino',
    component: CasinoLayout,
    meta: {
      view: 'liveCasino',
      live: true,
    },
    children: [
      {
        path: 'games',
        name: 'liveCasino.games',
        component: CasinoGames,
        meta: {
          view: 'liveCasino',
          live: true,
        },
        beforeEnter: multiguard([casinoGuard(true)]),
      },
      {
        path: 'providers',
        name: 'liveCasino.providers',
        component: CasinoProviders,
        meta: {
          view: 'liveCasino',
          providers: true,
          live: true,
        },
        beforeEnter: multiguard([casinoGuard(true)]),
      },
      {
        path: 'providers/:providerId',
        name: 'liveCasino.provider',
        component: CasinoGames,
        meta: {
          view: 'liveCasino',
          providers: true,
          live: true,
        },
        beforeEnter: multiguard([casinoGuard(true)]),
      },
      {
        path: 'categories',
        name: 'liveCasino.categories',
        component: CasinoCategories,
        meta: {
          view: 'liveCasino',
          live: true,
        },
        beforeEnter: multiguard([mobileGuard(true), casinoGuard(true)]),
      },
      {
        path: 'games/:id',
        name: 'liveCasino.game',
        component: CasinoGame,
        meta: {
          view: 'liveCasino',
          live: true,
        },
        beforeEnter: multiguard([casinoGuard(true)]),
      },
      {
        path: '',
        redirect: {
          name: 'liveCasino.games',
        },
      },
      {
        path: '*',
        redirect: {
          name: 'liveCasino.games',
        },
      },
    ],
  },
];
