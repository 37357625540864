var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"first-bonus"},[_c('div',{staticClass:"first-bonus__label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"flex items-stretch",class:{
      'mx-rxs': _vm.mxSize === 'xs',
      'mx-rsm': _vm.mxSize === 'sm',
    }},_vm._l((_vm.bonusList),function(item){return _c('div',{key:item.id,staticClass:"first-bonus__item flex-grow w-1/3",class:{
        'first-bonus__item_active': item.id === _vm.value ,
        'mx-xs': _vm.mxSize === 'xs',
        'mx-sm': _vm.mxSize === 'sm',
      },on:{"click":function($event){return _vm.changeBonus(item.id)}}},[_c('img',{staticClass:"first-bonus__icon",attrs:{"src":require(("@/assets/images/bonus/" + (item.id) + ".png")),"alt":""}}),_vm._v(" "+_vm._s(item.name)+" ")])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }