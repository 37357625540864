<template>
  <div class="signup-panel-email mb-sm">
    <div class="flex items-start m-rsm">
      <UiInput
        class="signup-panel-email__input m-sm"
        :value="value"
        :label="label"
        :placeholder="placeholder"
        :name="name"
        :error="error"
        @input="$emit('input', $event)"
      />
      <!-- <div class="m-sm">
        <UiButton
          v-if="!isCodeSended"
          key="send"
          class="signup-panel-email__button"
          substyle="fas"
          :disabled="isSendCodeDisabled"
          @click="handleSendCode"
        >
          {{ $t('buttons.sendCode') }}
        </UiButton>
        <UiButton
          v-else
          key="resend"
          class="signup-panel-email__button"
          substyle="fas"
          :disabled="diff > 0 || loading"
          @click="handleSendNewCode"
        >
          {{ $t('buttons.resendCode') }}
        </UiButton>
      </div> -->
    </div>
    <div v-if="resendMessage" class="flex justify-center">
      {{ resendMessage }}
    </div>
    <div v-if="isCodeSended" class="flex items-start m-rsm">
      <UiInput
        class="signup-panel-email__input m-sm"
        v-model="codeValue"
        label="Confirmation Code"
        type="tel"
        placeholder="Code from E-mail"
        name="confirmation_code"
        :error="codeErrors.code"
      />
      <div class="m-sm">
        <UiButton
          class="signup-panel-email__button"
          substyle="fas"
          :disabled="!codeValue"
          @click="handleConfirm"
        >
          {{ $t('buttons.confirm') }}
        </UiButton>
      </div>
    </div>
  </div>
</template>

<script>
import confirmCodeMixin from '@/views/Auth/components/Signup/mixins/confirm-code.js';

export default {
  name: 'SignupPanelEmail',
  mixins: [confirmCodeMixin],
  props: {
    value: {
      type: String,
      default: '',
    },
    error: {
      type: Array,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    formMeta: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
      default: '',
    },
    isValid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCodeSended: false,
      isCodeSendBlocked: false,
    };
  },
  computed: {
    isSendCodeDisabled() {
      return !this.isValid || this.isCodeSended || this.isCodeSendBlocked;
    },
  },
  methods: {
    handleSendCode() {
      const resolve = (params) => {
        this.setCode(params.code);
        this.pMessage = params.message;
        this.isCodeSended = true;
        this.isCodeSendBlocked = false;
      };

      const reject = () => {
        this.isCodeSendBlocked = false;
      };

      this.isCodeSendBlocked = true;
      this.$emit('action', 'send-code', [resolve, reject]);
    },
    close() {
      this.$emit('action', 'close');
    },
  },
};
</script>

<style lang="scss">
.signup-panel-email {
  &__input {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
  }

  &__button {
    width: 100px;
    margin-top: 34px;
  }
}
</style>
