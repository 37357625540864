var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"casino-providers"},[(!_vm.isMobile)?_c('div',{staticClass:"casino-providers__header"},[_c('UiTitle',[_vm._v(_vm._s(_vm.$t(("casino.subsection." + (_vm.liveCasinoParams.i18nKey) + ".providers"))))]),_c('Breadcrumbs',{attrs:{"items":_vm.breadcrumbs}})],1):_c('div',[_c('UiTitle',{staticClass:"mb-lg",attrs:{"back-route":null},on:{"click":_vm.handleBack}},[_vm._v(_vm._s(_vm.$t(("casino.subsection." + (_vm.liveCasinoParams.i18nKey) + ".providers"))))])],1),_c('div',{staticClass:"casino-providers__content"},[_c('UiInput',{staticClass:"mb-2xl",attrs:{"name":"search","placeholder":_vm.$t('ui.search'),"autocomplete":"off","is-search":""},on:{"search":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}}),(_vm.casinoProvidersFitered.length)?_c('div',{class:[
        'casino-providers__providers-list',
        'flex',
        'flex-wrap',
        { 'ml-rlg': !_vm.isMobile },
        { 'mt-rlg': !_vm.isMobile },
        { 'ml-rmd': _vm.isMobile },
        { 'mt-rmd': _vm.isMobile } ]},_vm._l((_vm.casinoProvidersFitered),function(provider){return _c('router-link',{key:provider.id,class:[
          'casino-providers__provider-item',
          { 'pl-lg': !_vm.isMobile },
          { 'pt-lg': !_vm.isMobile },
          { 'pl-md': _vm.isMobile },
          { 'pt-md': _vm.isMobile } ],attrs:{"to":("/" + (_vm.liveCasinoParams.path) + "/games?provider=" + (provider.id))}},[_c('Panel',{staticClass:"casino-providers__provider-panel",attrs:{"size":"custom","title":provider.name}},[_c('div',{staticClass:"casino-providers__provider-content"},[_c('div',{staticClass:"casino-providers__provider-image-wrapper"},[(provider.image)?[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({ src: provider.image }),expression:"{ src: provider.image }"}],staticClass:"casino-providers__provider-image",staticStyle:{"max-height":"100%"}})]:[_c('div',{staticClass:"casino__provider-wrapper"},[_c('UiIcon',{staticClass:"mb-2xs",attrs:{"size":20,"color":_vm.$config.cp['text-secondary'],"name":"window-maximize","lib":"fa","substyle":"fas"}})],1)]],2),_c('div',{staticClass:"casino-providers__provider-label"},[_vm._v(" "+_vm._s(provider.name)+" ")])])])],1)}),1):_c('div',{staticClass:"casino-providers__empty-wrapper"},[_c('Empty',{staticClass:"casino-providers__empty",attrs:{"text":_vm.$t('casino.emptyProviders')}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }