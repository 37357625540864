<template>
  <Popup
    :title="$t('popups.shareBetslip.title')"
    :is-visible.sync="isVisible"
    @close="handleClose"
  >
    <div slot="body">
      {{ $t('popups.shareBetslip.text') }}
      <div class="bet-slip__wrapper">
        <UiIcon
          class="bet-slip__spinner"
          v-if="isLoading"
          :size="34"
          name="spinner"
          :color="$config.cp.primary"
        />
        <div v-else class="bet-slip__code">
          {{ code }}
        </div>
      </div>
    </div>
    <div slot="footer" class="flex space-x-lg">
      <UiButton
        class="w-1/2"
        substyle="fas"
        icon="check"
        type="secondary"
        @click="cancel"
      >
        {{ $t('buttons.ok') }}
      </UiButton>
      <UiButton
        class="w-1/2"
        substyle="fas"
        icon="copy"
        @click="copy"
        :disabled="isLoading"
      >
        {{ $t('buttons.copy') }}
      </UiButton>
    </div>
  </Popup>
</template>

<script>
import copyHelper from '@/helpers/copy';
import { mapGetters } from 'vuex';

export default {
  name: 'ShareBetSlip',

  mixins: [copyHelper],

  data() {
    return {
      isVisible: false,
      isLoading: true,
      code: '',
    };
  },

  computed: {
    ...mapGetters('market', ['slip']),
  },

  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },

  methods: {
    async open() {
      const params = {
        events: [],
      };

      this.slip.forEach((event) => {
        params.events.push({
          id: event.matchId,
          is_live: event.isLive,
          market_id: event.marketId,
          outcome_id: event.id,
          odds: event.odds,
        });
      });

      const response = await this.$api.postBetsSave(params);
      this.code = response.data.payload.coupon_code;
      if (response.data.payload.error) {
        this.$eventBus.$emit('notify-event', {
          type: 'error',
          text: response.data.payload.error,
        });
      } else {
        this.isVisible = true;
        this.isLoading = false;
      }
    },

    copy() {
      this.copyInfo(this.code);
    },

    cancel() {
      this.isVisible = false;
    },

    handleClose() {
      this.cancel();
    },
  },
};
</script>

<style lang="scss">
.bet-slip {
  &__wrapper {
    margin-top: 4px;
  }
  &__spinner {
    animation: rotating 2s linear infinite;
  }
  &__code {
    font-size: 24px;
    font-weight: 900;
    color: var(--color-primary);
  }
}
</style>
