export function checkAcl(route) {
  let _result = undefined;
  const next = (result) => {
    if (result && !_result) {
      _result = result;
    }
  };

  const lastRouteIndex = route.matched.length - 1;
  const beforeEnter = [];

  if (route.matched && route.matched.length > 0) {
    let match = route.matched[lastRouteIndex];
    do {
      if (match.beforeEnter) {
        beforeEnter.push(match.beforeEnter);
      }

      match = match.parent;
    } while (match.parent);

    beforeEnter.forEach((item) => {
      item(route, route, next);
    });
  }

  //route.meta.aclBeforeEnter(route, route, next);

  return _result;
}
