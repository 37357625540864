<template>
  <div>
    <UiTitle class="mb-lg" back-route="/auth/password-recovery"
      >Recovery Code</UiTitle
    >
    <RecoveryCodePanel
      ref="recoveryCodePanel"
      :recovery-code-type="pRecoveryCodeType"
    />
    <UiButton class="mt-lg" substyle="fas" icon="check" @click="apply">
      Check Code
    </UiButton>
  </div>
</template>

<style lang="scss"></style>

<script>
import RecoveryCodePanel from '@/views/Auth/components/RecoveryCode/RecoveryCodePanel';

export default {
  name: 'RecoveryCode',

  components: { RecoveryCodePanel },

  computed: {
    pRecoveryCodeType() {
      return this.$route.params.recoveryCodeType
        ? this.$route.params.recoveryCodeType
        : 'email';
    },
  },

  methods: {
    apply() {
      this.$router.push({
        name: 'NewPassword',
        params: {
          isBack: false,
        },
      });
    },
  },
};
</script>
