<template functional>
  <div
    class="event-coef"
    :class="{
      [data.staticClass]: data.staticClass,
      ...(data.class || {}),
      [`event-coef_type-${props.type}`]: true,
      'event-coef_active': props.active,
      'event-coef_blocked': props.isBlocked,
    }"
    :style="data.staticStyle || ''"
    v-bind="data.attrs"
    @click="listeners.click && listeners.click($event)"
  >
    <UiIcon
      class="event-coef__lock"
      :size="12"
      name="lock"
      lib="fa"
      substyle="fas"
    />
    <div v-if="props.type === 'detailed'" class="event-coef__detailed">
      <slot name="detailed" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'EventCoef',

  props: {
    type: {
      type: String,
      default() {
        return 'default';
      },
    },
    active: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isBlocked: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';
@import '@/styles/mixins.scss';

.event-coef {
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  background-color: var(--color-on-surface);
  margin-right: 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: var(--color-text);
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
  &__lock.ui-icon {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    color: var(--color-text);
    display: none;
  }

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.2s ease-out;
    background-color: var(--color-button-hover);
  }

  @include hover {
    &:after {
      opacity: 1;
    }
  }

  &_type {
    &-default {
      width: 46px;
      height: 40px;
    }
    &-detailed {
      min-width: 106px;
      height: 32px;
      font-size: 14px;
      justify-content: space-between;
      margin-right: 0;
    }
    &-head {
      cursor: default;
      pointer-events: none;
      width: 46px;
      background-color: transparent;
      padding: 0;
    }
  }

  &:before {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    top: 0;
  }

  &_up {
    &:before {
      animation: 'coef-anim' 1s 4 ease-in-out forwards;
      top: 0;
      background-color: var(--color-success);
    }
  }

  &_down {
    &:before {
      animation: 'coef-anim' 1s 4 ease-in-out forwards;
      bottom: 0;
      top: inherit;
      background-color: var(--color-error);
    }
  }

  &_line {
    background-color: var(--color-surface);
  }

  &_active {
    background-color: var(--color-primary);
    color: var(--color-on-primary);
    .event-coef__detailed {
      color: var(--color-on-primary);
    }
  }
  &_shine:before {
    top: -100%;
    left: -100%;
    width: 70%;
    height: 250%;
    transform: rotate(33deg);
    transform-origin: 170% 50%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.2)
    );
    animation: coef-highlight 1.5s cubic-bezier(0.39, 0.58, 0.57, 1) 1;
    animation-iteration-count: infinite;
  }
  &_blocked {
    pointer-events: none;
    cursor: not-allowed;
    .event-coef__lock.ui-icon {
      display: inline-block;
    }

    &:before {
      width: 100%;
      height: 100%;
      opacity: 0.9;
      top: 0;
      left: 0;
      transform: none;
      transform-origin: 0;
      background: var(--color-surface);

      animation: none;
      animation-iteration-count: infinite;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }

  &__detailed {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    color: var(--color-text-secondary);
  }
}
@keyframes coef-highlight {
  0% {
    transform: rotate(33deg) translate3d(0, 0, 0);
  }

  to {
    transform: rotate(33deg) translate3d(300%, 0, 0);
  }
}

@keyframes coef-anim {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: $screen-mobile) {
  .event-coef__detailed {
    font-size: 10px;
  }
  .event-line .event-coef_type-detailed {
    text-align: center;
    margin-right: 4px;
    width: 46px;
    min-width: 46px;
    min-height: 46px;
    height: 46px;
    display: flex;
    font-size: 12px;
    flex-direction: column;
    justify-content: center;
    &:last-of-type {
      margin-right: 0;
    }
  }
}
</style>
