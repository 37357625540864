<template>
  <div :class="['casino-game', { 'casino-game_mobile': isMobile }]">
    <div class="casino-game__title" @click="backRoute">
      <UiIcon
        :size="16"
        name="chevron-left"
        lib="fa"
        substyle="fas"
        class="mr-sm"
      />
      <div class="casino-game__title-label">{{ gameName }}</div>
    </div>
    <div class="casino-game__wrapper">
      <div
        v-if="type === 'softswiss'"
        :id="softSwissId"
        class="casino-game__frame casino-game__frame_wrapper"
        :class="[{ 'casino-game__frame_mobile': isMobile }]"
      ></div>
      <iframe
        v-else-if="type === 'iframe'"
        ref="iframe"
        :src="dataSrcIframe"
        frameborder="0"
        allowfullscreen="allowfullscreen"
        class="casino-game__frame"
        :class="[{ 'casino-game__frame_mobile': isMobile }]"
      />
      <div v-if="loading" class="casino-game__loading">
        <Spinner />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import routerMixin from '@/helpers/router.js';
import liveCasinoMixin from '@/views/Casino/mixins/liveCasino.js';

export default {
  name: 'CasinoGame',
  mixins: [routerMixin, liveCasinoMixin],
  data() {
    return {
      loading: true,
      gameUrl: '',
      gameName: '',
      type: 'iframe',
    };
  },
  computed: {
    ...mapGetters('misc', ['isMobile']),
    ...mapGetters('auth', ['isAuthenticated', 'balance']),
    dataSrcIframe() {
      return this.gameUrl;
    },
    softSwissId() {
      return 'softswiss-game-container';
    },
  },
  async mounted() {
    const userId = this.balance?.id;
    const demo = this.$route.query.demo || false;

    try {
      if (!userId && !demo) {
        await this.getAuthenticatePromise();
      }

      await this.loadGame();
    } catch (error) {
      this.handleError(error);
    }
  },
  methods: {
    handleError(error) {
      const errors = error?.data?.message || this.$t('casino.generalError');
      if (this.isAuthenticated) {
        this.$eventBus.$emit('notify-event', {
          type: 'error',
          text: errors,
        });
      } else {
        this.$eventBus.$emit('notify-event', {
          type: 'error',
          text: this.$t('casino.card.auth'),
        });
      }

      this.backRoute();
    },
    getBackRouteQuery() {
      return this.$_.pick(this.$route.query, [
        'type',
        'category',
        'provider',
      ]);
    },
    backRoute() {
      if (!this.returnTo(false)) {
        const query = this.getBackRouteQuery();
        this.$router.push({
          name: `${this.liveCasinoParams.name}.games`,
          query,
        });
      }
    },
    async loadGame() {
      const { id } = this.$route.params;
      const demo = this.$route.query.demo || false;
      const userId = this.balance?.id;

      const backRouteQuery = this.getBackRouteQuery();
      let queryString = '';
      if (!this.$_.isEmpty(backRouteQuery)) {
        const queryStringBody = this.$_.reduce(backRouteQuery, (result, value, key) => {
          result.push(`${key}=${value}`);
          return result;
        }, []).join('&');

        queryString = `?${queryStringBody}`;
      }

      const params = {
        //для теста
        game_id: id,
        play_mode: demo ? 'demo' : 'real',
        theme: this.$config.themeName,
        lobby_url: `${window.location.protocol}//${window.location.host}/${this.liveCasinoParams.path}/games${queryString}`,
        cashier_url: `${window.location.origin}/payments/deposit/methods`,
      };

      if (userId) {
        params.account_id = userId;
      }

      const response = await this.$api.postCasinoGamesLaunch(params, {
        ignoreIntercetors: { 403: true },
      });

      const { url, name, provider, launch_options, script_url } = response.data.payload;

      this.gameName = name;

      if (provider === 'softswiss') {
        this.type = 'softswiss';

        if (!window.sg) {
          if (!script_url) {
            throw new Error('script_url not found');
          }

          const promise = new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = script_url;
            script.id = 'softswiss-script';

            const scriptsSection = document.getElementsByTagName('script')[0];
            scriptsSection.parentNode.insertBefore(script, scriptsSection);

            script.addEventListener('load', () => {
              resolve();
            });

            script.addEventListener('error', () => {
              reject();
            });
          });

          await promise;
        }

        const gameLaunchOptions = {
          launch_options,
          target_element: this.softSwissId,
        };

        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            try {
              window.sg.launch(gameLaunchOptions);
            } catch (error) {
              this.handleError(error);
            }
          });
        });

        this.loading = false;
      } else {
        this.type = 'iframe';
        this.gameUrl = url;

        this.$refs.iframe.onload = () => {
          this.loading = false;
        };
      }
    },

    getAuthenticatePromise() {
      return new Promise((resolve, reject) => {
        const clearPromises = () => {
          this.$eventBus.$on('auth-complete', handleAuthComplete);
          this.$eventBus.$on('auth-fail', handleAuthFail);
        };

        const handleAuthComplete = () => {
          clearPromises();
          resolve();
        };

        const handleAuthFail = () => {
          clearPromises();
          reject();
        };

        this.$eventBus.$on('auth-complete', handleAuthComplete);
        this.$eventBus.$on('auth-fail', handleAuthFail);
        this.$eventBus.$emit('open-popup', 'Login', {}, { key: 'login' });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
$title-height: 48px;
.casino-game {
  &__title {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: var(--color-text);
    margin-bottom: 16px;
    transition: 0.2s color ease-out;
    @include hover {
      color: var(--color-link-hover);
    }
  }

  &_mobile &__title {
    padding: 8px 16px;
    margin-bottom: 0;
  }

  &__title-label {
    font-size: 20px;
    font-weight: 700;
  }

  &__frame {
    width: 100%;
    height: calc(var(--vh) * 75px);

    &_wrapper {
      &::v-deep > iframe {
        height: 100%;
        width: 100%;
      }
    }

    &_mobile {
      height: calc(var(--vh) * 100px - $title-height - 4px);
    }
  }

  &__wrapper {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    line-height: 0;
  }

  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
