import store from '@/store';
import { GET_BANNERS } from '../action_types/promo';

const state = {
  banners: null,
};

const getters = {
  banners: (state) => state.banners,
};

const actions = {
  [GET_BANNERS]: async ({ commit }) => {
    const response = await store.$api.getBanners();
    const values = response.data.payload.list;
    commit(GET_BANNERS, values);
  },
};

const mutations = {
  [GET_BANNERS]: (state, payload) => {
    state.banners = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
