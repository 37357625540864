<template>
  <Popup
    :title="$t('popups.timeRangeFilter.title')"
    :is-visible.sync="isVisible"
  >
    <div class="time-range-filter" slot="body">
      <el-scrollbar view-class="wallets__scrollbar">
        <div class="pb-4xl mb-sm">
          <div
            v-for="selectItem in selectList"
            :key="selectItem.value"
            :class="[
              'time-range-filter__item',
              { 'time-range-filter__item_selected': selected === selectItem.value },
            ]"
            @click="handleSelectRange(selectItem.value)"
          >
            <div class="flex items-center">
              {{ selectItem.label }}
            </div>
            <UiIcon
              v-if="selected === selectItem.value"
              :size="12"
              name="check"
              lib="fa"
              substyle="fas"
            />
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div slot="footer" class="flex">
      <UiButton
        class="flex-grow"
        substyle="fas"
        icon="times-circle"
        type="secondary"
        @click="handleReset"
      >
        {{ $t('buttons.reset') }}
      </UiButton>
      <UiButton
        substyle="fas"
        icon="check"
        class="ml-lg flex-grow"
        @click="handleSave"
      >
        {{ $t('buttons.confirm') }}
      </UiButton>
    </div>
  </Popup>
</template>

<script>
import timeRangeFilterMixin from '@/views/Market/mixins/timeRangeFilterMixin.js';

export default {
  name: 'TimeRangeFilter',

  mixins: [timeRangeFilterMixin],

  props: {
    isTop: {
      type: Boolean,
      defaults: false,
    },
  },

  data() {
    return {
      selected: this.pTimeRangeFilter,
      isVisible: false,
      source: 'line',
    };
  },

  computed: {},

  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },

  methods: {
    open() {
      this.isVisible = true;
      this.selected = this.pTimeRangeFilter;
    },
    handleSelectRange(value) {
      this.selected = value;
    },
    handleSave() {
      this.pTimeRangeFilter = this.selected;
      this.isVisible = false;
    },
    handleReset() {
      this.pTimeRangeFilter = -1;
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
.time-range-filter {
  &__item {
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 44px;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    border-bottom: 1px solid var(--color-on-surface);
    // padding: 0 8px;

    &_selected {
      color: var(--color-primary);

      .item-logo__img_mask {
        background-color: var(--color-primary);
      }
    }
  }
}
</style>
