import ResultLayout from '@/views/Result/ResultLayout/selector.js';
import Result from '@/views/Result/Result/selector.js';

export default [
  {
    path: '',
    name: 'result.root',
    component: ResultLayout,
    children: [
      {
        name: 'result',
        path: 'results',
        component: Result,
        meta: {
          view: 'results',
        },
      },
    ],
  },
];
