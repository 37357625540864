import Vue from 'vue';
const configModule = require('./config');
let config = configModule.getConfig(true);

if (window.APP_CONFIG) {
  config = {
    ...config,
    ...window.APP_CONFIG,
  };
}

export class ConfigService {
  params = config;
  themeCount = 0;
  lsTheme = 'theme-preference';

  initTheme(theme) {
    if (this.params.themes[theme]) {
      const { themes } = this.params;
      const cp = {};
      const cpRaw = {};

      Vue.set(this, 'theme', themes[theme]);
      Vue.set(this, 'themeName', theme);

      Object.keys(this.theme.palettes).forEach((color) => {
        const name = `--color-${color}`;
        cp[color] = `var(${name})`;
        cpRaw[color] = this.theme.palettes[color];
        document.documentElement.style.setProperty(
          name,
          this.theme.palettes[color]
        );
      });

      Vue.set(this, 'cp', cp);
      Vue.set(this, 'cpRaw', cpRaw);
    }
  }

  detectSize() {
    const height = window.innerHeight / 100;

    document.documentElement.style.setProperty('--vh', height.toString());
  }

  switchTheme() {
    if (this.themeCount === 2) {
      const themes = Object.keys(this.params.themes);
      const newTheme =
        themes.indexOf(this.themeName) === 0 ? themes[1] : themes[0];
      localStorage.setItem(this.lsTheme, newTheme);
      this.initTheme(newTheme);
    }
  }

  detectTheme() {
    if (this.themeCount === 2) {
      let theme = '';
      if (localStorage.getItem(this.lsTheme)) {
        theme = localStorage.getItem(this.lsTheme);
      } else {
        const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
        theme = darkThemeMq.matches ? 'dark' : 'light';
      }
      this.initTheme(theme);
    }
  }

  getDefaultConfig() {
    return {
      detectTheme: true,
    };
  }

  install(Vue, config = this.getDefaultConfig()) {
    Vue.util.defineReactive(this, 'theme', {});
    Vue.util.defineReactive(this, 'cp', {});
    Vue.util.defineReactive(this, 'cpRaw', {});
    Vue.util.defineReactive(this, 'themeName', '');
    Vue.util.defineReactive(this, 'height', '');

    this.themeCount = Object.keys(this.params.themes).length;

    this.detectSize();

    if (config.detectTheme) {
      this.detectTheme();
    } else {
      this.initTheme(this.params.defaultTheme);
    }

    Vue.prototype.$config = this;
  }
}

export default new ConfigService();
