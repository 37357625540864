<template>
  <div class="tabs-buttons" :class="[`tabs-buttons_type-${type}`]">
    <object class="tabs-buttons__content-wrapper" :is="wrapperType">
      <div
        v-for="item in items"
        :key="item[itemValue]"
        class="tabs-buttons__item"
        :class="{
          'tabs-buttons__item_inactive': currentTab !== item[itemValue],
          'tabs-buttons__item_active': currentTab === item[itemValue],
          'element-disabled': disabled === item[itemValue] && disabled !== '',
        }"
        @click="handleClick(item[itemValue])"
      >
        <template v-if="item[itemIcon] || iconType === 'image-mask'">
          <UiIcon
            v-if="iconType === 'icon'"
            :size="14"
            :name="item[itemIcon]"
            lib="fa"
            substyle="fas"
          />
          <div v-if="isImage" class="tabs-buttons__item-image-wrapper">
            <ItemLogo
              :is-mask="iconType === 'image-mask'"
              :link="item[itemIcon]"
            />
          </div>
        </template>
        <div class="tabs-buttons__item-name">{{ item[itemName] }}</div>
        <div class="tabs-buttons__item-line"></div>
      </div>
    </object>
  </div>
</template>

<script>
export default {
  name: 'TabsButtons',

  props: {
    currentTab: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'buttons',
    },
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: [String, Boolean],
      default: '',
    },
    itemName: {
      type: String,
      default: 'name',
    },
    itemValue: {
      type: String,
      default: 'label',
    },
    itemIcon: {
      type: String,
      default: 'icon',
    },
    iconType: {
      type: String,
      default: 'icon', //[icon, image, image-mask]
    },
    hasScroll: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },
  computed: {
    isImage() {
      return ['image', 'image-mask'].includes(this.iconType);
    },
    wrapperType() {
      return this.hasScroll ? 'el-scrollbar' : 'div';
    },
  },

  methods: {
    handleClick(label) {
      if (this.currentTab !== label) {
        this.$emit('update:currentTab', label);
        this.$emit('select', label);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/variables';

.tabs-buttons {
  margin-bottom: 16px;
  display: flex;

  &_type {
    &-buttons,
    &-buttons-vertical {
      border-radius: 8px;
      background-color: var(--color-surface);

      .tabs-buttons__item {
        flex-basis: 100%;
        border-radius: 8px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.1;
        color: var(--color-text);
        i {
          margin-right: 8px;
        }
        @include hover {
          background-color: var(--color-button-hover);
        }
        &_active {
          background-color: var(--color-primary);
          color: var(--color-on-primary);

          .item-logo__img_mask {
            background-color: var(--color-on-primary);
          }

          @include hover {
            background-color: var(--color-primary);
          }
        }
      }
    }

    &-buttons {
      .tabs-buttons__item_inactive + .tabs-buttons__item_inactive {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 4px;
          left: 0;
          width: 1px;
          height: calc(100% - 8px);
          background-color: var(--color-highlight);

          @media (max-width: $screen-mobile) {
            background-color: var(--color-on-surface);
          }
        }
      }
    }

    &-buttons-vertical {
      padding: 8px;

      .tabs-buttons__item {
        font-size: 12px;
        font-weight: 600;
        line-height: 1.33;
      }

      .tabs-buttons__item-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .tabs-buttons__content-wrapper {
        flex-direction: column;
      }
    }

    &-tabs {
      padding: 12px 8px;
      border-bottom: 1px solid var(--color-on-surface);
      .tabs-buttons__item {
        padding: 0 8px;
        font-size: 14px;
        font-weight: 700;
        line-height: 1;
        transition: all 0.2s;
        white-space: nowrap;
        @include hover {
          color: var(--color-primary);
          .tabs-buttons__item-line {
            width: 100%;
          }
        }
        &_active {
          color: var(--color-primary);
          .tabs-buttons__item-line {
            width: 100%;
          }
        }
        &:last-of-type {
          margin-right: 0;
        }

        &-line {
          transition: 0.2s ease-out;
          width: 0;
          height: 1px;
          background-color: var(--color-primary);
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -13px;
        }
      }
    }
  }

  &__item {
    cursor: pointer;
    position: relative;
    &-name {
      text-align: center;
    }
  }

  &_type-buttons-vertical &__item {
    flex-grow: 1;
    justify-content: flex-start;
    min-height: 36px;
    padding: 0 6px;
  }

  &__content-wrapper {
    display: flex;
    flex-grow: 1;
  }

  &__item-image-wrapper {
    width: 24px;
    display: flex;
    justify-content: center;
  }

  .el-scrollbar__bar.is-horizontal {
    display: none;
  }

  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>
