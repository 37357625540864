<template>
  <el-dropdown
    class="ui-dropdown"
    :class="{ 'ui-dropdown__open': refresh }"
    placement="bottom-start"
    trigger="click"
    @visible-change="checkOpen"
    @command="clickItem"
  >
    <div class="ui-dropdown__head">
      <slot name="head" />
      <UiIcon
        :size="16"
        name="angle-down"
        lib="fa"
        substyle="fas"
        class="ml-2xs ui-dropdown__arrow"
      />
    </div>

    <el-dropdown-menu
      ref="uiDropdownMenu"
      slot="dropdown"
      class="ui-dropdown__menu"
    >
      <UiInput
        v-model="searchValue"
        is-search
        type="text"
        class="timezone__search"
      />
      <template v-if="pList.length > 0">
        <el-scrollbar :key="refresh" class="dropdown__scrollbar">
          <el-dropdown-item
            v-for="item in pList"
            :key="item.zone"
            :command="item"
            class="ui-dropdown__item"
            :class="{ 'ui-dropdown__item_active': item.zone === value }"
          >
            {{ item.name }}
          </el-dropdown-item>
        </el-scrollbar>
      </template>
      <template v-else>
        <div class="ui-select__no-data">{{ $t('ui.noData') }}</div>
      </template>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'UiDropdown',

  props: {
    value: {
      type: [String, Number, Array],
      default() {
        return '';
      },
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      searchValue: '',
      refresh: 0,
    };
  },

  computed: {
    pList() {
      const searchValue = this.searchValue.toLowerCase().trim();
      return this.list.filter((item) => {
        const name = item.name.toLowerCase();
        if (searchValue === '') {
          return this.list;
        } else {
          return name.includes(searchValue);
        }
      });
    },
  },
  methods: {
    clickItem(value) {
      this.$emit('update:value', value.zone);
    },
    checkOpen(isOpen) {
      this.refresh = isOpen;
      if (isOpen) {
        this.searchValue = '';

        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            const parent = document.querySelector(
              '.dropdown__scrollbar .el-scrollbar__wrap'
            );
            const elem = this.$refs.uiDropdownMenu.$el.querySelector(
              '.ui-dropdown__item_active'
            );
            if (elem) {
              const elemTop = elem.offsetTop - 8;
              parent.scrollTo(0, elemTop);
            }
            const search =
              this.$refs.uiDropdownMenu.$el.querySelector('.timezone__search');
            if (search) {
              search.focus();
            }
          });
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';

.ui-dropdown {
  display: flex;
  align-items: center;
  color: var(--color-text);
  &__head {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__menu {
    max-height: 250px;
    display: flex;
    flex-direction: column;
    margin: 0;
    border-radius: 8px;
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 30%);
    background-color: var(--color-on-surface);
    border: 1px solid var(--color-on-surface);
    min-width: 150px !important;
    max-width: calc(100vw - 20px);
    padding: 0;
    .ui-select__no-data {
      padding: 16px;
      text-align: left;
    }
    .ui-input {
      border-bottom: 1px solid var(--color-highlight);
      &__wrapper {
        border: none;
      }
    }
    .el-scrollbar {
      &__wrap {
        max-height: 203px;
      }
      &__view {
        padding: 8px;
      }
    }
    &.el-popper[x-placement^='bottom'] {
      margin-top: 8px;
    }
    .popper__arrow {
      display: none;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    padding: 8px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 4px;
    color: var(--color-text);
    &:focus,
    &:not(.is-disabled):hover {
      background-color: var(--color-selected);
      color: var(--color-text);
    }
    &_active {
      background-color: var(--color-primary);
      color: var(--color-on-primary);
      &:focus,
      &:not(.is-disabled):hover {
        background-color: var(--color-primary);
        color: var(--color-on-primary);
      }
    }
  }
  &__arrow {
    transition: transform 0.3s;
  }
  &__open & {
    &__arrow {
      transform: rotateZ(-180deg);
    }
  }
}
@media (max-width: $screen-mobile) {
  .ui-dropdown {
    &__menu {
      left: 16px !important;
      width: 100%;
      min-width: 150px !important;
      max-width: calc(100vw - 32px);
    }
  }
}
</style>
