import store from '@/store';
import app from '@/main';
import _ from 'lodash';

import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SAVE_TOKEN,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_GET_REGISTRATION_PARAMS,
  AUTH_CLEAR_REGISTRATION_PARAMS,
  AUTH_USER_SETUP,
  AUTH_USER,
  AUTH_USER_BALANCE,
  AUTH_USER_ADD_ACCOUNT,
  AUTH_USER_DELETE_ACCOUNT,
  AUTH_USER_DELETE_BONUS_ACCOUNT,
  AUTH_USER_SELECT_ACCOUNT,
  AUTH_ON_TOKEN_FAIL,
  AUTH_REQUEST_STATUS,
  AUTH_CLEAR_TIMER,
  AUTH_TFA_PASSED,
  AUTH_REMEMBER_ME,
  AUTH_LOST_CONNECTION_TOKEN,
  AUTH_UPDATE_BALANCE,
  AUTH_TWO_FACTOR_AUTHED,
  AUTH_IS_CONNECTED,
  AUTH_TOKEN_CHANGED,
  AUTH_TOKEN_CHANGED_DEBOUNCED,
  AUTH_ADD_TO_WAITING_RETRY,
  AUTH_CLEAR_WAITING_RETRY,
} from '../action_types/auth';

import dayjs from 'dayjs';
import { SETTINGS_VALUES } from '../action_types/settings';
import { CHECK_FAVORITES } from '../action_types/market';
import { CookieStorage } from 'cookie-storage';
const cookieStorage = new CookieStorage();
import { EventBusInstance } from '@/setup/eventBus';
import { MISC_INIT_DICTIONARIES } from '@/store/action_types/misc';

// const TOKEN_STATUS_VALID = 'valid';
// const TOKEN_STATUS_ON_REFRESH = 'refresh';
// const TOKEN_STATUS_INVALID = 'valid';

const persistentStorage = localStorage;
const temporaryStorage = sessionStorage;

const tokenTimeoutStep = 180;
let balanceLoading = false;
const balanceLoadingColldown = 10000;

const refreshTokenBC = new BroadcastChannel('refresh-token');

refreshTokenBC.onmessage = (event) => {
  const { data } = event;
  if (data.type === 'refresh-token-login') {
    store.dispatch(`auth/${AUTH_REQUEST}`, {
      type: 'sync',
      payload: data.payload,
    });
  }

  if (data.type === 'refresh-token-logout') {
    console.log('refresh-token-logout: logout');
    store.commit(`auth/${AUTH_LOGOUT}`);
    location.reload();
  }
};

const bothStorage = {
  getItem(key) {
    return persistentStorage.getItem(key) || temporaryStorage.getItem(key);
  },
  setItem(key, value) {
    persistentStorage.setItem(key, value);
    sessionStorage.setItem(key, value);
  },
  removeItem(key) {
    persistentStorage.removeItem(key);
    temporaryStorage.removeItem(key);
  },
};

const getStorage = (both = false, onlyPersistent = true) => {
  const result = both
    ? bothStorage
    : onlyPersistent
      ? persistentStorage
      : persistentStorage.getItem('remember-me') === 'true'
    ? persistentStorage
        : temporaryStorage;
  return result;
};

if (
  persistentStorage.getItem('remember-me') !== 'true' &&
  cookieStorage.getItem('remember-me') !== 'true'
) {
  console.log('clear storage');
  const storage = getStorage(true);

  storage.removeItem('auth-token');
  storage.removeItem('token-ttl');
  storage.removeItem('token-expires');
  storage.removeItem('refresh-token');
  storage.removeItem('refresh-ttl');
  storage.removeItem('refresh-expires');
  storage.removeItem('two-factor-authed');
  // storage.removeItem('selectedAccount');
}

const state = {
  user: null,
  token: getStorage().getItem('auth-token') || '',
  tokenTtl: getStorage().getItem('token-ttl') || 0,
  tokenExpires: getStorage().getItem('token-expires') || 0,
  refreshToken: getStorage().getItem('refresh-token') || '',
  refreshTtl: getStorage().getItem('refresh-ttl') || 0,
  refreshExpires: getStorage().getItem('refresh-expires') || 0,
  twoFactorAuthed: getStorage().getItem('two-factor-authed') || 0,
  status: getStorage().getItem('auth-token') ? 'success' : '',
  selectedAccount: +getStorage().getItem('selectedAccount') || null,
  registrationParams: null,
  balanceList: [],
  is2faPassed: true,
  rememberMe: null,
  isConnected: true,
  waitingRetry: [],
};

const getters = {
  isAuthenticated: (state) => state.status === 'success' || state.status === 'retry',
  is2faPassed: (state) => state.is2faPassed,
  token: (state) => state.token,
  isAuthorized: (state, getters) =>
    getters.isAuthenticated && getters.is2faPassed,
  registrationParams: (state) => state.registrationParams,
  registrationParamsIndex: (state) => {
    const result = {};
    if (state.registrationParams) {
      state.registrationParams.forEach((item, index) => {
        result[item.reg_type] = item;
        result[item.reg_type].index = index;
      });
    }
    return result;
  },
  user: (state) => state.user,
  balance: (state) =>
    state.balanceList.find((item) => item.id === state.selectedAccount),
  bonusBalance: (state) => state.balanceList.find((item) => item.is_bonus_sport || item.is_bonus_casino),
  balanceList: (state) => state.balanceList,
  isConnected: (state) => state.isConnected,
};

const actions = {
  [AUTH_REQUEST]: async ({ commit, dispatch, state }, request) => {
    commit(AUTH_REQUEST);
    console.log('request.type', request.type);
    console.log('request.payload', request.payload);

    try {
      const { type, payload } = request;
      let data = null;
      if (type === 'token' || type === 'sync') {
        data = {
          success: true,
          payload: {
            token: payload,
          },
        };
      } else if (type === 'signup') {
        data = {
          success: true,
          payload,
        };
      } else if (type === 'login') {
        ({ data } = await store.$api.postAuthLogin(payload));
      } else {
        throw new Error('AUTH ERROR: unknown type');
      }

      if (data.success) {
        const rememberMe =
          type === 'login' ? request?.params?.rememberMe : true;

        commit(AUTH_SAVE_TOKEN, data.payload.token);
        commit(AUTH_SUCCESS);

        if (type === 'sync') {
          store.$tokenService.setStatus({
            accessToken: state.token,
            accessTokenExp: state.tokenExpires,
            refreshToken: state.refreshToken,
            refreshTokenExp: state.refreshExpires,
          });
        } else {
          refreshTokenBC.postMessage({
            type: 'refresh-token-login',
            payload: data.payload.token,
          });
        }

        const user = data.payload.user || (await dispatch(AUTH_USER));
        const balance = data.payload.balance;

        const is2faPassed =
          !user?.two_factor_enabled ||
          (user?.two_factor_enabled && state.twoFactorAuthed);
        commit(AUTH_TFA_PASSED, is2faPassed);

        const completeAuth = async () => {
          commit(AUTH_REMEMBER_ME, rememberMe);
          await dispatch(AUTH_USER_SETUP, { user, balance });
          EventBusInstance.$emit('auth-complete');
          app.$intercom.update();

          if (type !== 'signup') {
            location.reload();
          }

        };

        if (is2faPassed) {
          await completeAuth();
        } else {
          EventBusInstance.$emit(
            'open-popup',
            'Check2FA',
            {
              listeners: {
                complete: completeAuth,
              },
            },
            { key: 'check2FA' }
          );
        }
        return data.payload;
      }
      commit(AUTH_ERROR);
      throw new Error('AUTH ERROR');
    } catch (error) {
      EventBusInstance.$emit('auth-fail', {
        reason: 'error',
        error,
      });
      console.log(AUTH_REQUEST, 'error', error);
      if (error.data.errors) {
        const errorMessage = error.data.errors;
        commit(AUTH_ERROR);
        throw errorMessage;
      }

      if (error.data.errors === null) {
        const errorMessage = {
          message: error.data.message,
          email: [
            {
              message: error.data.message,
              code: error.data.code,
            },
          ],
          password: [
            {
              message: error.data.message,
              code: error.data.code,
            },
          ],
        };
        commit(AUTH_ERROR);
        throw errorMessage;
      }
      commit(AUTH_ERROR);
      throw error;
    }
  },
  [AUTH_LOGOUT]: async ({ dispatch, commit }, emergency = false) => {
    //console.trace(AUTH_LOGOUT, emergency);
    try {
      if (emergency) {
        app.$router.push('/');
      } else {
        await store.$api.postAuthLogout(null, {
          ignoreIntercetors: { 401: true },
        });
      }
    } finally {
      await commit(AUTH_LOGOUT);
      await dispatch(`settings/${SETTINGS_VALUES}`, {}, { root: true });
      await commit(`market/${CHECK_FAVORITES}`, 0, { root: true });
      store.$api.baseApi.defaults.headers.common['x-build-country'] = store.getters['settings/values'].country_code;
      await dispatch(`misc/${MISC_INIT_DICTIONARIES}`, {}, { root: true });
      commit(AUTH_REMEMBER_ME, null);
      app.$intercom.reboot();

      refreshTokenBC.postMessage({
        type: 'refresh-token-logout',
      });

      if (!emergency) {
        location.reload();
      }
    }
  },
  [AUTH_CLEAR_REGISTRATION_PARAMS]: ({ commit }) => {
    commit(AUTH_CLEAR_REGISTRATION_PARAMS);
  },
  [AUTH_GET_REGISTRATION_PARAMS]: async ({ commit }) => {
    if (!store.getters['settings/values']) {
      const promise = new Promise((resolve, reject) => {
        EventBusInstance.$once('settings-loaded', (value) => {
          if (value) {
            resolve(value);
          } else {
            reject();
          }
        });
      });

      await promise;
    }

    const partner_group = store.getters['settings/values']?.partner_group;
    if (partner_group) {
      const response = await store.$api.getRegistration({
        group_id: partner_group,
      });

      if (response.data.success) {
        commit(AUTH_GET_REGISTRATION_PARAMS, response.data.payload.forms);
      }
    }
  },
  [AUTH_USER_SETUP]: async ({ dispatch, state, commit }, params = {}) => {
    const { user, balance } = params;
    if (state.token) {
      try {
        store.$tokenService.setStatus({
          accessToken: state.token,
          accessTokenExp: state.tokenExpires,
          refreshToken: state.refreshToken,
          refreshTokenExp: state.refreshExpires,
        });

        const profile = await dispatch(AUTH_USER, user);
        store.$api.baseApi.defaults.headers.common['x-build-country'] = profile.country_code;
        const _balance = await dispatch(AUTH_USER_BALANCE, balance);
        await dispatch(`settings/${SETTINGS_VALUES}`, {}, { root: true });
        await dispatch(`market/${CHECK_FAVORITES}`, {}, { root: true });

        return _balance;
      } catch (error) {
        await dispatch(`settings/${SETTINGS_VALUES}`, {}, { root: true });
        console.error('invalid token', error);
      }
    } else {
      console.info('AUTH_USER_SETUP => AUTH_LOGOUT', state);
      commit(AUTH_LOGOUT);

      await dispatch(`settings/${SETTINGS_VALUES}`, {}, { root: true });
      store.$api.baseApi.defaults.headers.common['x-build-country'] = store.getters['settings/values'].country_code;
    }
  },
  [AUTH_USER]: async ({ commit }, user) => {
    let payload = user;
    if (!user) {
      //const response = await store.$api.getUserProfile({ group_id: store.getters['settings/values'].partner_group }); метод пока без параметров
      const response = await store.$api.getUserProfile();
      payload = response.data.payload.user;
    }

    commit(AUTH_USER, payload);
    return payload;
  },
  [AUTH_USER_BALANCE]: async (
    { commit, state, getters },
    balance = null,
    forceUpdate = true
  ) => {
    let balanceList = balance;
    if (!balanceList) {
      const response = await store.$api.getUserBalance();
      balanceList = response.data.payload.list;
    }

    if (!getters.balanceList || forceUpdate) {
      await commit(AUTH_USER_BALANCE, balanceList);
    }

    if (
      !state.selectedAccount ||
      !getters.balance ||
      getters.balance.is_hidden
    ) {
      const balance = balanceList.find((item) => item.is_default);
      if (balance) {
        commit(AUTH_USER_SELECT_ACCOUNT, balance.id);
      }
    }

    return balanceList;
  },
  [AUTH_USER_ADD_ACCOUNT]: async ({ dispatch }, payload) => {
    await store.$api.postUserAccount(payload);
    await dispatch(AUTH_USER_BALANCE);
  },
  [AUTH_USER_DELETE_ACCOUNT]: async ({ dispatch }, accountId) => {
    await store.$api.deleteUserAccount(accountId);
    await dispatch(AUTH_USER_BALANCE);
  },
  [AUTH_USER_DELETE_BONUS_ACCOUNT]: async ({ dispatch }, accountId) => {
    await store.$api.deleteBonusAccount(accountId);
    await dispatch(AUTH_USER_BALANCE);
  },
  [AUTH_TOKEN_CHANGED]: async ({ state, commit }, newStatus) => {
    const _newStatus = newStatus || store.$tokenService.getStatus();
    const isSuccess = _newStatus.loggedIn;
    console.log('AUTH_TOKEN_CHANGED!');
    state.waitingRetry.forEach((item) => {
      console.log('AUTH_TOKEN_CHANGED item', item);
      if (isSuccess) {
        if (item.resolve) {
          item.resolve(_newStatus.accessToken);
        }
      } else {
        if (item.reject) {
          item.reject();
        }
      }
    });

    commit(AUTH_CLEAR_WAITING_RETRY);
  },
  [AUTH_TOKEN_CHANGED_DEBOUNCED]: _.debounce(({ dispatch }) => {
    dispatch(AUTH_TOKEN_CHANGED);
  }, 1000),
  [AUTH_ON_TOKEN_FAIL]: async (
    { commit, dispatch },
    payload = {
      resolve: () => {},
      reject: () => {},
    }
  ) => {
    commit(AUTH_ADD_TO_WAITING_RETRY, payload);
    try {
      await store.$tokenService.refresh();
      dispatch(AUTH_TOKEN_CHANGED_DEBOUNCED);
    } catch (error) {
      commit(AUTH_CLEAR_WAITING_RETRY);
      console.error(error);
    }
  },

  [AUTH_TFA_PASSED]: ({ commit }, payload) => {
    commit(AUTH_TFA_PASSED, payload);
  },
  [AUTH_UPDATE_BALANCE]: _.throttle(async ({ dispatch, getters }) => {
    if (balanceLoading || !getters.isAuthorized) {
      return;
    }

    try {
      balanceLoading = true;
      await dispatch(AUTH_USER_BALANCE, null, true);
    } finally {
      balanceLoading = false;
    }
  }, balanceLoadingColldown),
  [AUTH_TWO_FACTOR_AUTHED]: ({ commit }, payload) => {
    commit(AUTH_TWO_FACTOR_AUTHED, payload);
  },
  [AUTH_IS_CONNECTED]: ({ commit }, payload) => {
    commit(AUTH_IS_CONNECTED, payload);
  },
};

const mutations = {
  [AUTH_REQUEST_STATUS]: (state, status) => {
    state.status = status;
  },
  [AUTH_CLEAR_TIMER]: () => {
    if (state.refreshTimer) {
      clearTimeout(state.refreshTimer);
      state.refreshTimer = null;
    }
  },
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [AUTH_SUCCESS]: (state) => {
    const storage = getStorage(true);

    storage.setItem('auth-token', state.token);
    storage.setItem('refresh-token', state.refreshToken);
    storage.setItem('token-ttl', state.tokenTtl);
    storage.setItem('refresh-ttl', state.refreshTtl);
    storage.setItem('token-expires', state.tokenExpires);
    storage.setItem('refresh-expires', state.refreshExpires);
    storage.setItem('two-factor-authed', state.twoFactorAuthed);

    store.$api.baseApi.defaults.headers.common.authorization = `Bearer ${state.token}`;
    state.status = 'success';
  },
  [AUTH_LOGOUT]: (state) => {
    //console.trace(AUTH_LOGOUT);
    state.status = '';
    state.user = null;
    state.is2faPassed = true;
    state.balanceList = [];

    const storage = getStorage(true);
    storage.removeItem('auth-token');
    storage.removeItem('refresh-token');
    storage.removeItem('token-ttl');
    storage.removeItem('refresh-ttl');
    storage.removeItem('token-expires');
    storage.removeItem('refresh-expires');
    storage.removeItem('two-factor-authed');

    if (state.refreshTimer) {
      clearTimeout(state.refreshTimer);
      state.refreshTimer = null;
    }

    store.$tokenService.setLoggedOut();

    //store.$api.baseApi.defaults.headers.common.authorization = '';
  },
  [AUTH_SAVE_TOKEN]: (state, response) => {
    state.token = response.token;
    state.refreshToken = response.refresh_token;
    state.tokenTtl = response.token_ttl;
    state.refreshTtl = response.refresh_ttl;
    state.tokenExpires = dayjs().add(state.tokenTtl, 'seconds').unix() - 120;
    state.refreshExpires = dayjs().add(state.refreshTtl, 'seconds').unix() - 120;
    state.twoFactorAuthed = response.two_factor_authed;
  },
  [AUTH_LOST_CONNECTION_TOKEN]: (state) => {
    state.tokenExpires = +state.tokenExpires + tokenTimeoutStep;
  },
  [AUTH_TWO_FACTOR_AUTHED]: (state, payload) => {
    const storage = getStorage(true);

    state.twoFactorAuthed = payload;
    storage.setItem('two-factor-authed', state.twoFactorAuthed);
  },
  [AUTH_ERROR]: (state) => {
    //console.trace(AUTH_ERROR);
    getStorage().removeItem('auth-token');
    state.status = 'error';
  },
  [AUTH_GET_REGISTRATION_PARAMS]: (state, payload) => {
    state.registrationParams = payload;
  },
  [AUTH_CLEAR_REGISTRATION_PARAMS]: (state) => {
    state.registrationParams = null;
  },
  [AUTH_USER]: (state, user) => {
    state.user = user;
  },
  [AUTH_USER_BALANCE]: (state, balanceList) => {
    state.balanceList = balanceList;
  },
  [AUTH_USER_SELECT_ACCOUNT]: (state, selectedAccount) => {
    state.selectedAccount = +selectedAccount;
    getStorage().setItem('selectedAccount', selectedAccount);
  },
  [AUTH_TFA_PASSED]: (state, payload) => {
    state.is2faPassed = payload;
  },
  [AUTH_REMEMBER_ME]: (state, rememberMe) => {
    state.rememberMe = rememberMe;
    if (rememberMe === null) {
      localStorage.removeItem('remember-me');
      cookieStorage.removeItem('remember-me');
    } else {
      if (rememberMe) {
        localStorage.setItem('remember-me', true);
        cookieStorage.removeItem('remember-me');
      } else {
        localStorage.removeItem('remember-me');
        cookieStorage.setItem('remember-me', true);
      }
    }
  },
  [AUTH_IS_CONNECTED]: (state, value) => {
    state.isConnected = value;
  },
  [AUTH_ADD_TO_WAITING_RETRY]: (state, value) => {
    state.waitingRetry.push(value);
  },
  [AUTH_CLEAR_WAITING_RETRY]: (state) => {
    state.waitingRetry = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
