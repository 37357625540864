export default () => {
  const isMobile = localStorage.getItem('is-mobile') === 'true';
  return isMobile
    ? import(
      /* webpackChunkName: "mobile" */ '@/views/Search/components/SearchLine/SearchLine.mobile.vue'
    )
    : import(
      /* webpackChunkName: "desktop" */ '@/views/Search/components/SearchLine/SearchLine.desktop.vue'
    );
};
