<template>
  <div class="bonus-history-item__info">
    <div class="bonus-history-item__row">
      <div class="bonus-history-item__info-name">
        {{ $t('profile.bonusHistory.rows.amount') }}
      </div>
      <div class="bonus-history-item__info-value">
        {{ data.currency_id | dictionary('currencies', 'symbol')}}&#x202f;{{ data.bet | formatCoef }}
      </div>
    </div>
    <div class="bonus-history-item__row">
      <div class="bonus-history-item__info-name">
        {{ $t('profile.bonusHistory.rows.betType') }}
      </div>
      <div class="bonus-history-item__info-value">
        <template v-if="data.bet_type_id === 1">
          {{ $t('profile.bets.type.single') }}
        </template>
        <template v-else>
          {{ $t('profile.bets.type.accumulator') }}
        </template>
      </div>
    </div>
    <div class="bonus-history-item__row">
      <div class="bonus-history-item__info-name">
        {{ $t('profile.bonusHistory.rows.minEvents') }}
      </div>
      <div class="bonus-history-item__info-value">
        {{ data.min_events }}
      </div>
    </div>
    <div class="bonus-history-item__row">
      <div class="bonus-history-item__info-name">
        {{ $t('profile.bonusHistory.rows.minOdds') }}
      </div>
      <div class="bonus-history-item__info-value">
        {{ data.min_odds }}
      </div>
    </div>
  </div>
</template>

<script>
import logic from '@/views/Profile/ProfileBonuses/components/BonusHistory/components/logic';
export default {
  name: 'Freebet',

  mixins: [logic],
};
</script>
