var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-select",class:[
    { 'ui-select_focused': _vm.focused },
    { 'ui-select_error': _vm.currentError },
    { 'ui-select_disabled': _vm.disabled },
    { 'ui-select_no-border': !_vm.border },
    { 'ui-select_medium': _vm.type === 'medium' },
    { 'ui-select_small': _vm.type === 'small' },
    { 'ui-select_extra-small': _vm.type === 'extra-small' } ]},[(_vm.label)?_c('span',{staticClass:"ui-select__label-text ui-m-sm-b"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('span',{staticClass:"ui-select__wrapper"},[(_vm.isDynamic)?_c('span',{ref:"shadowSpan",staticClass:"resizeable-shadow"}):_vm._e(),_c('el-select',{ref:"elSelect",staticClass:"ui-select__element",attrs:{"value":_vm.value,"placeholder":_vm.pPlaceholder,"disabled":_vm.disabled,"multiple":_vm.multiple,"filterable":_vm.filterable,"filter-method":_vm.filterMethod,"popper-class":_vm.pPopperClass},on:{"input":function($event){return _vm.$emit('update:value', $event)},"change":_vm.change,"focus":_vm.focus,"blur":_vm.blur,"visible-change":_vm.handleVisibleChange}},[(_vm.isItemIco && _vm.value !== '' && _vm.isIconsDictionary !== '')?_c('template',{slot:"prefix"},[_c('ItemLogo',{attrs:{"link":_vm.itemLogo}})],1):_vm._e(),(_vm.prependIcon !== '')?_c('template',{slot:"prefix"},[_c('UiIcon',{attrs:{"size":14,"color":_vm.$config.cp.text,"name":_vm.prependIcon}})],1):_vm._e(),_c('template',{slot:"empty"},[_c('div',{staticClass:"ui-select__no-data"},[_vm._v(_vm._s(_vm.$t('ui.noData')))])]),_vm._l((_vm.list),function(item){return _c('el-option',{key:item[_vm.optionValueParam],attrs:{"label":item[_vm.optionLabelParam],"value":item[_vm.optionValueParam]}},[(_vm.isListItemIco && item.image)?[(_vm.listItemIcoType === 'logo')?[_c('ItemLogo',{attrs:{"link":item.image}})]:_vm._e(),(_vm.listItemIcoType === 'ico')?[_c('UiIcon',{staticClass:"mr-sm",attrs:{"size":14,"color":_vm.$config.cp.text,"name":item.image}})]:_vm._e()]:_vm._e(),_c('span',[_vm._v(_vm._s(item[_vm.optionNameParam]))])],2)})],2),(_vm.clearable && _vm.value)?_c('div',{staticClass:"ui-select__clear-icon",on:{"click":_vm.handleClear}},[_c('UiIcon',{attrs:{"size":16,"name":"times-circle","lib":"fa","substyle":"fas"}})],1):_vm._e()],1),(_vm.currentError)?_c('UiHelp',{staticClass:"mt-2xs",attrs:{"is-error":true,"text":_vm.currentError}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }