import store from '@/store';
import {
  SETTINGS_CURRENT_TIMEZONE,
  SETTINGS_VALUES,
  SETTINGS_LOAD_RECAPTCHA,
} from '../action_types/settings';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import Vue from 'vue';
import {
  FAST_BETS,
  LS_GET_SLIP,
  UPDATE_MAX,
  UPDATE_SLIP,
} from '@/store/action_types/market';
import { EventBusInstance } from '@/setup/eventBus';
import dayjs from 'dayjs';

const state = {
  currentTimezone: 'UTC',
  values: null,
  serverTimeDelta: 0,
};

const getters = {
  currentTimezone: (state) => state.currentTimezone,
  values: (state) => state.values,
  serverTimeDelta: (state) => state.serverTimeDelta,
};

const actions = {
  [SETTINGS_VALUES]: async ({ commit, dispatch, state }) => {
    try {
      await store.dispatch(`market/${UPDATE_MAX}`, 0);
      const params = {
        host: window.location.hostname,
        // host: 'comboplay.rs',
      };
      if (store.getters['auth/isAuthorized']) {
        params.account_id = store.getters['auth/balance']?.id;
        params.country_code = store.getters['auth/user'].country_code;
      }
      const response = await store.$api.getSettings(params);
      const values = response.data.payload;
      if (values.services.recaptcha.site_key !== state.values?.services?.recaptcha?.site_key) {
        dispatch(SETTINGS_LOAD_RECAPTCHA, values);
      }
      commit(SETTINGS_VALUES, values);
      await store.dispatch(`market/${FAST_BETS}`, {}, { root: true });
      await store.commit(`market/${LS_GET_SLIP}`);
      await store.dispatch(`market/${UPDATE_SLIP}`);

      EventBusInstance.$emit('settings-loaded', values);
    } catch (error) {
      EventBusInstance.$emit('settings-loaded', null);
    }
  },
  [SETTINGS_LOAD_RECAPTCHA]: (store, values) => {
    //console.log('SETTINGS_LOAD_RECAPTCHA');
    VueReCaptcha(Vue, {
      siteKey: values.services.recaptcha.site_key,
    });

    if (!Vue.prototype.$recaptchaChallange) {
      Vue.prototype.$recaptchaChallange = async (captchaType = 'recaptcha') => {
        const { recaptcha, recaptchaID } = Vue.prototype.$recaptchaInstance;
        const status = await Vue.prototype.$recaptchaLoaded();
        if (status) {
          const token = await new Promise((resolve) => {
            recaptcha.ready(async () => {
              const result = await recaptcha.execute();
              resolve(result);
            });
          });

          const captchaStageBypass = sessionStorage.getItem('feature-flag-csb') === 'true';

          const _captchaType =
            window.location.host === 'betstaging.com' && captchaStageBypass
              ? 'test-captcha'
              : captchaType;

          return {
            captcha_type: _captchaType,
            captcha_id: recaptchaID + '',
            captcha_text: token,
          };
        }
      };
    }
  },
};

const mutations = {
  [SETTINGS_CURRENT_TIMEZONE]: (state, payload) => {
    state.currentTimezone = payload;
  },
  [SETTINGS_VALUES]: (state, payload) => {
    state.values = payload;
    const currentDate = dayjs().unix();
    state.serverTimeDelta = payload.unix_timestamp - currentDate;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
