<template>
  <div class="bonus-history-item__info">
    <div class="bonus-history-item__row">
      <div class="bonus-history-item__info-name">
        {{ $t('profile.bonusHistory.rows.balance') }}
      </div>
      <div class="bonus-history-item__info-value">
        {{ data.currency_id | dictionary('currencies', 'symbol') }}&#x202f;{{ data.balance | formatMoney }} / <span>{{data.currency_id | dictionary('currencies', 'symbol') }}&#x202f;{{
          data.bonus_amount | formatMoney }}</span>
      </div>
    </div>
    <div class="bonus-history-item__row">
      <div class="bonus-history-item__info-name">
        {{ $t('profile.bonusHistory.rows.wagering') }}
      </div>
      <div class="bonus-history-item__info-value">
        {{ data.currency_id | dictionary('currencies', 'symbol') }}&#x202f;{{ data.bet_amount | formatMoney }} / <span>{{data.currency_id | dictionary('currencies', 'symbol') }}&#x202f;{{
          data.wagering_amount | formatMoney }}</span>
      </div>
    </div>
    <TypeAccordion :games="data.games" />
  </div>
</template>

<script>
import logic from '@/views/Profile/ProfileBonuses/components/BonusHistory/components/logic';
import TypeAccordion from '@/views/Profile/ProfileBonuses/components/BonusHistory/components/types/TypeAccordion.vue';
export default {
  name: 'Casino',
  components: {TypeAccordion},

  mixins: [logic],
};
</script>
