export default () => {
  const isMobile = localStorage.getItem('is-mobile') === 'true';
  return isMobile
    ? import(
      /* webpackChunkName: "mobile" */ '@/views/Profile/ProfileHistory/ProfileHistory.mobile.vue'
      )
    : import(
      /* webpackChunkName: "desktop" */ '@/views/Profile/ProfileHistory/ProfileHistory.desktop.vue'
      );
};
