import Vue from 'vue';
import _ from 'lodash';
import store from '@/store';
import { EventBusInstance } from '@/setup/eventBus';
import i18n from '@/i18n';

var JSONbigNative = require('json-bigint')({ useNativeBigInt: true });

import {
  ADD_TO_FAVORITE,
  REMOVE_FAVORITE,
  ADD_SLIP,
  CHANGE_SLIP,
  REMOVE_SLIP,
  CLEAR_SLIP,
  LS_SET_SLIP,
  LS_GET_SLIP,
  UPDATE_SLIP,
  UPDATE_MAX,
  NEW_TIMER,
  CLEAR_TIMER,
  SET_TIMER_SLIP,
  FAST_BETS,
  FAST_BETS_SET,
  ODDS_CHANGE,
  CHANGE_BET_BLOCK,
  SUBSCRIBE_TOURNAMENT,
  UNSUBSCRIBE_TOURNAMENT,
  UPDATE_TOURNAMENT_SUBSCRIPTION,
  TIME_RANGE_FILTER,
  TIME_RANGE_FILTER_TOP,
  DISABLED_FREE_BET,
  CHANGE_SLIP_LOADING_STATUS,
  CHECK_FAVORITES,
} from '../action_types/market';
import { AUTH_UPDATE_BALANCE } from '../action_types/auth';

const state = {
  hasFavorites: false,
  slip: [],
  fastBets: [],
  oddsChange: '',
  slipIndex: {},
  blockBets: false,
  tournaments: [],
  updateAmountMax: 0,
  freeBets: null,
  timeRangeFilter: -1,
  timeRangeFilterTop: -1,
  isSlipLoading: false,
  isDisabledFreeBet: false,
};

let betSlipTicket = Number.MIN_SAFE_INTEGER;

function invalidateTicket() {
  if (betSlipTicket === Number.MAX_SAFE_INTEGER) {
    betSlipTicket = Number.MIN_SAFE_INTEGER;
  }

  betSlipTicket += 1;
}


let slipTimer;
let tournamentsTimer;
let tournamentsTimerSource = null;
let tournamentsTimerStatus = 'ready';

const getters = {
  hasFavorites: (state) => state.hasFavorites,
  slip: (state) => state.slip,
  updateAmountMax: (state) => state.updateAmountMax,
  freeBets: (state) => state.freeBets,
  fastBets: (state) => state.fastBets,
  oddsChange: (state) => state.oddsChange,
  slipIndex: (state) => state.slipIndex,
  blockBets: (state) => state.blockBets,
  tournaments: (state) => state.tournaments,
  isSlipLoading: (state) => state.isSlipLoading,
  isDisabledFreeBet: (state) => state.isDisabledFreeBet,
  tournamentsIndex: (state) => {
    const result = {};
    state.tournaments.forEach((item) => {
      result[item.key] = item;
    });
    return result;
  },
  timeRangeFilter: (state) => state.timeRangeFilter,
  timeRangeFilterTop: (state) => state.timeRangeFilterTop,
};

const actions = {
  [ADD_TO_FAVORITE]: async ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      store.$api.postFavorites(id).then(response => {
        commit(CHECK_FAVORITES, response.data.payload.total);
        resolve();
      }, error => {
        reject(error);
      })
    })
  },
  [REMOVE_FAVORITE]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      store.$api.deleteFavorites(id).then(response => {
        commit(CHECK_FAVORITES, response.data.payload.total);
        resolve();
      }, error => {
        reject(error);
      })
    })
  },
  [CHECK_FAVORITES]: async ({ commit }) => {
    const response = await store.$api.getFavoritesCount();
    commit(CHECK_FAVORITES, response.data.payload.total);
  },
  [UPDATE_MAX]: ({ commit }, payload) => {
    commit(UPDATE_MAX, payload);
  },
  [ADD_SLIP]: ({ commit, dispatch }, payload) => {
    if (!state.blockBets) {
      payload.forEach(payloadItem => {
        if (typeof payloadItem.partId !== 'undefined') {
          state.slip
            .filter((item) => item.matchId === payloadItem.matchId)
            .forEach((item) => {commit(REMOVE_SLIP, item.key);});
        }
      })

      if (state.slip.length < store.getters['settings/values'].bets.coupon_max_size ) {
        commit(ADD_SLIP, payload);
        commit(LS_SET_SLIP);
        dispatch(`auth/${AUTH_UPDATE_BALANCE}`, {}, { root: true });
        dispatch(UPDATE_SLIP);
      } else {
        EventBusInstance.$emit('notify-event', {
          type: 'error',
          text: i18n.t('betSlip.slip.limitEvents', {
            maxCoupons: store.getters['settings/values'].bets.coupon_max_size,
          }),
        });
      }
    }
  },
  [CHANGE_SLIP]: ({ commit }, payload) => {
    commit(CHANGE_SLIP, payload);
  },
  [REMOVE_SLIP]: ({ commit, dispatch }, key) => {
    if (!state.blockBets) {
      commit(REMOVE_SLIP, key);
      commit(LS_SET_SLIP);
      dispatch(`auth/${AUTH_UPDATE_BALANCE}`, {}, { root: true });
      dispatch(UPDATE_SLIP);
    }
  },

  [CLEAR_SLIP]: ({ commit, dispatch }) => {
    if (!state.blockBets) {
      commit(CLEAR_SLIP);
      commit(LS_SET_SLIP);
      dispatch(`auth/${AUTH_UPDATE_BALANCE}`, {}, { root: true });
      dispatch(NEW_TIMER);
    }
  },
  [UPDATE_SLIP]: async ({ commit, dispatch, state }) => {
    commit(CHANGE_SLIP_LOADING_STATUS, true);
    const events = [];

    invalidateTicket();
    const betSlipTicketLocal = betSlipTicket;

    state.slip.forEach((event) => {
      events.push({
        id: event.matchId,
        market_id: event.marketId,
        outcome_id: event.id,
        odds: event.odds,
        is_live: event.isLive,
      });
    });

    const params = {
      events: events,
    };
    if (store.getters['auth/isAuthenticated']) {
      params.account_id = store.getters['auth/balance']?.id;
    } else {
      params.currency_id = store.getters['settings/values']?.currency_id;
    }
    try {
      let response = {};
      let updatedEvents = {};
      if (state.slip.length > 0) {
        response = await store.$api.postBetsUpdate(params);
        updatedEvents = response.data.payload;
      }

      if (betSlipTicketLocal === betSlipTicket) {
        commit(UPDATE_SLIP, updatedEvents);
      }
    } finally {
      dispatch(SET_TIMER_SLIP);
      commit(CHANGE_SLIP_LOADING_STATUS, false);
    }
  },
  [CLEAR_TIMER]: () => {
    clearTimeout(slipTimer);
  },
  [NEW_TIMER]: ({ dispatch }) => {
    dispatch(CLEAR_TIMER);
    dispatch(SET_TIMER_SLIP);
  },
  [SET_TIMER_SLIP]: ({ dispatch }) => {
    const updatePeriodLive =
      store.getters['settings/values']?.update_periods?.basket?.live || 5000;
    const updatePeriodLine =
      store.getters['settings/values']?.update_periods?.basket?.line || 30000;
    const period =
      _.filter(state.slip, ['isLive', 1]).length > 0
        ? updatePeriodLive
        : updatePeriodLine;

    dispatch(CLEAR_TIMER);

    if (state.slip.length > 0) {
      slipTimer = setTimeout(() => {
        dispatch(UPDATE_SLIP);
      }, period);
    }
  },
  [FAST_BETS]: ({ commit }) => {
    const fastBetsLS =
      JSONbigNative.parse(localStorage.getItem('fastBets')) || [];
    let fastBetsArray = [];
    if (fastBetsLS.length > 0) {
      fastBetsArray = _.clone(fastBetsLS);
    } else {
      fastBetsArray = _.clone(
        store.getters['settings/values']?.bets?.fast_bets
      );
    }
    commit(FAST_BETS, fastBetsArray);
  },
  [ODDS_CHANGE]: ({ commit }) => {
    const oddsChangeLS = JSONbigNative.parse(
      localStorage.getItem('oddsChange')
    );
    commit(ODDS_CHANGE, oddsChangeLS ? oddsChangeLS : 'accept_any');
  },
  [SUBSCRIBE_TOURNAMENT]: async (
    { commit, getters, dispatch },
    { sportsId, source }
  ) => {
    if (!sportsId) {
      return;
    }

    const key = `${source}_${sportsId}`;
    if (getters.tournamentsIndex[key]) {
      const item = getters.tournamentsIndex[key];
      commit(SUBSCRIBE_TOURNAMENT, {
        ...item,
        subscribed: (item.subscribed || 1) + 1,
      });
      dispatch(UPDATE_TOURNAMENT_SUBSCRIPTION, source);
      return;
    }

    const additionalsParams = {};
    if (
      source === 'line' &&
      getters.timeRangeFilter &&
      getters.timeRangeFilter !== -1
    ) {
      additionalsParams.time_range = getters.timeRangeFilter;
    }

    const tournamentPromise = store.$api.getTournaments(source, {
      sports: [sportsId],
      ...additionalsParams,
    });

    commit(SUBSCRIBE_TOURNAMENT, {
      key,
      source: source,
      sportsId: sportsId,
      subscribed: 1,
      status: 'loading',
      payload: tournamentPromise,
    });

    try {
      const response = await tournamentPromise;
      const { list } = response.data.payload;
      const item = getters.tournamentsIndex[key];

      commit(SUBSCRIBE_TOURNAMENT, {
        key,
        source: source,
        sportsId: sportsId,
        ...item,
        status: 'loaded',
        payload: list,
      });
    } catch (error) {
      const item = getters.tournamentsIndex[key];

      commit(SUBSCRIBE_TOURNAMENT, {
        ...item,
        status: 'error',
        payload: error,
      });
    } finally {
      dispatch(UPDATE_TOURNAMENT_SUBSCRIPTION, source);
    }
  },
  [UNSUBSCRIBE_TOURNAMENT]: async (
    { commit, getters, dispatch },
    { source, sportsId }
  ) => {
    const key = `${source}_${sportsId}`;
    const item = getters.tournamentsIndex[key];
    if (item) {
      if (item.subscribed <= 1) {
        commit(UNSUBSCRIBE_TOURNAMENT, key);
      } else {
        commit(SUBSCRIBE_TOURNAMENT, {
          ...item,
          subscribed: item.subscribed - 1,
        });
      }
    }

    dispatch(UPDATE_TOURNAMENT_SUBSCRIPTION, source);
  },
  [UPDATE_TOURNAMENT_SUBSCRIPTION]: async ({ getters, commit }, source) => {
    if (tournamentsTimer && tournamentsTimerSource === source) {
      return;
    }

    if (!store.getters['settings/values']) {
      // block other calls of this function
      tournamentsTimer = -1;

      const promise = new Promise((resolve, reject) => {
        EventBusInstance.$once('settings-loaded', (value) => {
          if (value) {
            resolve(value);
          } else {
            reject();
          }
        });
      });

      await promise;
    }

    const updateSubscriptions = () => {
      tournamentsTimerSource = source;
      const period =
        store.getters['settings/values']?.update_periods?.lists[source];
      clearTimeout(tournamentsTimer);
      tournamentsTimer = setTimeout(async () => {
        tournamentsTimer = null;
        try {
          if (tournamentsTimerStatus === 'ready') {
            tournamentsTimerStatus = 'request';
            EventBusInstance.$emit('before-update-subscription');
            if (getters.tournaments && getters.tournaments.length) {
              const sports = getters.tournaments.map((item) => item.sportsId);
              const additionalsParams = {};
              if (
                source === 'line' &&
                getters.timeRangeFilter &&
                getters.timeRangeFilter !== -1
              ) {
                additionalsParams.time_range = getters.timeRangeFilter;
              }
              const tournamentPromise = store.$api.getTournaments(source, {
                sports,
                ...additionalsParams,
              });
              const response = await tournamentPromise;
              const { list } = response.data.payload;
              list.forEach((tournament) => {
                const key = `${source}_${tournament.id}`;
                const item = getters.tournamentsIndex[key];

                commit(SUBSCRIBE_TOURNAMENT, {
                  key,
                  source: source,
                  sportsId: tournament.id,
                  ...item,
                  status: 'loaded',
                  payload: [tournament],
                });
              });
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          tournamentsTimerStatus = 'ready';
          EventBusInstance.$emit('update-subscription');
          updateSubscriptions();
        }
      }, period);
    };

    updateSubscriptions();
  },
  [TIME_RANGE_FILTER]: ({ commit }, value) => {
    commit(TIME_RANGE_FILTER, value);
  },
  [TIME_RANGE_FILTER_TOP]: ({ commit }, value) => {
    commit(TIME_RANGE_FILTER_TOP, value);
  },
};

const mutations = {
  [CHANGE_SLIP_LOADING_STATUS]: (state, payload) => {
    state.isSlipLoading = payload;
  },
  [DISABLED_FREE_BET]: (state, payload) => {
    state.isDisabledFreeBet = payload;
  },
  [SUBSCRIBE_TOURNAMENT]: (state, payload) => {
    const index = state.tournaments.findIndex(
      (item) => item.key === payload.key
    );
    if (index === -1) {
      state.tournaments.push(payload);
    } else {
      Vue.set(state.tournaments, index, payload);
    }
  },
  [UNSUBSCRIBE_TOURNAMENT]: (state, key) => {
    const index = state.tournaments.findIndex((item) => item.key === key);
    if (index !== -1) {
      Vue.delete(state.tournaments, index);
    }
  },
  [CHECK_FAVORITES]: async(state, payload) => {
    state.hasFavorites = payload > 0;
  },
  [ADD_SLIP]: (state, payload) => {
    payload.forEach(item => {
      _.omit(item, ['updatedSlip']);
      state.slip.push(item);
      Vue.set(state.slipIndex, item.key, item);
    })
    // _.omit(payload, ['updatedSlip']);
    // state.slip.push(payload);
    // Vue.set(state.slipIndex, payload.key, payload);
  },
  [CHANGE_SLIP]: (state, { newSlip, id }) => {
    const newSlipClone = _.cloneDeep(newSlip);
    Vue.set(state.slip, id, newSlipClone);
  },
  [UPDATE_SLIP]: (state, payload) => {
    state.updateAmountMax = payload.amount_max;
    state.freeBets = payload.free_bets || null;
    state.slip.forEach((item) => {
      const searchItem = payload.events.find((slipItem) => {
        return slipItem.id === item.matchId;
      });
      if (searchItem) {
        Vue.set(item, 'updatedSlip', searchItem);
        Vue.set(item, 'isLive', searchItem.is_live);
        Vue.set(item, 'isBlocked', searchItem.is_blocked);
        Vue.set(item, 'isDeleted', 0);
        const acceptAsk = store.getters['market/oddsChange'] === 'always_ask';
        const raise = +item.odds < +searchItem.odds;
        const acceptRaise =
          store.getters['market/oddsChange'] === 'raise' && !raise;
        if ((acceptAsk || acceptRaise) && +item.odds !== +searchItem.odds) {
          Vue.set(item, 'acceptOddSetting', true);
        } else {
          if (searchItem.odds) {
            Vue.set(item, 'odds', searchItem.odds);
          }
          Vue.set(item, 'acceptOddSetting', false);
        }

        store.commit(`market/${LS_SET_SLIP}`);
      } else {
        Vue.set(item, 'isDeleted', 1);
      }
    });
  },
  [REMOVE_SLIP]: (state, key) => {
    const index = state.slip.findIndex((item) => item.key === key);
    Vue.delete(state.slipIndex, key);
    if (index !== -1) {
      Vue.delete(state.slip, index);
    }
  },
  [CLEAR_SLIP]: (state) => {
    state.slip = [];
    state.slipIndex = {};
  },
  [LS_SET_SLIP]: (state) => {
    const refactorSlip = [];
    state.slip.forEach((item) => {
      refactorSlip.push(
        _.omit(item, [
          'updatedSlip',
          'acceptOddSetting',
          'animName',
          'animType',
        ])
      );
    });

    localStorage.setItem('slip', JSONbigNative.stringify(refactorSlip));
  },
  [LS_GET_SLIP]: (state) => {
    const slipFromLS = JSONbigNative.parse(localStorage.getItem('slip'));
    if (slipFromLS && slipFromLS.length > 0) {
      state.slip = _.cloneDeep(slipFromLS);
      state.slip.forEach((item) => {
        Vue.set(state.slipIndex, item.key, item);
      });
    }
  },
  [UPDATE_MAX]: (state, payload) => {
    state.updateAmountMax = payload;
  },
  [FAST_BETS]: (state, payload) => {
    state.fastBets = payload;
  },
  [FAST_BETS_SET]: (state, payload) => {
    state.fastBets = payload;
    localStorage.setItem('fastBets', JSONbigNative.stringify(state.fastBets));
  },
  [ODDS_CHANGE]: (state, payload) => {
    state.oddsChange = payload;
    localStorage.setItem(
      'oddsChange',
      JSONbigNative.stringify(state.oddsChange)
    );
  },
  [CHANGE_BET_BLOCK](state, data) {
    clearTimeout(slipTimer);
    state.blockBets = data;
  },
  [TIME_RANGE_FILTER](state, data) {
    state.timeRangeFilter = data;
  },
  [TIME_RANGE_FILTER_TOP](state, data) {
    state.timeRangeFilterTop = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
