import store from '@/store';

export function mobileGuardCondition(preferIsMobile) {
  return preferIsMobile === store.state.misc.isMobile;
}

export default function mobileGuard(preferIsMobile, route = '/') {
  return function (to, from, next) {
    if (!mobileGuardCondition(preferIsMobile)) {
      next(route);
    } else {
      next();
    }
  };
}
