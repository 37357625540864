const palettes = {
  //main colors
  primary: '#ff3c0c',

  //fones
  background: '#f1f2f4',
  surface: '#ffffff',
  'on-surface': '#dde0e3',
  highlight: '#c1c4c8',

  //text and icons
  text: '#05162b',
  'text-secondary': '#5d666e',
  'text-disable': '#b2c1d1',
  'text-error': '#d60000',
  'text-success': '#268a00',
  'text-alert': '#d18200',
  link: '#ff3c0c',
  'link-hover': '#d73100',
  'on-primary': '#ffffff',
  selected: '#0000001a',
  'selected-code': '#c7cacd',
  'selected-row': '#d8d9db',
  'button-hover': '#00000033',

  //states
  error: '#d60000',
  success: '#2ea600',
  alert: '#d18200',
  blue: '#1a47bd',
};

const palettesAlpha = {
  // main10: palettes.main + '1a',
  // danger10: palettes.danger + '1a',
  // success10: palettes.success + '1a',
};

module.exports = {
  logo: require('../assets/Logo@Light.svg'),
  disabled2FA: require('../assets/Disabled2FA@Light.svg'),
  enabled2FA: require('../assets/Enabled2FA@Light.svg'),
  palettes: {
    ...palettes,
    ...palettesAlpha,
  },
};
