<template>
  <div class="languages-select">
    <div class="languages-select__flag" :style="flagIconStyle"></div>
    <el-select
      :value="currentLang"
      languages
      collapse-tags
      class="ui-select__element"
      popper-class="ui-select__dropdown ui-select__lang-list"
      @change="changeLang"
    >
      <template slot="empty">
        <div class="ui-select__no-data">{{ $t('ui.noData') }}</div>
      </template>
      <el-option
        v-for="item in languages"
        :key="item.code"
        :label="item.native"
        :value="item.code"
      >
        <div
          class="languages-select__flag"
          :style="`background-image: url(${item.image});`"
        ></div>
        <span>{{ item.native }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LanguagesSelect',

  computed: {
    ...mapGetters('misc', ['languages', 'languagesIndex']),
    currentLang() {
      return this.$i18n.locale;
    },
    flagIcon() {
      return this.currentLang !== ''
        ? this.languagesIndex[this.currentLang]?.image
        : '';
    },
    flagIconStyle() {
      return this.flagIcon ? `background-image: url(${this.flagIcon});` : '';
    },
  },

  methods: {
    changeLang(value) {
      this.$i18n.locale = value;
      localStorage.setItem('language', value);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';

.languages-select {
  display: flex;
  align-items: center;
  &__flag {
    border: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: inline-block;
    z-index: 1;
    margin-right: 4px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .ui-select {
    &__element {
      &.el-select {
        width: 110px;
        .el-input__inner {
          padding: 0;
          height: 100%;
          border-radius: 0;

          font-family: 'Montserrat', sans-serif;
          font-size: 12px;
          font-weight: 700;
          line-height: 1.33;
          color: var(--color-text);
        }
        .el-input__suffix {
          right: 0;
        }
        .el-select__caret {
          font-size: 12px;
          color: var(--color-text);
        }
      }
    }
    &__wrapper {
      background-color: transparent;
      border: none;
      height: 100%;
    }
    &_medium .el-input__inner {
      height: 100%;
      opacity: 0;
      padding: 0;
    }
  }
}
.ui-select {
  &__lang-list {
    margin-left: -20px !important;
    .el-select-dropdown__wrap {
      max-height: 280px;
    }
    .el-select-dropdown__item {
      display: flex;
      align-items: center;
    }
    .ui-select__flag {
      border: none;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      display: inline-block;
      z-index: 1;
      margin-right: 4px;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
}
@media (max-width: $screen-mobile) {
  .ui-select__lang-list {
    margin-left: -32px !important;
  }
  .languages-select .ui-select__element.el-select .el-input__inner {
    font-weight: 500;
  }
}
</style>
