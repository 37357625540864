import token from './modules/token';

export default class RequestsTokenService {
  constructor(baseApi) {
    Object.assign(this, { baseApi });

    const requests = {
      ...token,
    };

    Object.assign(this, requests);
  }
}
