<script>
import SignupPanelBonus from '@/components/SelectBonus.vue';
import SignupPanelDictionary from '@/views/Auth/components/Signup/components/SignupPanelDictionary.vue';
import SignupPanelInput from '@/views/Auth/components/Signup/components/SignupPanelInput.vue';
import SignupPanelPromocode from '@/views/Auth/components/Signup/components/SignupPanelPromocode.vue';
import SignupPanelPhone from '@/views/Auth/components/Signup/components/SignupPanelPhone.vue';
import SignupPanelEmail from '@/views/Auth/components/Signup/components/SignupPanelEmail.vue';
import SignupPanelDatepicker from '@/views/Auth/components/Signup/components/SignupPanelDatepicker.vue';
import SignupPanelCheckbox from '@/views/Auth/components/Signup/components/SignupPanelCheckbox.vue';

export default {
  name: 'SignupPanelForm',
  components: {
    SignupPanelCheckbox,
    SignupPanelBonus,
    SignupPanelDictionary,
    SignupPanelInput,
    SignupPanelPromocode,
    SignupPanelPhone,
    SignupPanelEmail,
    SignupPanelDatepicker,
  },

  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    formMeta: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pIsVisible: false,
    };
  },
  computed: {
    propsBind() {
      return {
        form: this.form,
        formMeta: this.formMeta,
        isValid: this.isValid,
        isLocked: this.isLocked,
      };
    },
  },
  watch: {
    isVisible: {
      immediate: true,
      handler(value) {
        if (value) {
          this.pIsVisible = value;
        }
      },
    },
  },
  methods: {
    handleAction(action, params) {
      this.$emit('action', action, params);
    },
  },

  render(c) {
    if (!this.pIsVisible) {
      return '';
    }

    const form = this.fields.map((field) =>
      c(field.component, {
        props: {
          ...field.params,
          value: this.form[field.code],
          name: field.code,
          error: this.errors[field.code],
          ...this.propsBind,
        },
        on: {
          action: this.handleAction,
          input: (event) => {
            this.$set(this.form, field.code, event);
            if (this.errors[field.code]) {
              this.$delete(this.errors, field.code);
            }
          },
        },
      })
    );

    return c('div', form);
  },
};
</script>
