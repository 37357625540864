/* istanbul ignore file */

export { consoleWarn, consoleError, deprecate, breaking, removed };

function deprecate({ original, replacement, vm }) {
  consoleWarn(
    `[UPGRADE] '${original}' is deprecated, use '${replacement}' instead.`,
    vm
  );
}
function breaking({ original, replacement, vm }) {
  consoleError(
    `[BREAKING] '${original}' has been removed, use '${replacement}' instead.`,
    vm
  );
}
function removed({ original, vm }) {
  consoleWarn(
    `[REMOVED] '${original}' has been removed. You can safely omit it.`,
    vm
  );
}

// ----------------------
// Helpers
// ----------------------
function createMessage({ message, vm }) {
  if (vm) {
    // Only show each message once per instance
    vm.$_alreadyWarned = vm.$_alreadyWarned || [];
    if (vm.$_alreadyWarned.includes(message)) return;
    vm.$_alreadyWarned.push(message);
  }

  return `[VueDatePicker] ${message}` + (vm ? generateComponentTrace(vm) : '');
}

function consoleWarn(message, vm) {
  const newMessage = createMessage({ message, vm });
  if (newMessage == null) return;

  console.warn(newMessage);
}

function consoleError(message, vm) {
  const newMessage = createMessage({ message, vm });
  if (newMessage == null) return;

  console.error(newMessage);
}

/**
 * Shamelessly stolen from vuejs/vue/blob/dev/src/core/util/debug.js
 */

const classifyRE = /(?:^|[-_])(\w)/g;
const classify = (str) =>
  str.replace(classifyRE, (c) => c.toUpperCase()).replace(/[-_]/g, '');

function formatComponentName(vm, includeFile) {
  if (vm.$root === vm) {
    return '<Root>';
  }
  const options =
    typeof vm === 'function' && vm.cid != null
      ? vm.options
      : vm._isVue
      ? vm.$options || vm.constructor.options
      : vm || {};
  let name = options.name || options._componentTag;
  const file = options.__file;
  if (!name && file) {
    const match = file.match(/([^/\\]+)\.vue$/);
    name = match && match[1];
  }

  return (
    (name ? `<${classify(name)}>` : '<Anonymous>') +
    (file && includeFile !== false ? ` at ${file}` : '')
  );
}

function generateComponentTrace(vm) {
  if (vm._isVue && vm.$parent) {
    const tree = [];
    let currentRecursiveSequence = 0;
    while (vm) {
      if (tree.length > 0) {
        const last = tree[tree.length - 1];
        if (last.constructor === vm.constructor) {
          currentRecursiveSequence++;
          vm = vm.$parent;
          continue;
        } else if (currentRecursiveSequence > 0) {
          tree[tree.length - 1] = [last, currentRecursiveSequence];
          currentRecursiveSequence = 0;
        }
      }
      tree.push(vm);
      vm = vm.$parent;
    }
    return (
      '\n\nfound in\n\n' +
      tree
        .map(
          (vm, i) =>
            `${i === 0 ? '---> ' : ' '.repeat(5 + i * 2)}${
              Array.isArray(vm)
                ? `${formatComponentName(vm[0])}... (${vm[1]} recursive calls)`
                : formatComponentName(vm)
            }`
        )
        .join('\n')
    );
  } else {
    return `\n\n(found in ${formatComponentName(vm)})`;
  }
}
