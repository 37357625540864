<template>
  <div class="password-recovery-panel">
    <template v-if="['pending-identity', 'confirm-identity'].includes(status)">
      <div class="mb-sm">
        {{ $t(`auth.passwordRecovery.${currentTab}.step1`) }}
      </div>
      <div class="mb-lg">
        <UiInput
          v-show="currentTab === 'email'"
          v-model="form.email"
          :placeholder="$t('auth.passwordRecovery.email.placeholder')"
          :label="$t('auth.passwordRecovery.email.label')"
          error-clear-on-change
          :error.sync="errors.email"
          :disabled="pLoading || isCodeSended"
        />

        <PhoneWithCountry
          v-show="currentTab === 'phone'"
          key="phone"
          class="mb-sm"
          :country.sync="form.country"
          :phone.sync="form.phoneMasked"
          :disabled="pLoading || isCodeSended"
          :errors="errors"
          @change="handlePhoneChange"
        />
      </div>
      <UiButton
        class="mb-4xl"
        substyle="fas"
        icon="paper-plane"
        :disabled="!pIsValid || pLoading || isCodeSended"
        @click="handlePasswordRecovery"
      >
        {{ $t('buttons.sendRecoveryCode') }}
      </UiButton>
      <div class="mb-sm">
        {{ $t(`auth.passwordRecovery.${currentTab}.step2`) }}
      </div>

      <UiInput
        v-model="codeValue"
        placeholder="Recovery Code"
        label="Recovery Code"
        error-clear-on-change
        :error.sync="codeErrors.code"
        :disabled="!isCodeSended || pLoading"
      />

      <ResendCodeButton
        class="flex justify-center mt-lg"
        :message="resendMessage"
        :is-resend="isResend"
        :is-code-sended="isCodeSended"
        @resend="handleSendNewCode"
      ></ResendCodeButton>

      <UiButton
        class="mt-lg"
        substyle="fas"
        icon="check"
        :disabled="isCheckCodeDisabled || pLoading || !isCodeValid"
        @click="handleConfirm"
      >
        {{ $t('buttons.checkCode') }}
      </UiButton>
    </template>
    <div v-if="status === 'success'" class="mb-4xl">
      <SuccessPanel class="mt-4xl mb-2xl" />
      <p class="text-center">
        {{ $t(`auth.passwordRecovery.${currentTab}.success`) }}
      </p>
    </div>
  </div>
</template>

<script>
import PhoneWithCountry from '@/components/PhoneWithCountry.vue';
import ResendCodeButton from '@/components/ResendCodeButton.vue';

import loginFormMixin from '@/views/Auth/components/Login/mixins/loginFormMixin.js';
import confirmCodeMixin from '@/views/Auth/components/Signup/mixins/confirm-code.js';

export default {
  name: 'PasswordRecoveryPanel',

  mixins: [loginFormMixin, confirmCodeMixin],

  components: { PhoneWithCountry, ResendCodeButton },

  props: {
    currentTab: {
      type: String,
      default: 'email',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: String,
      default: 'pending-identity',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  computed: {
    isCheckCodeDisabled() {
      return !this.isCodeSended;
    },
    isResend() {
      return this.diff <= 0;
    },
    pLoading() {
      return this.loading || this.isLoading;
    },
  },

  watch: {},

  created() {
    if (this.form.phoneMasked) {
      const phoneMasked = this.form.phoneMasked;
      this.$nextTick(() => {
        this.form.phoneMasked = phoneMasked;
      });
    }
  },

  methods: {
    async handlePasswordRecovery(event) {
      this.$emit('submit', event);
    },
    close() {
      this.$emit('success');
    },
  },
};
</script>

<style lang="scss">
.password-recovery-panel {
  // &__success-panel {

  // }
}
</style>
