export default () => {
  const isMobile = localStorage.getItem('is-mobile') === 'true';
  return isMobile
    ? import(
      /* webpackChunkName: "mobile" */ '@/views/Auth/components/Setup2FA/Setup2FAPanel.mobile.vue'
      )
    : import(
      /* webpackChunkName: "desktop" */ '@/views/Auth/components/Setup2FA/Setup2FAPanel.desktop.vue'
      );
};
