<template>
  <Popup
    class="filter__popup"
    :title="$t('popups.transactionsFilter.title')"
    :is-visible.sync="isVisible"
    @close="close"
  >
    <div slot="body">
      <div class="filter__popup-title">
        {{ $t('popups.transactionsFilter.type') }}
      </div>
      <TabsButtons :current-tab.sync="pFilters.type" :items="typesItems" />
      <div class="filter__popup-title">
        {{ $t('popups.transactionsFilter.status') }}
      </div>
      <UiSelect
        class="mb-lg"
        :value.sync="pFilters.status"
        :list="transactionsStatusList"
        filterable
        option-value-param="value"
        option-label-param="name"
        type="medium"
      />
    </div>
    <div class="flex space-x-lg" slot="footer">
      <UiButton
        class="w-1/2"
        substyle="fas"
        icon="times-circle"
        type="secondary"
        @click="reset"
      >
        {{ $t('buttons.reset') }}
      </UiButton>
      <UiButton class="w-1/2" substyle="fas" icon="check" @click="filter">
        {{ $t('buttons.confirm') }}
      </UiButton>
    </div>
  </Popup>
</template>

<script>
import betMixin from '@/helpers/betMixin.js';

export default {
  name: 'TransactionsFilter.vue',

  mixins: [betMixin],

  props: {
    filters: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      isVisible: false,
      pFilters: {},
    };
  },

  computed: {
    typesItems() {
      return [
        {
          label: 'all',
          name: this.$t('profile.bets.events.all'),
        },
        {
          label: 'deposit',
          name: this.$t('profile.transactions.type.deposit'),
        },
        {
          label: 'withdraw',
          name: this.$t('profile.transactions.type.withdrawal'),
        },
      ];
    },
  },

  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },

  methods: {
    open() {
      this.isVisible = true;
      this.pFilters = this.filters
        ? this.$_.cloneDeep(this.filters)
        : this.getFilters();
    },
    filter() {
      this.$eventBus.$emit('transactions-filter-confirm', this.pFilters);
      this.isVisible = false;
    },
    close() {
      this.isVisible = false;
    },
    reset() {
      this.close();
      this.pFilters = this.getFilters();
      this.$eventBus.$emit('transactions-filter-confirm', this.pFilters);
    },
    getFilters() {
      return {
        type: 'all',
        status: '',
      };
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';

.filter__popup {
  &-title {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
  }
  &-types,
  &-events {
    display: flex;
    margin-bottom: 16px;
  }
  &-statuses {
    display: flex;
  }
  .tabs-buttons {
    background-color: var(--color-on-surface);
  }
}
@media (max-width: $screen-mobile) {
  .filter__popup .el-scrollbar__wrap {
    max-height: calc((var(--vh) * 100px) - 200px);
  }
}
</style>
