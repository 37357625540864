<template functional>
  <div
    class="layout-panel"
    :class="{
      [data.staticClass]: data.staticClass,
      ...(data.class || {}),
    }"
    :style="data.staticStyle || ''"
    v-bind="data.attrs"
    @click="listeners.click && listeners.click($event)"
  >
    <div
      class="layout-panel__content-wrapper"
      :class="[
        `layout-panel__content-wrapper_size-${props.size}`,
        `layout-panel__content-wrapper_type-${
          props.active ? 'active' : props.type
        }`,
        {
          'layout-panel__content-wrapper_error': props.error,
        },
      ]"
    >
      <slot name="prepend" />
      <div class="layout-panel__content">
        <slot />
      </div>
      <slot name="append" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Panel',
  props: {
    type: {
      type: String,
      default: 'default',
    },
    active: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'default',
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.layout-panel {
  width: 100%;

  &__content-wrapper {
    display: flex;
    border-radius: 8px;
    height: 100%;

    &_type {
      &-default {
        color: var(--color-text);
        background-color: var(--color-surface);
      }

      &-contrast {
        color: var(--color-text);
        background-color: var(--color-on-surface);
      }

      &-active {
        color: var(--color-text);
        background-color: var(--color-primary);
      }
    }

    &_size {
      &-none {
        padding: 0;
      }
      &-default {
        & > .layout-panel__content {
          padding: 10px 16px;
        }
      }
      &-small {
        & > .layout-panel__content {
          padding: 8px;
        }
      }
      &-minor {
        & > .layout-panel__content {
          padding: 12px;
        }
      }
      &-medium {
        & > .layout-panel__content {
          padding: 16px;
        }
      }
      &-big {
        & > .layout-panel__content {
          padding: 24px;
        }
      }
    }

    &_error {
      border: 1px solid var(--color-error);
    }
  }

  &__content {
    flex-grow: 1;
    min-width: 0;
    overflow: hidden;
  }
}
</style>
