<template functional>
  <div
    class="three-section-layout"
    :class="{
      [data.staticClass]: data.staticClass,
      ...(data.class || {}),
    }"
    :style="data.staticStyle || ''"
    v-bind="data.attrs"
  >
    <aside class="three-section-layout__left">
      <slot name="left"></slot>
    </aside>
    <section class="three-section-layout__center">
      <slot></slot>
    </section>
    <aside class="three-section-layout__right">
      <slot name="right"></slot>
    </aside>
  </div>
</template>

<script>
export default {
  name: 'ThreeSectionLayout',
};
</script>

<style lang="scss">
@import '@/styles/variables';

.three-section-layout {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;

  &__left {
    max-width: 232px;
    min-width: 232px;
    width: 232px;
    position: sticky;
    top: 72px;
    align-self: baseline;
    &:empty {
      display: none;
    }
  }
  &__center {
    max-width: 100%;
    flex-basis: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    min-width: 0;
  }
  &__right {
    min-width: 280px;
    width: 280px;
    position: sticky;
    top: 72px;
    align-self: baseline;
    &:empty {
      display: none;
    }
  }
}

@media (max-width: $screen-medium) {
  .three-section-layout {
    &__left {
      max-width: 185px;
      min-width: auto;
      width: 185px;
    }
  }
}
</style>
