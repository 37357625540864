import { SET_MAIN_VIEW, SET_PREV_PATH } from '../action_types/navigation';

const getters = {
  mainView: (state) => state.mainView,
  prevPath: (state) => state.prevPath,
};

const mutations = {
  [SET_MAIN_VIEW]: (state, payload) => {
    state.mainView = payload;
  },
  [SET_PREV_PATH]: (state, payload) => {
    state.prevPath = payload;
  },
};

const actions = {};

const state = {
  mainView: '',
  prevPath: '/',
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
