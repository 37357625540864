// import router from '@/router';
// import store from '@/store';
// import { SET_MAIN_VIEW, SET_PREV_PATH } from '@/store/action_types/navigation';

export default () => {
  // eslint-disable-next-line consistent-return
  // router.beforeEach(async (to, from, next) => {
  //   const view = to.matched.find((item) => !!item.meta.view);
  //   store.commit(`navigation/${SET_PREV_PATH}`, from.fullPath);
  //   store.commit(`navigation/${SET_MAIN_VIEW}`, view ? view.meta.view : '');
  //   return next();
  // });
};
