export const ADD_TO_FAVORITE = 'ADD_TO_FAVORITE';
export const REMOVE_FAVORITE = 'REMOVE_FAVORITE';
export const ADD_SLIP = 'ADD_SLIP';
export const CHANGE_SLIP = 'CHANGE_SLIP';
export const REMOVE_SLIP = 'REMOVE_SLIP';
export const CLEAR_SLIP = 'CLEAR_SLIP';
export const LS_SET_SLIP = 'LS_SET_SLIP';
export const LS_GET_SLIP = 'LS_GET_SLIP';
export const UPDATE_SLIP = 'UPDATE_SLIP';
export const UPDATE_MAX = 'UPDATE_MAX';
export const NEW_TIMER = 'NEW_TIMER';
export const CLEAR_TIMER = 'CLEAR_TIMER';
export const SET_TIMER_SLIP = 'SET_TIMER_SLIP';
export const FAST_BETS = 'FAST_BETS';
export const FAST_BETS_SET = 'FAST_BETS_SET';
export const ODDS_CHANGE = 'ODDS_CHANGE';
export const CHANGE_BET_BLOCK = 'CHANGE_BET_BLOCK';
export const SUBSCRIBE_TOURNAMENT = 'SUBSCRIBE_TOURNAMENT';
export const UNSUBSCRIBE_TOURNAMENT = 'UNSUBSCRIBE_TOURNAMENT';
export const UPDATE_TOURNAMENT_SUBSCRIPTION = 'UPDATE_TOURNAMENT_SUBSCRIPTION';
export const TIME_RANGE_FILTER_TOP = 'TIME_RANGE_FILTER_TOP';
export const TIME_RANGE_FILTER = 'TIME_RANGE_FILTER';
export const DISABLED_FREE_BET = 'DISABLED_FREE_BET';
export const CHANGE_SLIP_LOADING_STATUS = 'CHANGE_SLIP_LOADING_STATUS';
export const CHECK_FAVORITES = 'CHECK_FAVORITES';
