export const spoil = (value, spoilText) => {
  if (typeof value === 'object') {
    const keys = Object.keys(value);
    const result = {};
    keys.forEach((key) => {
      result[key] = spoil(value[key], spoilText);
    });
    return result;
  } else {
    return spoilText;
  }
};
