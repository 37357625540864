export default () => {
  const isMobile = localStorage.getItem('is-mobile') === 'true';
  return isMobile
    ? import(
      /* webpackChunkName: "mobile" */ '@/views/Auth/components/Setup2FA/components/Setup2FASkeleton/Setup2FASkeleton.mobile.vue'
    )
    : import(
      /* webpackChunkName: "desktop" */ '@/views/Auth/components/Setup2FA/components/Setup2FASkeleton/Setup2FASkeleton.desktop.vue'
    );
};
