export default {
  getFavorites(params) {
    return this.baseApi.get('/user/favorites', { params });
  },
  getFavoritesCount() {
    return this.baseApi.get('/user/favorites/count');
  },
  postFavorites(eventId) {
    return this.baseApi.post('/user/favorites', {event_id: eventId});
  },
  deleteFavorites(eventId) {
    return this.baseApi.delete('/user/favorites', {
      data: {
        event_id: eventId
      },
    });
  },
};
