<template>
  <Popup :title="$t('popups.wallets.title')" :is-visible.sync="isVisible">
    <div class="wallets" slot="body">
      <el-scrollbar view-class="wallets__scrollbar">
        <div class="wallets__list">
          <el-radio-group v-model="accountId">
            <el-radio
              v-for="wallet in walletsList"
              :key="wallet.id"
              :label="wallet.id"
              class="checkbox"
            >
              <UiIcon v-if="wallet.bonus" class="mr-sm" :size="14" :color="$config.cp.text" name="gift" />
              {{ wallet.name }}
            </el-radio>
          </el-radio-group>
        </div>
      </el-scrollbar>
    </div>
    <UiButton slot="footer" substyle="fas" icon="check" @click="save">
      {{ $t('buttons.select') }}
    </UiButton>
  </Popup>
</template>

<script>
import { AUTH_USER_SELECT_ACCOUNT } from '@/store/action_types/auth';
import { SETTINGS_VALUES } from '@/store/action_types/settings';
import { mapGetters } from 'vuex';

export default {
  name: 'Wallets',

  props: {
    walletsList: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      accountId: 0,
      isVisible: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['balance']),
    currentWallet() {
      return this.balance.id;
    },
  },

  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },

  methods: {
    open() {
      this.accountId = this.currentWallet;
      this.isVisible = true;
    },
    async save() {
      this.$store.commit(`auth/${AUTH_USER_SELECT_ACCOUNT}`, this.accountId);
      this.$store.dispatch(`settings/${SETTINGS_VALUES}`);
      this.isVisible = false;
    },
    cancel() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';

.wallets {
  &__list {
    .el-radio-group {
      display: block;
    }
    .el-radio {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px;
      margin-bottom: 8px;
      border-radius: 8px;
      background-color: var(--color-on-surface);
      border: 2px solid var(--color-on-surface);
      &__input {
        line-height: 0;
      }
      &__inner {
        width: 16px;
        height: 16px;
        border: none;
        background-color: var(--color-highlight);
        &:after {
          width: 8px;
          height: 8px;
          background-color: var(--color-on-primary);
        }
      }
      &__label {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;
        color: var(--color-text);
      }

      &.is-checked {
        border-color: var(--color-primary);
        .el-radio__inner {
          background: var(--color-primary);
        }
        .el-radio__label {
          color: var(--color-text);
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .el-scrollbar__wrap {
    margin-bottom: 0 !important;
  }
}

@media (max-width: $screen-mobile) {
  .wallets__scrollbar {
    max-height: calc((var(--vh) * 100px) - 200px);
  }
}
</style>
