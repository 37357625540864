import { AUTH_REQUEST } from '@/store/action_types/auth';
import { version } from '../../../../../package.json';

export default {
  name: 'Login',

  data() {
    return {
      isVisible: false,
      currentTab: 'email',
      form: this.getDefaultForm(),
      errors: {},
      isValid: false,
      version: version,
    };
  },

  computed: {
    tabsItems() {
      return [
        {
          label: 'email',
          name: this.$t('auth.login.tab.email'),
          formParams: ['id', 'password'],
        },
        {
          label: 'phone',
          name: this.$t('auth.login.tab.phone'),
          formParams: ['phone', 'password'],
        },
      ];
    },
    currentTabMeta() {
      const meta = this.tabsItems.find(
        (item) => item.label === this.currentTab
      );

      return meta || {};
    },
  },
  methods: {
    init() {
      this.form = this.getDefaultForm();
    },
    async login() {
      try {
        this.errors = {};
        const recaptchaParams = await this.$recaptchaChallange();
        const form = this.$_.pick(this.form, this.currentTabMeta.formParams);
        await this.$store.dispatch(`auth/${AUTH_REQUEST}`, {
          type: 'login',
          params: {
            rememberMe: !!this.form.rememberMe,
          },
          payload: {
            app_v: this.version,
            ...form,
            ...recaptchaParams,
          },
        });
        this.handleLoginSuccess();
      } catch (errors) {
        console.error(errors);
        if (errors.captcha_text) {
          this.$eventBus.$emit('notify-event', {
            type: 'error',
            text: errors.captcha_text[0],
          });
        }
        this.errors = errors;
      }
    },
    handlePasswordRecovery() {
      this.close();
      this.$eventBus.$emit('open-popup', 'passwordRecovery');
    },
    getDefaultForm() {
      return {
        country: null,
        rememberMe: true,
      };
    },
    // override
    handleLoginSuccess() {},
  },
};
