<template>
  <div class="coupon-divider">
    <div class="coupon-divider__dot coupon-divider__dot_left"></div>
    <div class="coupon-divider__line"></div>
    <div class="coupon-divider__dot coupon-divider__dot_right"></div>
  </div>
</template>

<script>
export default {
  name: 'CouponDivider',
};
</script>

<style lang="scss">
.coupon-divider{
  display: flex;
  align-items: center;
  margin: 12px 0;
  &__line{
    background: repeating-linear-gradient( to right, transparent, transparent 4px, var(--color-background) 4px, var(--color-background) 12px );
    height: 2px;
    flex-basis: 100%;
  }
  &__dot{
    display: block;
    width: 8px;
    height: 16px;
    background-color: var(--color-background);
    &_left{
      border-radius: 0 100% 100% 0 / 0 50% 50% 0;
    }
    &_right{
      border-radius: 100% 0 0 100% / 50% 0 0 50%;
    }
  }
}
</style>
