<template>
  <UiCheckbox
    class="signup-panel__checkbox"
    :model-value="value"
    :value="value"
    :true-value="1"
    :false-value="0"
    :label="label"
    :disabled="isLocked"
    :name="name"
    @change="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'SignupPanelCheckbox',
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    formMeta: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
