<template>
  <SkeletonTheme
    class="skeleton"
    :color="$config.cp['on-surface']"
    :highlight="$config.cp.highlight"
  >
    <slot />
  </SkeletonTheme>
</template>

<script>
import { SkeletonTheme } from 'vue-loading-skeleton';

export default {
  name: 'SkeletonComponent',

  components: {
    SkeletonTheme,
  },
};
</script>

<style lang="scss">
.skeleton {
  .pu-skeleton {
    opacity: 0.2;
    border-radius: 8px;
  }
}
</style>
