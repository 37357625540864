import { EventBusInstance } from '@/setup/eventBus';

var isFailedShow = false;

export default (api) => ({
  requestSuccessInterceptor(config) {
    return config;
  },
  requestErrorInterceptor(error) {
    return Promise.reject(error);
  },
  responseSuccessInterceptor(response) {
    EventBusInstance.$emit('check-connection', true);
    return response;
  },
  async responseErrorInterceptor(error) {
    const ignoreIntercetors = error.config?.ignoreIntercetors || {};
    try {
      if (error.response) {
        if (error.response.status !== 0) {
          EventBusInstance.$emit('check-connection', true);
        }

        switch (error.response.status) {
          case 401:
            if (!(ignoreIntercetors[401] || ignoreIntercetors.all)) {
              if (error.response?.data?.code === 'TwoFactorRequired') {
                EventBusInstance.$emit(
                  'open-popup',
                  'Check2FA',
                  {},
                  { key: 'check2FA' }
                );
                EventBusInstance.$emit('auth-2fa');
              } else {
                try {
                  console.log('responseErrorInterceptor 1');
                  const token = await new Promise((resolve, reject) => {
                    EventBusInstance.$emit('auth-fail', { resolve, reject });
                  });
                  console.log('responseErrorInterceptor 2');
                  error.config.headers.authorization = `Bearer ${token}`;
                  return api(error.config);
                } catch (e) {
                  console.log('responseErrorInterceptor 3');
                  error.config.headers.authorization = '';
                  return api(error.config);
                }
              }
            }

            break;
          case 403:
            if (!(ignoreIntercetors[403] || ignoreIntercetors.all)) {
              EventBusInstance.$emit('auth-fail');
            }
            break;
          case 0:
            if (!isFailedShow) {
              isFailedShow = true;
              EventBusInstance.$emit('check-connection', false);
            }
            break;
        }
      }
    } catch (e) {
      console.error('connection error', e);
    }
    // await new Promise(() => )
    // setTimeout(() => {

    // }, 30000);
    return Promise.reject(error.response);
  },
});
