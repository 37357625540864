import marketMixin from '@/helpers/market.js';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

export default {
  components: { dayjs },
  mixins: [marketMixin],
  data() {
    return {
      // match: null,
    };
  },
  computed: {
    ...mapGetters('misc', ['sportsIndex']),
    pMatch() {
      return this.match || this.getDefaultMatch();
    },
    isFavorite() {
      return !!this.match?.is_favorite || false;
    },
    parts() {
      return this.pMatch.parts || [];
    },
    partsIndex() {
      const result = {};
      this.parts.forEach((item) => {
        result[item.part_id] = item;
      });
      return result;
    },
    eventPart() {
      return this.pMatch.current_part;
    },
    scores() {
      return this.pMatch?.info?.scores || [];
    },
    scoresIndex() {
      const result = {};
      this.scores.forEach((item) => {
        result[item.part_id] = item;
      });
      return result;
    },
    redCards() {
      return this.pMatch?.info?.red_cards || [];
    },
    redCardsIndex() {
      const result = {};
      this.redCards.forEach((item) => {
        result[item.part_id] = item;
      });
      return result;
    },
    teams() {
      return [
        {
          ...this.pMatch.team_home,
          type: 'home',
        },
        {
          ...this.pMatch.team_away,
          type: 'away',
        },
      ]
        .filter((item) => !!item)
        .map((item) => ({
          ...item,
          totalScore:
            this.scoresIndex[this.pMatch.root_part_id]?.[item.type] ?? '',
        }));
    },
    totalScoreArray() {
      return this.teams.map((item) => item.totalScore);
    },
    totalScore() {
      if (
        this.teams.length === 2 &&
        this.totalScoreArray.every((item) => item !== '')
      ) {
        return this.totalScoreArray.join(' : ');
      }
      return '';
    },
    eventDay() {
      let matchDay = null;
      if (this.match) {
        matchDay = this.$options.filters.dateNOFormat(
          this.match.start_time * 1000
        );

        const currentDate = this.$options.filters.dateNOFormat(Date.now());
        const isSameDay = currentDate.isSame(matchDay, 'day');
        const tomorrowDate = currentDate.add(1, 'day');
        const isNextDay = tomorrowDate.isSame(matchDay, 'day');

        if (isSameDay) {
          return this.$t('market.day.today');
        }
        if (isNextDay) {
          return this.$t('market.day.tomorrow');
        }
        return this.$options.filters.date(matchDay);
      }
      return '';
    },
    isRadius() {
      return !!this.sportsIndex[this.match.sport_id].round;
    },
  },

  methods: {
    getDefaultMatch() {
      return {
        id: 0,
        markets: [],
        parts: [],
        tournament: {},
      };
    },
    getScore(partId, type) {
      const score = this.scoresIndex[partId];
      return score ? score[type] : '-';
    },
  },
};
