var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-button",class:[
    ("ui-button_size-" + _vm.size),
    ("ui-button_type-" + _vm.type),
    {
      'ui-button_active': _vm.isActive,
      'ui-button_disabled': _vm.isBtnDisabled,
      'ui-button_loading': _vm.loading,
    } ],on:{"click":_vm.onButtonClick}},[(_vm.icon)?_c('UiIcon',{staticClass:"ui-button__icon",attrs:{"name":_vm.icon,"substyle":_vm.substyle,"color":_vm.getIconColor,"size":_vm.getIconSize}}):_vm._e(),_c('span',{staticClass:"ui-button__text"},[_vm._t("default")],2),(_vm.loading)?_c('div',{staticClass:"ui-button__loading-cover"},[_c('UiIcon',{staticClass:"ui-button__loading-spiner",attrs:{"color":_vm.getIconColor,"size":_vm.getIconSize + 4,"name":"spinner"}}),(_vm.loadingTimer)?_c('span',{staticClass:"ml-2xs"},[_vm._v(_vm._s(_vm.loadingTimerValue))]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }