import i18n from '@/i18n';
import store from '@/store';
import { getIsMobile } from '@/helpers/mobile.js';

// const INTERCOM_TIMER_INTERVAL = 100000; // ~20 updates quota in 30 min

const createIntecom = function () {
  return {
    config: null,
    timer: 0,
    init: function (config) {
      this.config = config;
      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === 'function') {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = `https://widget.intercom.io/widget/${config.app_id}`;
            var x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === 'complete') {
            l();
          } else if (w.attachEvent) {
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })();
    },
    command(cmd, params) {
      return window.Intercom(cmd, params);
    },
    getConfig() {
      const config = {
        ...this.config,
      };

      if (getIsMobile()) {
        config.hide_default_launcher = true;
      }

      if (!config.language_override) {
        config.language_override = i18n.locale;
      }

      if (store.getters['auth/user']) {
        const user = store.getters['auth/user'];
        config.user_id = `${user.id}`;

        if (user.ext) {
          const { ext } = user;
          if (ext.intercom_hash) {
            config.user_hash = ext.intercom_hash;
          }
        }

        if (user.email) {
          const suffix = user.email_verified_at ? '' : ' (not verified)';
          config.email = `${user.email}${suffix}`;
          config.name = config.email;
        } else {
          config.name = `User ${user.id}`;
        }

        if (user.phone) {
          const suffix = user.phone_verified_at ? '' : ' (not verified)';
          config.phone = `${user.phone}${suffix}`;
        }
      }

      return config;
    },
    reboot(overrideParams = {}) {
      const config = {
        ...this.getConfig(),
        ...overrideParams,
      };
      this.command('shutdown');
      this.command('boot', config);

      this.updateLoop();
    },
    boot: function () {
      this.command('boot', this.getConfig());
      this.updateLoop();
    },
    updateLoop: function () {
      // clearTimeout(this.timer);
      // this.timer = setTimeout(() => {
      //   this.update();
      // }, INTERCOM_TIMER_INTERVAL);
    },
    update: function () {
      if (window.Intercom) {
        this.command('update', this.getConfig());
        this.updateLoop();
      }
    },
  };
};

export default (App) => {
  if (!App.prototype.$intercom) {
    const intercom = createIntecom();
    Object.defineProperty(App.prototype, '$intercom', {
      value: intercom,
      writable: false,
    });
  }
};
