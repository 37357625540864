<template>
  <div>
    <UiTitle v-if="!isHide" :back-route="isMobile ? '/more' : ''" class="mb-lg">{{ $t('profile.contacts.title') }}</UiTitle>
    <div class="profile-contacts__text">{{ $t('profile.contacts.text') }}</div>
    <div class="profile-contacts__info">
      {{ $t('profile.contacts.generalQueries') }}
      <a :href="'mailto:' + pEmail" class="link">{{ pEmail }}</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileContactList',

  props: {
    isHide: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  computed: {
    ...mapGetters('misc', ['isMobile']),
    ...mapGetters('settings', {
      settingsValues: 'values',
    }),

    pEmail() {
      return this.settingsValues.contacts.email;
    },
  },
};
</script>

<style lang="scss">
.profile-contacts {
  &__text {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    color: var(--color-text);
    margin-bottom: 16px;
  }
  &__info {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    color: var(--color-text);
    display: flex;
    flex-direction: column;

    .link {
      font-size: 14px;
      font-weight: 600;
      margin-top: 4px;
    }
  }
}
</style>
