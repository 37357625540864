<template>
  <div class="team-logo__component">
    <img
      v-for="(image, index) of teamImages"
      :key="index"
      v-lazy="{
        src: image,
        error: require('@/assets/images/icons/users.svg'),
      }"
      :class="{ 'team-logo_circle': isRadius }"
    />
  </div>
</template>

<script>
export default {
  name: 'TeamLogo',

  props: {
    teamImages: {
      type: Array,
      default: () => [],
    },
    isRadius: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style lang="scss">
.team-logo_circle {
  border-radius: 50%;
}
</style>
