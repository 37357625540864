export default {
  props: {
    data: {
      type: [Object],
      default() {
        return null;
      },
    },
  },
};
