<template>
  <div class="phone-with-country">
    <div class="phone-with-country__label">
      {{ $t('ui.phoneLabel') }}
    </div>
    <div class="phone-with-country__wrapper">
      <UiSelect
        v-if="!countryLocked"
        :value.sync="computedCountry"
        :list="selectList"
        filterable
        :filter-method="filterMethod"
        is-list-item-ico
        option-value-param="id"
        option-label-param="code"
        option-name-param="name_and_code"
        type="medium"
        placeholder="XX"
        :disabled="disabled"
        @focus="focusList"
      />
      <UiInput
        v-model="computedPhone"
        type="tel"
        :is-phone="!!phoneData"
        :phone-data="phoneData"
        placeholder="Phone"
        :disabled="!country || disabled"
        error-clear-on-change
        :error.sync="errors.phone"
        @phone="$emit('change', $event)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PhoneWithCountry',

  props: {
    phone: {
      type: String,
      default: '',
    },
    country: {
      type: [Number, String],
      default: '',
    },
    countryLocked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      selectList: [],
    };
  },

  computed: {
    ...mapGetters('misc', ['countries']),
    pCountries() {
      return this.countries.filter((item) => item.phone_code && !item.is_reg_off);
    },

    phoneData() {
      if (!this.computedCountry) {
        return null;
      }
      const result = this.$_.find(this.pCountries, {
        id: this.computedCountry,
      });
      return result.phone_code ? result : null;
    },

    computedPhone: {
      get() {
        return this.phone;
      },
      set(newVal) {
        this.$emit('update:phone', newVal);
      },
    },
    computedCountry: {
      get() {
        return this.country;
      },
      set(newVal) {
        this.$emit('update:country', newVal);
      },
    },
  },
  mounted() {
    this.selectList = this.$_.cloneDeep(this.pCountries);
  },
  watch: {
    computedCountry: {
      immediate: true,
      handler() {
        this.$emit('change', {
          phone: '',
          isValid: false,
        });

        this.$emit('update:phone', '');
      },
    },
  },
  methods: {
    filterMethod(query) {
      this.selectList = this.$_.filter(this.pCountries, (country) => {
        const lcCountry = country.name_and_code.toLowerCase();
        const lcQuery = query.toLowerCase();
        return lcCountry.includes(lcQuery) || lcCountry.includes(lcQuery);
      });
    },
    focusList() {
      this.selectList = this.$_.cloneDeep(this.pCountries);
    },
  },
};
</script>

<style lang="scss">
.phone-with-country {
  &__label {
    font-size: 12px;
    font-weight: 600;
    line-height: 2.67;
    color: var(--color-text);
  }
  &__wrapper {
    display: flex;
    .ui-select {
      min-width: 74px;
      width: 74px;
      margin-right: 16px;
      .el-input__inner {
        padding-right: 30px;
      }
    }
    .ui-input {
      flex-basis: 100%;
      min-width: 0;
    }
  }
}
</style>
