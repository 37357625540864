<template>
  <div class="sport-list" :class="{ 'sport-list_open': isOpen }">
    <div
      :class="['sport-list__head', { 'sport-list__head_hidden': !isOpenable }]"
      @click="open"
    >
      <div class="sport-list__head-content">
        <ItemLogo :is-mask="groupBy === 'sport'" :link="sport.image" />
        <div class="sport-list__head-name">
          <slot name="sport-name">
            <template v-if="groupBy === 'sport'">
              {{ sport.id | dictionary('sports') }}
            </template>
            <template v-else>{{ sport.name }}</template>
          </slot>
        </div>
      </div>
      <div class="sport-list__head-content">
        <div v-if="isMobile" class="sport-list__head-count">
          {{ matchesCount }}
        </div>
        <UiChevron :rotate="!isOpen"></UiChevron>
      </div>
    </div>
    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div class="accordion__content" v-show="isOpen">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EventGroup',

  props: {
    isOpenable: {
      type: Boolean,
      default: true,
    },
    sport: {
      type: Object,
      default: () => ({}),
    },
    groupBy: {
      type: String,
      default: 'sport',
    },
    count: {
      type: Number,
      default: 0,
    },
    startOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen:
        !this.isOpenable || this.sport.status === 'loaded' || this.startOpen,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('misc', ['isMobile']),
    matchesCount() {
      if (this.sport.items) {
        return this.sport.items
          .map((item) => {
            return item.games.length;
          })
          .reduce((acc, currentValue) => {
            return acc + currentValue;
          }, 0);
      }
      return this.count;
    },
  },

  methods: {
    open() {
      if (!this.isOpenable || this.sport.status === 'loading') {
        return;
      }

      if (this.sport.status === 'not_loaded') {
        this.isOpen = !this.isOpen;
        this.$emit('load-tournament', this.sport);
      } else {
        this.isOpen = !this.isOpen;
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    end(el) {
      el.style.height = '';
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';
@import '@/styles/mixins.scss';

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.2s ease, opacity 0.2s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
.sport-list {
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }

  &__head {
    padding: 12px 16px;
    border-radius: 8px;
    background-color: var(--color-surface);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transform: translateZ(0);
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: 0.2s ease-out;
      background-color: var(--color-selected);
    }
    @include hover {
      &:after {
        opacity: 1;
      }
    }
    &_hidden {
      display: none;
    }

    &-content {
      display: flex;
      align-items: center;
      min-width: 0;
    }
    &-count {
      margin-right: 8px;
      font-size: 12px;
      font-weight: 500;
      color: var(--color-text-secondary);
    }
    &-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      img {
        width: 100%;
        background-color: currentColor;
      }
    }

    &-name {
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      color: var(--color-text);
      padding-right: 8px;

      //white-space: nowrap;
      //text-overflow: ellipsis;
      //overflow: hidden;
      //flex-shrink: 1;
    }
  }
  &_open & {
    &__head {
      border-radius: 8px 8px 0 0;
      margin-bottom: 1px;
    }
  }
}

@media (max-width: $screen-mobile) {
  .sport-list {
    margin-bottom: 0;
    &__head {
      padding: 12px 0;
      background-color: transparent;
      border-bottom: 1px solid var(--color-on-surface);
      border-radius: 0;
      min-width: 0;
      &-content {
        max-width: 90%;
      }
    }
    &_open & {
      &__head {
        margin-bottom: 0;
        border-bottom: none;
      }
    }

    .accordion__content {
      margin: 0 -8px 0;
    }
  }
}
</style>
