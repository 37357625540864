import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import navigation from './modules/navigation';
import misc from './modules/misc';
import settings from './modules/settings';
import market from './modules/market';
import promo from './modules/promo';
import search from './modules/search';
import casino from './modules/casino';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    auth,
    navigation,
    misc,
    settings,
    market,
    promo,
    search,
    casino,
  },
  strict: debug,
});
