<template>
  <div class="ui-router-tabs">
    <router-link
      v-for="item in items"
      :key="item.id"
      class="ui-router-tabs__item"
      :class="{
        'ui-router-tabs__item_active': active === item.id,
      }"
      :to="item.to"
      @click.native="handleChange(item.id)"
    >
      <UiIcon
        class="ui-router-tabs__icon"
        :size="14"
        :name="item.icon"
        lib="fa"
        substyle="fas"
      />
      {{ item.name }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'UiRouterTabs',

  props: {
    active: {
      type: [String, Number],
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    handleChange(id) {
      this.$emit('change', id);
    },
  },
};
</script>

<style lang="scss">
.ui-router-tabs {
  margin-bottom: 6px;
  display: flex;
  background-color: var(--color-surface);
  border-radius: 8px;

  &__item {
    cursor: pointer;
    position: relative;
    text-decoration: none;

    border-radius: 8px;
    flex-basis: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.71;
    color: var(--color-text);

    &_active {
      background-color: var(--color-primary);
      color: var(--color-on-primary);
    }
  }

  &__icon {
    margin-right: 8px;
  }
}
</style>
