export default {
  getCasinoProviders() {
    return this.baseApi.get('/casino/providers');
  },
  getCasinoGames(params) {
    return this.baseApi.get('/casino/games', { params });
  },
  postCasinoGamesLaunch(payload, config = {}) {
    return this.baseApi.post('/casino/games/launch', payload, config);
  },
  getCasinoDictionaries() {
    return this.baseApi.get('/casino/dictionaries');
  },
};
