<template>
  <Popup
    :title="$t('popups.pickFreeBet.title')"
    :is-visible.sync="isVisible"
    class="pick-free-bet-popup"
    @close="handleClose"
  >
    <div slot="body">
      <el-scrollbar view-class="pick-free-bet-popup__scrollbar">
        <el-radio-group v-model="freeBetValue">
          <el-radio
            v-for="item in freeBets"
            :key="item.id"
            :label="item.id"
            class="pick-free-bet-popup__item"
          >
            <span class="pick-free-bet-popup__item-title">{{item.title}}</span>
            <div class="pick-free-bet-popup__item-text">{{item.description}}</div>
          </el-radio>
        </el-radio-group>

      </el-scrollbar>
    </div>
    <div slot="footer" class="flex">
      <UiButton
        class="flex-grow"
        substyle="fas"
        icon="times"
        type="secondary"
        @click="handleClose"
      >
        {{ $t('buttons.cancel') }}
      </UiButton>
      <UiButton
        class="flex-grow ml-lg"
        substyle="fas"
        icon="check"
        @click="handleConfirm"
      >
        {{ $t('buttons.confirm') }}
      </UiButton>
    </div>
  </Popup>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'PickFreeBet',

  props: {
    fbId: {
      type: [Number, String],
      default() {
        return '';
      },
    },
  },

  data() {
    return {
      isVisible: false,
      freeBetValue: '',
    };
  },

  computed: {
    ...mapGetters('market', ['freeBets']),
  },

  watch: {
    isVisible(value) {

      if (!value) {
        this.$emit('close');
      } else {
        this.freeBetValue = this.fbId
      }
    },
  },

  methods: {
    open() {
      this.isVisible = true;
    },


    cancel() {
      this.isVisible = false;
    },

    handleClose() {
      this.cancel();
    },

    handleConfirm() {
      this.$emit('freeBetPicked', this.freeBetValue);
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
.pick-free-bet-popup{
  .el-radio__input.is-checked .el-radio__inner{
    border-color: var(--color-primary) !important;
    background: var(--color-primary) !important;
  }
  &__item{
    padding: 12px;
    border-radius: 8px;
    background-color: var(--color-on-surface);
    border: solid 2px var(--color-on-surface);

    margin-bottom: 12px;

    &.el-radio{
      color: var(--color-text);
      font-weight: 500;
      margin-right: 0;
      &.is-checked{
        border: solid 2px var(--color-primary);
        .pick-free-bet-popup__item{
          &-text,
          &-title{
            color: var(--color-text);
          }
        }
      }
    }

    white-space: initial !important;

    &-title {
      font-size: 14px;
      line-height: 1.43;
    }
    &-text {
      font-size: 12px;
      line-height: 1.33;
      margin-top: 6px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__scrollbar {
    max-height: calc(100vh - 300px);
  }
}

</style>
