import {
  // Pagination,
  // Dialog,
  // Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  // Menu,
  // Submenu,
  // MenuItem,
  // MenuItemGroup,
  // Input,
  // InputNumber,
  Radio,
  RadioGroup,
  // RadioButton,
  // Checkbox,
  // CheckboxButton,
  // CheckboxGroup,
  // Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  Scrollbar,
  // ButtonGroup,
  Table,
  TableColumn,
  // DatePicker,
  // TimeSelect,
  // TimePicker,
  // Popover,
  // Tooltip,
  // Breadcrumb,
  // BreadcrumbItem,
  // Form,
  // FormItem,
  // Tabs,
  // TabPane,
  // Tag,
  // Tree,
  // Alert,
  // Slider,
  // Icon,
  // Row,
  // Col,
  // Upload,
  // Progress,
  // Spinner,
  // Badge,
  // Card,
  // Rate,
  // Steps,
  // Step,
  // Carousel,
  // CarouselItem,
  // Collapse,
  // CollapseItem,
  // Cascader,
  // ColorPicker,
  // Transfer,
  // Container,
  // Header,
  // Aside,
  // Main,
  // Footer,
  // Timeline,
  // TimelineItem,
  // Link,
  // Divider,
  // Image,
  // Calendar,
  // Backtop,
  // PageHeader,
  // CascaderPanel,
  // Loading,
  // MessageBox,
  // Message,
  // Notification
} from 'element-ui';

export default (App) => {
  // App.use(Pagination);
  // App.use(Dialog);
  // App.use(Autocomplete);
  App.use(Dropdown);
  App.use(DropdownMenu);
  App.use(DropdownItem);
  // App.use(Menu);
  // App.use(Submenu);
  // App.use(MenuItem);
  // App.use(MenuItemGroup);
  // App.use(Input);
  // App.use(InputNumber);
  App.use(Radio);
  App.use(RadioGroup);
  // App.use(RadioButton);
  // App.use(Checkbox);
  // App.use(CheckboxButton);
  // App.use(CheckboxGroup);
  // App.use(Switch);
  App.use(Select);
  App.use(Option);
  App.use(OptionGroup);
  App.use(Button);
  App.use(Scrollbar);
  // App.use(ButtonGroup);
  App.use(Table);
  App.use(TableColumn);
  // App.use(DatePicker);
  // App.use(TimeSelect);
  // App.use(TimePicker);
  // App.use(Popover);
  // App.use(Tooltip);
  // App.use(Breadcrumb);
  // App.use(BreadcrumbItem);
  // App.use(Form);
  // App.use(FormItem);
  // App.use(Tabs);
  // App.use(TabPane);
  // App.use(Tag);
  // App.use(Tree);
  // App.use(Alert);
  // App.use(Slider);
  // App.use(Icon);
  // App.use(Row);
  // App.use(Col);
  // App.use(Upload);
  // App.use(Progress);
  // App.use(Spinner);
  // App.use(Badge);
  // App.use(Card);
  // App.use(Rate);
  // App.use(Steps);
  // App.use(Step);
  // App.use(Carousel);
  // App.use(CarouselItem);
  // App.use(Collapse);
  // App.use(CollapseItem);
  // App.use(Cascader);
  // App.use(ColorPicker);
  // App.use(Transfer);
  // App.use(Container);
  // App.use(Header);
  // App.use(Aside);
  // App.use(Main);
  // App.use(Footer);
  // App.use(Timeline);
  // App.use(TimelineItem);
  // App.use(Link);
  // App.use(Divider);
  // App.use(Image);
  // App.use(Calendar);
  // App.use(Backtop);
  // App.use(PageHeader);
  // App.use(CascaderPanel);
  // App.use(Loading.directive);

  // App.prototype.$loading = Loading.service;
  // App.prototype.$msgbox = MessageBox;
  // App.prototype.$alert = MessageBox.alert;
  // App.prototype.$confirm = MessageBox.confirm;
  // App.prototype.$prompt = MessageBox.prompt;
  // App.prototype.$notify = Notification;
  // App.prototype.$message = Message;
};
