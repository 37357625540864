<template>
  <div>
    <UiInput
      v-if="formParamsIndex.id"
      key="id"
      class="mb-sm"
      v-model="form.id"
      :placeholder="$t('auth.login.emailOrIdPlaceholder')"
      :label="$t('auth.login.emailOrId')"
      name="username"
      autocomplete="on"
      error-clear-on-change
      :error.sync="errors.id"
    />
    <PhoneWithCountry
      v-if="formParamsIndex.phone"
      key="phone"
      class="mb-sm"
      :country.sync="form.country"
      :phone.sync="form.phoneMasked"
      @change="handlePhoneChange"
    />
    <UiInput
      v-if="formParamsIndex.password"
      key="password"
      v-model="form.password"
      class="mb-md"
      placeholder="********"
      :label="$t('auth.login.password')"
      type="password"
      name="password"
      error-clear-on-change
      :error.sync="errors.password"
    />
    <div class="login-popup__options">
      <UiCheckbox
        :label="$t('auth.login.rememberMe')"
        value="remember_me"
        v-model="form.rememberMe"
      />
      <div class="link" @click="forgotPassword">
        {{ $t('auth.login.forgotPassword') }}
      </div>
    </div>
  </div>
</template>

<script>
import PhoneWithCountry from '@/components/PhoneWithCountry';
import { validateEmail } from '@/helpers/validate.js';
export default {
  name: 'LoginPanel',

  components: { PhoneWithCountry },

  props: {
    currentTab: {
      type: String,
      default: 'email',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    tabMeta: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isPhoneValid: false,
    };
  },

  computed: {
    isValid() {
      const validChecks = {
        phone: () => {
          return this.form.phone && this.isPhoneValid;
        },
        id: () => {
          const login = this.form.id || '';
          const isValidId = login.match(/^\d+$/);
          const isValidEmail = validateEmail(login);
          return !!(this.form.id && (!!isValidId || isValidEmail));
        },
        password: () => {
          return this.form.password && this.form.password.length >= 6;
        },
      };
      const result = {};

      this.formParams.map((param) => {
        result[param] = validChecks[param]();
      });

      return result;
    },
    formParams() {
      return this.tabMeta.formParams || [];
    },
    formParamsIndex() {
      const result = {};
      this.formParams.forEach((item) => {
        result[item] = true;
      });
      return result;
    },
  },

  watch: {
    isValid: {
      immediate: true,
      handler(value) {
        const keys = Object.keys(value);
        const event = keys.every((key) => value[key]);
        this.$emit('isValid', event);
      },
    },
  },

  methods: {
    forgotPassword() {
      this.$emit('password-recovery');
    },
    handlePhoneChange(phone) {
      if (phone) {
        const phoneNumber = phone?.number
          ? phone.number.replace(/[^0-9]/g, '')
          : '';
        this.$set(this.form, 'phone', phoneNumber);
        this.isPhoneValid = phone.isValid;
      } else {
        this.isPhoneValid = false;
      }
    },
  },
};
</script>
