<template>
  <div class="signup-panel-phone mb-sm">
    <div class="flex items-start m-rsm">
      <UiInput
        class="signup-panel-phone__input m-sm"
        :value="value"
        :label="label"
        :placeholder="placeholder"
        type="tel"
        :is-phone="!!phoneData"
        :phone-data="phoneData"
        :disabled="!countryId"
        :name="name"
        :error="error"
        @phone="handlePhone"
      />
      <!-- <div class="m-sm">
        <UiButton
          v-if="!isCodeSended"
          key="send"
          class="signup-panel-phone__button"
          substyle="fas"
          :disabled="isSendCodeDisabled"
          @click="handleSendCode"
        >
          Send Code
        </UiButton>
        <UiButton
          v-else
          key="resend"
          class="signup-panel-phone__button"
          substyle="fas"
          :disabled="diff > 0 || loading"
          @click="handleSendNewCode"
        >
          Resend Code
        </UiButton>
      </div> -->
    </div>
    <div v-if="resendMessage" class="flex justify-center">
      {{ resendMessage }}
    </div>
    <div v-if="isCodeSended" class="flex items-start m-rsm">
      <UiInput
        class="signup-panel-phone__input m-sm"
        v-model="codeValue"
        label="Confirmation Code"
        placeholder="Code from SMS"
        :disabled="!countryId || !phone.isValid"
        name="confirmation_code"
        :error="codeErrors.code"
      />
      <div class="m-sm">
        <UiButton
          class="signup-panel-phone__button"
          substyle="fas"
          :disabled="!countryId || !phone.isValid || !codeValue"
          @click="handleConfirm"
        >
          {{ $t('buttons.confirm') }}
        </UiButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import confirmCodeMixin from '@/views/Auth/components/Signup/mixins/confirm-code.js';

export default {
  name: 'SignupPanelPhone',
  mixins: [confirmCodeMixin],
  props: {
    value: {
      type: String,
      default: '',
    },
    error: {
      type: Array,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    formMeta: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
      default: '',
    },
    isValid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCodeSended: false,
      isCodeSendBlocked: false,
      phone: {},
    };
  },
  computed: {
    ...mapGetters('misc', ['countries']),
    phoneData() {
      if (!this.countryId) {
        return null;
      }
      const result = this.$_.find(this.countries, { id: this.countryId });
      return result.phone_code ? result : null;
    },
    countryId() {
      const { country_id } = this.form;
      return country_id;
    },
    isSendCodeDisabled() {
      return (
        !this.countryId ||
        !this.phone.isValid ||
        !this.isValid ||
        this.isCodeSended ||
        this.isCodeSendBlocked
      );
    },
  },

  watch: {
    countryId: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$emit('input', '');
        }
      },
    },
  },

  methods: {
    handlePhone(phone) {
      this.phone = phone;
      this.$emit('input', phone.nationalNumber);
      this.$set(this.formMeta, 'phone', phone.number);
    },
    handleSendCode() {
      const resolve = (params) => {
        this.setCode(params.code);
        this.pMessage = params.message;
        this.isCodeSended = true;
        this.isCodeSendBlocked = false;
      };

      const reject = () => {
        this.isCodeSendBlocked = false;
      };

      this.isCodeSendBlocked = true;
      this.$emit('action', 'send-code', [resolve, reject]);
    },
    close() {
      this.$emit('action', 'close');
    },
  },
};
</script>

<style lang="scss">
.signup-panel-phone {
  &__input {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
  }

  &__button {
    width: 100px;
    margin-top: 34px;
  }
}
</style>
