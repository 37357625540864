const isAllowed = function (type) {
  const alowedTypes = ['line', 'live'];
  return alowedTypes.includes(type);
};

export default {
  getEvents(type) {
    if (!isAllowed(type)) {
      return Promise.reject();
    }

    return this.baseApi.get(`/events/${type}`);
  },
  async getSports(type, params) {
    if (!isAllowed(type)) {
      return Promise.reject();
    }

    return this.baseApi.post(`/events/${type}/sports`, params);
  },
  getTournaments(type, params) {
    if (!isAllowed(type)) {
      return Promise.reject();
    }

    return this.baseApi.post(`/events/${type}/tournaments`, params);
  },

  async getMatches(type, params) {
    if (!isAllowed(type)) {
      return Promise.reject();
    }

    return this.baseApi.post(`/events/${type}/matches`, params);
  },

  postMatch(params) {
    return this.baseApi.post('/events/match', params);
  },
  postMatchesBest(type, params) {
    if (!isAllowed(type)) {
      return Promise.reject();
    }

    return this.baseApi.post(`/events/${type}/matches-best`, params);
  },
  postSearch(params) {
    return this.baseApi.post('/events/search', params);
  },
  postEventResults(params) {
    return this.baseApi.post('/events/results', params);
  },
  postEventResultsInfo(params) {
    return this.baseApi.post('/events/results/info', params);
  },
};
