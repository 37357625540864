<template>
  <div>
    <template v-if="is2FAEnabled">
      <p class="mb-lg">
        {{ $t('auth.newPassword.tfaText') }}
      </p>
      <UiInput
        v-model="form.code"
        :placeholder="$t('auth.newPassword.codePlaceholder')"
        :label="$t('auth.newPassword.codeLabel')"
        type="text"
        class="mb-lg"
        name="code"
        error-clear-on-change
        :error.sync="errors.code"
      />
      <UiSeparator class="mb-lg" />
    </template>
    <UiInput
      v-model="form.password"
      placeholder="********"
      :label="$t('auth.newPassword.oldPasswordLabel')"
      type="password"
      name="password"
      autocomplete="new-password"
      error-clear-on-change
      :error.sync="errors.password"
    />
    <UiInput
      v-model="form.new_password"
      class="mb-sm"
      placeholder="********"
      :label="$t('auth.newPassword.newPasswordLabel')"
      type="password"
      name="newPassword"
      autocomplete="new-password"
      error-clear-on-change
      :error.sync="errors.new_password"
      :help="$t('auth.newPassword.passwordHelp')"
    />
    <UiInput
      v-model="form.new_password_confirmation"
      placeholder="********"
      :label="$t('auth.newPassword.newPasswordConfirmationLabel')"
      type="password"
      name="newPasswordConfirmation"
      autocomplete="new-password"
      error-clear-on-change
      :error.sync="errors.new_password_confirmation"
    />
  </div>
</template>
<script>
export default {
  name: 'NewPasswordPanel',

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    is2FAEnabled: {
      type: Boolean,
      default: () => false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    isValid() {
      const validChecks = {
        code: () => {
          return this.form.code;
        },
        password: () => {
          return this.form.password;
        },
        new_password: () => {
          return this.form.new_password;
        },
        new_password_confirmation: () => {
          return this.form.new_password_confirmation;
        },
      };
      const result = {};
      const checkParams = [
        'password',
        'new_password',
        'new_password_confirmation',
      ];
      if (this.is2FAEnabled) {
        checkParams.push('code');
      }
      checkParams.map((param) => {
        result[param] = validChecks[param]();
      });

      return result;
    },
  },

  watch: {
    isValid: {
      immediate: true,
      handler(value) {
        const keys = Object.keys(value);
        const event = keys.every((key) => value[key]);
        this.$emit('isValid', event);
      },
    },
  },
};
</script>
