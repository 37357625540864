<template>
  <div class="maintenance">
    <UiLogo size="big" class="mb-4xl" />
    <UiIcon
      :size="64"
      :color="$config.cp.primary"
      name="location-dot-slash"
      lib="fa"
      substyle="fas"
      class="mb-lg"
    />
    <div class="maintenance__text">
      {{ $t('ui.blockGeo') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Maintenance',
};
</script>

<style lang="scss">
.maintenance {
  height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__text {
    text-align: center;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    color: var(--color-text);
  }
}
</style>
