<template>
  <div class="terms">
    <template v-if="!isLoading">
      <template v-if="isMobile">
        <UiTitle class="mb-lg" :back-route="backRoute">
          {{ article.title }}
        </UiTitle>
      </template>
      <template v-else>
        <div class="terms__title">
          {{ article.title }}
        </div>
      </template>
      <Panel class="terms__panel">
        <div v-html="article.body"></div>
      </Panel>
    </template>
    <template v-else>
      <TermsSkeleton />
    </template>
  </div>
</template>

<script>
import TermsSkeleton from '@/views/Terms/components/TermsSkeleton';
import { mapGetters } from 'vuex';

export default {
  name: 'TermsPage',

  components: {
    TermsSkeleton,
  },

  data() {
    return {
      isLoading: true,
      article: {},
      errors: {},
    };
  },

  computed: {
    ...mapGetters('misc', ['isMobile']),
    backRoute() {
      return this.isMobile ? '/more' : '';
    },
    currentRoute() {
      return this.$route.params.id;
    },
  },

  watch: {
    async '$i18n.locale'() {
      await this.getPageTerms();
    },
    async currentRoute() {
      await this.getPageTerms();
    },
  },

  mounted() {
    this.getPageTerms();
  },

  methods: {
    async getPageTerms() {
      this.isLoading = true;
      try {
        const response = await this.$api.getPage(this.currentRoute);
        this.article = this.$_.cloneDeep(response.data.payload.list[0]);
        this.isLoading = false;
      } catch (error) {
        this.errors = error.data.errors || {};
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';

.terms {
  padding-right: 16px;
  &__title {
    font-size: 20px;
    font-weight: 700;
    color: var(--color-text);
    margin-bottom: 18px;
  }
  &__panel {
    color: var(--color-text);
    .layout-panel__content {
      padding: 24px;
    }
    h1 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 12px;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 12px;
    }
    h3 {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 12px;
    }
    div {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5;
      margin-bottom: 12px;
    }
    a {
      color: var(--color-primary);
    }
    ol,
    ul {
      font-size: 12px;
      font-weight: 500;
      line-height: 2.33;
      margin-top: 0;
      margin-bottom: 16px;
      padding-left: 22px;
      li {
        line-height: 1.63;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    ul {
      li {
        ul {
          padding-left: 12px;
          li {
            list-style: none;
          }
        }
      }
    }
    ol {
      li {
        ul {
          padding-left: 12px;
          li {
            list-style: none;
          }
        }
      }
    }
    .no-dot {
      li {
        list-style: none;
      }
    }
    .table-wrapper {
      margin-bottom: 12px;
      width: 100%;
      overflow: auto;
      table {
        width: 100%;
      }
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
      border: 1px solid;
      td {
        border: 1px solid;
        padding: 6px;
        strong {
          font-weight: 700;
        }
      }
    }
    .table {
      &_gray {
        background-color: var(--color-selected);
      }
      &_green {
        background-color: var(--color-success);
      }
      &_yellow {
        background-color: var(--color-alert);
      }
      &_red {
        background-color: var(--color-error);
      }
    }
  }
}
@media (max-width: $screen-mobile) {
  .terms {
    padding-right: 0;
  }
}
</style>
