<template>
  <div class="mobile-menu">
    <router-link
      v-for="(item, id) in mainMenu"
      :title="item.name"
      :to="item.path"
      :key="id"
      :class="[{ 'mobile-menu__item_active': item.isActive }]"
      class="mobile-menu__item"
    >
      <div class="mobile-menu__item-count" v-if="item.count && item.count > 0">
        {{ item.count }}
      </div>
      <UiIcon
        :size="20"
        :name="item.icon"
        lib="fa"
        substyle="fas"
        class="mobile-menu__item-icon"
      />
      <div class="mobile-menu__item-text">
        {{ item.name }}
      </div>
    </router-link>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import betsStatusMixin from '@/components/BetSlip/mixin/betsStatusMixin';
import { MISC_MOBILE_NAVBAR_ROUTE } from '@/store/action_types/misc';

export default {
  name: 'MobileMenu',

  mixins: [betsStatusMixin],

  computed: {
    ...mapGetters('market', ['slip']),
    ...mapGetters('misc', ['mobileNavbar']),
    ...mapGetters('settings', {
      settingsValues: 'values',
    }),

    mainMenuBase() {
      const mainMenuBase = [
        {
          name: this.$t('mobileMenu.top'),
          icon: 'fire',
          key: ['top'],
          link: '/',
          routerRootName: 'top',
        },
        {
          name: this.$t('mobileMenu.sport'),
          icon: 'futbol',
          key: ['line', 'live', 'statistics'],
          link: '/live',
          routerRootName: 'market',
          allowOverride: ['top'],
        },
        {
          name: this.$t('mobileMenu.betSlip'),
          icon: 'basket-shopping',
          key: ['bet-slip'],
          link: '/bet-slip',
          count: this.slip?.length,
        },
        (() => {
          const { is_open_regular, is_open_live } = this.settingsValues?.casino || {};
          if (!is_open_regular && !is_open_live) {
            return false;
          }

          return {
            name: this.$t('mobileMenu.casino'),
            icon: 'spade',
            key: ['casino', 'liveCasino'],
            link: is_open_regular ? '/casino/games' : '/live-casino/games',
          };
        })(),
        {
          name: this.$t('mobileMenu.more'),
          icon: 'bars',
          key: [
            'more',
            'info',
            'contacts',
            'promotions',
            'profile',
            'wallets',
            '2fa',
            'sessions',
            'results',
            'results-filter',
            'withdraw',
            'deposit',
            'history',
          ],
          link: '/more',
        },
      ];

      return mainMenuBase.filter((item) => !!item);
    },

    mainMenu() {
      return this.mainMenuBase.map((item) => {
        const isActive = this.isLinkActive(item);
        const path =
          !isActive &&
          item.routerRootName &&
          this.mobileNavbar[item.routerRootName]
            ? this.mobileNavbar[item.routerRootName]
            : item.link;

        return {
          ...item,
          isActive,
          path,
        }
      });
    },

    currentRoute() {
      return this.$route.meta.view;
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        const routes = this.mainMenu.filter((item) => item.routerRootName && item.isActive);
        if (routes.length > 0) {
          const payload = {
            key: routes[0].routerRootName,
            path: this.$route.fullPath,
          };

          this.$store.dispatch(`misc/${MISC_MOBILE_NAVBAR_ROUTE}`, payload);
        }
        // const routerNames = this.mainMenu
        //   .map((item) => item.routerRootName)
        //   .filter((item) => item);
        // const matchedNames = this.$route.matched.map((item) => item.name);
        // const intersection = this.$_.intersection(routerNames, matchedNames);

        // if (intersection.length > 0) {
        //   const payload = {
        //     key: intersection[0],
        //     path: this.$route.path,
        //   };

        //   this.$store.dispatch(`misc/${MISC_MOBILE_NAVBAR_ROUTE}`, payload);
        // }
      },
    },
  },

  methods: {
    isLinkActive(item) {
      const fromPage = this.$route.query.fromPage || '';
      const result = item.key.includes(this.currentRoute);

      if (fromPage) {
        if (
          result &&
          item.allowOverride &&
          item.allowOverride.includes(fromPage)
        ) {
          return false;
        }

        if (!result && item.key.includes(fromPage)) {
          return true;
        }
      }

      return result;
    },
  },
};
</script>

<style lang="scss">
.mobile-menu {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 2;
  display: flex;
  padding: 12px 0;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: var(--color-surface);
  &__item {
    color: var(--color-text-secondary);
    text-decoration: none;
    text-align: center;
    flex: 1 0 auto;
    flex-basis: 20%;
    position: relative;
    &-count {
      width: 16px;
      height: 16px;
      font-size: 10px;
      font-weight: 600;
      line-height: 1.33;
      padding: 4px;
      color: var(--color-on-primary);
      background-color: var(--color-primary);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: absolute;
      top: -8px;
      left: 50%;
      margin-left: 6px;
    }
    &-icon {
      margin-bottom: 4px;
    }
    &-text {
      font-size: 12px;
      font-weight: 600;
      line-height: 1.33;
    }
    &_active {
      color: var(--color-primary);
    }
  }
}
</style>
