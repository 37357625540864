import Vue from 'vue';
import _ from 'lodash';
import store from '@/store';
import {
  LIVE_CASINO_SCRIPT_STATUS,
  SETUP_LIVE_CASINO,
  LIVE_CASINO_CONNECT,
  LIVE_CASINO_TABLE_KEY,
  LIVE_CASINO_SET_TABLE_INFO,
  LIVE_CASINO_SUBSCRIBE,
  LIVE_CASINO_UNSUBSCRIBE,
  LIVE_CASINO_CLEAR_TABLE_INFO,
  LIVE_CASINO_DGA_STATUS,
  LIVE_CASINO_CURRENCY,
  LIVE_CASINO_PROVIDERS,
} from '../action_types/casino';

const state = {
  liveCasinoScriptStatus: 'not_loaded',
  liveCasinoDgaStatus: 'disconnected',
  liveCasinoCurrency: '',
  liveCasinoTableKey: [],
  liveCasinoTableData: {},
  liveCasinoProviders: {},
  liveCasinoProvidersIndex: {},
};

let disconnectPromise = null;

const getters = {
  liveCasinoScriptStatus: (state) => state.liveCasinoScriptStatus,
  liveCasinoTableKey: (state) => state.liveCasinoTableKey,
  liveCasinoTableData: (state) => state.liveCasinoTableData,
  liveCasinoProviders: (state) => state.liveCasinoProviders,
  liveCasinoProvidersIndex: (state) => state.liveCasinoProvidersIndex,
};

const actions = {
  [SETUP_LIVE_CASINO]: async ({ state, commit, dispatch, getters }) => {
    if (state.liveCasinoScriptStatus !== 'not_loaded') {
      return;
    }

    if (!store.getters['settings/values']) {
      const promise = new Promise((resolve, reject) => {
        this.$eventBus.$once('settings-loaded', (value) => {
          if (value) {
            resolve(value);
          } else {
            reject();
          }
        });
      });

      await promise;
    }

    const providers = store.getters['settings/values']?.casino?.providers || [];
    commit(LIVE_CASINO_PROVIDERS, providers);

    if (getters.liveCasinoProviders.pragmatic_play) {
      const { pragmatic_play } = getters.liveCasinoProviders;
      const script = document.createElement('script');
      const head = document.head || document.getElementsByTagName('head')[0];
      script.src = pragmatic_play.dga.script_url;
      script.async = true;

      head.insertBefore(script, head.firstChild);
      commit(LIVE_CASINO_SCRIPT_STATUS, 'loading');
      const promise = new Promise((resolve, reject) => {
        const timerId = setTimeout(() => {
          commit(LIVE_CASINO_SCRIPT_STATUS, 'not_loaded');
          reject('timeout');
        }, 30000);
        script.addEventListener('load', () => {
          resolve('load');
          clearTimeout(timerId);
          commit(LIVE_CASINO_SCRIPT_STATUS, 'loaded');
          if (state.liveCasinoDgaStatus === 'disconnected' && !_.isEmpty(state.liveCasinoTableData)) {
            dispatch(LIVE_CASINO_CONNECT);
          }
        });
      });

      await promise;
    }
  },
  [LIVE_CASINO_CONNECT]: async ({ commit, getters }) => {
    if (getters.liveCasinoProviders.pragmatic_play) {
      if (state.liveCasinoDgaStatus === 'connecting') {
        return;
      }

      const config = getters.liveCasinoProviders.pragmatic_play;

      const { dga } = window;
      dga.connect(config.dga.server);
      commit(LIVE_CASINO_DGA_STATUS, 'connecting');
      dga.onConnect = (() => {
        commit(LIVE_CASINO_DGA_STATUS, 'connected');
        const { dga } = window;
        dga.available(config.dga.casino_id);

        const keys = Object.keys(state.liveCasinoTableData);
        const waitingConnectionKeys = keys.filter(
          (key) => state.liveCasinoTableData[key].status === 'waiting_connection'
        );

        waitingConnectionKeys.forEach((key) => {
          const { tableId, currencyCode } = state.liveCasinoTableData[key];
          dga.subscribe(config.dga.casino_id, tableId, currencyCode);
          const payload = {
            providerId: config.id,
            tableId: tableId,
            currencyCode: currencyCode,
            status: 'pending',
            value: null,
          };

          commit(LIVE_CASINO_SET_TABLE_INFO, payload);
        });

        dga.onMessage = (data) => {
          if (data.tableKey) {
            commit(LIVE_CASINO_TABLE_KEY, data.tableKey);
          }

          if (data.tableId) {
            const payload = {
              providerId: config.id,
              tableId: data.tableId,
              currencyCode: data.currency,
              status: 'ready',
              value: data,
            };

            commit(LIVE_CASINO_SET_TABLE_INFO, payload);
          }
        };
      });
    }
  },
  [LIVE_CASINO_SUBSCRIBE]: async (
    { commit, dispatch, state, getters },
    payload
  ) => {
    const { providerId, tableId, currencyCode } = payload;
    const providerCode = getters.liveCasinoProvidersIndex[providerId];

    if (!providerCode) {
      const payload = {
        providerId: providerId,
        tableId: tableId,
        currencyCode: currencyCode,
        status: 'invalid_provider',
        value: null,
      };

      commit(LIVE_CASINO_SET_TABLE_INFO, payload);

      return;
    }

    if (providerCode === 'pragmatic_play') {
      const { dga } = window;
      const provider = getters.liveCasinoProviders[providerCode];

      if (!state.liveCasinoCurrency) {
        commit(LIVE_CASINO_CURRENCY, currencyCode);
      } else if (state.liveCasinoCurrency !== currencyCode) {
        commit(LIVE_CASINO_CURRENCY, currencyCode);
        commit(LIVE_CASINO_CLEAR_TABLE_INFO, { providerId });

        if (state.liveCasinoDgaStatus === 'connected') {
          commit(LIVE_CASINO_DGA_STATUS, 'disconnected');
          const { dga } = window;
          if (!disconnectPromise) {
            disconnectPromise = new Promise((resolve) => {
              dga.onWsClose = () => {
                dga.onWsClose = function () {};
                resolve();
                disconnectPromise = null;
              };
            });
          }

          dga.disconnect();
          await disconnectPromise;
        }
      }

      if (state.liveCasinoScriptStatus === 'loaded' && state.liveCasinoDgaStatus === 'connected') {
        dga.subscribe(provider.dga.casino_id, tableId, currencyCode);
        const payload = {
          providerId: providerId,
          tableId: tableId,
          currencyCode: currencyCode,
          status: 'pending',
          value: null,
        };

        commit(LIVE_CASINO_SET_TABLE_INFO, payload);
      } else {
        const payload = {
          providerId: providerId,
          tableId: tableId,
          currencyCode: currencyCode,
          status: 'waiting_connection',
          value: null,
        };

        commit(LIVE_CASINO_SET_TABLE_INFO, payload);

        if (state.liveCasinoScriptStatus === 'loaded' && state.liveCasinoDgaStatus === 'disconnected') {
          if (disconnectPromise) {
            console.log('waiting to disconnect');
            await disconnectPromise;
          }

          dispatch(LIVE_CASINO_CONNECT);
        }
      }
    }
  },
  [LIVE_CASINO_UNSUBSCRIBE]: ({ commit, state, getters }, payload) => {
    const { providerId, tableId } = payload;

    const providerCode = getters.liveCasinoProvidersIndex[providerId];
    if (!providerCode) {
      commit(LIVE_CASINO_CLEAR_TABLE_INFO, { providerId, tableId });

      return;
    }

    if (providerCode === 'pragmatic_play') {
      const keys = Object.keys(state.liveCasinoTableData);
      const item = keys.find(
        (key) => state.liveCasinoTableData[key].tableId === tableId
      );

      if (item) {
        const payload = {
          ...state.liveCasinoTableData[item],
          status: 'unsubscribed',
        };
        commit(LIVE_CASINO_SET_TABLE_INFO, payload);
      }

      if (
        keys
          .filter(
            (key) => state.liveCasinoTableData[key].providerId === providerId
          )
          .every(
            (key) => state.liveCasinoTableData[key].status === 'unsubscribed'
          )
      ) {
        commit(LIVE_CASINO_CLEAR_TABLE_INFO, { providerId });

        if (state.liveCasinoDgaStatus === 'connected') {
          const { dga } = window;

          commit(LIVE_CASINO_DGA_STATUS, 'disconnected');
          commit(LIVE_CASINO_CURRENCY, '');

          if (!disconnectPromise) {
            disconnectPromise = new Promise((resolve) => {
              dga.onWsClose = () => {
                dga.onWsClose = function () {};
                resolve();
                disconnectPromise = null;
              };
            });
          }

          dga.disconnect();
        }
      }
    }
  },
};

const mutations = {
  [LIVE_CASINO_SCRIPT_STATUS]: (state, payload) => {
    state.liveCasinoScriptStatus = payload;
  },
  [LIVE_CASINO_DGA_STATUS]: (state, payload) => {
    state.liveCasinoDgaStatus = payload;
  },
  [LIVE_CASINO_TABLE_KEY]: (state, payload) => {
    state.liveCasinoTableKey = payload;
  },
  [LIVE_CASINO_SET_TABLE_INFO]: (state, payload) => {
    const { providerId, tableId, currencyCode } = payload;
    Vue.set(
      state.liveCasinoTableData,
      `${providerId}_${tableId}_${currencyCode}`,
      payload
    );
  },
  [LIVE_CASINO_CLEAR_TABLE_INFO]: (state, payload) => {
    const { tableId, providerId } = payload;
    const keys = Object.keys(state.liveCasinoTableData);
    const newKeys = keys.filter(
      (key) => state.liveCasinoTableData[key].providerId === providerId || state.liveCasinoTableData[key].tableId === tableId
    );

    state.liveCasinoTableData = _.omit(state.liveCasinoTableData, newKeys);
  },
  [LIVE_CASINO_CURRENCY]: (state, payload) => {
    state.liveCasinoCurrency = payload;
  },
  [LIVE_CASINO_PROVIDERS]: (state, payload) => {
    const providers = {};
    const providersIndex = {};

    payload.forEach((item) => {
      providers[item.code] = item;
      providersIndex[item.id] = item.code;
    });

    state.liveCasinoProviders = providers;
    state.liveCasinoProvidersIndex = providersIndex;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
