import Connectors from './connectors';
import Interceptors from './interceptors';
import Requests from './requests';

import initTokenService from '@/api/tokenService,js';

export default {
  install(Vue, config) {
    const { baseApi, setupBigInt } = Connectors;

    const baseApiInstance = baseApi(config.baseUrl);

    const interceptorsInstance = Interceptors(baseApiInstance);

    baseApiInstance.interceptors.request.use(
      interceptorsInstance.requestSuccessInterceptor,
      interceptorsInstance.requestErrorInterceptor
    );
    baseApiInstance.interceptors.response.use(
      interceptorsInstance.responseSuccessInterceptor,
      interceptorsInstance.responseErrorInterceptor
    );

    setupBigInt(baseApiInstance);
    initTokenService(baseApiInstance, config);

    if (!Vue.prototype.$api) {
      Object.defineProperty(Vue.prototype, '$api', {
        value: new Requests(baseApiInstance),
        writable: false,
      });
    }

    if (!config.store.$api) {
      Object.defineProperty(config.store, '$api', {
        value: Vue.prototype.$api,
        writable: false,
      });
    }
  },
};
