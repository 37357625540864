<template>
  <Popup
    class="statistics-popup"
    :title="$t('popups.statistics.title')"
    :is-visible.sync="isVisible"
    :is-footer="false"
  >
    <template slot="body">
      <StatisticsPanel
        class="statistics-popup__statistics-panel"
        :id="id"
        :match="match"
      />
    </template>
  </Popup>
</template>

<script>
import StatisticsPanel from '@/views/Market/Statistics/components/StatisticsPanel.vue';

export default {
  name: 'Statistics',
  components: {
    StatisticsPanel,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    match: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isVisible: false,
      loading: true,
      statistics: null,
      currentPart: -1,
      error: null,
    };
  },
  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },

  mounted() {
    this.$eventBus.$on(
      'close-statistics-popup',
      this.handleCloseStatisticsPopup
    );
  },

  beforeDestroy() {
    this.$eventBus.$off(
      'close-statistics-popup',
      this.handleCloseStatisticsPopup
    );
  },

  methods: {
    async open() {
      this.isVisible = true;
      // await this.init();
    },
    handleCloseStatisticsPopup() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
.statistics-popup {
  .popup__body {
    height: calc((var(--vh) * 100px) - 200px);
    display: flex;
    flex-direction: column;
    max-height: 704px;
  }

  &__statistics-panel {
    height: 100%;

    .statistics-panel__tabs.tabs-buttons {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
