<template>
  <Popup
    class="bet-slip-settings"
    :title="$t('popups.betslipSettings.title')"
    :is-visible.sync="isVisible"
  >
    <div class="" slot="body">
      <el-scrollbar view-class="bet-slip-settings__scrollbar">
        <div class="bet-slip-settings__title">
          {{ $t('popups.betslipSettings.oddsChange.title') }}
        </div>
        <div class="bet-slip-settings__odds-change">
          <el-radio-group v-model="pOddsChange">
            <el-radio label="accept_any" class="checkbox">
              {{ $t('popups.betslipSettings.oddsChange.any') }}
            </el-radio>
            <el-radio label="raise" class="checkbox">
              {{ $t('popups.betslipSettings.oddsChange.rise') }}
            </el-radio>
            <el-radio label="always_ask" class="checkbox">
              {{ $t('popups.betslipSettings.oddsChange.always') }}
            </el-radio>
          </el-radio-group>
        </div>
        <div class="bet-slip-settings__title">
          {{ $t('popups.betslipSettings.fastBets') }}
        </div>
        <div class="bet-slip-settings__fast-bets">
          <div
            v-for="(item, id) of pFastBets"
            :key="id"
            class="bet-slip-settings__fast-bets-item"
            :class="{ 'mb-lg': id === 0 || id === 1 }"
          >
            <UiInput
              v-model="pFastBets[id]"
              type="text"
              :currency="
                pBalance.currency_id | dictionary('currencies', 'symbol')
              "
              :placeholder="$t('popups.betslipSettings.fastBet')"
              @input="changeSum(id)"
            />
          </div>
        </div>
      </el-scrollbar>
    </div>
    <UiButton
      :disabled="isValid"
      slot="footer"
      substyle="fas"
      icon="check"
      @click="save"
    >
      {{ $t('buttons.save') }}
    </UiButton>
  </Popup>
</template>

<script>
import {
  CHANGE_SLIP,
  FAST_BETS_SET,
  LS_SET_SLIP,
  ODDS_CHANGE,
} from '@/store/action_types/market';

import { mapGetters } from 'vuex';
import helper from '@/helpers/helper';
export default {
  name: 'BetSlipSettings',

  data() {
    return {
      pFastBets: [],
      pOddsChange: '',
      isVisible: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['balance']),
    ...mapGetters('market', ['fastBets', 'oddsChange', 'slip']),

    pBalance() {
      const currencyId = this.values?.currency_id || 2;
      return this.balance || { currency_id: currencyId };
    },
    isValid() {
      return this.pFastBets.includes('') || this.pFastBets.includes('0');
    },
  },

  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },

  methods: {
    open() {
      this.pOddsChange = this.oddsChange;
      this.pFastBets = this.$_.clone(this.fastBets);
      this.isVisible = true;
    },
    async save() {
      this.$store.commit(`market/${ODDS_CHANGE}`, this.pOddsChange);
      this.$store.commit(`market/${FAST_BETS_SET}`, this.pFastBets);

      this.slip.map((item, id) => {
        let newSlip = this.$_.cloneDeep(item);

        const acceptAsk = this.oddsChange === 'always_ask';
        const raise = +newSlip.odds < +newSlip.updatedSlip.odds;
        const acceptRaise = this.oddsChange === 'raise' && !raise;

        if ((!acceptAsk && !acceptRaise) || (acceptRaise && raise)) {
          newSlip = this.$_.omit(newSlip, 'acceptOddSetting');
          newSlip.odds = newSlip.updatedSlip
            ? newSlip.updatedSlip.odds
            : newSlip.odds;
          if (newSlip.updatedSlip) {
            newSlip.updatedSlip.acceptOddSetting = false;
          }
        }

        this.$store.dispatch(`market/${CHANGE_SLIP}`, { newSlip, id: id });
        this.$store.commit(`market/${LS_SET_SLIP}`);
      });

      this.isVisible = false;
    },
    cancel() {
      this.isVisible = false;
    },
    changeSum(id) {
      let pValue = this.pFastBets[id].toString().replace(/[^0-9.?]/g, '');
      this.$nextTick(() => {
        if (
          pValue.indexOf('.') !== -1 &&
          !helper.isEmpty(pValue.split('.')[1])
        ) {
          const pValueAfterDot = pValue.split('.')[1];
          this.$set(
            this.pFastBets,
            id,
            `${this.$options.filters.formatMoneyNoDotNoRound(
              pValue
            )}.${pValueAfterDot}`
          );
          return;
        } else if (
          pValue.indexOf('.') !== -1 &&
          helper.isEmpty(pValue.split('.')[1])
        ) {
          this.$set(
            this.pFastBets,
            id,
            `${this.$options.filters.formatMoneyNoDotNoRound(pValue)}.`
          );
          return;
        }
        this.$set(
          this.pFastBets,
          id,
          pValue === ''
            ? ''
            : this.$options.filters.formatMoneyNoDotNoRound(pValue)
        );
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';

.bet-slip-settings {
  &__title {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: var(--color-text);
  }
  &__odds-change {
    margin-bottom: 24px;
    .el-radio-group {
      display: block;
    }
    .el-radio {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px;
      margin-bottom: 8px;
      border-radius: 8px;
      background-color: var(--color-on-surface);
      border: 2px solid var(--color-on-surface);
      &__input {
        line-height: 0;
      }
      &__inner {
        width: 16px;
        height: 16px;
        border: none;
        background-color: var(--color-highlight);
        &:after {
          width: 8px;
          height: 8px;
          background-color: var(--color-on-primary);
        }
      }
      &__label {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;
        color: var(--color-text);
      }

      &.is-checked {
        border-color: var(--color-primary);
        .el-radio__inner {
          background: var(--color-primary);
        }
        .el-radio__label {
          color: var(--color-text);
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &__fast-bets {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-item {
      width: calc((100% - 16px) / 2);
    }
  }
  .el-scrollbar__wrap {
    margin-bottom: 0 !important;
  }
}

@media (max-width: $screen-mobile) {
  .bet-slip-settings__scrollbar {
    max-height: calc((var(--vh) * 100px) - 200px);
  }
}
</style>
