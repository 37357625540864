<template>
  <Popup
    :title="$t('profile.bonus.popup')"
    :is-visible.sync="isVisible"
    @close="handleClose"
  >
    <div slot="body">
      <SelectBonus v-model="currentBonus" class="mb-4xl" />
    </div>
    <div slot="footer" class="flex space-x-lg">
      <UiButton
        class="w-1/2"
        substyle="fas"
        icon="times"
        type="secondary"
        :disabled="isLoading"
        @click="cancel"
      >
        {{ $t('buttons.cancel') }}
      </UiButton>
      <UiButton
        class="w-1/2"
        substyle="fas"
        icon="check"
        :disabled="isLoading"
        @click="changeBonus"
      >
        {{ $t('buttons.confirm') }}
      </UiButton>
    </div>
  </Popup>
</template>

<script>
import SelectBonus from '@/components/SelectBonus.vue';
import { AUTH_USER } from '@/store/action_types/auth';
import { mapGetters } from 'vuex';

export default {
  name: 'ChooseBonus',

  components: {
    SelectBonus,
  },

  data() {
    return {
      isVisible: false,
      isLoading: true,
      currentBonus: 0,
    };
  },

  computed: {
    ...mapGetters('auth', ['user']),
  },

  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },

  methods: {
    async open() {
      this.currentBonus = this.user.first_bonus;
      this.isVisible = true;
      this.isLoading = false;
    },
    async changeBonus() {
      this.isLoading = true;

      try {
        const response = await this.$api.putUserBonusChange({
          first_bonus: this.currentBonus,
        });
        await this.updateUser(response.data.payload);
      } catch (errors) {
        this.$eventBus.$emit('notify-event', {
          type: 'error',
          text: errors.data.message,
        });
      } finally {
        this.isLoading = false;
        this.handleClose();
      }
    },
    async updateUser(payload) {
      await this.$store.commit(`auth/${AUTH_USER}`, payload.user);
      this.$eventBus.$emit('profile-update-user');
      this.$eventBus.$emit('notify-event', {
        type: 'success',
        text: this.$t('profile.bonus.notify'),
      });
    },

    cancel() {
      this.isVisible = false;
    },

    handleClose() {
      this.cancel();
    },
  },
};
</script>
