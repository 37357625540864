<template>
  <Popup
    class="signup-popup"
    :title="$t('auth.signUp.title')"
    :is-tabs="registrationParams && state === signUpSteps.SIGNUP_STATE_REGISTER"
    :is-title="state === signUpSteps.SIGNUP_STATE_REGISTER"
    :is-visible.sync="isVisible"
    :current-tab.sync="currentTab"
    :tabs-items="tabsItems"
    :click-outside="false"
    @close="close"
  >
    <template v-if="!!registrationParams">
      <template slot="body">
        <el-scrollbar
          v-show="state === signUpSteps.SIGNUP_STATE_REGISTER"
          view-class="signup-popup__scrollbar"
          slot="body"
        >
          <SignupPanel
            :current-tab="currentTab"
            :default-form="defaultForm"
            :form="form"
            :form-meta="formMeta"
            :errors="pErrors"
            :is-valid.sync="isValid"
            :is-locked="isLocked"
            :state="state"
            :is-agree.sync="isAgree"
            @action="handleAction"
          />
        </el-scrollbar>
        <SignupConfirmPanel
          v-if="state === signUpSteps.SIGNUP_STATE_CONFIRM"
          ref="confirm"
          :current-tab="currentTab"
          :code="code"
          :form="form"
          :form-meta="formMeta"
          :is-valid.sync="isValidConfirm"
          @back="handleBack"
        ></SignupConfirmPanel>
      </template>
      <template slot="footer">
        <UiButton
          v-if="isOneStep"
          substyle="fas"
          icon="check"
          :disabled="isConfirmDisabled || loading"
          @click="handleSignup"
        >
          {{ $t('buttons.signUp') }}
        </UiButton>
        <UiButton
          v-if="isFinalStep"
          substyle="fas"
          icon="check"
          :disabled="isConfirmDisabled || loading"
          @click="handleSignup"
        >
          {{ $t('buttons.confirm') }}
        </UiButton>
        <UiButton
          v-if="!isFinalStep && !isOneStep"
          substyle="fas"
          icon="arrow-right"
          :disabled="isSingupDisabled || loading"
          @click="handleContinue"
        >
          {{ $t('buttons.continue') }}
        </UiButton>
      </template>
    </template>
    <SignupSkeleton v-else slot="body" />
  </Popup>
</template>

<script>
import logic from '@/views/Auth/components/Signup/logic';
import SignupPanel from '@/views/Auth/components/Signup/SignupPanel.vue';
import SignupConfirmPanel from '@/views/Auth/components/Signup/SignupConfirmPanel.vue';
import SignupSkeleton from '@/views/Auth/components/Signup/SignupSkeleton.vue';

export default {
  components: { SignupPanel, SignupConfirmPanel, SignupSkeleton },
  mixins: [logic],
  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },
  methods: {
    open() {
      this.isVisible = true;
      this.init();
    },
    handleRegistrationComplete() {
      this.close();
    },
    close() {
      this.currentTab = this.tabsItems[0].label;
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
.signup-popup {
  &__scrollbar {
    max-height: calc(100vh - 300px);
  }
  .popup__body{
    margin-right: -12px;
  }
  .signup-panel {
    padding-right: 12px;
  }

  .el-scrollbar__wrap {
    margin-bottom: 0 !important;
  }

  .add-promocode {
    margin: 16px 0;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: var(--color-link);
    cursor: pointer;
    transition: 0.2s linear all;
    i {
      margin-right: 4px;
    }
    &:hover {
      color: var(--color-link-hover);
    }
    &_hide {
      opacity: 0;
      height: 0;
    }
  }
  .promocode-enter-active,
  .promocode-leave-active {
    will-change: height, opacity;
    transition: height 0.2s ease, opacity 0.2s ease;
    overflow: hidden;
  }

  .promocode-enter,
  .promocode-leave-to {
    height: 0 !important;
    opacity: 0;
  }

  //.popup__window {
  //  top: 75px;
  //  width: 400px;
  //}
}
</style>
