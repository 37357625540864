<template>
  <div v-if="popups.length" class="popup-manager">
    <component
      v-for="(popup, index) in popups"
      :key="popup.id"
      ref="popup"
      :is="popup.component"
      v-bind="popup.data"
      v-on="popup.listeners"
      @close="handleClose(index)"
    />
  </div>
</template>

<script>
import Signup from '@/components/popups/Signup.vue';
import Login from '@/components/popups/Login.vue';
import Logout from '@/components/popups/Logout.vue';
import RegComplete from '@/components/popups/RegComplete.vue';
import Confirmation from '@/components/popups/Confirmation.vue';
import ShareBetSlip from '@/components/popups/ShareBetSlip.vue';
import LoadBetSlip from '@/components/popups/LoadBetSlip.vue';
import Setup2FA from '@/components/popups/Setup2FA.vue';
import ConfirmationTfa from '@/components/popups/ConfirmationTfa.vue';
import Disable2FA from '@/components/popups/Disable2FA.vue';
import Check2FA from '@/components/popups/Check2FA.vue';
import PasswordRecovery from '@/components/popups/PasswordRecovery.vue';
import NewPassword from '@/components/popups/NewPassword.vue';
import Wallets from '@/components/popups/Wallets.vue';

import BetFilter from '@/components/popups/BetFilter.vue';
import TransactionsFilter from '@/components/popups/TransactionsFilter.vue';
import BetSlipSettings from '@/components/popups/BetSlipSettings.vue';
import BetDetails from '@/components/popups/BetDetails.vue';
import ContactsPopup from '@/components/popups/ContactsPopup.vue';
import Alert from '@/components/popups/Alert.vue';
import UnlinkPhone from '@/components/popups/UnlinkPhone.vue';
import TerminateConfirm from '@/components/popups/TerminateConfirm.vue';
import ResultsFilter from '@/components/popups/ResultsFilter.vue';
import ChooseBonus from '@/components/popups/ChooseBonus.vue';
import TimeRangeFilter from '@/components/popups/TimeRangeFilter.vue';
import DeclineBonusConfirm from '@/components/popups/DeclineBonusConfirm.vue';
import PickFreeBet from '@/components/popups/PickFreeBet.vue';
import Statistics from '@/components/popups/Statistics.vue';

var JSONbigNative = require('json-bigint')({ useNativeBigInt: true });

export default {
  name: 'PopupManager',
  components: {
    Signup,
    Login,
    Logout,
    RegComplete,
    Confirmation,
    ShareBetSlip,
    LoadBetSlip,
    Setup2FA,
    ConfirmationTfa,
    Disable2FA,
    Check2FA,
    PasswordRecovery,
    NewPassword,
    BetFilter,
    TransactionsFilter,
    BetSlipSettings,
    BetDetails,
    ContactsPopup,
    Alert,
    UnlinkPhone,
    Wallets,
    TerminateConfirm,
    ResultsFilter,
    ChooseBonus,
    TimeRangeFilter,
    DeclineBonusConfirm,
    PickFreeBet,
    Statistics,
  },
  data() {
    return {
      nextId: 0,
      popups: [],
    };
  },

  computed: {
    popupQueryParam() {
      const alowedAutostartPopups = [];
      const { popup } = this.$route.query;

      return popup && alowedAutostartPopups.includes(popup) ? popup : null;
    },
  },

  mounted() {
    this.$eventBus.$on('open-popup', this.handleOpenConfirm);
    this.$eventBus.$on('update-popup-params', this.handleUpdatePopupParams);
  },

  beforeDestroy() {
    this.$eventBus.$off('open-popup', this.handleOpenConfirm);
    this.$eventBus.$off('update-popup-params', this.handleUpdatePopupParams);
  },

  methods: {
    handleRunAutostart() {
      if (this.popupQueryParam) {
        const payload = localStorage.getItem('popupManagerData');
        this.handleOpenConfirm(this.popupQueryParam, {
          source: 'autostart',
          ...JSONbigNative.parse(payload),
        });
      }
    },
    handleOpenConfirm(component, payload = {}, existBehaviour = {}) {
      const { key, onExist, disableScroll } = existBehaviour;
      if (key) {
        const isExist = this.popups.some((item) => item.key === key);
        if (isExist) {
          if (onExist && typeof onExist === 'function') {
            onExist();
          }
          return;
        }
      }

      const id = this.nextId++;
      const { data, listeners, persistent } = payload;
      const confirmData = {
        id,
        component,
        data: data,
        listeners: listeners,
        key: key,
        existBehaviour: existBehaviour,
      };

      this.popups.push(confirmData);

      if (persistent) {
        localStorage.setItem(
          'popupManagerData',
          JSONbigNative.stringify({
            data,
          })
        );

        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            popup: component,
          },
        });
      }

      this.$nextTick(() => {
        const popup = this.$refs.popup.find((ref) => ref.$vnode.key === id);
        if (popup) {
          if (disableScroll) {
            const body = document.querySelector('body');
            body.classList.add('body_scroll-disabled');
          }

          popup.open();
        }
      });
    },
    handleUpdatePopupParams(params) {
      if (this.popupQueryParam) {
        const oldParams = JSONbigNative.parse(
          localStorage.getItem('popupManagerData')
        );
        localStorage.setItem(
          'popupManagerData',
          JSONbigNative.stringify({
            ...oldParams,
            data: {
              ...oldParams.data,
              ...params,
            },
          })
        );
      }
    },
    handleClose(index) {
      const popup = this.popups[index];
      if (popup.component === this.popupQueryParam) {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {},
        });

        localStorage.removeItem('popupManagerData');
      }

      const { disableScroll } = popup.existBehaviour;
      if (disableScroll) {
        const body = document.querySelector('body');
        body.classList.remove('body_scroll-disabled');
      }

      console.log('this.popups[index]', this.popups[index]);

      this.popups.splice(index, 1);
    },
  },
};
</script>
