<template>
  <router-link class="no-underline" :to="info.link">
    <ProfileNavContent
      :class="[
        'profile-nav__item',
        {
          'profile-nav__item_active': currentProfileSection === info.view
        }
      ]"
      :to="info.link"
      :balance-currency="balanceCurrency"
      :icon="info.icon"
      :is-mobile="isMobile"
      :name="info.name"
      :type="info.type"
      :tfa-enable="tfaEnable"
    />
  </router-link>
</template>

<script>
import logic from '@/views/Profile/components/ProfileNav/logic';

export default {
  name: 'ProfileNavLink',

  mixins: [logic],
};
</script>

<style lang="scss">
@import 'style.scss';
</style>
