<template>
  <div
    class="status"
    :class="[`status_${type}`]"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'Status',

  props: {
    type: {
      type: String,
      default: '',
      required: true
    },
  }
};
</script>

<style lang="scss">
.status{
  font-size: 12px;
  font-weight: 700;
  line-height: 1.67;
  color: var(--color-on-primary);
  border-radius: 8px;
  padding: 0 8px;
  display: inline-block;
  &_sent,
  &_info {
    background-color: var(--color-primary);
  }
  &_refunded,
  &_lost {
    background-color: var(--color-error);
  }
  &_canceled {
    background-color: var(--color-on-surface);
    color: var(--color-text);
  }
  &_succeeded,
  &_won {
    background-color: var(--color-success);
  }
  &_waiting,
  &_pending {
    background-color: var(--color-blue);
  }
}
</style>