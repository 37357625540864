export default {
  methods: {
    copyInfo(value) {
      const textarea = document.createElement('textarea');
      textarea.contentEditable = true;
      textarea.readOnly = true;
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.value = value;
      textarea.select();
      textarea.setSelectionRange(0, 99999);
      document.execCommand('copy');
      textarea.remove();
      this.$eventBus.$emit('notify-event', { type: 'success', text: this.$t('ui.copied') });
      return false;
    },
  },
};
