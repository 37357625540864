import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      confirmationCodeType: '',
      codeValue: '',
      currentDate: 0,
      secondsPassed: 0,
      timer: null,
      pCode: this.getDefaultCode(),
      pMessage: '',
      loading: false,
      codeErrors: {},
      codeErrorsMessage: '',
      isCodeSended: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['serverTimeDelta']),
    canRepeatAfter() {
      const { sent_at, repeat_after } = this.pCode;
      return sent_at + repeat_after;
    },
    isCanRepeat() {
      return this.diff > 0;
    },
    isExpired() {
      return this.diffExpired <= 0;
    },
    diffExpired() {
      const { sent_at, code_ttl } = this.pCode;
      const expiresAfter = sent_at + code_ttl;

      return expiresAfter - (this.currentDate + this.secondsPassed);
    },
    diff() {
      return this.canRepeatAfter - (this.currentDate + this.secondsPassed);
    },
    resendMessage() {
      if (this.diff > 0) {
        const minutes = Math.floor(this.diff / 60);
        let seconds = this.diff % 60;
        if (seconds < 10) {
          seconds = '0' + seconds;
        }
        return this.$t('auth.signUp.resendCode', {
          time: `${minutes}:${seconds}`,
        });
      }

      return this.pMessage;
    },
    isCodeValid() {
      return this.codeValue && this.codeValue.length > 0;
    },
  },
  methods: {
    setCode(code) {
      this.isCodeSended = true;
      this.pCode = code;
      this.defaultValues();
      this.senitizeCodeTime();
      this.startTimer();
    },
    startTimer() {
      const timeoutFunc = () => {
        this.timer = setTimeout(() => {
          this.secondsPassed++;
          if (!this.isExpired) {
            clearTimeout(this.timer);
            timeoutFunc();
          } else {
            this.handleExpired();
          }
        }, 1000);
      };

      timeoutFunc();
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    defaultValues() {
      this.clearErrors();
      this.codeValue = '';
      this.currentDate = this.$dayjs().unix();
      this.secondsPassed = 0;
      this.timer = null;
    },
    async handleConfirm() {
      const { id, token } = this.pCode;
      this.loading = true;

      try {
        this.clearErrors();
        const response = await this.$api.postAuthCodeCheck({
          id,
          token,
          code: this.codeValue,
        });
        this.close(response.data.payload);
        return response.data.payload;
      } catch (error) {
        this.codeErrors = error?.data?.errors || {};
        this.codeErrorsMessage = error?.data?.message || '';
        if (this.codeErrors.id) {
          this.handleExpired(this.codeErrors.id[0] || '');
          this.clearErrors();
        }
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async handleSendNewCode() {
      if (this.isResend) {
        const { id, token } = this.pCode;
        this.loading = true;
        try {
          this.clearErrors();
          this.pMessage = '';
          const response = await this.$api.postAuthCodeSend({
            id,
            token,
          });

          this.setCode(response.data.payload.code);
          this.$eventBus.$emit('update-popup-params', {
            code: this.pCode,
          });
        } catch (error) {
          this.codeErrors = error?.data?.errors || {};
        } finally {
          this.loading = false;
        }
      }
    },
    getDefaultCode() {
      return {
        code_ttl: 0,
        repeat_after: 0,
        sent_at: 0,
      };
    },
    clearErrors() {
      this.codeErrors = {};
      this.codeErrorsMessage = '';
    },
    handleExpired(message) {
      this.isCodeSended = false;
      this.pCode = this.getDefaultCode();
      this.defaultValues();
      if (message) {
        this.$eventBus.$emit('notify-event', {
          type: 'error',
          text: message,
        });
      }
      this.expired();
    },
    senitizeCodeTime() {
      const upperThreshold = this.serverTimeDelta > 30;
      const lowerThreshold = this.serverTimeDelta < -30;

      if (upperThreshold || lowerThreshold) {
        this.currentDate = this.$dayjs().unix() + this.serverTimeDelta;
        // this.$eventBus.$emit('notify-event', {
        //   type: 'warn',
        //   text: 'Your device may have the wrong time', //i18n
        // });
      }
    },
    // override
    expired() {},
    // override
    close(/*payload*/) {},
  },
};
