<template>
  <Popup
    class="confirmation-popup"
    :title="computedTitle"
    :is-visible.sync="isVisible"
    :click-outside="false"
  >
    <div slot="body">
      <el-scrollbar view-class="confirmation-popup__scrollbar">
        <template v-if="!initialLoading">
          <div class="mb-4xl">
            <p class="mb-sm">
              {{ $t(`popups.confirmation.${type}.provideInfo`) }}
            </p>
            <template v-if="type === 'phone'">
              <PhoneWithCountry
                v-if="!isActivateOnly"
                class="mb-lg"
                :country.sync="selectCountry"
                :country-locked="true"
                :phone="phone"
                :disabled="isCodeSended"
                :errors="errors"
                @change="handlePhoneChange"
              />
              <div class="mb-sm" v-else>
                <div>
                  {{ $t('popups.confirmation.phoneActivateText1') }}
                  <div class="confirmation-popup__value">
                    {{ value | phoneHidden(true) }}
                  </div>
                </div>
                <div class="confirmation-popup__active-text">{{ $t('popups.confirmation.activateText') }}</div>
                <UiButton
                  class="mt-lg"
                  substyle="fas"
                  icon="paper-plane"
                  :disabled="isCodeSended"
                  @click="handleSendCode"
                >
                  {{ $t('popups.confirmation.sendCode') }}
                </UiButton>
              </div>
            </template>
            <template v-if="type === 'email'">
              <UiInput
                v-if="!isActivateOnly"
                class="mb-lg"
                v-model="value"
                :placeholder="$t('popups.confirmation.emailPlaceholder')"
                :label="$t('popups.confirmation.emailLabel')"
                error-clear-on-change
                :error="errors.email"
                :disabled="isCodeSended"
              />
              <div class="mb-sm" v-else>
                <div>
                  {{ $t('popups.confirmation.emailActivateText1') }}
                  <div
                    class="confirmation-popup__value confirmation-popup__value_email"
                  >
                    {{ value }}
                  </div>
                </div>
                <div class="confirmation-popup__active-text">{{ $t('popups.confirmation.activateText') }}</div>
                <UiButton
                  class="mt-lg"
                  substyle="fas"
                  icon="paper-plane"
                  :disabled="isCodeSended"
                  @click="handleSendCode"
                >
                  {{ $t('popups.confirmation.sendCode') }}
                </UiButton>
              </div>
            </template>

            <UiButton
              v-if="!isActivateOnly"
              substyle="fas"
              icon="paper-plane"
              :disabled="isCodeSended || !isValid || loading"
              @click="handleSendCodeInitial"
            >
              {{ $t('popups.confirmation.sendCode') }}
            </UiButton>
          </div>
          <div>
            <p class="mb-sm">
              {{ $t(`popups.confirmation.${type}.confirm`) }}
            </p>
            <UiInput
              class="mb-lg"
              v-model="codeValue"
              :placeholder="
                $t(`popups.confirmation.${type}.confirmPlaceholder`)
              "
              :label="$t(`popups.confirmation.confirmationCodeLabel`)"
              error-clear-on-change
              :error.sync="codeErrors.code"
              :disabled="!isCodeSended"
            />
            <ResendCodeButton
              class="flex justify-center mt-lg mb-lg"
              :message="resendMessage"
              :is-resend="isResend"
              :is-code-sended="isCodeSended"
              @resend="handleSendNewCode"
            ></ResendCodeButton>
          </div>
        </template>
        <SkeletonComponent v-else>
          <div v-for="item in [0, 1, 2]" :key="item" class="mb-sm">
            <div>
              <Skeleton class="mb-2xs" width="30%" height="15px" />
            </div>
            <div>
              <Skeleton width="100%" height="40px" />
            </div>
          </div>
        </SkeletonComponent>
      </el-scrollbar>
    </div>
    <UiButton
      slot="footer"
      substyle="fas"
      icon="check"
      :disabled="!isCodeSended || loading || !isCodeValid"
      @click="handleConfirm"
    >
      {{ $t('buttons.confirm') }}
    </UiButton>
  </Popup>
</template>

<script>
import { AUTH_USER } from '@/store/action_types/auth';
import confirmCodeMixin from '@/views/Auth/components/Signup/mixins/confirm-code.js';
import { mapGetters } from 'vuex';
import { validateEmail } from '@/helpers/validate.js';

import PhoneWithCountry from '@/components/PhoneWithCountry';
import ResendCodeButton from '@/components/ResendCodeButton.vue';
import parsePhoneNumberFromString from 'libphonenumber-js';

export default {
  name: 'Confirmation',

  components: { PhoneWithCountry, ResendCodeButton },

  mixins: [confirmCodeMixin],

  props: {
    type: {
      type: String,
      default: 'email',
    },
  },
  data() {
    return {
      isVisible: false,
      selectCountry: null,
      errors: {},
      value: '',
      phone: '',
      initialLoading: false,
      isPhoneValid: true,
      isActivateOnly: false,
    };
  },
  computed: {
    ...mapGetters('settings', {
      settingsValues: 'values',
    }),
    ...mapGetters('auth', ['user']),
    computedTitle() {
      return this.$t(`popups.confirmation.${this.type}.title`);
    },
    isResend() {
      return this.diff <= 0;
    },
    isValid() {
      if (this.type === 'phone') {
        return this.value && this.isPhoneValid;
      } else {
        return validateEmail(this.value);
      }
    },
  },
  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },
  methods: {
    async init() {
      this.errors = {};
      this.value = this.user[this.type];
      this.selectCountry = this.user.country_id;
      if (this.value) {
        this.isActivateOnly = true;
        if (this.type === 'phone') {
          this.$nextTick(() => {
            const phoneNumber = parsePhoneNumberFromString(
              `+${this.user.phone}`
            );
            this.phone = phoneNumber.nationalNumber;
          });
        }
      }
    },

    open() {
      this.isVisible = true;
      this.init();
    },
    async handleSendNewCode() {
      if (this.isResend) {
        await this.handleSendCode();
      }
    },

    async handleSendCode() {
      const type = `activate_${this.type}`;
      this.loading = true;
      try {
        this.clearErrors();
        this.pMessage = '';
        const response = await this.$api.postUserCodeSend(type);

        this.setCode(response.data.payload.code);
      } catch (error) {
        this.codeErrors = error?.data?.errors || {};
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async handleSendCodeInitial() {
      this.loading = true;
      try {
        this.errors = {};
        const group_id = this.settingsValues?.partner_group;
        const response = await this.$api.postUserProfileForm({
          group_id,
          fields: {
            [this.type]: this.value,
          },
        });
        await this.handleSendCode();
        await this.updateUser(response.data.payload);
        this.isCodeSended = true;
      } catch (error) {
        if (error.data) {
          this.$set(this.errors, this.type, error.data.message);
        } else {
          this.errors = {};
        }
      } finally {
        this.loading = false;
      }
    },
    handlePhoneChange(phone) {
      if (phone) {
        this.value = phone?.nationalNumber || '';
        this.isPhoneValid = phone.isValid;
      } else {
        this.isPhoneValid = false;
      }
    },
    async updateUser(payload) {
      await this.$store.commit(`auth/${AUTH_USER}`, payload.user);
      this.$eventBus.$emit('profile-update-user');
      this.$intercom.update();
    },
    expired() {
      this.isCodeSended = true;
    },
    async close(payload) {
      this.updateUser(payload);
      this.isVisible = false;
      this.$eventBus.$emit('open-popup', 'Alert', {
        data: {
          message: this.$t(
            `popups.confirmation.${this.type}.successAlertMessage`
          ),
          title: this.computedTitle,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';

.confirmation-popup {
  &__prompt {
    font-size: 14px;
    text-align: center;
    color: var(--color-text-disable);
  }
  &__scrollbar {
    max-height: calc(100vh - 300px);
  }
  &__value {
    font-weight: 700;
    color: var(--color-primary);
    &_email {
      width: 100%;
      display: block;
      overflow-wrap: break-word;
    }
  }
  &__active-text{
    margin-top: 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.67;
    color: var(--color-text-secondary);
  }
}

@media (max-width: $screen-mobile) {
  .confirmation-popup__scrollbar {
    max-height: calc((var(--vh) * 100px) - 200px);
  }
}
</style>
