import { mapGetters } from 'vuex';
import {
  LS_GET_SLIP,
  ODDS_CHANGE,
  UPDATE_SLIP,
} from '@/store/action_types/market';

export default {
  computed: {
    ...mapGetters('auth', ['isAuthenticated', 'balance']),
    ...mapGetters('settings', ['values']),
    waitForBalanceCondition() {
      const hasBalance = this.isAuthenticated && this.balance?.id;
      const hasNotBalance = !this.isAuthenticated;

      return !!((hasBalance || hasNotBalance) && this.values);
    },
  },
  // mounted() {
  //   if (this.waitForBalanceCondition) {
  //     this.getSlipFromLS();
  //   }
  // },
  watch: {
    waitForBalanceCondition: {
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue !== oldValue && newValue) {
          this.getSlipFromLS();
        }
      },
    },
  },

  methods: {
    async getSlipFromLS() {
      await this.$store.commit(`market/${LS_GET_SLIP}`);
      if (this.slip.length > 0) {
        await this.$store.dispatch(`market/${ODDS_CHANGE}`);
        await this.$store.dispatch(`market/${UPDATE_SLIP}`);
      }
    },
  },
};
