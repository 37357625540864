export default () => {
  const isMobile = localStorage.getItem('is-mobile') === 'true';
  return isMobile
    ? import(
      /* webpackChunkName: "mobile" */ '@/views/Market/Favorites/components/FavoritesSkeleton/FavoritesSkeleton.mobile.vue'
    )
    : import(
      /* webpackChunkName: "desktop" */ '@/views/Market/Favorites/components/FavoritesSkeleton/FavoritesSkeleton.desktop.vue'
    );
};
