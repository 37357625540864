import cookieMixin from '@/helpers/cookieMixin';
import {mapGetters} from 'vuex';

export default {
  mixins: [cookieMixin],

  data() {
    return {
      defaultAppLink: 'https://comboplay-apk.com',
      qrParams: {
        url: '',
        size: '108',
        level: 'H',
        renderAs: 'svg'
      }
    };
  },

  computed: {
    ...mapGetters('settings', {
      settingsValues: 'values',
    }),
    appDownloadUrl() {
      const click_id = this.getCookie('btag') || null;
      let finalLink = this.settingsValues?.web?.apk?.android?.unsigned;
      if (click_id) {
        const mask = /{click_id}/gi;
        finalLink = this.settingsValues?.web?.apk?.android?.signed;
        finalLink = finalLink.replace(mask, click_id);
      }
      this.qrParams.url = finalLink;
      return finalLink;
    }
  },
};
