<template>
  <div class="success-panel">
    <UiIcon
      class="success-panel__icon"
      :size="64"
      name="check-circle"
      lib="fa"
      substyle="fas"
      :color="pColor"
    />
  </div>
</template>

<script>
export default {
  name: 'SuccessPanel',
  props: {
    color: {
      type: String,
      default: null,
    },
  },
  computed: {
    pColor() {
      return this.color || this.$config.cp['success'];
    },
  },
};
</script>

<style lang="scss">
.success-panel {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
