<template>
  <div class="casino-categories">
    <UiTitle class="mb-lg" :back-route="null" @click="handleBack">
      {{ $t(`casino.subsection.${liveCasinoParams.i18nKey}.categories`) }}
    </UiTitle>
    <div class="casino-categories__types flex mt-rmd ml-rmd mb-lg">
      <div
        v-for="typeItem in types"
        :key="typeItem.key"
        class="w-1/3 pt-md pl-md"
      >
        <Panel size="none" @click="handleSelectType(typeItem.key)">
          <div
            class="casino-categories__wrapper flex justify-center items-center flex-col"
          >
            <UiIcon
              class="mb-md"
              :size="24"
              :name="typeItem.icon"
              lib="fa"
              substyle="fas"
              :color="typeItem.iconColor"
            />
            <div class="casino-categories__type-label">
              {{ $t(`casino.types.${typeItem.key}.label`) }}
            </div>
          </div>
        </Panel>
      </div>
    </div>

    <div class="casino-categories__categories">
      <div
        v-for="categoryItem in casinoCategories"
        :key="categoryItem.id"
        class="casino-categories__category flex justify-between items-center"
        @click="handleSelectCategory(categoryItem.id)"
      >
        <div class="flex items-center">
          <ItemLogo :is-mask="true" :link="categoryItem.image" />
          <div class="casino-categories__category-label">
            {{ categoryItem.name }}
          </div>
        </div>
        <UiIcon
          :size="12"
          name="angle-right"
          lib="fa"
          substyle="fas"
          color="var(--color-text-secondary)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import filterMixin from '@/views/Casino/mixins/filter.js';
import routerMixin from '@/helpers/router.js';

export default {
  name: 'CasinoCategories',
  mixins: [filterMixin, routerMixin],
  methods: {
    handleBack() {
      if (!this.returnTo(false)) {
        this.$router.push({
          name: `${this.liveCasinoParams.name}.games`,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.casino-categories {
  &__type-label {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
  }

  &__wrapper {
    padding: 12px 0 8px 0;
  }

  &__icon-container {
    margin-left: -8px;
    margin-right: 6px;
    width: 30px;
    display: flex;
    justify-content: center;
  }

  &__category {
    height: 44px;
    & + & {
      border-top: 1px solid var(--color-on-surface);
    }
  }

  &__category-label {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
  }
}
</style>
