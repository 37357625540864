import { mapGetters } from 'vuex';

export default {
  name: 'NewPassword',

  data() {
    return {
      isVisible: false,
      loading: false,
      isClicked: false,
      form: this.getDefaultForm(),
      errors: {},
      isValid: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['user']),
    is2FAEnabled() {
      return !!this.user?.two_factor_enabled;
    },
  },

  methods: {
    init() {
      this.form = this.getDefaultForm();
    },
    getDefaultForm() {
      const params = {
        password: '',
        new_password: '',
        new_password_confirmation: '',
      };
      if (this.is2FAEnabled) {
        params.code = '';
      }
      return params;
    },
    async changePassword() {
      this.loading = true;
      try {
        this.errors = {};
        if (!this.isClicked) {
          this.isClicked = true;
          await this.$api.putUserChangePassword(this.form);
          this.$eventBus.$emit('notify-event', {
            type: 'success',
            text: this.$t('auth.newPassword.success'),
          });
          await this.$emit('close', this);
          await this.handleComplete();
        }
      } catch (error) {
        this.errors = error?.data?.errors || {};
      } finally {
        this.isClicked = false;
        this.loading = false;
      }
    },
  },
};
