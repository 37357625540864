<template>
  <div class="profile-confirmation" v-if="profile">
    <UiButton
      v-if="!isPhoneVerified"
      substyle="fas"
      icon="phone"
      class="mt-md"
      type="secondary"
      @click="openPopupHandler('phone')"
    >
      {{ $t('buttons.confirmPhone') }}
    </UiButton>
    <UiButton
      v-if="!isEmailVerified"
      substyle="fas"
      icon="envelope"
      class="mt-md"
      type="secondary"
      @click="openPopupHandler('email')"
    >
      {{ $t('buttons.confirmEmail') }}
    </UiButton>
    <div
      v-if="comfirmationCaption"
      class="profile-confirmation__verification-hint mt-sm mb-lg text-xs"
    >
      {{ comfirmationCaption }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileConfirmation',
  computed: {
    ...mapGetters('auth', { profile: 'user' }),
    isEmailVerified() {
      return this.profile.email && this.profile.email_verified_at;
    },
    isPhoneVerified() {
      return this.profile.phone && this.profile.phone_verified_at;
    },
    comfirmationCaption() {
      if (this.isPhoneVerified && this.isEmailVerified) {
        return '';
      }

      const subject = !(this.isPhoneVerified || this.isEmailVerified)
        ? 'both'
        : this.isEmailVerified
          ? 'phone'
          : 'email';

      const notConfirmedSubject = this.$t(
        `profile.notConfirmedSubject.${subject}`
      );
      return `${notConfirmedSubject} ${this.$t('profile.notConfirmedMessage')}`;
    },
  },
  methods: {
    openPopupHandler(type) {
      this.$eventBus.$emit('open-popup', 'Confirmation', {
        data: {
          type: type,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.profile-confirmation {
  &__verification-hint {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.67;
    color: var(--color-text-secondary);
  }
}
</style>
