<template>
  <label class="ui-checkbox" :class="{ 'ui-checkbox_disabled': disabled }">
    <input
      class="ui-checkbox__item"
      type="checkbox"
      :checked="isChecked"
      :value="value"
      :name="name"
      @change="updateInput"
    />
    <span class="ui-checkbox__checkmark">
      <UiIcon
        :color="$config.cp['on-primary']"
        :size="12"
        name="check"
        lib="fa"
        substyle="fas"
      />
    </span>
    <span v-html="label" />
  </label>
</template>

<script>
export default {
  name: 'UiCheckbox',

  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default() {
        return '';
      },
    },
    modelValue: {
      type: [String, Number, Boolean],
      default() {
        return '';
      },
    },
    label: {
      type: String,
      default() {
        return '';
      },
    },
    name: {
      type: String,
      default() {
        return '';
      },
    },
    trueValue: {
      type: [Number, Boolean],
      default() {
        return true;
      },
    },
    falseValue: {
      type: [Number, Boolean],
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      return this.modelValue === this.trueValue;
    },
  },
  methods: {
    updateInput(event) {
      let isChecked = event.target.checked;
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit('change', newValue);
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins.scss';

.ui-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  line-height: 2;
  color: var(--color-text);
  user-select: none;
  @include hover {
    & &__checkmark {
      background-color: var(--color-button-hover);
    }
  }

  &__checkmark {
    height: 24px;
    width: 24px;
    min-width: 24px;
    border-radius: 8px;
    background-color: var(--color-highlight);
    margin-right: 8px;
    transition: 0.2s ease-out;

    display: flex;
    align-items: center;
    justify-content: center;

    i {
      opacity: 0;
    }
  }
  &__item {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .ui-checkbox__checkmark {
      background-color: var(--color-primary);
      i {
        opacity: 1;
      }
    }
  }
  &_disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
}
</style>
