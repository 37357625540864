<template>
  <div>
    <div class="profile-nav__wrapper">
      <UiIcon
        :size="16"
        :name="icon"
        lib="fa"
        substyle="fas"
        class="mr-md"
      />
      {{ name }}
    </div>
    <div v-if="isMobile" class="profile-nav__wrapper">
      <div class="profile-nav__item-status">
        <template v-if="type === '2fa'">
          {{
            tfaEnable ? $t('2fa.panel.enabled.title') : $t('2fa.panel.disabled.title')
          }}
        </template>
        <template v-if="type === 'wallet'">
          {{ balanceCurrency | dictionary('currencies', 'code') }}
        </template>
      </div>
      <UiIcon
        :size="12"
        :color="$config.cp['text-secondary']"
        name="angle-right"
        lib="fa"
        substyle="fas"
        class="ml-md"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileNavContent',

  props: {
    name: {
      type: String,
      default() {
        return '';
      },
    },
    icon: {
      type: String,
      default() {
        return '';
      },
    },
    type: {
      type: String,
      default() {
        return '';
      },
    },
    tfaEnable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isMobile: {
      type: Boolean,
      default() {
        return false;
      },
    },
    balanceCurrency: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
};
</script>
