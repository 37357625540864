<template>
  <div
    class="ui-select"
    :class="[
      { 'ui-select_focused': focused },
      { 'ui-select_error': currentError },
      { 'ui-select_disabled': disabled },
      { 'ui-select_no-border': !border },
      { 'ui-select_medium': type === 'medium' },
      { 'ui-select_small': type === 'small' },
      { 'ui-select_extra-small': type === 'extra-small' },
    ]"
  >
    <span v-if="label" class="ui-select__label-text ui-m-sm-b">
      {{ label }}
    </span>
    <span class="ui-select__wrapper">
      <span v-if="isDynamic" ref="shadowSpan" class="resizeable-shadow"></span>
      <el-select
        ref="elSelect"
        :value="value"
        :placeholder="pPlaceholder"
        :disabled="disabled"
        :multiple="multiple"
        :filterable="filterable"
        :filter-method="filterMethod"
        class="ui-select__element"
        :popper-class="pPopperClass"
        @input="$emit('update:value', $event)"
        @change="change"
        @focus="focus"
        @blur="blur"
        @visible-change="handleVisibleChange"
      >
        <template
          v-if="isItemIco && value !== '' && isIconsDictionary !== ''"
          slot="prefix"
        >
          <ItemLogo :link="itemLogo" />
        </template>
        <template v-if="prependIcon !== ''" slot="prefix">
          <UiIcon :size="14" :color="$config.cp.text" :name="prependIcon" />
        </template>
        <template slot="empty">
          <div class="ui-select__no-data">{{ $t('ui.noData') }}</div>
        </template>
        <el-option
          v-for="item in list"
          :key="item[optionValueParam]"
          :label="item[optionLabelParam]"
          :value="item[optionValueParam]"
        >
          <template v-if="isListItemIco && item.image">
            <template v-if="listItemIcoType === 'logo'">
              <ItemLogo :link="item.image" />
            </template>
            <template v-if="listItemIcoType === 'ico'">
              <UiIcon class="mr-sm" :size="14" :color="$config.cp.text" :name="item.image" />
            </template>
          </template>
          <span>{{ item[optionNameParam] }}</span>
        </el-option>
      </el-select>
      <div
        v-if="clearable && value"
        class="ui-select__clear-icon"
        @click="handleClear"
      >
        <UiIcon :size="16" name="times-circle" lib="fa" substyle="fas" />
      </div>
    </span>
    <UiHelp
      v-if="currentError"
      class="mt-2xs"
      :is-error="true"
      :text="currentError"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UiSelect',
  props: {
    value: {
      type: [String, Number, Array],
      default() {
        return '';
      },
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default() {
        return '';
      },
    },
    placeholder: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    filterable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    error: {
      type: [String, Number, Array],
      default() {
        return '';
      },
    },
    errorTooltip: {
      type: Boolean,
      default() {
        return false;
      },
    },
    multiple: {
      type: Boolean,
      default() {
        return false;
      },
    },
    border: {
      type: Boolean,
      default() {
        return true;
      },
    },
    type: {
      type: String,
      default() {
        return 'medium';
      },
    },
    optionNameParam: {
      type: String,
      default() {
        return 'name';
      },
    },
    optionLabelParam: {
      type: String,
      default() {
        return 'name';
      },
    },
    optionValueParam: {
      type: String,
      default() {
        return 'id';
      },
    },
    prependIcon: {
      type: String,
      default() {
        return '';
      },
    },
    isItemIco: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isListItemIco: {
      type: Boolean,
      default() {
        return false;
      },
    },
    listItemIcoType: {
      type: String,
      default() {
        return 'logo';
      },
    },
    isDynamic: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isIconsDictionary: {
      type: String,
      default() {
        return '';
      },
    },
    popperClass: {
      type: String,
      default() {
        return '';
      },
    },
    filterMethod: {
      type: Function,
    },
    compactDropdown: {
      type: Boolean,
      default() {
        return true;
      },
    },
    clearable: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      focused: false,
      currentError: null,
      currentValue: '',
    };
  },
  computed: {
    ...mapGetters('misc', ['isMobile']),
    pPlaceholder() {
      return this.placeholder !== '' ? this.placeholder : this.$t('ui.search');
    },
    itemLogo() {
      const dict = this.$store?.state?.misc[this.isIconsDictionary];
      return this.value !== '' && dict ? dict[this.value]?.image : '';
    },
    pPopperClass() {
      const classes = ['ui-select__dropdown', this.popperClass];

      if (this.isMobile) {
        classes.push('ui-select__dropdown_mob');
      }

      if (this.customSearch) {
        classes.push('ui-select__dropdown_search');
      }

      if (this.compactDropdown) {
        classes.push('ui-select__dropdown_compact-dropdown');
      }

      return classes.join(' ');

      // const defaultClasses = this.isMobile
      //   ? 'ui-select__dropdown ui-select__dropdown_mob'
      //   : 'ui-select__dropdown';
      // const customSearchClass = this.customSearch
      //   ? 'ui-select__dropdown_search'
      //   : '';
      // return `${defaultClasses} ${this.popperClass} ${customSearchClass}`;
    },
    dynamicParams() {
      return {
        value: this.value,
        list: this.list,
      };
    },
  },

  watch: {
    error(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentError = this.$_.isArray(newValue) ? newValue[0] : newValue;
      }
    },
    dynamicParams() {
      if (this.isDynamic) {
        this.adjustSize();
      }
    },
  },

  created() {
    this.currentValue = this.value;
    this.currentError = this.$_.isArray(this.error)
      ? this.error[0].message
      : this.error;

    this.$eventBus.$on('filterable-select-fix', this.onFilterableSelectFix);
  },
  beforeDestroy() {
    this.$eventBus.$off('filterable-select-fix', this.onFilterableSelectFix);
  },

  mounted() {
    this.$nextTick(() => {
      const elSelect = this.$refs.elSelect;
      const reference = elSelect.$refs.reference;
      const element = reference.$refs.input;
      if (this.isDynamic) {
        this.addShadow();
        this.adjustSize();
      }
      element.addEventListener('input', (event) => {
        if (reference.isComposing && this.filterable) {
          const compositionEvent = {
            type: 'compositionend',
            target: {
              value: event.target.value,
            },
          };
          reference.$emit('compositionend', compositionEvent);
          reference.$emit('input', event.target.value);
          this.$nextTick(reference.setNativeInputValue);
        }
      });
    });
  },

  methods: {
    getElements() {
      const input = this.$refs.elSelect.$el.querySelector('.el-input__inner');
      const span = this.$refs.shadowSpan;
      return { input, span };
    },
    addShadow(useMinWidth = false) {
      const { input, span } = this.getElements();
      if (useMinWidth) {
        span.style.minWidth = `${input.getBoundingClientRect().width}px`;
      }
    },
    adjustSize() {
      this.$nextTick(() => {
        const { input, span } = this.getElements();
        const css = window.getComputedStyle(input, null);

        span.style.font = css.font;
        span.style.padding = css.padding;
        span.style.border = css.border;
        span.textContent = input.value;
        input.style.width = `${span.getBoundingClientRect().width + 5}px`;
      });
    },

    focus() {
      this.focused = true;
      this.$emit('focus', event);
    },
    blur() {
      this.focused = false;
    },
    change(value) {
      if (this.isDynamic) {
        this.adjustSize();
      }
      this.$emit('change', value);
    },

    handleVisibleChange(value) {
      if (this.filterable && !value) {
        this.$refs.elSelect.blur();
      }

      if (value) {
        this.$eventBus.$emit('filterable-select-fix', this._uid);
      }
    },
    onFilterableSelectFix(uid) {
      if (this.filterable) {
        if (uid !== this._uid) {
          this.$refs.elSelect.blur();
        }
      }
    },
    handleClear() {
      this.$emit('update:value', '');
      this.$emit('clear');
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';
.resizeable-shadow {
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  left: -99999px;
  top: -99999px;
}
.ui-select {
  display: flex;
  flex-direction: column;
  &__dropdown {
    margin: 0;
    border-radius: 8px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
    background-color: var(--color-on-surface);
    border: 1px solid var(--color-on-surface);
    &_compact-dropdown {
      min-width: 150px !important;
    }
    &_mob {
      width: calc(100% - 32px);
    }
    &_timezone {
      margin-left: -12px;
    }
    .el-popper {
      margin-top: 8px;
    }
    .popper__arrow {
      display: none;
    }
    .el-select-dropdown {
      &__list {
        padding: 8px;
        margin: 0;
      }
      &__item {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        padding: 8px;
        height: auto;
        border-radius: 8px;
        margin-bottom: 4px;
        &:last-child {
          margin-bottom: 0;
        }
        color: var(--color-text);
        &.hover,
        &:hover {
          background-color: var(--color-selected);
        }
        &.selected {
          background-color: var(--color-primary);
          color: var(--color-on-primary);
        }
      }
    }
    &.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
      background-color: var(--color-main10);
      color: var(--color-dark1);
    }
  }
  &__label-text {
    font-size: 12px;
    font-weight: 600;
    line-height: 2.67;
    color: var(--color-text);
  }
  &__wrapper {
    display: inline-flex;
    align-items: center;
    border: 1px solid var(--color-on-surface);
    background-color: var(--color-on-surface);
    transition: all 0.2s;
    border-radius: 8px;
    position: relative;

    .el-input__inner {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.33;
      padding-left: 16px;
      color: var(--color-text);
      border: none;
      border-radius: 8px;
      background-color: transparent;
    }
    .el-input--prefix {
      .el-input__inner {
        padding-left: 34px;
      }
    }
    .el-input__prefix {
      left: 12px;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
      }
    }
    .el-input.is-disabled .el-input__inner {
      background-color: transparent;
      border-color: transparent;
    }
  }
  &__element {
    display: flex;
    align-items: center;
    height: 100%;
    min-width: 10%;
    max-width: 100%;
    flex: 10 1 auto;
    border: none;
    outline: none;
    padding: 0;
    background: transparent;

    .el-icon-arrow-up {
      font-weight: 600;
      font-family: 'Font Awesome 6 Pro' !important;
      font-size: 12px;
      line-height: 1.33;
      color: var(--color-text) !important;
      &:before {
        content: '\f077';
      }
    }

    .el-input__inner {
      border: none;
      border-radius: 8px;
      background-color: transparent;
    }

    .el-tag {
      border-radius: 8px;
      border: solid 1px var(--color-dark4);
      background-color: var(--color-dark5);
      color: var(--color-dark1);
      padding: 4px 8px;
      font-weight: 500;

      &__close {
        right: 0;
        margin-left: 4px;
        color: var(--color-text) !important;
        font-family: 'Font Awesome 6 Pro' !important;
        font-weight: 900;
        font-size: 18px;
        background-color: transparent !important;
        border: none;
        width: auto;
        height: auto;
        line-height: 19px;
        border-radius: 0;
        &:before {
          content: '\f057';
        }
      }
    }
  }
  &_medium {
    .el-input__inner {
      height: 38px;
    }
  }
  &_small {
    .el-input__inner {
      height: 34px;
    }
  }
  &_extra-small {
    .el-input__inner {
      height: 30px;
    }
  }
  &_focused & {
    &__wrapper {
      border-color: var(--color-primary);
    }
  }
  &_error & {
    &__wrapper {
      border-color: var(--color-error);
      // background-color: var(--color-danger10);
    }
  }
  &_disabled & {
    &__wrapper {
      opacity: 0.5;
      .el-select .el-input .el-select__caret {
        cursor: not-allowed;
      }
      .el-input.is-disabled .el-input__inner {
        color: var(--color-text);
      }
    }
  }
  &_no-border & {
    &__wrapper {
      background-color: var(--color-on-surface);
      border-color: var(--color-on-surface);
    }
  }
  &__no-data {
    padding: 8px;
    margin: 0;
    text-align: center;
    font-weight: 600;
    color: var(--color-text);
    font-size: 12px;
  }
  &__clear-icon {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 29px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: $screen-mobile) {
  .ui-select {
    &__dropdown {
      .el-select-dropdown {
        &__wrap {
          max-height: 40vh;
        }
      }
    }
  }
}
</style>
