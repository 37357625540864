<template>
  <UiInput
    :value="value"
    :label="label"
    :type="inputType"
    :placeholder="placeholder"
    :name="name"
    :error="error"
    :disabled="isLocked"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'SignupPanelInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    error: {
      type: Array,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      default: '',
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    formMeta: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
