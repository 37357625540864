import {
  TIME_RANGE_FILTER,
  TIME_RANGE_FILTER_TOP,
} from '@/store/action_types/market';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('market', ['timeRangeFilter', 'timeRangeFilterTop']),
    pTimeRangeFilter: {
      get() {
        const timeRangeFilter = this.isTop
          ? this.timeRangeFilterTop
          : this.timeRangeFilter;
        return timeRangeFilter !== -1 && this.source === 'line'
          ? timeRangeFilter
          : '';
      },
      set(value) {
        if (!value) {
          value = -1;
        }

        const key = this.isTop ? TIME_RANGE_FILTER_TOP : TIME_RANGE_FILTER;
        this.$store.dispatch(`market/${key}`, value);
      },
    },
    selectList() {
      return [
        // {
        //   value: -1,
        //   label: this.$t('popups.timeRangeFilter.selectList.all'),
        // },
        {
          value: 3600,
          label: this.$t('popups.timeRangeFilter.selectList.hour1'),
        },
        {
          value: 10800,
          label: this.$t('popups.timeRangeFilter.selectList.hour3'),
        },
        {
          value: 43200,
          label: this.$t('popups.timeRangeFilter.selectList.hour12'),
        },
        {
          value: 86400,
          label: this.$t('popups.timeRangeFilter.selectList.day1'),
        },
        {
          value: 172800,
          label: this.$t('popups.timeRangeFilter.selectList.day2'),
        },
      ];
    },
  },
};
