const palettes = {
  //main colors
  primary: '#ff6036',

  //fones
  background: '#121212',
  surface: '#2a313a',
  'on-surface': '#48525f',
  highlight: '#6a7f96',

  //text and icons
  text: '#ffffff',
  'text-secondary': '#acbed2',
  'text-disable': '#ffffff80',
  'text-error': '#ff4949',
  'text-success': '#55db22',
  'text-alert': '#ffb741',
  link: '#ff6036',
  'link-hover': '#ff3c0c',
  'on-primary': '#ffffff',
  selected: '#ffffff33',
  'selected-code': '#7f868f',
  'selected-row': '#23262A',
  'button-hover': '#ffffff33',

  //states
  error: '#d60000',
  success: '#59b835',
  alert: '#d18200',
  blue: '#3b62c9',
};

const palettesAlpha = {
  // main10: palettes.main + '1a',
  // danger10: palettes.danger + '1a',
  // success10: palettes.success + '1a',
};

module.exports = {
  logo: require('../assets/Logo@Dark.svg'),
  disabled2FA: require('../assets/Disabled2FA@Dark.svg'),
  enabled2FA: require('../assets/Enabled2FA@Dark.svg'),
  palettes: {
    ...palettes,
    ...palettesAlpha,
  },
};
