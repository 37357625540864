export default () => {
  const isMobile = localStorage.getItem('is-mobile') === 'true';
  return isMobile
    ? import(
      /* webpackChunkName: "mobile" */ '@/components/TheFooter/TheFooter.mobile.vue'
      )
    : import(
      /* webpackChunkName: "desktop" */ '@/components/TheFooter/TheFooter.desktop.vue'
      );
};
