<template>
  <transition :name="isMobile ? 'nested' : 'fade'" appear>
    <div v-if="isVisible" class="popup">
      <div class="popup__overlay" @click.self="handleClickOutside">
        <div class="popup__window" :class="[`popup__window_${size}`]">
          <Panel
            class="popup__panel"
            v-loading="isLoadingPopup"
            :size="isMobile ? 'medium' : 'big'"
          >
            <UiButton
              v-if="['enabled', 'disabled'].includes(closeStatus)"
              class="popup__close"
              substyle="fas"
              type="icon"
              size="small"
              :disabled="closeStatus === 'disabled'"
              @click="close"
            >
              <UiIcon :size="16" name="times" lib="fa" substyle="fas" />
            </UiButton>
            <UiIcon
              v-if="popupLogo"
              :size="64"
              :color="$config.cp['success']"
              name="check-circle"
              lib="fa"
              substyle="fas"
              class="mb-lg"
            />
            <template v-if="isTitle">
              <SkeletonComponent v-if="title === ''">
                <Skeleton width="50px" height="32px" />
              </SkeletonComponent>
              <div v-else class="popup__title" v-html="title"></div>
            </template>
            <TabsButtons
              v-if="isTabs"
              class="popup__tabs"
              type="tabs"
              :current-tab.sync="pCurrentTab"
              :items="tabsItems"
              :disabled="tabsDisabled"
            />
            <div class="popup__body">
              <slot name="body"></slot>
            </div>
            <div v-if="isFooter" class="popup__footer mt-lg">
              <slot name="footer"></slot>
            </div>
          </Panel>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Popup',

  props: {
    isVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isLoadingPopup: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isTabs: {
      type: Boolean,
      default() {
        return false;
      },
    },
    tabsDisabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isTitle: {
      type: Boolean,
      default() {
        return true;
      },
    },
    size: {
      type: String,
      default() {
        return 'default';
      },
    },
    title: {
      type: String,
      default() {
        return '';
      },
    },
    currentTab: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    tabsItems: {
      type: Array,
      default() {
        return [];
      },
    },
    clickOutside: {
      type: Boolean,
      default: false,
    },
    closeStatus: {
      type: String,
      default: 'enabled',
    },
    popupLogo: {
      type: Boolean,
      default: false,
    },
    isFooter: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters('misc', ['isMobile']),
    pCurrentTab: {
      get() {
        return this.currentTab;
      },
      set(newVal) {
        this.$emit('update:currentTab', newVal);
      },
    },
  },

  methods: {
    handleClickOutside() {
      if (this.clickOutside) {
        this.close();
      }
    },
    close() {
      this.$emit('update:isVisible', false);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';

.popup {
  position: relative;
  z-index: 900;
  .el-scrollbar {
    &__wrap {
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
  .el-scrollbar__bar.is-horizontal {
    display: none;
  }
  &__panel {
    .layout-panel__content-wrapper {
      border-radius: 16px;
    }
  }
  &__tabs {
    margin: 0 -24px 12px;
    padding: 0 16px 12px;
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    align-items: center;
  }
  &__window {
    //top: 10vh;
    margin: 0 auto;
    position: relative;
    &_default {
      width: 400px;
    }
    &_medium {
      width: 782px;
    }
  }
  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.6;
    color: var(--color-text);
    margin-bottom: 16px;
    padding-right: 40px;
  }
  &__body {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--color-text);
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      color: var(--color-text);
    }
  }
  &__footer {
    &:empty {
      padding: 0 24px 24px;
    }
  }
  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
    transition: all 0.2s ease-out 0s;
  }
}

@media (max-width: $screen-mobile) {
  .popup {
    &__close {
      top: 16px;
      right: 16px;
    }
    &__window {
      top: auto;
      bottom: 0;
      position: absolute;
      border-radius: 8px 8px 0 0;
      .layout-panel__content {
        padding-bottom: 32px !important;
      }

      &_default,
      &_medium {
        width: 100%;
      }
    }
    .layout-panel__content-wrapper {
      border-radius: 8px 8px 0 0;
    }
  }
}
</style>
