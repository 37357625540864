<template>
  <div>
    <transition
      name="promocode"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div class="promocode__content" v-show="promocodeIsOpen">
        <UiInput
          :value="value"
          :label="label"
          :placeholder="placeholder"
          type="text"
          :error="error"
          :disabled="isLocked"
          @input="$emit('input', $event)"
        />
      </div>
    </transition>
    <div
      v-if="!isLocked"
      class="add-promocode"
      :class="{ 'add-promocode_hide': promocodeIsOpen }"
      @click="addPromocode"
    >
      <UiIcon :size="12" name="plus" lib="fa" substyle="fas" />
      {{ $t('auth.signUp.addPromocode') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignupPanelPromocode',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    formMeta: {
      type: Object,
      default: () => ({}),
    },
    error: {
      type: Array,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      promocodeIsOpen: false,
    };
  },
  methods: {
    addPromocode() {
      this.promocodeIsOpen = true;
    },
    start(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    end(el) {
      el.style.height = '';
    },
  },
};
</script>
