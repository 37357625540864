<template>
  <Popup
    class="filter__popup"
    :title="$t('popups.betFilter.title')"
    :is-visible.sync="isVisible"
    @close="close"
  >
    <el-scrollbar slot="body">
      <div class="filter__popup-title">
        {{ $t('popups.betFilter.events.title') }}
      </div>
      <TabsButtons
        :current-tab.sync="pFilters.feed_type"
        :items="eventsItems"
      />
      <div class="filter__popup-title">
        {{ $t('popups.betFilter.betType.title') }}
      </div>
      <TabsButtons :current-tab.sync="pFilters.bet_type" :items="typeList" />
      <div class="filter__popup-title">
        {{ $t('popups.betFilter.status.title') }}
      </div>
      <div class="filter__popup-statuses">
        <UiButton
          v-for="(button, id) in statusList"
          :key="id"
          substyle="fas"
          type="secondary"
          :is-active="pFilters.status === button.value"
          class="mr-sm"
          @click="changeStatus(button.value)"
        >
          {{ button.name }}
        </UiButton>
      </div>
    </el-scrollbar>
    <div class="flex space-x-lg" slot="footer">
      <UiButton
        class="w-1/2"
        substyle="fas"
        icon="times-circle"
        type="secondary"
        @click="reset"
      >
        {{ $t('buttons.reset') }}
      </UiButton>
      <UiButton class="w-1/2" substyle="fas" icon="check" @click="filter">
        {{ $t('buttons.confirm') }}
      </UiButton>
    </div>
  </Popup>
</template>

<script>
import betMixin from '@/helpers/betMixin.js';

export default {
  name: 'BetFilter',

  mixins: [betMixin],

  props: {
    filters: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      isVisible: false,
      pFilters: this.getFilters(),
    };
  },

  computed: {
    eventsItems() {
      return [
        {
          label: 'all',
          name: this.$t('profile.bets.events.all'),
        },
        {
          label: 'line',
          name: this.$t('profile.bets.events.line'),
        },
        {
          label: 'live',
          name: this.$t('profile.bets.events.live'),
        },
      ];
    },
  },

  watch: {
    isVisible(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },

  methods: {
    open() {
      this.isVisible = true;
      const filters = this.filters
        ? this.$_.cloneDeep(this.filters)
        : this.getFilters();
      this.pFilters = filters;
    },
    filter() {
      this.$eventBus.$emit('filter-confirm', this.pFilters);
      this.isVisible = false;
    },
    changeStatus(value) {
      this.pFilters.status = value;
    },
    close() {
      this.isVisible = false;
    },
    reset() {
      this.close();
      this.pFilters = this.getFilters();
      this.$eventBus.$emit('filter-confirm', this.pFilters);
    },
    getFilters() {
      return {
        feed_type: 'all',
        bet_type: '',
        status: '',
      };
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables';

.filter__popup {
  &-title {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
  }
  &-types,
  &-events {
    display: flex;
    margin-bottom: 16px;
  }
  &-statuses {
    display: flex;
    margin-bottom: 20px;
  }
  .tabs-buttons {
    background-color: var(--color-on-surface);
  }
}
@media (max-width: $screen-mobile) {
  .filter__popup .el-scrollbar__wrap {
    max-height: calc((var(--vh) * 100px) - 200px);
  }
}
</style>
