<template>
  <SkeletonComponent>
    <div class="mb-md">
      <Skeleton width="200px" height="20px" />
    </div>
    <div class="terms-skeleton__panel">
      <div class="mb-md">
        <Skeleton width="200px" height="20px" />
      </div>
      <Skeleton width="100%" height="12px" />
      <Skeleton width="100%" height="12px" />
      <Skeleton width="100%" height="12px" />
      <div class="mb-md mt-lg">
        <Skeleton width="200px" height="20px" />
      </div>
      <Skeleton width="100%" height="12px" />
      <Skeleton width="100%" height="12px" />
      <Skeleton width="100%" height="12px" />
    </div>
  </SkeletonComponent>
</template>

<script>
export default {
  name: 'TermsSkeleton',
};
</script>

<style scoped lang="scss">
.terms-skeleton {
  &__panel {
    padding: 12px 16px;
    background-color: var(--color-surface);
    width: 100%;
    border-radius: 8px;
  }
}
</style>
