import store from '@/store';

import numeral from 'numeral';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { parsePhoneNumber } from 'libphonenumber-js';

export default (App) => {
  App.prototype.$dayjs.extend(utc);
  App.prototype.$dayjs.extend(timezone);

  App.filter('time', (value) =>
    App.prototype
      .$dayjs(value)
      .tz(store.getters['settings/currentTimezone'])
      .format('HH:mm')
  );
  App.filter('date', (value) =>
    App.prototype
      .$dayjs(value)
      .tz(store.getters['settings/currentTimezone'])
      .format('D MMM')
  );
  App.filter('dateResult', (value) =>
    App.prototype
      .$dayjs(value)
      .tz(store.getters['settings/currentTimezone'])
      .format('DD.MM.YYYY')
  );
  App.filter('dateNOFormat', (value) =>
    App.prototype.$dayjs(value).tz(store.getters['settings/currentTimezone'])
  );
  App.filter('dateJSFormat', (value) =>
    value.format('YYYY-MM-DD')
  );
  App.filter('timeNoTZ', (value) =>
    App.prototype.$dayjs(value).format('HH:mm')
  );
  App.filter('timer', (value) =>
    App.prototype.$dayjs(value).format('HH:mm:ss')
  );
  App.filter('formatDate', (value) =>
    App.prototype.$dayjs(value).format('DD MMM YYYY')
  );
  App.filter('formatDateString', (value) =>
    App.prototype.$dayjs(value).tz(store.getters['settings/currentTimezone']).format('YYYY-MM-DD')
  );
  App.filter('formatDateTime', (value) =>
    App.prototype
      .$dayjs(value)
      .tz(store.getters['settings/currentTimezone'])
      .format('DD MMM YYYY HH:mm')
  );

  App.filter('formatMoney', (value) => numeral(value).format('0,0.[000]'));
  App.filter('formatMoneyForBet', (value) => numeral(value).format('0,0.[00]'));
  App.filter('formatCoef', (value) => numeral(value).format('0.[000]', Math.floor));
  App.filter('formatMoneyNoDot', (value) => numeral(value).format('0,0'));
  App.filter('formatMoneyNoDotNoRound', (value) =>
    numeral(value).format('0,0', Math.floor)
  );

  App.filter(
    'formatPercent',
    (value) => Math.abs(numeral(value).format('0.0')) + '%'
  );

  App.filter('dictionary', (value, dictionary, field = 'name') => {
    const index = store.getters[`misc/${dictionary}Index`];
    return index && index[value] ? index[value][field] : '';
  });

  App.filter('phoneHidden', (value, short = false) => {
    if (!value || value.lenght === 0) {
      return '';
    }

    const phoneBase = value[0] !== '+' ? `+${value}` : value;
    const phoneNumber = parsePhoneNumber(phoneBase);
    const phone = phoneNumber.formatInternational();
    const match = phone.match(/(\d\s?){4}$/i);
    if (match) {
      const index = match.index;
      const notHidden = phone.slice(index);
      const hidden = short
        ? '** '
        : phone.slice(0, index).replace(/\d/gi, '*').replace(/\+/gi, '');
      return `${hidden}${notHidden}`;
    }

    return '';
  });
};
