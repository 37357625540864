<template>
  <div class="search" :class="{ 'px-lg': !isMobile }">
    <UiTitle :back-route="isMobile ? null : ''" @click="handleBack">
      {{ $t('search.title') }}
    </UiTitle>
    <UiInput
      v-model.trim="search"
      class="mb-2xl"
      name="search"
      autocomplete="off"
      is-search
      :placeholder="$t('ui.search')"
      :min-search-length="searchLength"
      @clear="clearList"
      @search="searchEvents"
    />
    <template v-if="isLoading">
      <SearchSkeleton />
    </template>
    <div v-if="(emptyInput || emptyList) && !isLoading" class="search__empty">
      <UiIcon
        :size="32"
        :color="$config.cp['text-secondary']"
        name="magnifying-glass"
        lib="fa"
        substyle="fas"
        class="mb-lg"
      />
      <div class="search__empty-text">
        <template v-if="emptyInput && !error">
          {{ $t('search.emptyInput') }}
        </template>
        <template v-if="emptyList && !error">
          {{ $t('search.emptyList') }}
        </template>
        <template v-if="error">
          {{ $t('search.error') }}
        </template>
      </div>
    </div>
    <template v-if="!emptyInput && !emptyList && !isLoading">
      <div v-if="matchListLive.length > 0" class="search__events-wrapper">
        <div class="search__title" :class="{ 'search__title_mobile': isMobile }">
          {{ $t('search.live') }}
        </div>
        <SearchLine
          v-for="event of matchListLive"
          :key="event.id"
          :data="event"
        />
      </div>
      <div v-if="matchListLine.length > 0" class="search__events-wrapper">
        <div class="search__title" :class="{ 'search__title_mobile': isMobile }">
          {{ $t('search.line') }}
        </div>
        <SearchLine
          v-for="event of matchListLine"
          :key="event.id"
          :data="event"
        />
      </div>
    </template>
  </div>
</template>

<script>
import SearchLine from '@/views/Search/components/SearchLine/selector';
import SearchSkeleton from '@/views/Search/components/SearchSkeleton/selector';
import { mapGetters } from 'vuex';
import { SET_VALUE } from '@/store/action_types/search';

export default {
  name: 'Search',

  components: {
    SearchLine,
    SearchSkeleton,
  },

  data() {
    return {
      searchLength: 3,
      error: false,
      isLoadingRequest: true,
      matchListLine: null,
      matchListLive: null,
    };
  },

  computed: {
    ...mapGetters('misc', ['isMobile']),
    ...mapGetters('search', ['storeSearch']),
    isLoading() {
      return this.isLoadingRequest && this.search !== '';
    },
    search: {
      get() {
        return this.storeSearch || '';
      },
      set(value) {
        this.$store.commit(`search/${SET_VALUE}`, value);
      },
    },
    emptyInput() {
      return this.matchListLine === null && this.matchListLive === null;
    },
    emptyList() {
      return (
        !this.emptyInput &&
        this.matchListLine.length === 0 &&
        this.matchListLive.length === 0
      );
    },
    isClear() {
      return !!this.$route?.query?.clear;
    },
  },

  created() {
    if (this.isClear) {
      this.clearList();
      this.$router.replace({ query: {} });
    }
  },

  methods: {
    async searchEvents() {
      this.isLoadingRequest = true;
      this.error = false;
      const params = {
        text: this.search,
        limit: 50,
      };
      try {
        const response = await this.$api.postSearch(params);
        this.matchListLine = response.data.payload.line;
        this.matchListLive = response.data.payload.live;
      } catch (error) {
        this.error = true;
        this.$eventBus.$emit('notify-event', {
          type: 'error',
          text: error.data.message,
        });
      } finally {
        this.isLoadingRequest = false;
      }
    },
    clearList() {
      this.error = false;
      this.matchListLine = null;
      this.matchListLive = null;
      this.$store.commit(`search/${SET_VALUE}`, '');
    },
    handleBack() {
      if (this.isMobile) {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss">
.search {
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-text);
    padding: 11px 16px;
    border-radius: 8px;
    background-color: var(--color-surface);
    &_mobile {
      font-size: 16px;
      line-height: 1.5;
      background-color: transparent;
      padding: 0;
      margin-bottom: 8px;
    }
  }
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 465px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.67;
    color: var(--color-text-secondary);
    &-text {
      text-align: center;
    }
  }
}
</style>
