<template>
  <div class="slider">
    <VueSlickCarousel
      v-if="isSlider"
      :arrows="true"
      :dots="true"
      :swipeToSlide="true"
      :draggable="true"
      :autoplay="true"
      :speed="1000"
      :autoplaySpeed="8000"
      @reInit="$emit('reInit', $event)"
    >
      <SliderItem
        v-for="banner in banners"
        :key="banner.id"
        :item="banner"
        :max-height="maxHeight"
        @click="$emit('click', $event)"
      />
      <template #prevArrow>
        <div class="custom-arrow">
          <UiIcon
            :size="16"
            :color="$config.cp['on-primary']"
            name="chevron-left"
            lib="fa"
            substyle="fas"
            class="mx-2xs"
          />
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-arrow">
          <UiIcon
            :size="16"
            :color="$config.cp['on-primary']"
            name="chevron-right"
            lib="fa"
            substyle="fas"
          />
        </div>
      </template>
      <template #customPaging>
        <div class="custom-dot"></div>
      </template>
    </VueSlickCarousel>
    <SliderItem v-else :item="banners[0]" @click="$emit('click', $event)" />
  </div>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import VueSlickCarousel from 'vue-slick-carousel';
import SliderItem from '@/components/Slider/components/SliderItem';

export default {
  name: 'Slider',
  components: {
    VueSlickCarousel,
    SliderItem,
  },
  props: {
    banners: {
      type: Array,
      default: () => [],
    },
    maxHeight: {
      type: String,
      default: null,
    },
  },
  computed: {
    isSlider() {
      return this.banners.length > 1;
    },
  },

  mounted() {
    this.$emit('reInit');
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/variables';

.slider {
  border-radius: 16px;
  overflow: hidden;
}
.slick {
  &-slider {
    overflow: hidden;
    border-radius: 16px;
    transform: translateZ(0);
    aspect-ratio: 15/4;
    @include hover {
      .custom-arrow {
        transition: 0.2s opacity ease-out;
        opacity: 1;
      }
    }
  }
  &-list {
    line-height: 0;
  }
  &-dots {
    bottom: 0;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    li {
      width: 8px;
      height: 8px;
      margin: 0 4px;
    }
  }
  &-next {
    right: 0;
    &:before {
      content: none;
    }
    &:hover {
      background-color: var(--color-button-hover);
    }
  }
  &-prev {
    left: 0;
    &:before {
      content: none;
    }
    &:hover {
      background-color: var(--color-button-hover);
    }
  }
  &-active {
    .custom-dot {
      background-color: var(--color-primary) !important;
    }
  }
}
.custom {
  &-arrow {
    transition: 0.2s opacity ease-out;
    z-index: 1;
    width: 40px;
    height: 100%;
    background-color: var(--color-button-hover);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
  }
  &-dot {
    width: 8px;
    height: 8px;
    background-color: var(--color-button-hover);
    border-radius: 50%;
  }
}

@media (max-width: $screen-mobile) {
  .custom-arrow {
    display: none;
  }
}
</style>
