export default {
  getArticles(params) {
    return this.baseApi.get('/offers/articles', { params });
  },
  getCategories() {
    return this.baseApi.get('/offers/categories');
  },
  getBanners() {
    return this.baseApi.get('/offers/tags');
  },
};
