import UiButton from '@/components/ui/UiButton.vue';
import UiInput from '@/components/ui/UiInput.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiSeparator from '@/components/ui/UiSeparator.vue';
import UiLogo from '@/components/ui/UiLogo.vue';
import UiChevron from '@/components/ui/UiChevron.vue';
import UiTitle from '@/components/ui/UiTitle.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiHelp from '@/components/ui/UiHelp.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiDatepicker from '@/components/ui/UiDatepicker.vue';
import UiCopyRow from '@/components/ui/UiCopyRow.vue';
import UiInfiniteScroll from '@/components/ui/UiInfiniteScroll.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiTapable from '@/components/ui/UiTapable.vue';
import UiRouterTabs from '@/components/ui/UiRouterTabs.vue';

import Tech from '@/components/Tech.vue';
import Slider from '@/components/Slider/Slider.vue';
import ItemLogo from '@/components/ItemLogo.vue';
import LiveBadge from '@/components/LiveBadge.vue';
import TeamLogo from '@/components/TeamLogo.vue';
import Status from '@/components/Status.vue';

import { Skeleton } from 'vue-loading-skeleton';
import SkeletonComponent from '@/components/SkeletonComponent.vue';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import TabsButtons from '@/components/TabsButtons';

import Spinner from '@/components/loaders/components/Spinner';
import CuracaoVerification from '@/components/license/CuracaoVerification.vue';

// import EventCard from '@/views/Market/components/EventCard';
import EventGroup from '@/views/Market/components/GroupedEventList/EventGroup';
import MatchList from '@/views/Market/components/GroupedEventList/MatchList/selector.js';
import EventLine from '@/views/Market/components/GroupedEventList/EventLine/selector.js';
import EventCoef from '@/views/Market/components/GroupedEventList/EventCoef';

import Popup from '@/components/Popup';
import Confirm from '@/components/popups/Confirm';

export default (Vue) => {
  Vue.component('UiButton', UiButton);
  Vue.component('UiInput', UiInput);
  Vue.component('UiIcon', UiIcon);
  Vue.component('UiSeparator', UiSeparator);
  Vue.component('UiLogo', UiLogo);
  Vue.component('UiChevron', UiChevron);
  Vue.component('UiTitle', UiTitle);
  Vue.component('UiCheckbox', UiCheckbox);
  Vue.component('UiHelp', UiHelp);
  Vue.component('UiSelect', UiSelect);
  Vue.component('UiDatepicker', UiDatepicker);
  Vue.component('UiCopyRow', UiCopyRow);
  Vue.component('UiInfiniteScroll', UiInfiniteScroll);
  Vue.component('UiDropdown', UiDropdown);
  Vue.component('UiTapable', UiTapable);
  Vue.component('UiRouterTabs', UiRouterTabs);
  
  Vue.component('Tech', Tech);
  Vue.component('Slider', Slider);
  Vue.component('ItemLogo', ItemLogo);
  Vue.component('LiveBadge', LiveBadge);
  Vue.component('TeamLogo', TeamLogo);
  Vue.component('Status', Status);

  Vue.component('Skeleton', Skeleton);
  Vue.component('SkeletonComponent', SkeletonComponent);

  Vue.component('Breadcrumbs', Breadcrumbs);
  Vue.component('TabsButtons', TabsButtons);

  Vue.component('Spinner', Spinner);
  Vue.component('CuracaoVerification', CuracaoVerification);

  // Vue.component('EventCard', EventCard);
  Vue.component('EventGroup', EventGroup);
  Vue.component('MatchList', MatchList);
  Vue.component('EventLine', EventLine);
  Vue.component('EventCoef', EventCoef);
  Vue.component('Popup', Popup);
  Vue.component('Confirm', Confirm);
};
