import liveCasinoMixin from '@/views/Casino/mixins/liveCasino.js';

export default {
  mixins: [liveCasinoMixin],
  computed: {
    types() {
      return [
        {
          key: 'all',
          icon: 'list',
          iconColor: 'var(--color-text-secondary)',
        },
        {
          key: 'top',
          icon: 'fire',
          iconColor: 'var(--color-primary)',
        },
        {
          key: 'new',
          icon: 'sparkles',
          iconColor: 'var(--color-success)',
        },
      ];
    },
    // typesKeys() {
    //   return this.types.map((item) => item.key);
    // },
    type() {
      const routeCondition =
        (this.$route.meta.view === 'casino' && !this.$route.meta.live) ||
        (this.$route.meta.view === 'liveCasino' && this.$route.meta.live);

      const defaultValue =
        routeCondition && !this.isProviders && !this.category ? 'all' : '';
      return this.$route.query.type || defaultValue;
    },
    category() {
      return this.$route.query.category || '';
    },
    isProviders() {
      const isProvidersMeta = !!this.$route.meta.providers;
      const isProvidersQuery = !!(this.$route.query.provider || '');

      return isProvidersMeta || isProvidersQuery;
    },
  },
  methods: {
    isTypeSelected(type) {
      if (type === 'all') {
        return !this.type || this.type === 'all';
      }

      return this.type === type;
    },
    applyFilters(query = {}, path = this.$router.path) {
      this.$router.push({
        path,
        query,
      });
    },
    handleSelectType(type) {
      //this.activeType = type;
      this.applyFilters(
        {
          type,
        },
        `/${this.liveCasinoParams.path}/games`
      );
    },
    handleSelectCategory(category) {
      this.applyFilters(
        {
          category,
        },
        `/${this.liveCasinoParams.path}/games`
      );
    },
  },
};
